import { useEffect, useCallback } from 'react';

export default function usePostMessage({ origin, iframeId, allowedEvents }) {
    let promiseResolve, promiseReject;

    const postMessage = data => {
        const iframeWin = document.getElementById(iframeId);
        iframeWin.contentWindow.postMessage(data, origin);
    };

    const getMessageResponse = useCallback(
        message => {
            return new Promise(function (resolve, reject) {
                promiseResolve = resolve;
                promiseReject = reject;
                postMessage(message);
            });
        },
        [promiseResolve, promiseReject],
    );
    const messageEventHandler = ({ origin: eventOrigin, type, data }) => {
        if (
            eventOrigin === origin &&
            type === 'message' &&
            allowedEvents.includes(data.Name)
        ) {
            if (data && typeof promiseResolve === 'function') {
                promiseResolve(data);
            } else {
                if (typeof promiseReject === 'function') {
                    promiseReject(new Error('Failed to receive response'));
                }
            }
        }
    };
    useEffect(() => {
        window.addEventListener('message', messageEventHandler);
        return () => {
            window.removeEventListener('message', messageEventHandler);
        };
    }, []);

    return {
        getMessageResponse,
    };
}
