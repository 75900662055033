function parseErrorMessage(error, prop = 'message') {
    return (
        (error &&
            error.response &&
            error.response.data &&
            error.response.data[prop]) ||
        error.message
    );
}
export default parseErrorMessage;
