import { createSlice, createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const NAME = 'sharePanel';

const sharePanel = createSlice({
    name: NAME,
    initialState: {
        donorPfl: null,
    },
    reducers: {
        setDonorPfl: (state, { payload }) => {
            state.donorPfl = payload;
        },
    },
});

export const { setDonorPfl } = sharePanel.actions;

export const selectSharePanel = state => get(state, ['ui', NAME], {});

export const selectDonorPfl = createSelector(
    selectSharePanel,
    sharePanel => sharePanel.donorPfl,
);

export default sharePanel.reducer;
