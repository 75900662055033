import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import PageSection from '../../PageSection/PageSection';
import Perk from './Perk/Perk';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
    selectPageName,
    selectSelectedCampaign,
} from '../../../features/campaign/campaignSlice';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';

const useStyles = makeStyles({
    perkSection: {
        paddingTop: 60,
        '@media (max-width: 480px)': {
            paddingTop: 16,
        },
    },
    perks: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
        gridGap: 30,
        gap: 30,
        justifyContent: 'center',
        '&.grid-3': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(275px, 1fr))',
        },
        '&.grid-4': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
        },
        '&.grid-5': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        },
        '@media (max-width: 480px)': {
            gridTemplateColumns:
                'repeat(auto-fit, minmax(135px, 1fr)) !important',
            gridGap: 25,
            gap: 25,
        },
    },
});

function Perks({ givings, isPublished, enableDonations }) {
    const classes = useStyles();
    const campaign = useSelector(selectSelectedCampaign);
    const pageName = useSelector(selectPageName);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const perks = givings.filter(perk => {
        const currency = selectPageOptions(perk, 'currency');
        const isActive = selectPageOptions(perk, 'isActive');
        if (
            campaign.payments[pageName].currencies.includes(currency) &&
            !perk.isHidden &&
            isActive
        ) {
            return perk;
        }
    });

    return (
        <PageSection className={classNames(classes.perkSection, 'perks')}>
            <div
                className={classNames(
                    classes.perks,
                    perks.length % 3 === 0 ? 'grid-3' : '',
                    perks.length % 4 === 0 ? 'grid-4' : '',
                    perks.length % 5 === 0 ? 'grid-5' : '',
                )}
            >
                {perks.map(perk => (
                    <Perk
                        {...perk}
                        key={perk.id}
                        disabled={!enableDonations || !isPublished}
                        className={classNames(
                            perks.length === 3 ? 'perk-3' : '',
                            perks.length === 4 ? 'perk-4' : '',
                            perks.length > 4 ? 'perk-5' : '',
                        )}
                    />
                ))}
            </div>
        </PageSection>
    );
}

Perks.propTypes = {
    givings: PropTypes.array,
    isPublished: PropTypes.bool,
    enableDonations: PropTypes.bool,
};

export default Perks;
