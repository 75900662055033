export const parseCampaignPagePath = () => {
    return {
        slug:
            window.location.pathname.match(/^\/([a-zA-Z0-9-_]{2,})\/?/)?.[1] ??
            null,
        donorPflSlug:
            window.location.pathname.match(
                /^\/([a-zA-Z0-9-_]{2,})\/d\/([0-9BbDdGgLlQqVvWwZz_.~:=-]+)(\/|$)/,
            )?.[2] ?? null,
        isDonate: window.location.pathname.match(/\/(donate)\/?$/)?.[1]
            ? true
            : false,
    };
};
