import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles, Tooltip } from '@material-ui/core';
import { ReactComponent as ApplePayIcon } from './ApplePayLogo.svg';
import { ReactComponent as ApplePayCheckedIcon } from './ApplePayLogoChecked.svg';
import { ReactComponent as GooglePayIcon } from './GooglePayLogo.svg';
import { ReactComponent as GooglePayCheckedIcon } from './GooglePayLogoChecked.svg';
import InfoIcon from '@material-ui/icons/WarningOutlined';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 55,
        height: 19,
        '@media (max-width: 600px)': {
            width: 50,
        },
    },
}));

function GoogleApplePayLogo(props) {
    const { isChecked, isDisabled, paymentMethod } = props;
    const { formatMessage } = useIntl();
    const classes = useStyles();

    const SvgLogoComponent = svgProps => {
        return (
            <SvgIcon
                {...props}
                component={svgProps.component}
                viewBox={svgProps.viewBox}
                className={classes.svg}
            ></SvgIcon>
        );
    };
    return (
        <div className={classes.root}>
            {paymentMethod === 'applepay' && (
                <SvgLogoComponent
                    component={isChecked ? ApplePayCheckedIcon : ApplePayIcon}
                    viewBox="0 0 47.196 20.021"
                />
            )}
            {paymentMethod === 'googlepay' && (
                <SvgLogoComponent
                    component={isChecked ? GooglePayCheckedIcon : GooglePayIcon}
                    viewBox="0 0 55.34 21.713"
                />
            )}
            {isDisabled && (
                <Tooltip
                    title={formatMessage({
                        id: 'DonationForm.googlePayWarning',
                        defaultMessage:
                            'You do not have a supported browser/saved payment method!',
                    })}
                    arrow
                    enterTouchDelay={0}
                >
                    <InfoIcon />
                </Tooltip>
            )}
        </div>
    );
}

GoogleApplePayLogo.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    paymentMethod: PropTypes.string,
};

export default GoogleApplePayLogo;
