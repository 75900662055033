import Repository from 'client/services/api/Repository';

const repository = new Repository({ baseUrl: '/stripe' });

repository.createApplePayDomain = async function (accountId, domainNames) {
    return this.axios
        .post(`/apple-pay-domain/${accountId}`, { domainNames })
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.initiateStripeConnect = async function (campaignData) {
    return this.axios
        .post(`/connect/initiate`, campaignData)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.resumeStripeConnect = async function ({
    financialIdentifier,
    accountId,
    redirectToUrl = false,
}) {
    return this.axios
        .get(
            `/connect/refresh/${encodeURIComponent(
                financialIdentifier,
            )}/${accountId}/${redirectToUrl}`,
        )
        .then(this.handleResponse)
        .catch(this.handleError);
};

export default repository;
