import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ReactComponent as ArrowIcon } from './ArrowIcon.svg';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        display: 'flex',
        width: 22,
        height: 22,
        border: `1px solid ${palette.primary.main}`,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 999,
    },
}));

function ArrowCircleIcon(props) {
    const { className } = props;
    const classes = useStyles();
    const { palette } = useTheme();

    return (
        <div className={classNames(classes.root, className)}>
            <ArrowIcon stroke={palette.primary.main} />
        </div>
    );
}

ArrowCircleIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowCircleIcon;
