import Repository from './Repository';

const repository = new Repository({ baseUrl: '/donate-page-abandonments' });

repository.getDonatePageAbandonments = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);
    return this.axios
        .get(queryString ? `?${queryString}` : '')
        .then(this.handleResponse)
        .catch(this.handleError);
};

export default repository;
