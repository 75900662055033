import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const removeEndPadding = ({ ignoreDir }) => ({
    'html:not([dir=rtl]) &': {
        paddingRight: 0,
    },
    'html[dir=rtl] &': ignoreDir ? { paddingRight: 0 } : { paddingLeft: 0 },
});

const removeStartPadding = ({ ignoreDir }) => ({
    'html:not([dir=rtl]) &': {
        paddingLeft: 0,
    },
    'html[dir=rtl] &': ignoreDir ? { paddingLeft: 0 } : { paddingRight: 0 },
});

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        direction: ({ ignoreDir }) => (ignoreDir ? 'ltr' : 'inherit'),
    },
    prefix: props => ({
        ...removeEndPadding(props),
    }),
    suffix: props => ({
        ...removeStartPadding(props),
    }),
    withPrefix: props => ({
        ...removeStartPadding(props),
    }),
    withSuffix: props => ({
        ...removeEndPadding(props),
    }),
}));

function AdornedInput({
    prefix = null,
    suffix = null,
    ignoreDir = false,
    className,
    prefixClassName,
    suffixClassName,
    inputRef,
    ...rest
}) {
    const classes = useStyles({ ignoreDir });

    return (
        <div className={classes.root}>
            {!!prefix && (
                <span className={classNames(prefixClassName, classes.prefix)}>
                    {prefix}
                </span>
            )}
            <input
                ref={inputRef}
                className={classNames(
                    className,
                    classes.input,
                    prefix ? classes.withPrefix : null,
                    suffix ? classes.withSuffix : null,
                )}
                {...rest}
            />
            {!!suffix && (
                <span className={classNames(suffixClassName, classes.suffix)}>
                    {suffix}
                </span>
            )}
        </div>
    );
}

AdornedInput.propTypes = {
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    ignoreDir: PropTypes.bool,
    className: PropTypes.string,
    prefixClassName: PropTypes.string,
    suffixClassName: PropTypes.string,
    inputRef: PropTypes.func,
};

export default AdornedInput;
