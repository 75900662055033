import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Icon } from './PaypalLogo.svg';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 90,
        height: 22,
        paddingLeft: 8,
        paddingBottom: 2,
        '@media (max-width: 600px)': {
            width: 75,
            height: 17,
        },
    },
}));

function PaypalLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={Icon}
                viewBox="0 0 82.519 20.908"
                className={classes.svg}
            ></SvgIcon>
        </div>
    );
}

export default PaypalLogo;
