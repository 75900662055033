import { createSlice } from '@reduxjs/toolkit';

const STATE_PREFIX = 'lastDonation';

export const lastDonationSlice = createSlice({
    name: STATE_PREFIX,
    initialState: {},
    reducers: {
        setLastDonation(state, { payload }) {
            return { ...state, ...payload };
        },
        resetLastDonation() {
            return {};
        },
    },
});

export const selectLastDonation = state => state[STATE_PREFIX];

export const { setLastDonation, resetLastDonation } = lastDonationSlice.actions;

export default lastDonationSlice.reducer;
