import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        margin: '30px 0',
        padding: '8px 0',
        textAlign: 'center',
        fontFamily: 'Heebo, sans-serif',
        fontSize: 18,
        fontWeight: 'bold',
        color: '#999',
        border: 'solid #B3B3B3',
        borderWidth: '1.5px 0',

        '@media (max-width: 600px)': {
            margin: '15px 0',
            fontSize: 15,
            borderWidth: '1px 0',
        },
    },
}));

function DoubleLineHeading(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {children}
        </div>
    );
}

DoubleLineHeading.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default DoubleLineHeading;
