import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DonationTabPanel from '../DonationTabPanel/DonationTabPanel';
import FormGroup from '../FormGroup/FormGroup';
import FormLabel from '../FormLabel/FormLabel';
import Select from '../Select/Select';
import SearchInput from '../SearchInput/SearchInput';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {
            margin: '20px 0',
        },

        formLabel: {
            marginInlineEnd: '15px !important',
            color: palette.primary.main,

            '@media (max-width: 600px)': {
                display: 'none',
            },
        },

        select: {
            border: `2px solid ${palette.primary.main}`,
            '& select': {
                color: palette.primary.main,
            },
            '&::before': {
                borderInlineStart: `2px solid ${palette.primary.main}`,
            },
            '&::after': {
                border: `solid ${palette.primary.main}`,
                borderWidth: '2px 0 0 0',
                borderInlineEnd: `2px solid ${palette.primary.main}`,
            },
            '@media (max-width: 600px)': {
                width: 130,
            },
        },

        search: {
            '@media (max-width: 600px)': {
                width: 130,
            },
        },
    }),
    { name: 'DonationTabContentSearch' },
);

function DonationTabContentSearch(props) {
    const {
        onChange,
        order = '',
        searchText = '',
        sortOptions = null,
        disabled = false,
    } = props;
    const classes = useStyles();
    const [query, setQuery] = useState({ order, searchText });

    function changeQuery(property) {
        return event => {
            const value =
                typeof event.target !== 'undefined'
                    ? event.target.value
                    : event;
            const newQuery = {
                ...query,
                [property]: value,
                page: 1,
            };
            setQuery(newQuery);
            onChange(newQuery);
        };
    }

    return (
        <DonationTabPanel className={classNames(classes.root, 'rdp-filter')}>
            {!!sortOptions && (
                <FormGroup inline>
                    <FormLabel className={classes.formLabel}>
                        <FormattedMessage
                            id="DonationTabPanel.sortBy"
                            defaultMessage="Sort by:"
                        />
                    </FormLabel>
                    <Select
                        className={classes.select}
                        disabled={disabled}
                        value={query.order}
                        onChange={changeQuery('order')}
                        options={Object.entries(sortOptions).map(
                            ([value, label]) => ({
                                name: label,
                                value,
                            }),
                        )}
                    />
                </FormGroup>
            )}
            <FormGroup inline>
                <SearchInput
                    className={classes.search}
                    value={query.searchText}
                    onChange={debounce(changeQuery('searchText'), 300)}
                    width={190}
                />
            </FormGroup>
        </DonationTabPanel>
    );
}

DonationTabContentSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    order: PropTypes.string,
    searchText: PropTypes.string,
    sortOptions: PropTypes.object,
    disabled: PropTypes.bool,
};

export default React.memo(DonationTabContentSearch, (prevProps, nextProps) => {
    return (
        prevProps.order !== nextProps.order ||
        prevProps.searchText !== nextProps.searchText
    );
});
