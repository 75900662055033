import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import PageSection from '../../PageSection/PageSection';
import Perk from './Perk/Perk';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
    selectPageName,
    selectSelectedCampaign,
} from '../../../features/campaign/campaignSlice';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';

const useStyles = makeStyles({
    perkSection: {
        marginTop: 30,
        marginBottom: 30,
        '@media (max-width: 480px)': {
            marginTop: 25,
            marginBottom: 30,
        },
    },
    perks: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingRight: 18,
        marginLeft: ({ columnGap }) => -columnGap, // column gap alternative for older browsers
        marginBottom: ({ rowGap }) => -rowGap, // row gap alternative for older browsers
        'html[dir=rtl] &': {
            paddingRight: 0,
            paddingLeft: 18,
            marginLeft: 0,
            marginRight: ({ columnGap }) => -columnGap,
            '@media (max-width: 480px)': {
                marginRight: ({ columnGapMobile }) => -columnGapMobile,
            },
        },
        '@media (max-width: 480px)': {
            marginLeft: ({ columnGapMobile }) => -columnGapMobile,
            marginBottom: ({ rowGapMobile }) => -rowGapMobile,
            padding: '3px !important',
        },
    },
});

function Perks({ givings, isPublished, enableDonations }) {
    const perkGaps = {
        columnGap: 34,
        columnGapMobile: 25,
        rowGap: 48,
        rowGapMobile: 32,
    };
    const classes = useStyles({
        ...perkGaps,
    });
    const campaign = useSelector(selectSelectedCampaign);
    const pageName = useSelector(selectPageName);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const perks = givings.filter(perk => {
        const currency = selectPageOptions(perk, 'currency');
        const isActive = selectPageOptions(perk, 'isActive');
        if (
            campaign.payments[pageName].currencies.includes(currency) &&
            !perk.isHidden &&
            isActive
        ) {
            return perk;
        }
    });

    return (
        <PageSection className={classNames(classes.perkSection, 'perks')}>
            <div className={classes.perks}>
                {perks.map(perk => (
                    <Perk
                        {...{ ...perk, perkGaps }}
                        key={perk.id}
                        disabled={!enableDonations || !isPublished}
                        className={classNames(
                            perks.length <= 3 || perks.length % 3 === 0
                                ? 'perk-3'
                                : '',
                            perks.length % 4 === 0 ? 'perk-4' : '',
                            perks.length % 5 === 0 ? 'perk-5' : '',
                        )}
                    />
                ))}
            </div>
        </PageSection>
    );
}

Perks.propTypes = {
    givings: PropTypes.array,
    isPublished: PropTypes.bool,
    enableDonations: PropTypes.bool,
};

export default Perks;
