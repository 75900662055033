import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BitLogo from 'client/components/CampaignPage/components/BitLogo/BitLogo';
import CashAppLogo from 'client/components/CampaignPage/components/CashAppLogo/CashAppLogo';
import DonorsFundLogo from 'client/components/CampaignPage/components/DonorsFundLogo/DonorsFundLogo';
import GoogleApplePayLogo from 'client/components/CampaignPage/components/GoogleApplePayIcon/GoogleApplePayIcon';
import MatbiaLogo from 'client/components/CampaignPage/components/MatbiaLogo/MatbiaLogo';
import OjcFundLogo from 'client/components/CampaignPage/components/OjcFundLogo/OjcFundLogo';
import PaypalLogo from 'client/components/CampaignPage/components/PaypalIcon/PaypalIcon';
import PledgerLogo from 'client/components/CampaignPage/components/PledgerLogo/PledgerLogo';
import RadioGroupTab from 'client/components/CampaignPage/components/RadioGroupTab/RadioGroupTab';
import RadioGroupTabs from 'client/components/CampaignPage/components/RadioGroupTabs/RadioGroupTabs';
import { usePaymentRequest } from 'client/components/CampaignPage/features/checkout/StripeWalletInfo';
import { PAYMENT_GATEWAYS } from 'common/constants';
import { paymentMethodLabelProp } from 'common/helpers/paymentMethods';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    selectEnabledPaymentMethods,
    selectPaymentGateway,
} from './checkoutSlice';
import classNames from 'classnames';
import IdealLogo from '../../components/IdealLogo/IdealLogo';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        '@media (max-width: 600px)': {
            flexWrap: 'wrap',

            '& input + div': {
                padding: '5px 25px',
            },
        },
    },
    bitPayment: {
        'input:checked + &': {
            background: `linear-gradient(180deg, ${palette.brands.bit.primary}, ${palette.brands.bit.secondary})`,
        },
    },
    paypal: {
        'input:checked + &': {
            background: palette.secondary.main,
        },
    },
    checkedBlack: {
        'input:checked + &': {
            background: palette.common.black,
        },
    },
    matbia: {
        'input:checked + &': {
            background: palette.brands.matbia,
        },
    },
    donorsFund: {
        padding: '4px 20px',
    },
}));
function PaymentMethodSelector(props) {
    const { onChange, value } = props;
    const classes = useStyles();
    const enabledPaymentMethods = useSelector(selectEnabledPaymentMethods);
    const paymentGateway = useSelector(selectPaymentGateway);

    const { canMakePayment } = usePaymentRequest({
        isDisabled: !['googlepay', 'applepay'].some(i =>
            enabledPaymentMethods.includes(i),
        ),
    });

    if (!enabledPaymentMethods.length) {
        return null;
    }

    const getPaymentMethodContent = (paymentMethod, isDisabled) => {
        switch (paymentMethod) {
            case 'bit':
                return <BitLogo checked={paymentMethod === value} />;
            case 'paypal':
                return <PaypalLogo checked={paymentMethod === value} />;
            case 'applepay':
            case 'googlepay':
                return (
                    <GoogleApplePayLogo
                        paymentMethod={paymentMethod}
                        isChecked={paymentMethod === value}
                        isDisabled={isDisabled}
                    />
                );
            case 'cashapp':
                return <CashAppLogo checked={paymentMethod === value} />;
            case 'ideal':
                return <IdealLogo />;
            case 'pledger':
                return <PledgerLogo checked={paymentMethod === value} />;
            case 'matbia':
                return <MatbiaLogo checked={paymentMethod === value} />;
            case 'ojcfund':
                return <OjcFundLogo checked={paymentMethod === value} />;
            case 'donorsfund':
                return <DonorsFundLogo checked={paymentMethod === value} />;
            default:
                return (
                    <FormattedMessage
                        id={paymentMethodLabelProp(paymentMethod)}
                        defaultMessage={paymentMethod}
                    />
                );
        }
    };

    const getClassName = paymentMethod => {
        switch (paymentMethod) {
            case 'paypal':
                return classes.paypal;
            case 'bit':
                return classes.bitPayment;
            case 'matbia':
                return classes.matbia;
            case 'donorsfund':
                return classNames(classes.checkedBlack, classes.donorsFund);
            case 'pledger':
            case 'applepay':
            case 'googlepay':
            case 'ojcfund':
                return classes.checkedBlack;
            default:
                return null;
        }
    };

    const isDisabled = paymentMethod => {
        switch (paymentMethod) {
            case 'applepay':
                return (
                    paymentGateway === PAYMENT_GATEWAYS.STRIPE &&
                    !canMakePayment?.applePay
                );
            case 'googlepay':
                return (
                    paymentGateway === PAYMENT_GATEWAYS.STRIPE &&
                    !canMakePayment?.googlePay
                );
            default:
                return false;
        }
    };

    return (
        <RadioGroupTabs className={classes.root}>
            {enabledPaymentMethods.map(paymentMethod => (
                <RadioGroupTab
                    name="paymentMethod"
                    key={paymentMethod}
                    onChange={onChange(paymentMethod)}
                    checked={paymentMethod === value}
                    buttonClassName={getClassName(paymentMethod)}
                    disabled={isDisabled(paymentMethod)}
                >
                    {getPaymentMethodContent(
                        paymentMethod,
                        isDisabled(paymentMethod),
                    )}
                </RadioGroupTab>
            ))}
        </RadioGroupTabs>
    );
}

PaymentMethodSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

export default PaymentMethodSelector;
