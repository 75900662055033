const LIVE_EVENT_TICKER_TYPES = Object.freeze({
    CAMPAIGN: 'campaign',
});

const LIVE_EVENT_TICKER_TYPE_VALUES = Object.freeze(
    Object.values(LIVE_EVENT_TICKER_TYPES),
);

const LIVE_EVENT_TICKER_BACKGROUND_TYPES = Object.freeze({
    VIDEO: 'video',
    IMAGE: 'image',
    COLOR: 'color',
});

const LIVE_EVENT_TICKER_BACKGROUND_TYPE_VALUES = Object.freeze(
    Object.values(LIVE_EVENT_TICKER_BACKGROUND_TYPES),
);

module.exports = {
    LIVE_EVENT_TICKER_TYPES,
    LIVE_EVENT_TICKER_TYPE_VALUES,
    LIVE_EVENT_TICKER_BACKGROUND_TYPES,
    LIVE_EVENT_TICKER_BACKGROUND_TYPE_VALUES,
};
