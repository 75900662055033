import React from 'react';
import PropTypes from 'prop-types';

function SvgCommunityIcon({ fill = '#fff', ...props }) {
    const styles = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 1.25,
        fill: '#fff',
        color: fill,
        stroke: 'currentColor',
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39.102"
            height="24.159"
            viewBox="0 0 39.102 24.159"
            {...props}
        >
            <g transform="translate(843.615 -171.375)" style={styles}>
                <g transform="translate(-842.99 182.523)">
                    <path
                        d="M245.124,1607.643a4.426,4.426,0,0,1,4.426-4.426,4.573,4.573,0,0,1,2.574.825,4.325,4.325,0,0,1,1.853,3.6Z"
                        transform="translate(-245.124 -1597.429)"
                    />
                    <path
                        d="M252.988,1597.724a2.724,2.724,0,1,1-2.724-2.724A2.724,2.724,0,0,1,252.988,1597.724Z"
                        transform="translate(-245.838 -1595)"
                    />
                </g>
                <g transform="translate(-813.99 182.523)">
                    <path
                        d="M245.124,1607.643a4.426,4.426,0,0,1,4.426-4.426,4.573,4.573,0,0,1,2.574.825,4.325,4.325,0,0,1,1.853,3.6Z"
                        transform="translate(-245.124 -1597.429)"
                    />
                    <path
                        d="M252.988,1597.724a2.724,2.724,0,1,1-2.724-2.724A2.724,2.724,0,0,1,252.988,1597.724Z"
                        transform="translate(-245.838 -1595)"
                    />
                </g>
                <g transform="translate(-822.408 178.887)">
                    <path
                        d="M245.124,1609.455a6.237,6.237,0,0,1,6.238-6.237,6.446,6.446,0,0,1,3.628,1.162,6.1,6.1,0,0,1,2.611,5.075Z"
                        transform="translate(-245.124 -1595.06)"
                    />
                    <path
                        d="M255.218,1598.839a3.839,3.839,0,1,1-3.839-3.839A3.839,3.839,0,0,1,255.218,1598.839Z"
                        transform="translate(-245.142 -1595)"
                    />
                </g>
                <g transform="translate(-838.408 178.887)">
                    <path
                        d="M245.124,1609.455a6.237,6.237,0,0,1,6.238-6.237,6.446,6.446,0,0,1,3.628,1.162,6.1,6.1,0,0,1,2.611,5.075Z"
                        transform="translate(-245.124 -1595.06)"
                    />
                    <path
                        d="M255.218,1598.839a3.839,3.839,0,1,1-3.839-3.839A3.839,3.839,0,0,1,255.218,1598.839Z"
                        transform="translate(-245.142 -1595)"
                    />
                </g>
                <g transform="translate(-833.819 172)">
                    <path
                        d="M245.124,1613.144a9.927,9.927,0,0,1,9.927-9.927,10.257,10.257,0,0,1,5.773,1.85,9.7,9.7,0,0,1,4.155,8.077Z"
                        transform="translate(-245.124 -1590.235)"
                    />
                    <path
                        d="M259.759,1601.11a6.109,6.109,0,1,1-6.11-6.11A6.11,6.11,0,0,1,259.759,1601.11Z"
                        transform="translate(-243.722 -1595)"
                    />
                </g>
            </g>
        </svg>
    );
}

SvgCommunityIcon.propTypes = {
    fill: PropTypes.string,
};

export default SvgCommunityIcon;
