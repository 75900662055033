const RALLY_EVENT_DEFAULT_PAGE_SETTINGS = Object.freeze({
    showOverallSum: {
        enabled: true,
        position: 'top-center',
        fontSize: 18,
    },
    showRallyEventGoal: {
        enabled: true,
        position: 'top-left',
        fontSize: 18,
    },
    showAmountEventRequests: {
        enabled: true,
        position: 'top-right',
        fontSize: 18,
    },
    showRecentTeam: {
        enabled: true,
        fontSize: 18,
    },
    tileResolution: {
        cols: 3,
        rows: 3,
    },
    tileSize: {
        width: 140,
        height: 140,
    },
    tileText: {
        fontSize: 16,
    },
    centerText: {
        fontSize: 18,
    },
});

const RALLY_EVENT_PAGE_SETTINGS_POSITIONS = Object.freeze([
    {
        label: 'rallyEventPositions.topRight',
        value: 'top-right',
    },
    {
        label: 'rallyEventPositions.topLeft',
        value: 'top-left',
    },
    {
        label: 'rallyEventPositions.topCenter',
        value: 'top-center',
    },
    {
        label: 'rallyEventPositions.pageMiddle',
        value: 'page-middle',
    },
]);

const RALLY_EVENT_PAGE_COLUMNS = 2;

module.exports = {
    RALLY_EVENT_DEFAULT_PAGE_SETTINGS,
    RALLY_EVENT_PAGE_SETTINGS_POSITIONS,
    RALLY_EVENT_PAGE_COLUMNS,
};
