import Repository from './Repository';

class RallyEventRequestRepository extends Repository {
    async import() {
        return null;
    }
}

export default new RallyEventRequestRepository({
    baseUrl: '/rallyeventrequest',
});
