import { useEffect } from 'react';

export default function useCloseTab(handler, dependencies = []) {
    useEffect(() => {
        window.addEventListener('beforeunload', handler, false);
        return () => window.removeEventListener('beforeunload', handler);
    }, dependencies);

    return null;
}
