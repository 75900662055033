import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FooterLinkGroupHeader from '../FooterLinkGroupHeader/FooterLinkGroupHeader';
import Link from '../Link/Link';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {
            flexBasis: 130,

            '@media (max-width: 600px)': {
                flexBasis: '50%',
                marginBottom: 30,
            },
        },

        link: {
            lineHeight: '30px',
            display: 'block',
            textDecoration: 'none',
            fontWeight: 500,
            color: palette.text.primary,
            'html[dir=rtl] &': {
                textAlign: 'right',
            },
        },
    }),
    { name: 'FooterLinkGroup' },
);

function FooterLinkGroup(props) {
    const { header, links } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FooterLinkGroupHeader>{header}</FooterLinkGroupHeader>
            {links.map(({ content, target = '_blank', ...linkProps }) => (
                <Link
                    key={content}
                    className={classes.link}
                    target={target}
                    {...linkProps}
                >
                    {content}
                </Link>
            ))}
        </div>
    );
}

FooterLinkGroup.propTypes = {
    header: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        }),
    ),
};

export default FooterLinkGroup;
