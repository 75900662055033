import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import { selectIsTestMode, selectTaxGift } from './checkoutSlice';

const TAXGIFT_TEST_URL = 'https://portal.preprod.taxgift.co.nz';
const TAXGIFT_URL = 'https://portal.taxgift.co.nz';

function taxGiftUrl(entityId, isTestMode) {
    const baseUrl = isTestMode ? TAXGIFT_TEST_URL : TAXGIFT_URL;

    return `${baseUrl}/register/${entityId}?r=1`;
}

const useStyles = makeStyles(() => ({
    root: {
        padding: 8,
        fontSize: '1rem',
    },
}));

function TaxGiftRedirect() {
    const classes = useStyles();
    const { taxGiftEntityId } = useSelector(selectTaxGift);
    const isTestMode = useSelector(selectIsTestMode);

    useEffect(() => {
        if (!taxGiftEntityId) {
            return;
        }

        const timeout = setTimeout(() => {
            window.location.href = taxGiftUrl(taxGiftEntityId, isTestMode);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [taxGiftEntityId, isTestMode]);

    return (
        <div className={classes.root}>
            <FormattedMessage
                id="CheckoutPopup.taxGift"
                defaultMessage="You are being redirected to TaxGift..."
            />
        </div>
    );
}

export default TaxGiftRedirect;
