//PLEASE USE NUMBERS ABOVE 100 FOR FAILURE STATUS
const DONATION_STATUS = Object.freeze({
    SUCCESS: 0,
    PENDING: 1,
    FAILURE: 100, //GENERAL FAILURE
    AUTH_FAILURE: 101,
    TIMEOUT: 102,
});

const DONATION_EVENT_TYPE = Object.freeze({
    DONATION_EDIT: 1,
    DONATION_DELETE: 2,
    SUBSCRIPTION_PAID: 3,
});

const DONATION_PAYMENT_MODE = Object.freeze({
    regular: 1,
    monthly: 2,
    installments: 3,
    unlimitedRecurring: 4,
});

const DONATION_TYPES = Object.freeze({
    CAMPAIGN: 'campaign',
    DONATE_PAGE: 'donate-page',
});

module.exports = {
    DONATION_STATUS,
    DONATION_EVENT_TYPE,
    DONATION_PAYMENT_MODE,
    DONATION_TYPES,
};
