import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import {
    VIDEO_PLAYER_STATE,
    selectHeroSlider,
    nextSlide,
    startVideo,
    playVideo,
    pauseVideo,
    endVideo,
} from './heroSliderSlice';
import PlayButton from './PlayButton';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';

function VideoSlide({
    blockHeight,
    url,
    isAutoPlay,
    isLoop,
    isSelected,
    isVideoOnly,
    slideCount,
}) {
    const dispatch = useDispatch();
    const playerRef = useRef();
    const { isVideoPlaying, videoPlayerState } = useSelector(selectHeroSlider);

    const isPlaying =
        isVideoPlaying ||
        (isSelected &&
            isAutoPlay &&
            videoPlayerState !== VIDEO_PLAYER_STATE.STOPPED);

    const handleStart = () => {
        dispatch(startVideo());
    };

    const handlePlay = () => {
        dispatch(playVideo());
        triggerGtmEvent(GTM_EVENTS.PLAY_VIDEO, { eventCategory: 'main_video' });
    };

    const handlePause = () => {
        dispatch(pauseVideo());
    };

    const handleEnded = () => {
        dispatch(endVideo());
        if (slideCount > 1) {
            dispatch(nextSlide());
        }
        playerRef.current?.seekTo(0);
        if (isVideoOnly) {
            playerRef.current?.showPreview();
        }
    };

    return (
        <ReactPlayer
            ref={playerRef}
            url={url}
            width="100%"
            height={blockHeight}
            controls
            loop={isLoop}
            light={isAutoPlay ? false : !!isVideoOnly}
            playIcon={<PlayButton onClick={handleStart} />}
            playing={isPlaying}
            volume={isAutoPlay ? 0 : null}
            muted={isAutoPlay}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
        />
    );
}

VideoSlide.propTypes = {
    blockHeight: PropTypes.number,
    url: PropTypes.string,
    isAutoPlay: PropTypes.bool,
    isLoop: PropTypes.bool,
    isSelected: PropTypes.bool,
    isVideoOnly: PropTypes.bool,
    slideCount: PropTypes.number,
};

export default VideoSlide;
