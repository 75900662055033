import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const firstIconTransform = (dir = 'ltr') => {
    return ({ startIcon, endIcon }) => {
        if (startIcon) {
            return `translate(${dir === 'ltr' ? '-50%' : '50%'}, -50%)`;
        }

        if (endIcon) {
            return `translate(${dir === 'ltr' ? '50%' : '-50%'}, -50%)`;
        }

        return 'none';
    };
};

const lastIconTransform = (dir = 'ltr') => {
    return ({ endIcon }) => {
        if (endIcon) {
            return `translate(${dir === 'ltr' ? '-50%' : '50%'}, -50%)`;
        }

        return 'none';
    };
};

const paddingWithIcon = (
    vertical,
    horizontal,
    iconStart,
    iconEnd,
    dir = 'ltr',
) => {
    return ({ startIcon, endIcon }) => {
        if (dir === 'ltr') {
            return `${vertical} ${endIcon ? iconEnd : horizontal} ${vertical} ${
                startIcon ? iconStart : horizontal
            }`;
        }

        return `${vertical} ${startIcon ? iconStart : horizontal} ${vertical} ${
            endIcon ? iconEnd : horizontal
        }`;
    };
};

const useStyles = makeStyles({
    root: {
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: 5,
        position: 'relative',
        boxSizing: 'border-box',
        width: props => props.width || '100%',
        '&::before': {
            content: '""',
            display: props => (props.startIcon ? 'block' : 'none'),
            width: 1,
            position: 'absolute',
            top: 8,
            bottom: 8,
            background: '#ccc',
            pointerEvents: 'none',
            left: props => (props.startIcon ? 55 : 'auto'),
            right: props => (props.endIcon ? 55 : 'auto'),

            'html[dir=rtl] &': {
                right: props => (props.startIcon ? 55 : 'auto'),
                left: props => (props.endIcon ? 55 : 'auto'),
            },

            '@media (max-width: 600px)': {
                left: props => (props.startIcon ? 50 : 'auto'),
                right: props => (props.endIcon ? 50 : 'auto'),
            },
        },
        '& svg:first-child': {
            position: 'absolute',
            top: '50%',
            pointerEvents: 'none',
            left: props => (props.startIcon ? 27 : 'auto'),
            right: props => (props.endIcon ? 27 : 'auto'),
            transform: firstIconTransform(),

            'html[dir=rtl] &': {
                right: props => (props.startIcon ? 27 : 'auto'),
                left: props => (props.endIcon ? 27 : 'auto'),
                transform: firstIconTransform('rtl'),
            },
        },
        '& svg:last-child': {
            position: 'absolute',
            top: '50%',
            pointerEvents: 'none',
            left: 'auto',
            right: 0,
            transform: lastIconTransform(),

            'html[dir=rtl] &': {
                right: 'auto',
                left: 0,
                transform: lastIconTransform('rtl'),
            },
        },
        '& .react-datepicker-wrapper': {
            width: '100%',
        },
    },
    input: {
        background: 'none',
        border: 0,
        outline: 'none',
        padding: paddingWithIcon('15px', '20px', '70px', '50px'),
        margin: 0,
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        fontFamily: 'Heebo, sans-serif',
        fontSize: 16,
        color: '#707070',
        '&::placeholder': {
            color: '#707070',
        },
        '&::-moz-placeholder': {
            color: 'black',
        },
        'html[dir=rtl] &': {
            padding: paddingWithIcon('15px', '20px', '70px', '50px', 'rtl'),
        },
        '@media (max-width: 600px)': {
            padding: paddingWithIcon('5px', '15px', '60px', '30px'),
            fontSize: 13,
            height: 38,
            'html[dir=rtl] &': {
                padding: paddingWithIcon('5px', '15px', '60px', '30px', 'rtl'),
            },
        },
    },
    error: {
        borderColor: 'red',
        '& input': {
            outlineColor: 'red',
        },
    },
});
function FormInput(props) {
    const {
        className,
        width,
        startIcon,
        endIcon,
        component: Component = null,
        inputRef,
        error,
        ...rest
    } = props;
    const classes = useStyles({ width, startIcon, endIcon });

    return (
        <div
            className={[classes.root, className, error ? classes.error : null]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {!!startIcon && startIcon}
            {Component ? (
                <Component
                    className={classes.input}
                    {...rest}
                    inputRef={inputRef}
                />
            ) : (
                <input className={classes.input} {...rest} ref={inputRef} />
            )}
            {!!endIcon && endIcon}
        </div>
    );
}

FormInput.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    className: PropTypes.string,
    component: PropTypes.func,
    inputRef: PropTypes.func,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
};

// eslint-disable-next-line
export default React.forwardRef((props, ref) => (
    <FormInput {...props} inputRef={ref} />
));
