const _ = require('lodash');
const {
    RALLY_EVENT_DEFAULT_PAGE_SETTINGS,
    CURRENCY_INFO,
    DONATION_PAYMENT_MODE,
} = require('../constants');
const { ABANDONED_DELAY_UNITS } = require('../constants/abandoned');

const assignDefaultValue = (target, propertyPath, defaultValue) => {
    _.set(target, propertyPath, _.get(target, propertyPath, defaultValue));
};

const assignDefaultPageSettingsParameter = (target, propertyPath) => {
    return assignDefaultValue(
        target,
        `pageSettings.${propertyPath}`,
        _.get(RALLY_EVENT_DEFAULT_PAGE_SETTINGS, propertyPath),
    );
};

const getPageSettingsParameter = (pageSettings, propertyPath) => {
    return _.get(
        pageSettings,
        propertyPath,
        _.get(RALLY_EVENT_DEFAULT_PAGE_SETTINGS, propertyPath, null),
    );
};

const calculateNotificationDelay = (delayPeriod, delayType = 'minutes') => {
    switch (delayType) {
        case ABANDONED_DELAY_UNITS.DAYS:
            return delayPeriod * 24 * 3600000;
        case ABANDONED_DELAY_UNITS.HOURS:
            return delayPeriod * 3600000;
        case ABANDONED_DELAY_UNITS.MINUTES:
        default:
            return delayPeriod * 60000;
    }
};

const arrayToQuery = (items, parameterName = 'id') =>
    items && items.length
        ? items
              .reduce((queryItems, value) => {
                  if (value) {
                      queryItems.push(`${parameterName}[]=${value}`);
                  }
                  return queryItems;
              }, [])
              .join('&')
        : '';

const glueClassName = classes => classes.filter(Boolean).join(' ').trim();

const currencySign = currency => {
    return _.get(CURRENCY_INFO, [currency, 'sign']);
};

const currencyLabel = currency => {
    return _.get(CURRENCY_INFO, [currency, 'label'], currency);
};

const getDonationMonths = donation => {
    switch (donation.paymentMode) {
        case DONATION_PAYMENT_MODE.monthly:
        case DONATION_PAYMENT_MODE.unlimitedRecurring:
            return donation.recurringMonths;
        case DONATION_PAYMENT_MODE.installments:
            return donation.installmentMonths;
        default:
            return 1;
    }
};

const roundAmount = amount => Math.round(amount * 100) / 100;
const integerAmount = amount => parseInt(roundAmount(amount), 10) || 0;

const getSingleDonationAmount = (amount, layerItemsCount = 1) => {
    const singleLayerItemAmount =
        Math.round((amount / Math.max(layerItemsCount, 1)).toFixed(2) * 100) /
        100;

    return singleLayerItemAmount;
};

module.exports = {
    assignDefaultValue,
    assignDefaultPageSettingsParameter,
    getPageSettingsParameter,
    calculateNotificationDelay,
    arrayToQuery,
    glueClassName,
    currencySign,
    currencyLabel,
    getDonationMonths,
    roundAmount,
    integerAmount,
    getSingleDonationAmount,
};
