class OrderFactory {
    constructor(order = []) {
        this.order = order;
    }

    add(fieldName, direction = 'ASC') {
        if (!fieldName) {
            return this;
        }
        const chunks = fieldName.split(':');

        if (chunks.length > 1) {
            this.order.push([chunks[0], chunks[1].toUpperCase()]);
        } else {
            this.order.push([fieldName, direction.toUpperCase()]);
        }

        return this;
    }

    remove(fieldName) {
        const fieldIndex = this.order.findIndex(
            orderItem => orderItem[0] === fieldName,
        );

        if (fieldIndex > -1) {
            this.order.splice(fieldIndex, 1);
        }

        return this;
    }

    toString() {
        return JSON.stringify(this.order);
    }
}

module.exports = () => new OrderFactory();
