const _ = require('lodash');
const { DONATION_PAYMENT_MODE } = require('../constants');
const MODE_NAMES = _.invert(DONATION_PAYMENT_MODE);

function getPaymentModeName(paymentMode) {
    const mode = DONATION_PAYMENT_MODE[paymentMode] || paymentMode;
    return _.get(MODE_NAMES, mode, 'unknown')
        .replace('regular', 'one-time')
        .replace('unlimitedRecurring', 'unlimited-monthly');
}

module.exports = getPaymentModeName;
