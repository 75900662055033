import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useIsMobile from 'client/hooks/isMobile';
import { currencySign } from 'common/helpers';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Avatar from '../Avatar/Avatar';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette, spacing, zIndex }) => {
    const defaultBoxShadow = `
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12)`;

    const glowColor = changeColorAlpha(palette.secondary.light, 0.8);

    return {
        root: {
            top: ({ avatarSrc }) => (avatarSrc ? 17 : 0),
            backgroundColor: palette.accent.main,
            padding: `${spacing(0.5)}px ${spacing(10)}px`,
            height: 80,
            justifyContent: 'center',
            position: 'sticky',
            zIndex: zIndex.appBar,
            color: palette.accent.contrastText,
        },
        avatar: {
            minWidth: ({ avatarSize }) => avatarSize,
            minHeight: ({ avatarSize }) => avatarSize,
            backgroundColor: palette.accent.main,
            boxShadow: ({ hasEnteredBonusGoal }) =>
                hasEnteredBonusGoal
                    ? `0px 0px 10px 0.5px ${glowColor}`
                    : defaultBoxShadow,
        },
        logo: {
            width: ({ avatarSize }) => avatarSize,
            height: ({ avatarSize }) => avatarSize,
            objectFit: 'cover',
        },
        title: {
            marginLeft: spacing(2),
            paddingRight: spacing(1),
            textShadow: ({ hasEnteredBonusGoal }) =>
                hasEnteredBonusGoal ? `${glowColor} 0px 0px 10px` : 'none',
            'html[dir=rtl] &': {
                marginLeft: 0,
                paddingRight: 0,
                marginRight: spacing(2),
                paddingLeft: spacing(1),
            },
        },
        stats: {
            margin: `0 ${spacing(3)}px`,
            display: 'flex',
            gap: spacing(1),
            flex: 1,
            justifyContent: 'space-evenly',
        },
        stat: {
            textAlign: 'center',
        },
        statData: {
            color: isLightColor(palette.secondary.main, 0.42)
                ? palette.secondary.main
                : palette.accent.contrastText,
            fontWeight: 700,
        },
        statTitle: {
            fontSize: '1rem',
        },
        '@media (max-width: 1024px)': {
            root: {
                padding: `${spacing(0.5)}px ${spacing(5)}px`,
            },
            name: {
                display: 'block',
                fontWeight: 600,
            },
            title: {
                fontSize: '1rem',
            },
            statData: {
                fontSize: '1.2rem',
            },
        },
        '@media (max-width: 820px)': {
            root: {
                top: '0 !important',
                padding: `${spacing(0.5)}px ${spacing(1)}px`,
                height: ({ isExpanded }) => (isExpanded ? '135px' : null),
                borderRadius: ({ isExpanded }) =>
                    isExpanded ? '0 0 15px 15px' : null,
            },
            avatar: {
                border: null,
            },
            title: {
                fontSize: '0.9rem',
                maxWidth: 185,
                marginLeft: spacing(1),
                'html[dir=rtl] &': {
                    marginLeft: 0,
                    marginRight: spacing(1),
                },
            },
            glowingLine: {
                height: '85%',
                width: 1.5,
                backgroundColor: palette.secondary.main,
                boxShadow: `0px 0px 10px 0.5px ${glowColor}`,
                marginLeft: spacing(1),
                'html[dir=rtl] &': {
                    marginLeft: 0,
                    marginRight: spacing(1),
                },
            },
            stats: {
                margin: 0,
            },
            statData: {
                fontSize: '1rem',
            },
            statTitle: {
                fontSize: '0.85rem',
            },
            mobileCollapse: {
                margin: ({ isExpanded }) => (isExpanded ? '12px 0' : 0),
            },
        },
    };
});

function ItemDetailsBar(props) {
    const {
        name,
        avatarSrc,
        currency,
        amountRaised,
        goalAmount,
        donorsCount,
        bonusGoalAmount = 0,
        hasEnteredBonusGoal = false,
    } = props;
    const { formatMessage } = useIntl();
    const { width, isMobile } = useIsMobile();
    const [stats, setStats] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const scrollOffset = 60; // to prevent scroll triggering on expand
    const avatarSize = width < 600 ? 60 : 110;
    const classes = useStyles({
        avatarSrc,
        avatarSize,
        isExpanded,
        hasEnteredBonusGoal,
    });

    function updateStats() {
        let updatedStats = [];
        if (goalAmount && goalAmount > 0) {
            updatedStats.push(
                {
                    key: 'percentageRaised',
                    title: formatMessage({
                        id: 'Amount.progress',
                        defaultMessage: 'Progress',
                    }),
                    data: Math.round((amountRaised / goalAmount) * 100) + '%',
                },
                {
                    key: 'goalAmount',
                    title: formatMessage(
                        hasEnteredBonusGoal
                            ? {
                                  id: 'common.originalGoal',
                                  defaultMessage: 'Original Goal',
                              }
                            : {
                                  id: 'Amount.goal',
                                  defaultMessage: 'Goal',
                              },
                    ),
                    data: formatAmount(goalAmount),
                },
            );
            if (hasEnteredBonusGoal) {
                updatedStats.unshift({
                    key: 'totalGoalAmount',
                    title: formatMessage({
                        id: 'DonationWidget.bonusTitle',
                        defaultMessage: 'Bonus Goal',
                    }),
                    data: formatAmount(goalAmount + bonusGoalAmount),
                });
            }
        }
        updatedStats.push(
            {
                key: 'amountRaised',
                title: formatMessage({
                    id: 'Amount.raised',
                    defaultMessage: 'Raised',
                }),
                data: formatAmount(amountRaised),
            },

            {
                key: 'donorsCount',
                title: formatMessage({
                    id: 'Amount.donors',
                    defaultMessage: 'Donors',
                }),
                data: donorsCount.toLocaleString(),
            },
        );

        if (isMobile && updatedStats.length > 2 && !isExpanded) {
            setStats(
                updatedStats.filter(stat =>
                    [
                        hasEnteredBonusGoal
                            ? 'totalGoalAmount'
                            : 'percentageRaised',
                        'amountRaised',
                    ].includes(stat.key),
                ),
            );
        } else {
            setStats(updatedStats);
        }
    }

    function formatAmount(amount) {
        return currencySign(currency) + amount.toLocaleString();
    }

    function handleItemBarClick() {
        if (isMobile) {
            setIsExpanded(!isExpanded);
        }
    }

    function renderStats() {
        return (
            <div className={classes.stats}>
                {stats.map(stat => (
                    <div key={stat.key} className={classes.stat}>
                        <Typography variant="h4" className={classes.statData}>
                            {stat.data}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.statTitle}
                        >
                            {stat.title}
                        </Typography>
                    </div>
                ))}
            </div>
        );
    }

    useEffect(() => {
        updateStats();
    }, [props, isMobile, isExpanded, formatMessage]);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolling =
                window.scrollY >= scrollOffset + lastScrollY ||
                window.scrollY <= lastScrollY - scrollOffset;
            if (isMobile && isScrolling) {
                setIsExpanded(false);
                setLastScrollY(window.scrollY);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile, lastScrollY, isExpanded]);

    return (
        <AppBar
            className={classes.root}
            component="section"
            onClick={handleItemBarClick}
        >
            <Toolbar disableGutters>
                {avatarSrc && (
                    <>
                        <Avatar size={avatarSize} className={classes.avatar}>
                            <img
                                className={classes.logo}
                                src={avatarSrc}
                                alt={name}
                            />
                        </Avatar>
                        <div className={classes.glowingLine}></div>
                    </>
                )}

                <Typography variant="h5" className={classes.title}>
                    <FormattedMessage
                        id="CampaignPage.layerTitle"
                        defaultMessage="Donation Page of {name}"
                        values={{ name: '' }}
                    />
                    <span className={classes.name}>{name}</span>
                </Typography>
                {!isExpanded && renderStats()}
            </Toolbar>
            <Collapse in={isExpanded} className={classes.mobileCollapse}>
                {renderStats()}
            </Collapse>
        </AppBar>
    );
}

ItemDetailsBar.propTypes = {
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    currency: PropTypes.string,
    goalAmount: PropTypes.number,
    amountRaised: PropTypes.number,
    donorsCount: PropTypes.number,
    bonusGoalAmount: PropTypes.number,
    hasEnteredBonusGoal: PropTypes.bool,
};

export default ItemDetailsBar;
