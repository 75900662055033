import { useEffect } from 'react';
import io from 'socket.io-client';
import config from 'client/config';

const socket = io(config.publicHost, {
    transports: ['websocket'],
});

const socketBulkApply = (method, eventHandlers) => {
    for (let eventName in eventHandlers) {
        socket[method](eventName, eventHandlers[eventName]);
    }
};

export default function useWebsocket(subscriptions) {
    useEffect(() => {
        socketBulkApply('on', subscriptions);
        return () => socketBulkApply('off', subscriptions);
    }, [subscriptions]);

    return { socket };
}
