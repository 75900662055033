const DYNAMIC_TIP_BRACKETS = [
    { minimumAmount: 10001, defaultTipPercent: 5, tippingPercents: [3, 5, 10] },
    { minimumAmount: 0, defaultTipPercent: 15, tippingPercents: [10, 12, 15] },
];

function getDynamicTipConfig(donationAmount) {
    const amount = Math.max(0, donationAmount || 0);
    return DYNAMIC_TIP_BRACKETS.find(
        ({ minimumAmount }) => amount >= minimumAmount,
    );
}

module.exports = { getDynamicTipConfig };
