import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';

class LiveEventTickerRepository extends Repository {
    async fetchCampaignTicker({ campaignId, campaignPage }) {
        try {
            const response = await this.axios.get(
                `/campaign/${campaignId}/${encodeURIComponent(campaignPage)}`,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async createCampaignTicker(tickerData) {
        try {
            const response = await this.axios.post('/campaign', tickerData);
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async update({ liveEventTickerId, tickerData }) {
        try {
            const response = await this.axios.put(
                `/${liveEventTickerId}`,
                tickerData,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async deleteCampaignTicker({ campaignId, campaignPage }) {
        try {
            const response = await this.axios.delete(
                `/campaign/${campaignId}/${encodeURIComponent(campaignPage)}`,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }
}

export default new LiveEventTickerRepository({ baseUrl: '/live-event-ticker' });
