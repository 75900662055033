import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useIsMobile from 'client/hooks/isMobile';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        margin: '15px 5px',
        padding: '5px 10px',
        display: 'flex',
        width: 'fit-content',
        background: palette.grey[300],
        borderRadius: 30,

        '@media (max-width: 1023px)': {
            margin: '10px 5px',
        },
    },
    item: {
        display: 'flex',
        fontFamily: typography.fontFamily,
        fontSize: '0.875em',
        '@media (max-width: 525px)': {
            fontSize: '0.75em',
        },
    },
    link: {
        color: palette.primary.main,
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    },
    current: {
        color: palette.text.primary,
    },
    icon: {
        margin: '0 -8px', // to get rid of the hard-coded separator margin from MUI
        'html[dir=rtl] &': {
            transform: 'rotate(180deg)',
        },
        '@media (max-width: 525px)': {
            fontSize: '0.8em',
        },
    },
}));

function Breadcrumbs(props) {
    const { className, items } = props;
    const classes = useStyles();
    const { isMobile } = useIsMobile();

    return (
        <MuiBreadcrumbs
            component="div"
            separator={
                <NavigateNextIcon className={classes.icon} fontSize="small" />
            }
            className={classNames(classes.root, className)}
            maxItems={isMobile ? 2 : 4}
            itemsAfterCollapse={2}
            itemsBeforeCollapse={1}
            aria-label="breadcrumb"
        >
            {items.map(item =>
                item.url ? (
                    <Link
                        key={item.title}
                        className={classNames(classes.item, classes.link)}
                        href={item.url}
                    >
                        {item.title}
                    </Link>
                ) : (
                    <Typography
                        key={item.title}
                        className={classNames(classes.item, classes.current)}
                    >
                        {item.title}
                    </Typography>
                ),
            )}
        </MuiBreadcrumbs>
    );
}

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default Breadcrumbs;
