import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
    },
}));

function DonationTabContent(props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, 'rdp-container')}>
            {children}
        </div>
    );
}

DonationTabContent.propTypes = {
    children: PropTypes.node,
};

export default DonationTabContent;
