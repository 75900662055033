const { DONATION_PAYMENT_MODE, PAYMENT_MODES } = require('common/constants');

function convertPaymentModeForFrontEnd(paymentMode) {
    switch (paymentMode) {
        case 1:
        case 'regular':
        case 'single':
            return 'regular';
        case 2:
        case 'monthly':
            return 'recurring';
        case 4:
        case 'unlimitedRecurring':
            return 'unlimitedRecurring';
        case 3:
        case 'installments':
            return 'installments';
        default:
            return 'regular';
    }
}

function convertPaymentModeForBackEnd(paymentMode) {
    switch (paymentMode) {
        case 'recurring':
        case 'monthly':
            return 'monthly';
        case 'unlimitedRecurring':
            return 'unlimitedRecurring';
        case 'installments':
            return 'installments';
        case 'single':
        case 'regular':
        default:
            return 'regular';
    }
}

module.exports = {
    convertPaymentModeForFrontEnd,
    convertPaymentModeForBackEnd,
};
