const TOKEN_SCOPES = Object.freeze({
    DONATION: 'donation',
    PEF_REPORT: 'pef-report',
});

const TOKEN_SCOPE_VALUES = Object.freeze(
    Object.keys(TOKEN_SCOPES).reduce((roles, key) => {
        roles.push(TOKEN_SCOPES[key]);
        return roles;
    }, []),
);

module.exports = {
    TOKEN_SCOPES,
    TOKEN_SCOPE_VALUES,
};
