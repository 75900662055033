const fp = require('lodash/fp');

function removeUrlProtocol(url) {
    return url.replace(/^https?:\/\//i, '');
}

function ensureHttpsProtocol(url) {
    if (!url || !fp.isString(url)) {
        return null;
    }

    return `https://${removeUrlProtocol(url)}`;
}

module.exports = {
    removeUrlProtocol,
    ensureHttpsProtocol,
};
