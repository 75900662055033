const _ = require('lodash');
const { GATEWAYS } = require('../constants/gateways');

const VOUCHER_GATEWAYS = _.pickBy(GATEWAYS, ({ isVoucher }) =>
    Boolean(isVoucher),
);

module.exports = function (currency) {
    return Object.keys(VOUCHER_GATEWAYS)
        .filter(
            key =>
                !VOUCHER_GATEWAYS[key].conditions ||
                VOUCHER_GATEWAYS[key].conditions(currency),
        )
        .reduce((curCollection, curKey) => {
            curCollection.push({
                label: VOUCHER_GATEWAYS[curKey].label,
                value: curKey,
            });
            return curCollection;
        }, []);
};

module.exports.voucherConfig = function (gateway) {
    return VOUCHER_GATEWAYS[gateway];
};

module.exports.voucherLabel = function (gateway) {
    return VOUCHER_GATEWAYS[gateway]?.label || gateway;
};

module.exports.voucherFlow = function (gateway) {
    return VOUCHER_GATEWAYS[gateway]?.flow || 'charge';
};

module.exports.getVoucherForDonation = function (gateway) {
    return Object.keys(VOUCHER_GATEWAYS).includes(gateway) ? gateway : '';
};

module.exports.voucherSupportsPaymentMode = function (voucher, paymentMode) {
    return _.get(VOUCHER_GATEWAYS, `${voucher}.${paymentMode}`, false);
};

module.exports.getOjcFundExpiry = function (expiry) {
    const parts = (expiry || '').split('/');

    const expiryMonths = parts[0] || '';
    const expiryYears = (parts[1] || '').split('').slice(-2).join('');

    return `${expiryMonths}${expiryYears}`;
};
