import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../components/FormInput/FormInput';
import FormInputHelperText from '../components/FormInputHelperText/FormInputHelperText';
import useFormStyling from '../hooks/formStyling';
import classNames from '../../../helpers/classNames';

export default function withFormInput(Component) {
    const ComponentWithFormInput = ({
        className,
        dirtyFields,
        endIcon,
        errors,
        name,
        ...rest
    }) => {
        const [getFormClass, getFormIcon] = useFormStyling(dirtyFields, errors);

        return (
            <div style={{ width: '100%' }}>
                <FormInput
                    component={Component}
                    name={name}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    className={classNames(className, getFormClass(name))}
                    endIcon={endIcon ? endIcon : getFormIcon(name)}
                    {...rest}
                />
                <FormInputHelperText error={errors?.[name]} />
            </div>
        );
    };

    ComponentWithFormInput.propTypes = {
        className: PropTypes.string,
        dirtyFields: PropTypes.object,
        endIcon: PropTypes.node,
        errors: PropTypes.object,
        name: PropTypes.string,
    };

    return ComponentWithFormInput;
}
