const get = require('lodash/get');

function findActiveRound(campaign, ratedAmount, goal, currency) {
    let cumulativeGoal = goal;
    const rounds = get(campaign, 'bonus.rounds', []);
    const isAllowed = round => {
        return round.enabled === true && ratedAmount >= cumulativeGoal;
    };

    return rounds.reduce((prevRound, currentRound) => {
        if (isAllowed(currentRound)) {
            cumulativeGoal += Number(currentRound.goal[currency]) || 0;
            return currentRound;
        }
        return prevRound;
    }, null);
}

module.exports = findActiveRound;
