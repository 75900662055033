import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DonorsFundIcon from './DonorsFundIcon';
import DonorsFundIconChecked from './DonorsFundIconChecked';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 145,
        height: 30,
        '@media (max-width: 600px)': {
            width: 135,
            height: 28,
        },
    },
}));

function OjcFundLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={
                    props.checked ? DonorsFundIconChecked : DonorsFundIcon
                }
                viewBox="0 0 389.3 81"
                className={props.className ? props.className : classes.svg}
            />
        </div>
    );
}

OjcFundLogo.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
};

export default OjcFundLogo;
