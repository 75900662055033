const NOTIFICATION_METHODS = Object.freeze({
    EMAIL: 'email',
});

const NOTIFICATION_METHOD_VALUES = Object.freeze(
    Object.values(NOTIFICATION_METHODS),
);

const DONATE_PAGE_DEFAULT_NOTIFICATION_BODY_VALUE = `You have a new donation! 
Donor Name: {{NAME}}
Donation Amount: {{AMOUNT}}
Donation Fee: {{FEE}}
Donation Total: {{TOTAL}}
Date: {{DATE}}`;

const DONATE_PAGE_DEFAULT_NOTIFICATION_SUBJECT_VALUE =
    'You have a new donation!';

module.exports = {
    DONATE_PAGE_DEFAULT_NOTIFICATION_BODY_VALUE,
    DONATE_PAGE_DEFAULT_NOTIFICATION_SUBJECT_VALUE,
    NOTIFICATION_METHODS,
    NOTIFICATION_METHOD_VALUES,
};
