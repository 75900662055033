import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        marginBottom: ({ inline }) => (inline ? 0 : 25),
        width: ({ width }) => width,

        '@media (max-width: 600px)': {
            marginBottom: ({ inline }) => (inline ? 0 : 20),
        },
    },
    main: {
        display: 'flex',
        flexDirection: props => (props.inline ? 'row' : 'column'),
        flexWrap: props => (props.wrap ? 'wrap' : 'nowrap'),
        alignItems: props =>
            props.inline && !props.flexStart ? 'center' : 'flex-start',
    },
    inline: {
        '& > *': {
            marginInlineEnd: props => {
                const gap = props.gap ? props.gap : '0px';
                if (typeof gap === 'number') {
                    return `${gap}px !important`;
                }
                return `${gap} !important`;
            },
            '&:last-child': {
                marginInlineEnd: '0 !important',
            },
        },
    },
    label: {
        marginBottom: 10,
        fontFamily: 'Heebo, sans-serif',
        fontSize: 16,
        color: '#707070',
        whiteSpace: 'nowrap',

        '@media (max-width: 600px)': {
            fontSize: 11,
            fontWeight: 300,
            marginBottom: 5,
        },
    },
});

function FormGroup(props) {
    const {
        children,
        className,
        inline = false,
        wrap = false,
        gap = 'inherit',
        label = '',
        flexStart = false,
        width = 'auto',
    } = props;
    const classes = useStyles({ inline, gap, wrap, flexStart, width });

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {label && <div className={classes.label}>{label}</div>}
            <div
                className={[classes.main, inline && classes.inline]
                    .filter(Boolean)
                    .join(' ')
                    .trim()}
            >
                {children}
            </div>
        </div>
    );
}

FormGroup.propTypes = {
    children: PropTypes.node,
    inline: PropTypes.bool,
    wrap: PropTypes.bool,
    flexStart: PropTypes.bool,
    label: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

export default FormGroup;
