import { createSlice } from '@reduxjs/toolkit';

export const name = 'donationFlusher';

const VISIBLE_DONATION_LIMIT = 3;

const orderDonationQueue = (a, b) => {
    if (a.isAnonymous) {
        return 1;
    } else if (b.isAnonymous) {
        return -1;
    }

    return b.amount - a.amount;
};

const donationFlasherSlice = createSlice({
    name,
    initialState: {
        isVisible: false,
        showTime: 3,
        showDelay: 5,
        donationsQueue: [],
        visibleDonations: [],
        activeTimerId: null,
        activeIntervalId: null,
    },
    reducers: {
        addDonationFlashMessage(state, { payload: donation }) {
            state.donationsQueue.push(donation);
        },

        showDonationsFlashMessage(state) {
            state.isVisible = true;
            state.visibleDonations = state.donationsQueue
                .sort(orderDonationQueue)
                .slice(0, VISIBLE_DONATION_LIMIT);
            state.donationsQueue = [];
        },

        hideDonationsFlashMessage(state) {
            state.isVisible = false;
        },

        setActiveTimerId(state, { payload: activeTimerId }) {
            state.activeTimerId = activeTimerId;
        },

        setActiveIntervalId(state, { payload: intervalId }) {
            state.activeIntervalId = intervalId;
        },

        stopActiveTimer(state) {
            if (state.activeTimerId) {
                clearTimeout(state.activeTimerId);
                state.activeTimerId = null;
            }
        },

        stopFlashMessagesQueue(state) {
            if (state.activeIntervalId) {
                clearInterval(state.activeIntervalId);
                state.activeIntervalId = null;
            }
            state.donationsQueue = [];
            state.visibleDonations = [];
            state.isVisible = false;
        },
    },
});

export function showFlash() {
    return (dispatch, getState) => {
        const state = getState();

        if (state[name].donationsQueue.length > 0) {
            dispatch(donationFlasherSlice.actions.showDonationsFlashMessage());
            dispatch(
                donationFlasherSlice.actions.setActiveTimerId(
                    setTimeout(() => {
                        dispatch(
                            donationFlasherSlice.actions.hideDonationsFlashMessage(),
                        );
                    }, state[name].showTime * 1000),
                ),
            );
        }
    };
}

export function startFlashMessagesQueue() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(
            donationFlasherSlice.actions.setActiveIntervalId(
                setInterval(() => {
                    if (state[name].isVisible) {
                        dispatch(
                            donationFlasherSlice.actions.stopActiveTimer(),
                        );
                        dispatch(
                            donationFlasherSlice.actions.hideDonationsFlashMessage(),
                        );
                    }
                    dispatch(showFlash());
                }, (state[name].showDelay + state[name].showTime) * 1000),
            ),
        );
    };
}

export const selectDonationFlasher = state => state[name];

export const {
    addDonationFlashMessage,
    showDonationsFlashMessage,
    hideDonationsFlashMessage,
    stopFlashMessagesQueue,
} = donationFlasherSlice.actions;

export default donationFlasherSlice.reducer;
