const _ = require('lodash');

function campaignTitle(campaign) {
    const defaultPage = campaign?.defaultPage;
    return (
        _.get(campaign, 'name') ||
        _.get(
            campaign,
            `landing.locales.${defaultPage}.title`,
            _.get(
                campaign,
                `landing.locales.${defaultPage}.name`,
                _.get(campaign, `locales.${defaultPage}.pageName`, ''),
            ),
        )
    );
}

function campaignOrganization(campaign, page = campaign.defaultPage) {
    return _.get(campaign, `locales.${page}.name`, campaignTitle(campaign));
}

module.exports = {
    campaignTitle,
    campaignOrganization,
};
