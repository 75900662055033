import React from 'react';
import PropTypes from 'prop-types';
import DonorsTabContent from '../donorsList/DonorsTabContent';
import TeamsTabContent from '../layersList/TeamsTabContent';
import AboutTabContent from './AboutTabContent';

function TabContent({ multiplier, tab, donorListProps }) {
    if (tab.id === -1) {
        return <DonorsTabContent multiplier={multiplier} {...donorListProps} />;
    } else if (tab.id === -2) {
        // about tab selected should reset Campaign page to the default state
        return <AboutTabContent />;
    } else if (tab.layer) {
        return <TeamsTabContent />;
    }
    return null;
}

TabContent.propTypes = {
    donorListProps: PropTypes.object,
    multiplier: PropTypes.number,
    tab: PropTypes.shape({
        id: PropTypes.number,
        layer: PropTypes.object,
    }),
};

export default TabContent;
