import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '50px 0 25px',
        color: palette.primary.main,
        fontFamily: 'Heebo, sans-serif',
        fontWeight: 500,
        fontSize: '1.188rem',

        '@media (max-width: 600px)': {
            marginTop: 45,
            fontSize: '0.875rem',
            alignItems: 'center',
        },
    },
    index: {
        borderRadius: '50%',
        background: palette.primary.main,
        marginInlineEnd: '15px',
        width: 27,
        height: 27,
        color: palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        fontSize: '1rem',
    },
    content: {
        '& small': {
            margin: '0 6px',
            fontWeight: 400,
            fontSize: '0.875rem',
        },

        '@media (max-width: 600px)': {
            '& small': {
                display: 'block',
                margin: 0,
                fontSize: '0.75rem',
            },
        },
    },
}));

function StepHeading(props) {
    const { children, className, index } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>
            {typeof index === 'number' && index > 0 && (
                <div className={classes.index}>{index}</div>
            )}
            <div className={classes.content}>{children}</div>
        </div>
    );
}

StepHeading.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    className: PropTypes.string,
};

export default StepHeading;
