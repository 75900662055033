function caseInsensitiveEqual(strA, strB) {
    return (
        strA !== undefined &&
        strB !== undefined &&
        strA !== null &&
        strB !== null &&
        String(strA).toLowerCase() === String(strB).toLowerCase()
    );
}

module.exports = caseInsensitiveEqual;
