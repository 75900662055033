import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
    span: {
        display: 'inline-block',
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1rem',
        color: palette.grey[600],
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        width: '100%',
        '& small': {
            fontSize: '0.813rem',
            fontWeight: 300,
        },
        '& a': {
            fontWeight: 500,
            color: palette.grey[600],
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',

            '& small': {
                fontSize: '0.688rem',
            },
        },
    },
}));

function LinkedText(props) {
    const { children } = props;
    const classes = useStyles();

    return <span className={classes.span}>{children}</span>;
}

LinkedText.propTypes = {
    children: PropTypes.node,
};

export default LinkedText;
