import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';

class MergeTagsRepository extends Repository {
    async getMergeTagDataByContext(context) {
        try {
            const response = await this.axios.get(`/${context}`);
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }
}

export default new MergeTagsRepository({ baseUrl: '/merge-tags' });
