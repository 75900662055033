import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { KinesisClient, PutRecordCommand } from '@aws-sdk/client-kinesis';
import filterQueryParams from '../helpers/filterQueryParams';
import { getTrackId } from '../../common/helpers/trackId';
import { parseCampaignPagePath } from '../helpers/parseCampaignPagePath';
import config from '../config';

const REGION = config.analyticsRegion;
const isConfigAvailable =
    config.analyticsRegion &&
    config.analyticsKinesisStreamName &&
    config.analyticsKinesisStreamArn;

const kinesisClient = isConfigAvailable
    ? new KinesisClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
              identityPoolId: config.analyticsIdentityPoolId,
              clientConfig: { region: REGION },
          }),
      })
    : null;

const sendAnalyticsEvent = async data => {
    if (!kinesisClient) {
        return;
    }
    try {
        const kinesisData = { ...data, ...getCampaignPageBaseData() };
        const input = {
            StreamName: config.analyticsKinesisStreamName,
            Data: JSON.stringify(kinesisData),
            PartitionKey: 'user_session_id',
            StreamARN: config.analyticsKinesisStreamArn,
        };
        const command = new PutRecordCommand(input);
        await kinesisClient?.send(command);
    } catch (err) {
        console.log(err);
    }
};

const getCampaignPageBaseData = () => {
    const { filteredQueryObject: utmData } = filterQueryParams(
        document.location.search,
    );
    const { donorPflSlug } = parseCampaignPagePath();
    return {
        user_tracking_id: getTrackId(),
        user_session_id: document.cookie
            .split(';')
            .find(cookie => cookie.trim().startsWith('vsid='))
            ?.split('=')[1],
        timestamp: new Date().toISOString().replace('T', ' ').replace('Z', ''),
        page_title: document.title,
        page_url: window.location.href,
        utm_source: utmData?.utmSource,
        utm_medium: utmData?.utmMedium,
        utm_campaign: utmData?.utmCampaign,
        utm_term: utmData?.utmTerm,
        utm_content: utmData?.utmContent,
        is_using_pfl: !!donorPflSlug,
    };
};

export { sendAnalyticsEvent };
