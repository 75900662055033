import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function PlusOutlineIcon(props) {
    const { palette } = useTheme();
    return (
        <svg focusable="false" viewBox="0 0 24 24" {...props}>
            <path
                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm5 9h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                fill={palette.background.paper}
            ></path>
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </svg>
    );
}

export default PlusOutlineIcon;
