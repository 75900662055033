import React from 'react';
import PropTypes from 'prop-types';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { ReactComponent as CardIcon } from 'client/components/CampaignPage/components/FormInput/CardIcon.svg';
import { ReactComponent as LockIcon } from 'client/components/CampaignPage/components/FormInput/LockIcon.svg';
import SecurePayments from '../../components/SecurePayments/SecurePayments';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStripeContext } from './StripeProvider';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import focusHandler from '../../../../helpers/focusHandler';

const CardNumber = withController(withFormInput(CardNumberElement));
const CardExpiry = withController(withFormInput(CardExpiryElement));
const CardCvc = withController(withFormInput(CardCvcElement));
const styles = {
    base: {
        color: '#707070',
        '::placeholder': {
            color: '#707070',
        },
    },
};

function StripeCardInfo({
    control,
    rules = { cc: {}, ccv: {}, expire: {} },
    dirtyFields,
    errors = {},
}) {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    return (
        <>
            <FormGroup inline className={classes.fields}>
                <CardNumber
                    type="text"
                    name="cardNumber"
                    id="stripeCardNumber"
                    placeholder="* Card Number"
                    required
                    startIcon={<CardIcon />}
                    className={classes.input}
                    control={control}
                    rules={rules.cc}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    defaultValue=""
                    options={{
                        styles: styles,
                    }}
                    onFocus={focusHandler('stripeCardNumber')}
                />
            </FormGroup>
            <FormGroup
                inline
                flexStart
                gap={19}
                label={formatMessage({
                    id: 'DonationForm.enterCardValidity',
                })}
                className={classes.fields}
                options={{
                    styles: styles,
                }}
            >
                <CardExpiry
                    placeholder="* Expire date"
                    name="expire"
                    id="stripeExpire"
                    width="100%"
                    required
                    className={classes.input}
                    control={control}
                    rules={rules.expire}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    defaultValue=""
                    options={{
                        styles: styles,
                    }}
                    onFocus={focusHandler('stripeExpire')}
                />
                <CardCvc
                    type="text"
                    name="ccv"
                    id="stripeCCV"
                    maxLength={3}
                    placeholder="* CVC / CW"
                    required
                    startIcon={<LockIcon />}
                    className={classes.input}
                    control={control}
                    rules={rules.cvc}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    defaultValue=""
                    onFocus={focusHandler('stripeCCV')}
                />
            </FormGroup>
            <FormGroup>
                <SecurePayments />
            </FormGroup>
        </>
    );
}

const useStyles = makeStyles({
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    input: {
        '@media (max-width: 600px)': {
            '& > div': {
                paddingTop: 11,
            },
        },
    },
});

StripeCardInfo.propTypes = {
    control: PropTypes.object.isRequired,
    rules: PropTypes.shape({
        cc: PropTypes.object.isRequired,
        ccv: PropTypes.object.isRequired,
        expire: PropTypes.object.isRequired,
    }).isRequired,
    dirtyFields: PropTypes.object,
    errors: PropTypes.object,
};

export default withStripeContext(StripeCardInfo);
