import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { LANGUAGES_INFO } from 'common/constants';
import GlobeIcon from './GlobeIcon';
import useIsMobile from 'client/hooks/isMobile';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            position: 'relative',
            marginBottom: spacing(0.5),

            '&::before': {
                border: 'none',
            },
            '&::after': {
                border: 'none',
            },
            '&:hover:not(.Mui-disabled)::before': {
                border: 'none',
            },
            '&:hover:not(.Mui-disabled)::after': {
                border: 'none',
            },

            '@media (max-width: 600px)': {
                marginBottom: '0px',
                marginTop: '3px',
            },
        },

        selectMenu: {
            textTransform: 'uppercase',
            color: palette.text.primary,
            paddingRight: spacing(4),
            paddingLeft: spacing(1),

            'html[dir=rtl] &': {
                paddingRight: spacing(1),
                paddingLeft: spacing(4),
            },

            '@media (max-width: 600px)': {
                paddingRight: spacing(3),
                paddingLeft: 0,

                'html[dir=rtl] &': {
                    paddingRight: 0,
                    paddingLeft: spacing(3),
                },
            },
        },

        arrowIcon: {
            right: 0,
            left: 'auto',
            color: palette.text.primary,
            'html[dir=rtl] &': {
                right: 'auto',
                left: 0,
            },
        },
        globeIcon: {
            fill: palette.text.primary,
            marginTop: -spacing(1),
            '@media (max-width: 600px)': {
                marginTop: 0,
                marginRight: 5,
                'html[dir=rtl] &': {
                    marginLeft: 5,
                },
            },
        },
        truncatedSelectName: {
            maxWidth: '80px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
    { name: 'LanguageSelector' },
);

function twoLetterCodeToLocalName(code) {
    return LANGUAGES_INFO[code]['localName'] || 'Unknown';
}

function LanguageSelector(props) {
    const { onChange, languages, value } = props;
    const [language, setLanguage] = useState(value);
    const classes = useStyles();
    const { isMobile } = useIsMobile();
    const handleChange = event => {
        const foundLang = languages.find(
            language => language.pageName === event.target.value,
        );

        if (typeof onChange === 'function') {
            onChange(foundLang.slug);
        }

        setLanguage(event.target.value);
    };

    useEffect(() => {
        setLanguage(value);
    }, [value]);

    if (languages.length < 2) {
        return null;
    }

    return (
        <>
            <GlobeIcon className={classes.globeIcon} />
            <Select
                className={classes.root}
                classes={{
                    selectMenu: classes.selectMenu,
                    icon: classes.arrowIcon,
                    select: isMobile ? classes.truncatedSelectName : '',
                }}
                IconComponent={KeyboardArrowDown}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                    },
                    transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top',
                    },
                }}
                value={language}
                onChange={handleChange}
            >
                {languages.map(lang => (
                    <MenuItem key={lang.pageName} value={lang.pageName}>
                        {lang.displayPageName
                            ? lang.pageName
                            : twoLetterCodeToLocalName(lang.value)}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}

LanguageSelector.propTypes = {
    languages: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default LanguageSelector;
