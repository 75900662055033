import React from 'react';
import PropTypes from 'prop-types';
import EnglishDarkLogo from './en-dark.png';
import EnglishLightLogo from './en-light.png';
import HebrewDarkLogo from './he-dark.png';
import HebrewLightLogo from './he-light.png';
import useIsMobile from 'client/hooks/isMobile';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useIntl } from 'react-intl';
import { getTextShadeByColor } from '../../../../helpers/colorUtils';
import classNames from 'classnames';

const LOGO_VARIATIONS = {
    en: {
        dark: EnglishDarkLogo,
        light: EnglishLightLogo,
    },
    he: {
        dark: HebrewDarkLogo,
        light: HebrewLightLogo,
    },
};

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 180,
        maxHeight: 45,
    },
    customLogo: {
        width: 270,
        height: 'auto',
    },
}));

function getHeaderImage(
    lang,
    { isEnabled, logo, isMobileEnabled, mobileLogo } = {},
    isMobile,
    backgroundColor,
) {
    const variant = getTextShadeByColor(backgroundColor);
    if (isMobile && isMobileEnabled && mobileLogo) {
        return mobileLogo;
    }
    if (isEnabled && logo) {
        return logo;
    }
    if (lang === 'he') {
        return LOGO_VARIATIONS['he'][variant];
    }
    return LOGO_VARIATIONS['en'][variant];
}

function HeaderLogo(props) {
    const { formatMessage } = useIntl();
    const { palette } = useTheme();
    const {
        headerLogoConfig,
        className,
        lang,
        backgroundColor = palette.background.default,
        ...rest
    } = props;
    const classes = useStyles();
    const { isMobile } = useIsMobile();
    const headerImg = getHeaderImage(
        lang,
        headerLogoConfig,
        isMobile,
        backgroundColor,
    );

    return (
        <img
            src={headerImg}
            alt={formatMessage({
                id: 'headerLogo.causematchLogo',
                defaultMessage: 'Causematch Logo',
            })}
            className={classNames(
                headerLogoConfig?.isEnabled && headerLogoConfig?.logo
                    ? classes.customLogo
                    : classes.root,
                className,
            )}
            {...rest}
        />
    );
}

HeaderLogo.propTypes = {
    className: PropTypes.string,
    lang: PropTypes.string,
    headerLogoConfig: PropTypes.object,
    backgroundColor: PropTypes.string,
};

export default HeaderLogo;
