import Repository from './Repository';
import axios from 'axios';

class LayerRepository extends Repository {
    async getActiveLayers(queryJson) {
        if (typeof queryJson !== 'object') {
            queryJson = { campaignId: queryJson };
        }
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios
            .get('/active' + (queryString ? `?${queryString}` : ''))
            .then(response => response.data);
    }

    async getPublicLayers(queryJson) {
        if (typeof queryJson !== 'object') {
            queryJson = { campaignId: queryJson };
        }
        const queryString = Repository.prepareQueryString(queryJson);
        return axios
            .get('/public/layers' + (queryString ? `?${queryString}` : ''))
            .then(response => response.data);
    }

    async getPublicLayerItemChildLayers({ layerItemId, all = false }) {
        const filters = { all };
        const queryString = Repository.prepareQueryString(filters);
        return axios
            .get(
                `/public/${layerItemId}/child-layers/${
                    queryString ? `?${queryString}` : ''
                }`,
            )
            .then(response => response.data);
    }
}

export default new LayerRepository({ baseUrl: '/layer' });
