import React from 'react';
import { Controller } from 'react-hook-form';

export default function withController(Component) {
    const ComponentWithController = props => {
        return <Controller as={Component} {...props} />;
    };

    return ComponentWithController;
}
