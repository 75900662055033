import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '../Button/Button';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 0,
        padding: '12px 50px',
        textAlign: 'center',
        fontSize: 17,
        lineHeight: 1.2,

        '@media (max-width: 600px)': {
            fontSize: 13,
            padding: '11px 50px',
        },
    },
}));

function MoreButton(props) {
    const { children, className, ...rest } = props;
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Button
            {...rest}
            color={theme.palette.primary.main}
            hoverColor={theme.palette.primary.light}
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {children}
        </Button>
    );
}

MoreButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default MoreButton;
