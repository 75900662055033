import Repository from 'client/services/api/Repository';

const repository = new Repository({
    baseUrl: '/pef-batch',
});

repository.importPEFBatch = async function (file) {
    const formData = new FormData();
    formData.append('file', file || null);

    return this.axios
        .post('/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.upsertPEFBatchNumber = async function (paymentId, data) {
    return this.axios
        .post(`/${paymentId}`, data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

export default repository;
