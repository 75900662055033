const LAYER_ITEM_MESSAGE_STATUSES = Object.freeze({
    NOT_SENT: 'Not Sent',
    IN_PROGRESS: 'In Progress',
    SENT: 'Sent',
    ERROR: 'Error',
});

const LAYER_ITEM_MESSAGE_STATUS_VALUES = Object.freeze(
    Object.values(LAYER_ITEM_MESSAGE_STATUSES),
);

module.exports = {
    LAYER_ITEM_MESSAGE_STATUSES,
    LAYER_ITEM_MESSAGE_STATUS_VALUES,
};
