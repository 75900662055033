import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScrollTo from '../../hooks/scrollTo';
import {
    selectSelectedCampaign,
    selectSelectedCampaignId,
} from '../campaign/campaignSlice';

export default function useDonorsList(slice, layerItem = null) {
    const dispatch = useDispatch();
    const query = useSelector(slice.selectors.selectQuery);
    const donors = useSelector(slice.selectors.selectDonors);
    const groupedDonations = useSelector(
        slice.selectors.selectGroupedDonations,
    );
    const isLoading = useSelector(slice.selectors.selectIsLoading);
    const isEndCollection = useSelector(slice.selectors.selectIsEndCollection);
    const allowAutoLoad = useSelector(slice.selectors.selectAllowAutoLoad);
    const donorsCount = useSelector(slice.selectors.selectDonorsCount);
    const startScrollTop = useSelector(slice.selectors.selectScrollTop);
    const campaign = useSelector(selectSelectedCampaign);
    const campaignId = useSelector(selectSelectedCampaignId);
    const isMounted = useRef(false);
    const listRef = useRef(null);

    const handleQueryChange = useCallback(query => {
        dispatch(slice.actions.resetDonorsAfterLoad());
        dispatch(slice.actions.updateQuery(query));
    }, []);

    const handleAppendNextPage = useCallback(() => {
        dispatch(slice.actions.nextPage());
        dispatch(slice.actions.repeatAutoLoad());
    }, []);

    const handleAutoLoad = useCallback(() => {
        dispatch(slice.actions.autoLoad());
    }, []);

    useScrollTo(listRef, {
        startScrollTop,
        saveScrollPosition: scrollTop =>
            dispatch(slice.actions.updateScrollTop(scrollTop)),
    });

    useEffect(() => {
        // dispatch(slice.actions.resetDonors());
        dispatch(slice.actions.resetDonorsAfterLoad());
    }, [query.searchText]); // @TODO need to check why it is triggering after making a donation

    useEffect(() => {
        if (!campaign) {
            return;
        }

        const requestQuery = {
            ...query,
        };
        let promise = null;

        if (layerItem) {
            requestQuery.layerItemId = layerItem.id;
        }

        if (donors.length) {
            if (isMounted.current) {
                promise = dispatch(slice.actions.fetchDonors(requestQuery));
            }
        } else {
            promise = dispatch(slice.actions.fetchDonors(requestQuery));
        }

        isMounted.current = true;

        return () => {
            if (promise) {
                promise.abort();
            }
        };
    }, [JSON.stringify(query), campaignId]);

    return {
        query,
        donors,
        groupedDonations,
        isLoading,
        isEndCollection,
        allowAutoLoad,
        donorsCount,
        handleAppendNextPage,
        handleQueryChange,
        handleAutoLoad,
        listRef,
    };
}
