const MERGE_TAG_CONTEXTS = Object.freeze({
    CAMPAIGN_ABANDONMENT: 'campaignAbandonment',
    CAMPAIGN_DONATION_MESSAGING: 'campaignDonationMessaging',
    CAMPAIGN_DONATION_RECEIPT: 'campaignDonationReceipt',
    DONATE_PAGE_ABANDONMENT: 'donatePageAbandonment',
    DONATE_PAGE_DONATION: 'donatePageDonation',
    LAYER_ITEM_MESSAGING: 'layerItemMessaging',
    MMG: 'matchMyGift',
    SIGNUP_CONFIRMATION: 'signupConfirmation',
    CAMPAIGN_COMPARE: 'campaignCompare',
});

const MERGE_TAG_CONTEXTS_VALUES = Object.freeze(
    Object.values(MERGE_TAG_CONTEXTS),
);

module.exports = {
    MERGE_TAG_CONTEXTS,
    MERGE_TAG_CONTEXTS_VALUES,
};
