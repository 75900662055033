function addDashedBorderAfterOption(optionCount) {
    return {
        '& .customPopper': {
            '& ul[role="listbox"]': {
                [`& li:nth-child(${optionCount})`]: {
                    borderBottom: '1px dashed rgba(0, 0, 0, 0.23)',
                },
            },
        },
    };
}

module.exports = addDashedBorderAfterOption;
