import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import filterQueryParams from 'client/helpers/filterQueryParams';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import LayersTabContent from '../../components/LayersTabContent/LayersTabContent';
import TeamCard from '../../components/TeamCard/TeamCard';
import TeamCardPopup from '../../components/TeamCardPopup/TeamCardPopup';
import {
    selectCampaignPageData,
    selectCurrency,
    selectCurrentSlug,
    selectEnableDonations,
    selectPageSpecificCampaignData,
    selectPayments,
} from '../campaign/campaignSlice';
import { open } from '../checkout/checkoutPopupSlice';
import { updateDonationCurrency } from '../checkout/checkoutSlice';
import LayerItemDonorsTabContent from '../donorsList/LayerItemDonorsTabContent';
import { slice as donorSlice } from '../donorsList/teamDonorsListSlice';
import { SORT_LAYER_ITEMS } from '../recentDonationPanel/constants';
import {
    fetchLayerItemParentsData,
    resetChildLayers,
    selectChildLayers,
    selectSelectedItemParentsData,
    selectSelectedTab,
} from '../recentDonationPanel/recentDonationPanelSlice';
import LayerItemCard from './LayerItemCard';
import { slice as teamsListSlice } from './teamsListSlice';
import useTabContent from './useTabContent';

function TeamItem(props) {
    return <LayerItemCard component={TeamCard} {...props} />;
}

export default function TeamsTabContent() {
    const dispatch = useDispatch();
    const history = useHistory();
    let reduxProps = useTabContent(teamsListSlice);
    const { formatMessage } = useIntl();
    const selectedItem = useSelector(
        teamsListSlice.selectors.selectSelectedItem,
    );
    const selectedChildLayer = useSelector(
        teamsListSlice.selectors.selectSelectedChildLayer,
    );
    const childLayers = useSelector(selectChildLayers);
    const selectedItemParentsData = useSelector(selectSelectedItemParentsData);
    const currencies = useSelector(selectPayments).currencies;
    const currency = useSelector(selectCurrency);
    const campaignSlug = useSelector(selectCurrentSlug);
    const { logo } = useSelector(selectCampaignPageData);
    const { name: campaignName } = useSelector(selectPageSpecificCampaignData);
    const selectedTab = useSelector(selectSelectedTab);
    const enableCampaignDonations = useSelector(selectEnableDonations);
    const sortOptions = useMemo(
        () => ({
            [SORT_LAYER_ITEMS.LAST_DONATED_TO]: formatMessage({
                id: 'SortBy.lastDonatedTo',
                defaultMessage: 'Last Donated To',
            }),
            [SORT_LAYER_ITEMS.NAME_ASC]: formatMessage({
                id: 'SortBy.name.asc',
            }),
            [SORT_LAYER_ITEMS.NAME_DESC]: formatMessage({
                id: 'SortBy.name.desc',
            }),
            [SORT_LAYER_ITEMS.MOST_RAISED]: formatMessage({
                id: 'SortBy.donationsAmount.desc',
                defaultMessage: 'Most raised',
            }),
            [SORT_LAYER_ITEMS.LEAST_RAISED]: formatMessage({
                id: 'SortBy.donationsAmount.asc',
                defaultMessage: 'Least raised',
            }),
            [SORT_LAYER_ITEMS.MOST_PROGRESS]: formatMessage({
                id: 'SortBy.progress.desc',
                defaultMessage: 'Closest to goal',
            }),
            [SORT_LAYER_ITEMS.LEAST_PROGRESS]: formatMessage({
                id: 'SortBy.progress.asc',
                defaultMessage: 'Farthest from goal',
            }),
            [SORT_LAYER_ITEMS.LAST_ADDED]: formatMessage({
                id: 'SortBy.lastAdded',
                defaultMessage: 'Last Added',
            }),
        }),
        [],
    );
    const { filteredQueryString: queryString } = filterQueryParams(
        document.location.search,
    );

    const handleCloseLayerItem = useCallback(() => {
        dispatch(teamsListSlice.actions.resetQuery());
        dispatch(teamsListSlice.actions.selectChildLayer(null));
        dispatch(teamsListSlice.actions.selectItem(null));
        dispatch(teamsListSlice.actions.resetItems());
        dispatch(donorSlice.actions.reset());
        dispatch(resetChildLayers());
    }, []);

    const handleViewLayerItem = useCallback(() => {
        window.location = `/${campaignSlug}/${
            selectedItem.slug || selectedItem.id
        }/${queryString}`;
    }, [selectedItem ? selectedItem.id : null]);
    const handleDonate = useCallback(() => {
        currencies.includes(selectedItem.currency)
            ? dispatch(updateDonationCurrency(selectedItem.currency))
            : dispatch(updateDonationCurrency(currency));
        dispatch(open({ layerItem: selectedItem }));
        const virtualLocation = `${history.location.pathname.replace(
            /\/+$/,
            '',
        )}/donate/${queryString}`;
        triggerGtmEvent(GTM_EVENTS.CLICK_DONATE, {
            eventCategory: 'layerpopup-donate-button',
        });
        triggerGtmEvent(GTM_EVENTS.OPEN_CHECKOUT, {
            eventCategory: 'virtual-pageview',
            virtualLocation: virtualLocation,
        });
        history.push(virtualLocation);
    }, [selectedItem]);

    const handleChildLayerItemClick = useCallback(id => {
        dispatch(teamsListSlice.actions.resetQuery());
        dispatch(teamsListSlice.actions.resetItemsAfterLoad());
        dispatch(teamsListSlice.actions.selectChildLayer(id));
    });

    useEffect(() => {
        if (selectedItem) {
            dispatch(fetchLayerItemParentsData(selectedItem.id));
        } else {
            dispatch(resetChildLayers());
        }
    }, [selectedItem]);

    if (selectedItem) {
        return (
            <>
                <Breadcrumbs
                    items={[
                        {
                            title: campaignName,
                            url: `/${campaignSlug}/${queryString}`,
                        },
                        ...selectedItemParentsData.map(item => ({
                            title: item.name,
                            url: `/${campaignSlug}/${
                                item.slug || item.id
                            }/${queryString}`,
                        })),
                        {
                            title: selectedItem.name,
                        },
                    ]}
                />
                <TeamCardPopup
                    name={selectedItem.name}
                    description={selectedItem.description}
                    goalAmount={Number(selectedItem.goal)}
                    bonusGoalAmount={Number(selectedItem.bonusGoal)}
                    hasEnteredBonusGoal={
                        selectedItem?.statistics?.hasEnteredBonusGoal
                    }
                    amount={Number(
                        selectedItem.statistics
                            ? selectedItem.statistics.donationsAmount
                            : selectedItem.donationsAmount,
                    )}
                    donorsCount={Number(
                        selectedItem.statistics
                            ? selectedItem.statistics.donorsCount
                            : selectedItem.donorsCount,
                    )}
                    avatarSrc={selectedItem.logo || logo}
                    allowDonations={
                        selectedTab.layer?.allowDonations &&
                        enableCampaignDonations
                    }
                    currency={selectedItem.currency}
                    onDonate={handleDonate}
                    onClose={handleCloseLayerItem}
                    onView={handleViewLayerItem}
                    childLayers={childLayers}
                    selectedChildLayer={selectedChildLayer}
                    donorsClick={() => handleChildLayerItemClick(0)}
                    childLayerButtonClick={handleChildLayerItemClick}
                    isDisableAutoscroll={Boolean(
                        selectedItem.layer.isDisableAutoscroll,
                    )}
                />
                {selectedChildLayer ? (
                    <LayersTabContent
                        {...reduxProps}
                        item={TeamItem}
                        columns={2}
                        height={710}
                        currency={currency}
                        sortOptions={sortOptions}
                    />
                ) : (
                    <LayerItemDonorsTabContent layerItem={selectedItem} />
                )}
            </>
        );
    }

    return (
        <LayersTabContent
            {...reduxProps}
            item={TeamItem}
            columns={2}
            height={710}
            currency={currency}
            sortOptions={sortOptions}
        />
    );
}
