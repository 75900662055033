import React from 'react';
import { useSelector } from 'react-redux';
import { selectLandingData } from '../campaign/campaignSlice';
import PageCenterText from '../../components/PageCenterText/PageCenterText';

export default function AboutTabContent() {
    const { text } = useSelector(selectLandingData);

    return (
        <PageCenterText html alignment="left">
            {text}
        </PageCenterText>
    );
}
