import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/WarningOutlined';
import classNames from 'classnames';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import FormSelectSearch from 'client/components/CampaignPage/components/FormSelectSearch/FormSelectSearch';
import TagItem from 'client/components/CampaignPage/components/TagItem/TagItem';
import api from 'client/services/api';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {},

        selector: {
            width: '100%',
        },

        fields: {
            marginTop: -5,
            marginBottom: 10,
            '@media (max-width: 600px)': {
                marginBottom: '20px !important',
            },
        },

        tag: {
            marginTop: 15,
        },

        selectedItemsGroup: {
            marginBottom: 30,
        },

        selectorGroup: {
            marginBottom: 30,
        },

        selectedItemHelper: {
            width: '100%',
            marginTop: 13,
            marginRight: 0,
            color: palette.grey[600],

            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
                marginTop: 5,
            },
        },

        cursorNotAllowed: {
            '& input': {
                cursor: 'not-allowed',
            },
        },

        disabledMessage: {
            marginInlineEnd: '0 !important',
            color: palette.grey[600],
            padding: '23px',
            textAlign: 'center',
            background: palette.grey[100],
            display: 'flex',

            '@media (max-width: 600px)': {
                fontSize: '0.875rem',
            },

            '& svg': {
                color: palette.secondary.main,
                margin: '0 5px',
            },
        },
    }),
    { name: 'CheckoutLayerItemsSelector' },
);

function LayerItemsSelector(props) {
    const {
        items = [],
        onChange,
        additionQuery = {},
        deferMultiLayerChoice = false,
        disabled = false,
        disabledMessage = null,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const intl = useIntl();
    const [initialOptionsCount, setInitialOptionsCount] = useState(0);
    const [layerItemOptions, setLayerItemOptions] = useState(null);
    const lang = intl.locale;
    const [layerSelected, setLayerSelected] = useState(null);
    const [deferMultiLayer, setDeferMultiLayer] = useState(
        deferMultiLayerChoice,
    );
    const { formatMessage } = useIntl();
    const fetchLayerItems = useCallback(async name => {
        try {
            const data = await api.layerItem.getActiveLayerItems({
                ...additionQuery,
                name,
            });
            const layerItems = data?.filter(
                layer => !items.some(item => layer.id === item.value),
            );

            setInitialOptionsCount(layerItems.length + items.length);

            return layerItems.map(layerItem => ({
                name:
                    get(
                        layerItem,
                        `layer.locales[${lang}].name`,
                        layerItem.layer.name,
                    ) +
                    ' - ' +
                    layerItem.name,
                searchName: layerItem.name,
                value: String(layerItem.id),
                layer: { ...layerItem.layer },
            }));
        } catch (err) {
            console.error(err);
        }
        return null;
    }, []);

    useEffect(() => {
        fetchLayerItems().then(async items => {
            setLayerItemOptions(items);
        });
    }, []);

    const handleChange = (event, option) => {
        let newItems = items;
        if (deferMultiLayer && items[0]) {
            let preSelectedOptionLayerId = false;
            for (let layerItem of layerItemOptions) {
                if (String(layerItem.value) === String(items[0].value)) {
                    preSelectedOptionLayerId = layerItem.layer.id;
                }
            }
            if (
                preSelectedOptionLayerId &&
                preSelectedOptionLayerId != option.layer.id
            ) {
                newItems = items.slice(1);
                onChange(newItems);
                setDeferMultiLayer(false);
            }
        }
        if (!newItems.some(item => item.value === option.value)) {
            onChange([...newItems, option]);
        }
        layerItemOptions.splice(option.index, 1);
        setLayerItemOptions([...layerItemOptions]);
        setLayerSelected(option.value);
        requestAnimationFrame(() => {
            setLayerSelected(null);
        });
    };

    const handleRemove = index => {
        const newItems = items.slice();
        setLayerItemOptions([
            ...layerItemOptions,
            ...newItems.splice(index, 1),
        ]);
        onChange(newItems);
    };

    const renderEmptyMessage = () => (
        <div
            style={{
                padding: 5,
                textAlign: 'center',
                color: palette.grey[600],
            }}
        >
            {initialOptionsCount === items.length ? (
                <FormattedMessage
                    id="Checkout.layerItemsSelector.allSelected"
                    defaultMessage="All available items have been selected"
                />
            ) : (
                <FormattedMessage
                    id="Checkout.layerItemsSelector.notFound"
                    defaultMessage="Sorry, couldn't find any item by that name"
                />
            )}
        </div>
    );

    if (!layerItemOptions && !items.length) {
        return null;
    }

    const renderDisabledMessage = () => {
        if (disabled && disabledMessage) {
            return (
                <span className={classes.disabledMessage}>
                    <InfoIcon />
                    {disabledMessage}
                </span>
            );
        }
    };

    return (
        <div className={classes.root}>
            <FormGroup
                className={classNames(classes.fields, {
                    [classes.selectorGroup]: !items.length,
                })}
            >
                <FormSelectSearch
                    disabled={disabled}
                    width="100%"
                    placeholder={formatMessage({
                        id: 'Checkout.placeholder.items',
                    })}
                    options={layerItemOptions}
                    onChange={handleChange}
                    value={layerSelected}
                    emptyMessage={renderEmptyMessage}
                    closeOnSelect={true}
                    className={disabled ? classes.cursorNotAllowed : ''}
                    fuse={{
                        keys: ['searchName', 'name', 'groupName'],
                        threshold: 0.3,
                        distance: 300,
                    }}
                />
            </FormGroup>
            {items.length > 0 && (
                <FormGroup
                    inline
                    wrap
                    gap={15}
                    className={classNames(
                        classes.fields,
                        classes.selectedItemsGroup,
                    )}
                >
                    {renderDisabledMessage()}
                    <span className={classes.selectedItemHelper}>
                        <FormattedMessage
                            id="Checkout.layerItemsSelector.currentlyChosen"
                            defaultMessage="Currently you have chosen to donate to:"
                        />
                    </span>
                    {items.map((item, index) => (
                        <TagItem
                            key={item.value}
                            onClose={() => handleRemove(index)}
                            className={classes.tag}
                        >
                            {item.name}
                        </TagItem>
                    ))}
                </FormGroup>
            )}
        </div>
    );
}

LayerItemsSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array,
    additionQuery: PropTypes.object,
    deferMultiLayerChoice: PropTypes.bool,
    disabledMessage: PropTypes.string,
    disabled: PropTypes.bool,
};

export default LayerItemsSelector;
