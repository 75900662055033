import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Text from '../Text/Text';
import { scrollToElement } from '../../hooks/scrollTo';

const useStyles = makeStyles(
    () => ({
        root: {
            textAlign: 'center',
            fontSize: '1.2rem',
            color: '#666',
            padding: '20px 30px',
        },

        text: {
            textShadow: '0 0 2px rgba(0,0,0,.1)',
            color: 'orange',
            fontSize: '1.2rem',
        },
    }),
    { name: 'PopupMessage' },
);

function PopupMessage(props) {
    const { className, children } = props;
    const classes = useStyles();
    const popupRef = useRef();

    useEffect(() => {
        if (popupRef && popupRef.current) {
            scrollToElement(popupRef.current);
        }
    }, []);

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
            ref={popupRef}
        >
            <Text className={classes.text}>{children}</Text>
        </div>
    );
}

PopupMessage.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default PopupMessage;
