const ABANDONED_DELAY_UNITS = Object.freeze({
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days',
});

const ABANDONED_DELAY_UNIT_VALUES = Object.freeze(
    Object.values(ABANDONED_DELAY_UNITS),
);

const ABANDONED_DEFAULT_RECOVERY_EMAIL_SETTINGS = Object.freeze({
    IS_ENABLED: false,
    DELAY: 30,
    DELAY_UNITS: ABANDONED_DELAY_UNITS.MINUTES,
    FROM_NAME: null,
    FROM_EMAIL: null,
    EMAIL_SUBJECT_VALUE: '',
    EMAIL_BODY_VALUE: `Dear {{NAME}},

Recovery email goes to the Donor

Best Regards,
`,
});

const ABANDONED_DEFAULT_NOTIFICATION_SETTINGS = Object.freeze({
    IS_ENABLED: false,
    DELAY: 10,
    DELAY_UNITS: ABANDONED_DELAY_UNITS.MINUTES,
    FROM_NAME: null,
    FROM_EMAIL: null,
    EMAIL_SUBJECT_VALUE: '',
    EMAIL_BODY_VALUE: `Dear {{NAME}},

Recovery email goes to the client (campaign owner)

Best Regards,
CauseMatch Team
`,
});

module.exports = {
    ABANDONED_DELAY_UNITS,
    ABANDONED_DELAY_UNIT_VALUES,
    ABANDONED_DEFAULT_RECOVERY_EMAIL_SETTINGS,
    ABANDONED_DEFAULT_NOTIFICATION_SETTINGS,
};
