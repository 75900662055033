const USER_PASSWORD_RESET_LINK_TTL = 72 * 3600;
const USER_PASSWORD_SALT_LENGTH = 10;

const USER_STATUS = Object.freeze({
    PENDING: 0,
    ACTIVATED: 1,
    DEACTIVATED: 2,
});

const USER_AUTH_METHOD = Object.freeze({
    GOOGLE: 'google',
    PASSWORD: 'password',
});

const USER_ROLE_VALUES = Object.freeze([
    'Admin',
    'Client',
    'Client Account Admin',
    'Fiscal Admin',
    'Fundraiser',
    'Layer Admin',
    'Layer Signup User',
    'Manager',
    'Rally Event User',
    'Super Admin',
]);

const VERIFICATION_LINK_TYPE = Object.freeze({
    ACTIVATION: 'activation',
    RESET_PASSWORD: 'reset-password',
});

const USER_ROLE_TYPES = Object.freeze({
    ADMIN: 'admin',
    CLIENT: 'client',
    CLIENT_ACCOUNT_ADMIN: 'clientaccountadmin',
    DONOR: 'donor',
    FISCALADMIN: 'fiscaladmin',
    FUNDRAISER: 'fundraiser',
    LAYERADMIN: 'layeradmin',
    MANAGER: 'manager',
    RALLYEVENTUSER: 'rallyeventuser',
    SUPERADMIN: 'superadmin',
    USER: 'user',
});

// currently used only when loading campaign and layerItem
const USER_SAFE_ATTRIBUTES = Object.freeze([
    'id',
    'email',
    'firstName',
    'lastName',
    'name',
    'phone',
    'language',
    'locale',
]);

const IS_GOOGLE_ACCOUNT_COOKIE_NAME = 'gidp';
const PUBLIC_SESSION_COOKIE_PREFIX = 'psid';
const VISITOR_SESSION_COOKIE_NAME = 'vsid';

module.exports = {
    USER_AUTH_METHOD,
    USER_PASSWORD_RESET_LINK_TTL,
    USER_PASSWORD_SALT_LENGTH,
    USER_ROLE_TYPES,
    USER_ROLE_VALUES,
    USER_SAFE_ATTRIBUTES,
    USER_STATUS,
    VERIFICATION_LINK_TYPE,
    IS_GOOGLE_ACCOUNT_COOKIE_NAME,
    PUBLIC_SESSION_COOKIE_PREFIX,
    VISITOR_SESSION_COOKIE_NAME,
};
