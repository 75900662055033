import React from 'react';

function OjcFundIconChecked(props) {
    return (
        <svg
            version="1.1"
            width="243.393"
            height="98.442"
            viewBox="0 0 243.393 98.442"
            {...props}
        >
            <defs>
                <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="128.1006"
                    y1="49.221"
                    x2="215.9375"
                    y2="49.221"
                >
                    <stop offset="0.0323" stopColor="#3456A5" />
                    <stop offset="0.2097" stopColor="#3456A5" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1="76.6595"
                    y1="66.5264"
                    x2="130.2078"
                    y2="86.0164"
                >
                    <stop offset="0.0323" stystopColor="#3456A5" />
                    <stop offset="0.5323" stopColor="#3456A5" stopOpacity="0" />
                </linearGradient>
            </defs>
            <g>
                <path
                    fill="#45A5FF"
                    d="M176.914,98.442c-28.011,0-48.813-21.617-48.813-48.947v-0.273C128.101,22.163,148.498,0,177.728,0
		c17.949,0,28.694,5.982,37.529,14.686l-13.324,15.362c-7.341-6.663-14.819-10.74-24.339-10.74
		c-16.042,0-27.602,13.325-27.602,29.641v0.272c0,16.314,11.288,29.912,27.602,29.912c10.876,0,17.54-4.346,25.019-11.148
		l13.325,13.461C206.147,91.919,195.271,98.442,176.914,98.442"
                />
                <polygon
                    fill="#fff"
                    points="170.881,43.86 170.881,47.876 179.875,47.876 179.875,51.647 170.881,51.647 170.881,58.95 
		166.734,58.95 166.734,40.09 181.091,40.09 181.091,43.86 	"
                />
                <path
                    fill="#fff"
                    d="M192.26,59.245c-5.061,0-8.159-2.828-8.159-8.379V40.09h4.146v10.667
		c0,3.072,1.534,4.662,4.066,4.662c2.533,0,4.07-1.539,4.07-4.525V40.09h4.147v10.641C200.53,56.438,197.325,59.245,192.26,59.245"
                />
                <polygon
                    fill="#fff"
                    points="217.977,58.948 208.841,46.959 208.841,58.948 204.749,58.948 204.749,40.089 
		208.573,40.089 217.408,51.7 217.408,40.089 221.504,40.089 221.504,58.948 	"
                />
                <path
                    fill="#fff"
                    d="M233.374,58.946h-7.355V40.09h7.355c5.927,0,10.019,4.065,10.019,9.377v0.052
		C243.393,54.823,239.301,58.946,233.374,58.946 M239.058,49.519c0-3.339-2.29-5.682-5.684-5.682h-3.205v11.365h3.205
		c3.394,0,5.684-2.289,5.684-5.628V49.519z"
                />
                <path
                    opacity="0.9"
                    fill="url(#SVGID_1_)"
                    d="M176.914,98.442c-28.011,0-48.813-21.619-48.813-48.949v-0.271
		C128.101,22.163,148.498,0,177.728,0c17.949,0,28.694,5.981,37.529,14.685l-13.324,15.362
		c-7.341-6.661-14.819-10.738-24.339-10.738c-16.042,0-27.602,13.325-27.602,29.639v0.274c0,16.314,11.288,29.91,27.602,29.91
		c10.876,0,17.54-4.344,25.019-11.146l13.325,13.459C206.147,91.919,195.271,98.442,176.914,98.442"
                />
                <path
                    fill="#45A5FF"
                    d="M101.725,97.489c-14.822,0-24.612-6.523-31.139-14.275L83.777,68.53
		c5.437,5.986,10.74,9.518,17.405,9.518c7.883,0,12.777-4.758,12.777-15.771V0.953h21.484v62.272
		C135.444,86.338,121.845,97.489,101.725,97.489"
                />
                <path
                    opacity="0.9"
                    fill="url(#SVGID_2_)"
                    d="M101.725,97.489c-14.822,0-24.612-6.523-31.139-14.275L83.777,68.53
		c5.437,5.986,10.74,9.518,17.405,9.518c7.883,0,12.777-4.758,12.777-15.771l21.484,0.949
		C135.444,86.338,121.845,97.489,101.725,97.489"
                />
                <path
                    fill="#45A5FF"
                    d="M50.443,98.442C21.073,98.442,0,76.551,0,49.493v-0.272C0,22.167,21.344,0.003,50.715,0.003
		c29.366,0,50.445,21.891,50.445,48.948v0.27C101.16,76.278,79.813,98.442,50.443,98.442 M79.269,49.221
		c0-16.315-11.965-29.912-28.825-29.912c-16.861,0-28.554,13.323-28.554,29.643v0.27c0,16.319,11.965,29.911,28.825,29.911
		c16.861,0,28.554-13.322,28.554-29.638V49.221z"
                />
            </g>
        </svg>
    );
}

export default OjcFundIconChecked;
