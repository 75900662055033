import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import useLoadScript from '../../hooks/loadScript';
import { useIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import {
    paymentFailed,
    resetGiving,
    selectGivingAmount,
    selectGivingCount,
    selectGivingId,
    setPaymentAlert,
    updateDonationAmount,
} from './checkoutSlice';
import focusElement from 'client/helpers/focusElement';
import wait from 'common/helpers/wait';

const CHARIOT_SOURCE_URL = 'https://cdn.givechariot.com/chariot-connect.umd.js';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    loadingContainer: {
        padding: 12,
        fontSize: '1.25rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loader: {
        paddingRight: 8,
        paddingLeft: 8,
    },
}));

function ChariotButton({ cid, makeDonation, onDonateSuccess, onDonateFailed }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const scriptStatus = useLoadScript(CHARIOT_SOURCE_URL);
    const connectRef = useRef(null);
    const loadingRef = useRef(false);
    const { formatMessage } = useIntl();
    const { handleSubmit, getValues, setValue } = useFormContext();
    const givingId = useSelector(selectGivingId);
    const givingAmount = useSelector(selectGivingAmount);
    const givingCount = useSelector(selectGivingCount);

    const checkIsValid = async () => {
        return new Promise((resolve, reject) =>
            handleSubmit(() => resolve(true), reject)(),
        ).catch(errors => {
            if (errors?.amount) {
                setTimeout(() => focusElement('[name="amount"]'), 25);
            }
            return false;
        });
    };

    const handleDonationRequest = async () => {
        const isFormValid = await checkIsValid();
        if (!isFormValid || loadingRef.current) {
            return false;
        }

        loadingRef.current = true;

        const values = getValues();
        const { amount, firstName, lastName, email, phonePrefix, phone } =
            values;

        const donationInfo = {
            amount: Math.round(amount * 100),
            firstName,
            lastName,
        };

        if (email) {
            donationInfo.email = email;
        }

        if (phonePrefix && phone) {
            donationInfo.phone = `${phonePrefix}${phone}`;
        }

        return donationInfo;
    };

    const handleSuccess = async ({ detail }) => {
        const amount = detail.grantIntent.amount / 100;

        dispatch(updateDonationAmount(amount));
        setValue('amount', amount);

        let removeGiving = false;
        if (givingId) {
            const totalGivingAmount = givingAmount * givingCount;
            if (amount < totalGivingAmount || amount > totalGivingAmount) {
                removeGiving = true;

                dispatch(resetGiving());
                dispatch(
                    setPaymentAlert(
                        formatMessage({
                            id:
                                amount < totalGivingAmount
                                    ? 'DonationForm.alert.perkAmountUnder'
                                    : 'DonationForm.alert.perkAmountOver',
                        }),
                    ),
                );

                await wait(3);
            }
        }

        const { layerItems, ...donationData } = getValues();

        const donations = await makeDonation(donationData, layerItems, detail, {
            removeGiving,
        });

        if (typeof onDonateSuccess === 'function' && donations) {
            onDonateSuccess(donations);
        }

        loadingRef.current = false;
    };

    const handleExit = ({ detail }) => {
        if (detail.reason !== 'USER_EXIT') {
            if (typeof onDonateFailed === 'function') {
                onDonateFailed(new Error(detail.description));
            }
            dispatch(paymentFailed(detail.description));
        }

        loadingRef.current = false;
    };

    useEffect(() => {
        if (scriptStatus === 'ready' && !connectRef.current) {
            const connect = document.createElement('chariot-connect');
            connect.setAttribute('cid', cid);

            connect.addEventListener('CHARIOT_INIT', () => {
                connect.onDonationRequest(handleDonationRequest);
            });

            connect.addEventListener('CHARIOT_SUCCESS', handleSuccess);

            connect.addEventListener('CHARIOT_EXIT', handleExit);

            connectRef.current = connect;

            const container = document.getElementById(
                'chariot-connect-container',
            );
            container?.appendChild(connect);
        }
    }, [scriptStatus, cid]);

    return (
        <div className={classes.root}>
            <div
                id="chariot-connect-container"
                className={classes.chariotContainer}
            ></div>
            {loadingRef.current && (
                <div className={classes.loadingContainer}>
                    <FormattedMessage id="Checkout.pending" />
                    <FontAwesomeIcon
                        className={classes.loader}
                        icon={faCircleNotch}
                        spin
                    />
                </div>
            )}
        </div>
    );
}

ChariotButton.propTypes = {
    cid: PropTypes.string,
    makeDonation: PropTypes.string,
    onReady: PropTypes.func,
    onDonateSuccess: PropTypes.func,
    onDonateFailed: PropTypes.func,
};

export default ChariotButton;
