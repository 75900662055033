const DONATE_PAGE_AGGREGATED_PROPS = Object.freeze([
    'general',
    'abandoned',
    'checkout',
    'creative',
    'financials',
    'tracking',
    'thankyou',
]);

module.exports = {
    DONATE_PAGE_AGGREGATED_PROPS,
};
