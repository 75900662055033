import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AmountInput from 'client/components/CampaignPage/components/AmountInput/AmountInput';
import useIsMobile from 'client/hooks/isMobile';
import { PAYMENT_MODES } from 'common/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import { selectPaymentModes } from '../../features/checkout/checkoutSlice';
import ArrowCircleIcon from '../ArrowCircleIcon/ArrowCircleIcon';
import FormGroup from '../FormGroup/FormGroup';
import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';
import MultiplierIcon from '../MultiplierIcon/MultiplierIcon';

const useStyles = makeStyles(({ palette }) => ({
    root: {},
    icon: {
        '@media (max-width: 600px)': {
            height: 39,
        },
    },
    outer: {
        '& > div': {
            '@media (max-width: 600px)': {
                flexFlow: 'column wrap',
                alignItems: 'center',
            },
        },
    },
    donation: {
        flexGrow: 1,
        marginBottom: 0,
        '& > div': {
            color: palette.primary.main,
            '@media (min-width: 600px)': {
                'html[lang=de] &': {
                    fontSize: '0.813rem',
                },
            },
        },
        '@media (max-width: 600px)': {
            flexGrow: 0,
            width: '100%',
        },
    },
    period: {
        flexGrow: 1,
        marginBottom: 0,
        '& > div': {
            color: palette.primary.main,
            '@media (min-width: 600px)': {
                'html[lang=de] &': {
                    fontSize: '0.813rem',
                },
            },
        },
        '@media (max-width: 600px)': {
            flexGrow: 0,
            width: '100%',
            marginInlineEnd: '0 !important',
        },
    },
    get: {
        flexGrow: 1,
        marginBottom: 0,
        '& > div': {
            color: palette.primary.main,
            '@media (min-width: 600px)': {
                'html[lang=de] &': {
                    fontSize: '0.813rem',
                },
            },
        },

        '@media (max-width: 600px)': {
            marginTop: 14,
            width: '100%',
        },
    },
    arrow: {
        marginTop: 34,
        flexShrink: 0,

        '@media (max-width: 600px)': {
            marginTop: 22,
            width: 21,
            height: 21,
            transform: 'rotate(90deg)',
        },

        'html[dir=rtl] &': {
            transform: 'rotate(180deg)',
            transformOrigin: 'center center',
        },
    },
    arrow2: {
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
    input: {
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            textAlign: 'center',

            '@media (max-width: 600px)': {
                padding: '5px 15px',
                fontSize: '0.813rem',
                height: 38,
            },
        },
    },
    total: {
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            fontWeight: 'bold',
            textAlign: 'center',

            '@media (max-width: 600px)': {
                padding: '5px 15px',
                fontSize: '0.813rem',
                height: 38,
            },
        },
    },
    select: {
        minWidth: 150,
        width: '100%',
        borderColor: palette.primary.main,
        '&::after': {
            borderColor: palette.primary.main,
        },
        '& select': {
            color: palette.primary.main,

            '@media (max-width: 600px)': {
                paddingInlineStart: '15px',
                fontSize: '0.813rem',
                height: 38,
            },
        },
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
    },
    tooltip: {
        color: palette.primary.main,
        fontWeight: 900,
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
    },
    matching: {
        marginInlineStart: '18px',
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1rem',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            marginInlineStart: '10px',
            fontSize: '0.875rem',
        },
    },
}));

function RecurringWidget(props) {
    const {
        className,
        amount = 0,
        multiplier = 1,
        onChange,
        onBlur,
        selectProps,
        maxMonths = 12,
        duration = 12,
        paymentMode = 'recurring',
        currencySign = '$',
        error,
        onCurrencyChange,
        onPaymentModeChange,
        currencies,
        currency = 'USD',
        disableAll = false,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const { allowUnlimitedRecurring = false } = useSelector(selectPaymentModes);
    const { isMobile } = useIsMobile();
    const { formatMessage } = useIntl();
    const [decimalTooltip, setDecimalTooltip] = useState(false);
    const onlyUnlimitedRecurringAvailable =
        allowUnlimitedRecurring && maxMonths === 0;
    const isUnlimitedRecurringSelected =
        onlyUnlimitedRecurringAvailable ||
        paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING;

    const [selectedDuration, setSelectedDuration] = useState(duration);

    useEffect(() => {
        setSelectedDuration(duration);
    }, [duration]);

    const options = useMemo(() => {
        if (!selectProps.options) {
            let options = [];
            if (allowUnlimitedRecurring) {
                options.push({
                    name: formatMessage({
                        id: 'Checkout.unlimitedRecurringDropdownOption',
                        defaultMessage: 'Monthly',
                    }),
                    value: 0,
                    selected:
                        paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING
                            ? true
                            : false,
                });
            }
            for (let period = 2; period <= maxMonths; period++) {
                options.push({
                    name: formatMessage({ id: 'Month' }, { count: period }),
                    value: period,
                    selected:
                        paymentMode !== PAYMENT_MODES.UNLIMITED_RECURRING
                            ? period === selectedDuration
                            : false,
                });
            }
            return options;
        }
        return selectProps.options;
    }, [selectedDuration, maxMonths, allowUnlimitedRecurring, paymentMode]);

    const handleDurationChange = duration => {
        setSelectedDuration(duration);
        if (typeof selectProps.onChange === 'function') {
            selectProps.onChange(duration);
        }
    };

    const handleRecurringOptionChange = event => {
        const duration = parseInt(event.target.value);
        if (
            allowUnlimitedRecurring &&
            typeof onPaymentModeChange === 'function'
        ) {
            if (event.target.selectedIndex === 0) {
                onPaymentModeChange(PAYMENT_MODES.UNLIMITED_RECURRING)();
                return;
            } else if (paymentMode !== PAYMENT_MODES.RECURRING) {
                onPaymentModeChange(PAYMENT_MODES.RECURRING)();
            }
        }
        handleDurationChange(duration);
    };

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <FormGroup inline={!isMobile} gap={20} className={classes.outer}>
                <AmountInput
                    className={classes.donation}
                    label={
                        onlyUnlimitedRecurringAvailable
                            ? formatMessage({
                                  id: 'Checkout.yourMonthlyDonation',
                              })
                            : formatMessage({ id: 'Checkout.yourDonation' })
                    }
                    onCurrencyChange={onCurrencyChange}
                    onAmountBlur={onBlur}
                    onAmountChange={onChange}
                    onAmountDecimal={setDecimalTooltip}
                    currency={currency}
                    currencies={currencies}
                    defaultAmount={amount}
                    disabled={disableAll}
                />
                {!onlyUnlimitedRecurringAvailable && (
                    <>
                        <ArrowCircleIcon className={classes.arrow} />
                        <FormGroup
                            className={classes.period}
                            label={formatMessage({ id: 'Recurring.duration' })}
                        >
                            <FormSelect
                                className={classes.select}
                                {...selectProps}
                                options={options}
                                onChange={handleRecurringOptionChange}
                                disabled={disableAll}
                            />
                        </FormGroup>
                        {!isUnlimitedRecurringSelected && (
                            <>
                                <ArrowCircleIcon
                                    className={[classes.arrow, classes.arrow2]
                                        .filter(Boolean)
                                        .join(' ')
                                        .trim()}
                                />
                                <FormGroup
                                    className={classes.get}
                                    label={formatMessage({
                                        id: 'Recurring.theyGet',
                                    })}
                                >
                                    <FormInput
                                        className={classes.total}
                                        value={`${currencySign}${
                                            amount * selectedDuration
                                        }`}
                                        disabled
                                    />
                                </FormGroup>
                            </>
                        )}
                    </>
                )}
            </FormGroup>
            <div className={classes.messages}>
                {decimalTooltip && (
                    <FormInputHelperText className={classes.tooltip}>
                        <FormattedMessage
                            id="EnterAmountInput.decimalTooltip"
                            defaultMessage="Decimals are not allowed in donation amount"
                        />
                    </FormInputHelperText>
                )}
                {error && <FormInputHelperText error={error} />}
            </div>
            {multiplier > 1 && (
                <div className={classes.bottom}>
                    <MultiplierIcon
                        multiplier={multiplier}
                        height={51}
                        fill={palette.primary.main}
                        className={classes.icon}
                    />
                    <div className={classes.matching}>
                        <FormattedMessage
                            id={
                                isUnlimitedRecurringSelected
                                    ? 'Matching.amountPerMonth'
                                    : 'Matching.amount'
                            }
                            values={{
                                matchAmount: isUnlimitedRecurringSelected
                                    ? amount * multiplier
                                    : amount * selectedDuration * multiplier,
                                currencySign,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

RecurringWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    amount: PropTypes.number,
    multiplier: PropTypes.number,
    selectProps: PropTypes.object,
    className: PropTypes.string,
    duration: PropTypes.number,
    disableAll: PropTypes.bool,
    paymentMode: PropTypes.string,
    maxMonths: PropTypes.number,
    currencySign: PropTypes.string,
    error: PropTypes.object,
    currencies: PropTypes.array,
    currency: PropTypes.string,
    onCurrencyChange: PropTypes.func.isRequired,
    onPaymentModeChange: PropTypes.func.isRequired,
};

export default RecurringWidget;
