import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { languageDirection } from 'common/helpers/languages';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { ReactComponent as CardIcon } from 'client/components/CampaignPage/components/FormInput/CardIcon.svg';
import { ReactComponent as LockIcon } from 'client/components/CampaignPage/components/FormInput/LockIcon.svg';
import SecurePayments from '../../components/SecurePayments/SecurePayments';
import useIsMobile from 'client/hooks/isMobile';
import useLoadScript from '../../hooks/loadScript';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import focusHandler from '../../../../helpers/focusHandler';

const SOURCE_URL = 'https://tokenization.banquestgateway.com/tokenization/v0.2';
const TEST_SOURCE_URL =
    'https://tokenization.sandbox.banquestgateway.com/tokenization/v0.2';

const librarySource = isTestMode => (isTestMode ? TEST_SOURCE_URL : SOURCE_URL);

const useStyles = makeStyles({
    root: {
        position: 'relative',
    },
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    field: {
        height: 54,

        '@media (max-width: 600px)': {
            height: 42,
        },
    },
    cardForm: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
});

function cardFormStyles(direction, width, containerWidth) {
    const isWide = width > 600;
    const validityWidth = (containerWidth - 19) / 2;

    const container = [
        'position: aboslute',
        'top: 0',
        'right: 0',
        'bottom: 0',
        'left: 0',
    ];

    const card = [
        `direction: ${direction}`,
        'position: absolute',
        'background-color: transparent',
        `top: ${isWide ? 16 : 11}px`,
        `width: ${isWide ? containerWidth - 140 : containerWidth - 110}px`,
    ];

    const expiryContainer = [
        `direction: ${direction}`,
        'position: absolute',
        'background-color: transparent',
        `bottom: ${isWide ? 100 : 104}px`,
        `width: ${isWide ? validityWidth - 60 : validityWidth - 50}px`,
    ];

    const expiryMonth = [
        `direction: ${direction}`,
        'background-color: transparent',
        'width: 32px',
    ];

    const expiryYear = [
        `direction: ${direction}`,
        'background-color: transparent',
        'width: 32px',
    ];

    const cvv2 = [
        `direction: ${direction}`,
        `position: absolute`,
        'background-color: transparent',
        `bottom: ${isWide ? 100 : 104}px`,
        `width: ${isWide ? validityWidth - 140 : validityWidth - 110}px`,
    ];

    const positionProp = direction === 'rtl' ? 'right' : 'left';
    card.push(`${positionProp}: ${isWide ? 80 : 70}px`);
    expiryContainer.push(`${positionProp}: ${isWide ? 30 : 25}px`);
    cvv2.push(
        `${positionProp}: ${
            isWide ? validityWidth + 99 : validityWidth + 89
        }px`,
    );

    return {
        container: container.join(';'),
        card: card.join(';'),
        expiryContainer: expiryContainer.join(';'),
        expiryMonth: expiryMonth.join(';'),
        expiryYear: expiryYear.join(';'),
        cvv2: cvv2.join(';'),
    };
}

const BanquestInput = ({
    name,
    internalControl,
    dirtyFields,
    internalErrors,
    children,
}) => {
    const { setValue } = internalControl;
    const isDirty = dirtyFields[name];
    const errorMessage = internalErrors[name];

    useEffect(() => {
        if (setValue && isDirty) {
            setValue(
                name,
                {
                    empty: false,
                    error: errorMessage ? { message: errorMessage } : undefined,
                },
                { shouldValidate: true, shouldDirty: true },
            );
        }
    }, [setValue, name, isDirty, errorMessage]);

    return <>{children}</>;
};
BanquestInput.propTypes = {
    name: PropTypes.string,
    internalControl: PropTypes.object,
    dirtyFields: PropTypes.object,
    internalErrors: PropTypes.object,
    children: PropTypes.node,
};

const CardNumberElement = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <BanquestInput {...props}>
            <div
                id="banquest-card-number"
                className={classNames(classes.field, className)}
            ></div>
        </BanquestInput>
    );
};
CardNumberElement.propTypes = { className: PropTypes.string };

const CardExpiryElement = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <BanquestInput {...props}>
            <div
                id="banquest-expiry"
                className={classNames(classes.field, className)}
            ></div>
        </BanquestInput>
    );
};
CardExpiryElement.propTypes = { className: PropTypes.string };

const CardCvvElement = ({ className, ...props }) => {
    const classes = useStyles();
    return (
        <BanquestInput {...props}>
            <div
                id="banquest-cvv"
                className={classNames(classes.field, className)}
            ></div>
        </BanquestInput>
    );
};
CardCvvElement.propTypes = { className: PropTypes.string };

const BanquestCardNumber = withController(withFormInput(CardNumberElement));
const BanquestCardExpiry = withController(withFormInput(CardExpiryElement));
const BanquestCardCvv = withController(withFormInput(CardCvvElement));

function BanquestCardInfo({
    tokenizationPublicKey,
    testMode,
    locale,
    errors = {},
    onInstance,
}) {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { control } = useFormContext();
    const { width } = useIsMobile();
    const containerRef = useRef();
    const [containerWidth, setContainerWidth] = useState(width);
    const [cardForm, setCardForm] = useState(null);
    const [internalErrors, setinternalErrors] = useState({});
    const [dirtyFields, setDirtyFields] = useState({});

    const libraryStatus = useLoadScript(librarySource(testMode));

    const handleChange = useCallback(
        ({ error, result }) => {
            const internalErrors = {};

            if (!result.last4) {
                internalErrors.banquestCardNumber = formatMessage({
                    id: 'DonationForm.cardNumber.invalid',
                    defaultMessage: 'Card number is invalid',
                });
            }

            const now = new Date();
            const month = now.getMonth() + 1;
            const year = now.getFullYear();
            if (
                isNaN(result.expiryMonth) ||
                isNaN(result.expiryYear) ||
                result.expiryMonth < 1 ||
                result.expiryMonth > 12 ||
                result.expiryYear < year ||
                (result.expiryYear === year && result.expiryMonth < month)
            ) {
                internalErrors.banquestExpiry = formatMessage({
                    id: 'DonationForm.expire.invalid',
                    defaultMessage: 'Card expiration date is invalid',
                });
            }

            if (!result.maskedCvv2 || (error && error.includes('CVV'))) {
                internalErrors.banquestCvv = formatMessage({
                    id: 'DonationForm.cvv.invalid',
                    defaultMessage: 'Card CVV is invalid',
                });
            }

            setDirtyFields({
                banquestCardNumber: true,
                banquestExpiry: true,
                banquestCvv: true,
            });
            setinternalErrors(internalErrors);
        },
        [formatMessage, setinternalErrors],
    );

    useLayoutEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    }, [width, containerRef]);

    useEffect(() => {
        if (libraryStatus === 'ready' && window.HostedTokenization) {
            const hostedTokenization = new window.HostedTokenization(
                tokenizationPublicKey,
            );

            const cardForm = hostedTokenization.create('card-form');
            cardForm.mount('#banquest-card-form');
            cardForm.setStyles(
                cardFormStyles(
                    languageDirection(locale),
                    width,
                    containerWidth,
                ),
            );

            cardForm.on('change', handleChange);

            setCardForm(cardForm);
        }
    }, [tokenizationPublicKey, libraryStatus, handleChange]);

    useEffect(() => {
        if (!cardForm) {
            return;
        }

        cardForm.setStyles(
            cardFormStyles(languageDirection(locale), width, containerWidth),
        );
    }, [locale, width, containerWidth, cardForm]);

    useEffect(() => {
        if (!cardForm || typeof onInstance !== 'function') {
            return;
        }

        const instance = {
            isError: Object.keys(internalErrors).length > 0,
            async getToken({ paymentMode }) {
                const token = {};
                token.charge = await cardForm.getNonceToken();

                if (paymentMode !== 'regular') {
                    token.paymentMethod = await cardForm.getNonceToken();
                }

                return token;
            },
        };

        onInstance(instance);
    }, [cardForm, internalErrors, onInstance]);

    useEffect(() => {
        return () => cardForm?.destroy();
    }, [cardForm]);

    return (
        <div ref={containerRef} className={classes.root}>
            <FormGroup inline className={classes.fields}>
                <BanquestCardNumber
                    type="text"
                    name="banquestCardNumber"
                    required
                    startIcon={<CardIcon />}
                    control={control}
                    rules={{ required: true }}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    internalControl={control}
                    internalErrors={internalErrors}
                    defaultValue=""
                    onFocus={focusHandler('banquest-card-number')}
                />
            </FormGroup>
            <FormGroup
                inline
                gap={19}
                label={formatMessage({
                    id: 'DonationForm.enterCardValidity',
                })}
                className={classes.fields}
            >
                <BanquestCardExpiry
                    name="banquestExpiry"
                    width="100%"
                    required
                    control={control}
                    rules={{ required: true }}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    internalControl={control}
                    internalErrors={internalErrors}
                    defaultValue=""
                    onFocus={focusHandler('banquest-expiry')}
                />
                <BanquestCardCvv
                    type="text"
                    name="banquestCvv"
                    required
                    startIcon={<LockIcon />}
                    control={control}
                    rules={{ required: true }}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    internalControl={control}
                    internalErrors={internalErrors}
                    defaultValue=""
                    onFocus={focusHandler('banquest-cvv')}
                />
            </FormGroup>
            <FormGroup>
                <SecurePayments />
            </FormGroup>
            <div id="banquest-card-form" className={classes.cardForm}></div>
        </div>
    );
}

BanquestCardInfo.propTypes = {
    tokenizationPublicKey: PropTypes.string.isRequired,
    testMode: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    errors: PropTypes.object,
    onInstance: PropTypes.func.isRequired,
};

export default BanquestCardInfo;
