import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import { CURRENCY_SIGNS } from '../../../../../common/constants';
import SvgCommentIcon from './CommentIcon';
import TruncatedText from 'client/components/II/TruncatedText';
import { FormattedMessage } from 'react-intl';
import { EmojiEventsOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import { changeColorAlpha } from '../../../../helpers/colorUtils';

const commonCountValue = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    fontSize: '0.8rem',
    fontWeight: 'bolder',
    height: '1.2rem',
    bottom: '-0.3rem',
    lineHeight: '1px',
};

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        border: `0.75px solid ${palette.grey[200]}`,
        backgroundColor: props =>
            props.color || changeColorAlpha(palette.primary.light, 0.1),
        textAlign: 'center',
        color: palette.text.primary,
        position: 'relative',
        boxShadow: `0 2px 15px ${changeColorAlpha(palette.grey[300], 0.3)}`,
        transition: 'box-shadow .2s',
        display: 'grid',
        gridTemplateRows: '108px 1fr',
        margin: 10,
        minWidth: 0,

        '&:hover': {
            boxShadow: `0 2px 15px ${changeColorAlpha(palette.grey[300], 0.7)}`,
        },
    },
    top: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 20,
        position: 'relative',
        minWidth: 0,
        '@media (max-width: 600px)': {
            padding: 15,
        },
    },
    logo: {
        position: 'absolute',
        left: 10,
        top: 10,
        width: 40,
        height: 40,
        '& img': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            objectFit: 'cover',
        },
    },
    bottom: {
        padding: '15px 25px',
        backgroundColor: palette.background.default,
        flex: 1,
        minWidth: 0,

        '@media (max-width: 600px)': {
            padding: 15,
        },
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: 1.2,
        fontFamily: typography.fontFamily,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',

        '@media (max-width: 600px)': {
            fontSize: '1.125rem',
        },
    },
    amount: {
        fontSize: '2.438rem',
        lineHeight: '47px',
        fontFamily: typography.fontFamily,
        fontWeight: '600',

        '@media (max-width: 600px)': {
            fontSize: '1.813rem',
            lineHeight: 1,
        },
    },
    greeting: {
        fontSize: '0.875rem',
        color: palette.grey[600],

        '& svg': {
            width: 12,
            height: 12,
            marginInlineEnd: spacing(0.5),
        },
    },
    through: {
        marginBottom: 5,
        fontSize: '0.875rem',
        fontWeight: 300,
        fontFamily: typography.fontFamily,
        color: palette.grey[600],
    },
    throughUrl: {
        color: palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: palette.primary.main,
            textDecoration: 'underline',
        },
    },
    benefactor: {
        position: 'absolute',
        top: 11,
        left: 14,
        borderRadius: '50%',
        width: 24,
        height: 24,
        overflow: 'hidden',
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    matchMyGiftButton: {
        borderRadius: '15px',
        textTransform: 'none',
    },
    matchMyGiftIcon: {
        marginInlineStart: 0,
        marginInlineEnd: spacing(0.5),
    },
    matchingCountItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '1px',
        background: changeColorAlpha(palette.primary.main, 0.35),
        top: '15px',
        right: '15px',
        position: 'absolute',
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '50%',
        color: 'white',
        opacity: '0.8',
        transition: 'right ease 200ms, top ease 200ms, opacity ease 200ms',
        border: 'none',
        cursor: 'pointer',
    },
    countValue: {
        ...commonCountValue,
        background: palette.grey[300],
        color: palette.primary.light,
        width: '1.2rem',
        left: '-0.5rem',
        borderRadius: '50%',
    },
    countValueLong: {
        ...commonCountValue,
        background: palette.grey[300],
        color: palette.primary.light,
        width: '2rem',
        left: '-1rem',
        borderRadius: '1rem',
    },
    matchingCountIcon: {
        transform: 'scale(1.3)',
    },
    matchingCountItemsWrapper: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        width: '100px',
        height: '64px',
        '&:hover': {
            '& $matchingCountItem': {
                opacity: '1',
            },
        },
    },
    matchingCountToolTipContent: {
        color: palette.common.white,
        fontSize: '0.875rem',
        textAlign: 'center',
        textShadow: `0px 0px 1px ${palette.text.primary}`,
    },
    matchingCountToolTipContentItemNumber: {
        border: `1px solid ${palette.common.white}`,
        borderRadius: '3px',
        padding: '2px 3px',
        display: 'inline-block',
        margin: '0 0 0 5px',
        '[dir=rtl] & ': {
            margin: '0 5px 0 0',
        },
    },
    matchingCountToolTipContentItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    marginTop: {
        marginTop: '2px',
    },
}));

function DonateCard(props) {
    const {
        className,
        color,
        borderColor,
        bgColor,
        logoUrl,
        name,
        amount = 0,
        hideAmount = false,
        greetingText,
        throughText,
        throughLayerItemName,
        throughItemUrl,
        benefactor = null,
        currency = '$',
        throughLayerItem,
        isMatchMyGiftEnabled,
        onMatchMyGiftClicked,
        matchMyGiftCount = 0,
        isDonorPflEnabled,
        donorPflCount = 0,
        isFirstInGroup,
    } = props;
    const classes = useStyles({ color, throughText });
    const { palette } = useTheme();
    const showMatchMyGiftCount = isMatchMyGiftEnabled && matchMyGiftCount > 0;
    const showDonorPflCount =
        isFirstInGroup && isDonorPflEnabled && donorPflCount > 0;
    const totalMatchingCount =
        (showMatchMyGiftCount ? matchMyGiftCount : 0) +
        (showDonorPflCount ? donorPflCount : 0);

    const handleViewThroughItem = () => {
        if (!throughLayerItem.isPublished) {
            return false;
        }
        if (window.location.pathname !== throughItemUrl) {
            window.location = throughItemUrl;
        }
    };

    const HtmlTooltip = withStyles(({ palette }) => ({
        tooltip: {
            backgroundColor: palette.secondary.main,
            padding: '10px',
        },
        arrow: {
            color: palette.grey[50],
            '&::before': {
                border: `1px solid ${palette.secondary.main}`,
                backgroundColor: palette.secondary.main,
                boxSizing: 'border-box',
            },
        },
    }))(Tooltip);

    return (
        <div
            style={{ background: `${bgColor}` }}
            className={classNames(classes.root, className)}
        >
            <div className={classes.top}>
                {logoUrl && (
                    <div className={classes.logo}>
                        <img
                            src={logoUrl}
                            alt="Logo icon"
                            style={{ border: `3px solid ${borderColor}` }}
                        />
                    </div>
                )}
                <div className={classes.amount}>
                    {hideAmount ? (
                        <FormattedMessage
                            id="DonateCard.hiddenAmount"
                            defaultMessage="Donated"
                        />
                    ) : (
                        <>
                            <span style={{ marginRight: 6 }}>
                                {CURRENCY_SIGNS[currency] || currency}
                            </span>
                            {Math.round(amount).toLocaleString('en-EN')}
                        </>
                    )}
                </div>
                <Tooltip title={name || ''}>
                    <div className={classes.name}>{name}</div>
                </Tooltip>
            </div>
            <div className={classes.bottom}>
                {throughText && (
                    <div className={classes.through}>
                        {throughText}{' '}
                        {throughLayerItem?.isPublished ? (
                            <a
                                href={throughItemUrl}
                                className={classes.throughUrl}
                                onClick={handleViewThroughItem}
                            >
                                {throughLayerItemName}
                            </a>
                        ) : (
                            <b>{throughLayerItemName}</b>
                        )}
                    </div>
                )}
                {greetingText && (
                    <Tooltip title={greetingText || ''}>
                        <div className={classes.greeting}>
                            <TruncatedText
                                text={
                                    <>
                                        <SvgCommentIcon
                                            color={palette.primary.main}
                                        />
                                        {greetingText}
                                    </>
                                }
                                justify="center"
                                width="100%"
                                minHeight={throughText ? 30 : 60}
                                lines={throughText ? 1 : 2}
                            />
                        </div>
                    </Tooltip>
                )}
                {!!benefactor && (
                    <div className={classes.benefactor}>{benefactor}</div>
                )}
                {isMatchMyGiftEnabled && (
                    <Button
                        color="primary"
                        name="matchMyGift"
                        variant="outlined"
                        className={classes.matchMyGiftButton}
                        classes={{ startIcon: classes.matchMyGiftIcon }}
                        onClick={onMatchMyGiftClicked}
                        startIcon={<EmojiEventsOutlined />}
                    >
                        <FormattedMessage
                            id="DonateCard.matchMyGiftButton"
                            defaultMessage="Match This Gift"
                        />
                    </Button>
                )}
            </div>
            {(showMatchMyGiftCount || showDonorPflCount) && (
                <div className={classes.matchingCountItemsWrapper}>
                    <HtmlTooltip
                        arrow
                        title={
                            <div
                                className={classes.matchingCountToolTipContent}
                            >
                                {showMatchMyGiftCount && (
                                    <div
                                        className={
                                            classes.matchingCountToolTipContentItem
                                        }
                                    >
                                        <span>
                                            <FormattedMessage
                                                id="DonateCard.matchMyGiftButton"
                                                defaultMessage="Match This Gift"
                                            />
                                        </span>
                                        <span
                                            className={
                                                classes.matchingCountToolTipContentItemNumber
                                            }
                                        >
                                            {matchMyGiftCount}
                                        </span>
                                    </div>
                                )}
                                {showDonorPflCount && (
                                    <div
                                        className={classNames(
                                            classes.matchingCountToolTipContentItem,
                                            showDonorPflCount &&
                                                showMatchMyGiftCount
                                                ? classes.marginTop
                                                : '',
                                        )}
                                    >
                                        <span>
                                            <FormattedMessage
                                                id="DonateCard.personalFundraisingLink"
                                                defaultMessage="Personal Fundraising Link"
                                            />
                                        </span>
                                        <span
                                            className={
                                                classes.matchingCountToolTipContentItemNumber
                                            }
                                        >
                                            {donorPflCount}
                                        </span>
                                    </div>
                                )}
                            </div>
                        }
                        enterTouchDelay={0}
                    >
                        <div className={classes.matchingCountItem}>
                            <div>
                                <EmojiEventsOutlined
                                    className={classes.matchingCountIcon}
                                    fontSize={'default'}
                                ></EmojiEventsOutlined>
                            </div>
                            <div
                                className={
                                    totalMatchingCount >= 100
                                        ? classes.countValueLong
                                        : classes.countValue
                                }
                            >
                                <div>{totalMatchingCount}</div>
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>
            )}
        </div>
    );
}

DonateCard.propTypes = {
    onClick: PropTypes.func,
    color: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    hideAmount: PropTypes.bool,
    greetingText: PropTypes.string,
    throughText: PropTypes.string,
    className: PropTypes.string,
    currency: PropTypes.string,
    benefactor: PropTypes.node,
    throughLayerItem: PropTypes.object,
    throughLayerItemName: PropTypes.string,
    throughItemUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    isMatchMyGiftEnabled: PropTypes.bool,
    onMatchMyGiftClicked: PropTypes.func,
    matchMyGiftCount: PropTypes.number,
    isDonorPflEnabled: PropTypes.bool,
    donorPflCount: PropTypes.number,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    isFirstInGroup: PropTypes.bool,
};

export default DonateCard;
