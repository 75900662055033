import {
    createSlice,
    createAsyncThunk,
    createSelector,
} from '@reduxjs/toolkit';
import order from 'common/helpers/orderFactory';
import { selectSelectedCampaign } from 'client/components/CampaignPage/features/campaign/campaignSlice';
import { selectSelectedTab } from 'client/components/CampaignPage/features/recentDonationPanel/recentDonationPanelSlice';
import {
    DEFAULT_PAGES_PER_SET,
    MAX_PAGES_PER_SET,
    SORT_LAYER_ITEMS,
} from '../recentDonationPanel/constants';
import { uniqBy } from 'lodash';

const DEFAULT_SORT = SORT_LAYER_ITEMS.LAST_DONATED_TO;

function orderStringToStringifiedJson(orderString) {
    const orderBuilder = order();
    orderString
        .split(',')
        .forEach(orderSet => orderBuilder.add(...orderSet.split(':')));
    return orderBuilder.toString();
}

function addDonorReducer(state, action) {
    const { donor } = action.payload;
    const updateStats = (item, donor) => {
        item.donationsAmount += donor.amount * donor.multiplier;
        item.donorsCount += 1;
    };
    let donors;

    if (donor instanceof Array) {
        donors = donor;
    } else {
        donors = [donor];
    }

    donors.forEach(donor => {
        if (donor.layerItemId) {
            const layerItem = state.items.find(
                item => item.id === donor.layerItemId,
            );

            if (layerItem) {
                updateStats(layerItem, donor);
            }

            if (
                state.selectedItem &&
                state.selectedItem.id === donor.layerItemId
            ) {
                updateStats(state.selectedItem, donor);
            }
        }
    });
}

function queryNextPage(state) {
    state.query.page = state.query.page ? state.query.page + 1 : 2;
    state.loading = 'pending';
}

function resetAutoLoad(state, resetPagesPerSet = true) {
    state.allowAutoLoad = true;
    state.pagesInSet = 0;

    if (resetPagesPerSet) {
        state.pagesPerSet = DEFAULT_PAGES_PER_SET;
    }
}

/**
 *
 * @param {{}} params
 * @param {String} params.stateName
 * @param {Function} params.fetchItems
 */
export default function createListItemSlice(params) {
    const { stateName, fetchItems: apiFetchItems, paginate = 9 } = params;

    const fetchItems = createAsyncThunk(
        `${stateName}/fetchItems`,
        async (query, { getState }) => {
            const state = getState();
            const { id: campaignId = null } = selectSelectedCampaign(state);
            let { id: layerId = null } = selectSelectedTab(state);

            const selectedItem = selectSelectedItem(state);
            if (selectedItem) {
                layerId = selectSelectedChildLayer(state);
            }

            if (!campaignId) {
                throw new Error(
                    'Campaign is not loaded, can not load donors list',
                );
            }

            const preparedQuery = {
                order: query.order
                    ? orderStringToStringifiedJson(query.order)
                    : null,
                name: query.searchText,
                page: query.page || 1,
                paginate: query.paginate,
                campaignId,
                layerId,
                parentId: selectedItem ? selectedItem.id : null,
            };

            return apiFetchItems(preparedQuery);
        },
    );

    const slice = createSlice({
        name: stateName,
        initialState: {
            loading: 'idle',
            query: {
                paginate,
                order: DEFAULT_SORT,
                searchText: '',
                page: 1,
            },
            items: [],
            error: null,
            resetItemsAfterLoad: false,
            total: 0,
            selectedItem: window.selectedLayerItem,
            selectedChildLayer: null,
            scrollTop: 0,
            isEndCollection: false,
            allowAutoLoad: true,
            pagesInSet: 0,
            pagesPerSet: DEFAULT_PAGES_PER_SET,
        },
        reducers: {
            updateSelectedLayerItemStatistics(state, { payload }) {
                const updateItem = layerItem => {
                    const updateStats = item => {
                        // payload.* stats attributes filled on LayerItem scope `donationStatistics` using
                        // payload.statistics.* stats attributes allowed on use `layer_item_statistics` relation
                        item.donorsCount =
                            layerItem.donorsCount ||
                            layerItem.statistics?.donorsCount ||
                            0;
                        item.donationsAmount =
                            layerItem.donationsAmount ||
                            layerItem.statistics?.donationsAmount ||
                            0;
                        item.progress =
                            layerItem.progress ||
                            layerItem.statistics?.progress ||
                            0;
                        item.lastDonationAt =
                            layerItem.lastDonationAt ||
                            layerItem.statistics?.lastDonationAt ||
                            null;
                        item.statistics = layerItem.statistics;
                    };

                    if (layerItem) {
                        if (
                            state.selectedItem &&
                            state.selectedItem.id === layerItem.id
                        ) {
                            updateStats(state.selectedItem);
                        }

                        state.items.forEach(item => {
                            if (item.id === layerItem.id) {
                                updateStats(item);
                            }
                        });
                    }
                };

                if (Array.isArray(payload)) {
                    if (payload.length > 0) {
                        payload.forEach(updateItem);
                    }
                } else {
                    updateItem(payload);
                }
            },

            updateQuery(state, { payload }) {
                state.query = {
                    ...state.query,
                    ...payload,
                };

                resetAutoLoad(state);
            },

            resetItems(state) {
                state.items = [];

                resetAutoLoad(state);
            },

            nextPage(state, { payload: page }) {
                if (page) {
                    state.query.page = page;
                } else {
                    queryNextPage(state);
                }
            },

            autoLoad(state) {
                if (
                    !state.allowAutoLoad ||
                    state.isEndCollection ||
                    state.loading === 'pending'
                ) {
                    return;
                }

                queryNextPage(state);
            },

            repeatAutoLoad(state) {
                resetAutoLoad(state, false);
                state.pagesPerSet = Math.min(
                    state.pagesPerSet + 2,
                    MAX_PAGES_PER_SET,
                );
            },

            resetQuery(state) {
                state.query = {
                    paginate,
                    order: DEFAULT_SORT,
                    searchText: '',
                    page: 1,
                };
            },

            resetItemsAfterLoad(state) {
                state.resetItemsAfterLoad = true;
            },

            selectItem(state, { payload }) {
                state.selectedItem = payload;
            },

            selectChildLayer(state, { payload }) {
                state.selectedChildLayer = payload;
            },

            updateScrollTop(state, { payload }) {
                state.scrollTop = payload;
            },
        },
        extraReducers: builder => {
            builder.addCase(fetchItems.fulfilled, (state, action) => {
                if (state.resetItemsAfterLoad) {
                    state.items = [];
                    state.resetItemsAfterLoad = false;
                    resetAutoLoad(state);
                }
                if (state.selectedItem) {
                    state.items = uniqBy(
                        [
                            ...state.items,
                            ...action.payload.docs.map(doc => ({
                                ...doc,
                                removeOnClose: true,
                            })),
                        ],
                        'id',
                    );
                } else {
                    state.items = state.items
                        .filter(item => !item.removeOnClose)
                        .concat(action.payload.docs);
                }
                state.page = action.payload.page;
                state.pages = action.payload.pages;
                state.paginate = action.payload.paginate;
                state.total = action.payload.total;
                state.loading = 'idle';
                state.isEndCollection =
                    action.payload.page >= action.payload.pages;
                state.pagesInSet += 1;
                state.allowAutoLoad = state.pagesInSet < state.pagesPerSet;
            });
            builder.addCase(fetchItems.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error;
                console.error(action.error);
            });
            builder.addCase(fetchItems.pending, state => {
                state.loading = 'pending';
                state.error = null;
            });
            // All add donor (for each layer item) must placed below
            // builder.addCase(donorList.actions.addDonor, addDonorReducer);
        },
    });

    const selectList = state => state[stateName];
    const selectQuery = createSelector(selectList, list => list.query);
    const selectItems = createSelector(selectList, list => list.items);
    const selectIsLoading = createSelector(
        selectList,
        list => list.loading === 'pending',
    );
    const selectIsEndCollection = createSelector(
        selectList,
        ({ pages, page }) => page >= pages,
    );
    const selectAllowAutoLoad = createSelector(
        selectList,
        ({ allowAutoLoad }) => allowAutoLoad,
    );
    const selectItemsCount = createSelector(selectList, ({ total }) => total);
    const selectSelectedItem = createSelector(
        selectList,
        list => list.selectedItem,
    );
    const selectSelectedChildLayer = createSelector(
        selectList,
        list => list.selectedChildLayer,
    );
    const selectScrollTop = createSelector(
        selectList,
        ({ scrollTop }) => scrollTop,
    );

    slice.selectors = {
        selectList,
        selectQuery,
        selectItems,
        selectIsLoading,
        selectIsEndCollection,
        selectAllowAutoLoad,
        selectItemsCount,
        selectSelectedItem,
        selectSelectedChildLayer,
        selectScrollTop,
    };

    slice.actions.fetchItems = fetchItems;

    return slice;
}
