import { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useScrollTo from '../../hooks/scrollTo';
import { selectSelectedTab } from 'client/components/CampaignPage/features/recentDonationPanel/recentDonationPanelSlice';
import { slice as teamsSlice } from 'client/components/CampaignPage/features/layersList/teamsListSlice';

export default function useTabContent(slice, counterAction = null) {
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const query = useSelector(slice.selectors.selectQuery);
    const { id: layerId = null } = useSelector(selectSelectedTab);
    const selectedLayerItem = useSelector(
        teamsSlice.selectors.selectSelectedItem,
    );
    const items = useSelector(slice.selectors.selectItems);
    const selectedChildLayer = useSelector(
        slice.selectors.selectSelectedChildLayer,
    );
    const isLoading = useSelector(slice.selectors.selectIsLoading);
    const isEndCollection = useSelector(slice.selectors.selectIsEndCollection);
    const allowAutoLoad = useSelector(slice.selectors.selectAllowAutoLoad);
    const itemsCount = useSelector(slice.selectors.selectItemsCount);
    const startScrollTop = useSelector(slice.selectors.selectScrollTop);
    const listRef = useRef(null);

    const handleQueryChange = useCallback(query => {
        dispatch(slice.actions.resetItemsAfterLoad());
        dispatch(slice.actions.updateQuery(query));
    }, []);

    const handleAppendNextPage = useCallback(() => {
        dispatch(slice.actions.nextPage());
        dispatch(slice.actions.repeatAutoLoad());
    }, []);

    const handleAutoLoad = useCallback(() => {
        dispatch(slice.actions.autoLoad());
    }, []);

    useScrollTo(listRef, {
        startScrollTop,
        saveScrollPosition: scrollTop => {
            dispatch(slice.actions.updateScrollTop(scrollTop));
        },
    });

    useEffect(() => {
        let promise = null;

        if (items.length) {
            if (isMounted.current) {
                promise = dispatch(slice.actions.fetchItems(query));
            }
        } else {
            promise = dispatch(slice.actions.fetchItems(query));
        }

        isMounted.current = true;

        return () => {
            if (promise) {
                promise.abort();
            }
        };
    }, [JSON.stringify(query), layerId, selectedChildLayer, selectedLayerItem]);

    useEffect(() => {
        if (typeof counterAction === 'function') {
            dispatch(counterAction(itemsCount));
        }
    }, [itemsCount]);

    return {
        query,
        items,
        isLoading,
        isEndCollection,
        allowAutoLoad,
        itemsCount,
        listRef,
        startScrollTop,
        handleQueryChange,
        handleAppendNextPage,
        handleAutoLoad,
    };
}
