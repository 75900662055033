export const TILES_PER_PAGE = 18;
export const DEFAULT_PAGES_PER_SET = 3;
export const MAX_PAGES_PER_SET = 10;

export const SORT_LAYER_ITEMS = Object.freeze({
    LAST_DONATED_TO: 'statistics.lastDonationAt:desc nulls last,createdAt:desc',
    NAME_ASC: 'name:asc',
    NAME_DESC: 'name:desc',
    MOST_RAISED: 'statistics.donationsAmount:desc',
    LEAST_RAISED: 'statistics.donationsAmount:asc',
    MOST_PROGRESS: 'statistics.progress:desc',
    LEAST_PROGRESS: 'statistics.progress:asc',
    LAST_ADDED: 'createdAt:desc',
});
