import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        height: 0,
        width: '100%',
        margin: '30px 0',
        borderWidth: '2px 0 0 0',
        borderColor: '#999',
        borderStyle: props => (props.dotted ? 'dotted' : 'solid'),
    },
});

function Divider(props) {
    const { className, dotted = false } = props;
    const classes = useStyles({ dotted });

    return <div className={classNames(classes.root, className)} />;
}

Divider.propTypes = {
    dotted: PropTypes.bool,
    className: PropTypes.string,
};

export default Divider;
