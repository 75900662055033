import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RadioGroupTab from 'client/components/CampaignPage/components/RadioGroupTab/RadioGroupTab';
import RadioGroupTabs from 'client/components/CampaignPage/components/RadioGroupTabs/RadioGroupTabs';
import { selectPaymentModes } from './checkoutSlice';
import { useIntl } from 'react-intl';

function PaymentModeSelector(props) {
    const { onChange, value } = props;
    const {
        allowSinglePayment = true,
        allowInstallments = false,
        allowRecurring = false,
        allowUnlimitedRecurring = false,
    } = useSelector(selectPaymentModes);

    const { formatMessage } = useIntl();
    const modes = useMemo(() => {
        const result = [];
        if (allowSinglePayment) {
            result.push({
                name: formatMessage({
                    id: 'PaymentMode.singlePayment',
                }),
                value: 'regular',
            });
        }
        if (allowInstallments) {
            result.push({
                name: formatMessage({
                    id: 'PaymentMode.installments',
                }),
                value: 'installments',
            });
        }
        if (allowRecurring) {
            result.push({
                name: formatMessage({
                    id: 'PaymentMode.recurring',
                }),
                value: 'recurring',
            });
        }
        return result;
    }, [allowSinglePayment, allowInstallments, allowRecurring]);

    if (modes.length < 2) {
        return null;
    }

    const renderRecurringTab = mode => {
        return (
            <RadioGroupTab
                key={
                    allowUnlimitedRecurring ? 'unlimitedRecurring' : 'recurring'
                }
                name="paymentMode"
                onChange={onChange(
                    allowUnlimitedRecurring
                        ? 'unlimitedRecurring'
                        : 'recurring',
                )}
                checked={
                    value === 'recurring' || value === 'unlimitedRecurring'
                }
            >
                {mode.name}
            </RadioGroupTab>
        );
    };

    return (
        <RadioGroupTabs>
            {modes.map(mode =>
                mode.value === 'recurring' ? (
                    renderRecurringTab(mode)
                ) : (
                    <RadioGroupTab
                        key={mode.value}
                        name="paymentMode"
                        onChange={onChange(mode.value)}
                        checked={mode.value === value}
                    >
                        {mode.name}
                    </RadioGroupTab>
                ),
            )}
        </RadioGroupTabs>
    );
}

PaymentModeSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    recurringType: PropTypes.string,
};

export default React.memo(PaymentModeSelector);
