import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            width: 20,
            height: 20,
            border: 'none',
            padding: 0,
            background: 'none',
            position: 'relative',
            outline: 'none',
            cursor: 'pointer',
            top: -spacing(0.5),

            '&::before': {
                content: '""',
                display: 'block',
                width: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                height: 3,
                background: palette.text.primary,
                transition: 'all 0.3s ease',
                boxShadow: `0 7px 0 ${palette.text.primary}, 0 14px 0 ${palette.text.primary}`,
                transformOrigin: 'left center',
            },

            '&::after': {
                content: '""',
                display: 'block',
                width: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                height: 3,
                transition: 'all 0.3s ease',
                opacity: 0,
                backgroundColor: palette.text.primary,
            },

            '@media (max-width: 600px)': {
                height: 12,
                width: 14,
                position: 'relative',

                '&::before': {
                    height: 1,
                    boxShadow: `0 5px 0 ${palette.text.primary}, 0 10px 0 ${palette.text.primary}`,
                },
                '&::after': {
                    height: 1,
                },
            },
        },

        open: {
            '&::before': {
                boxShadow: 'none',
                backgroundColor: palette.text.primary,
                transform: 'rotate(45deg)',
                top: -1,
                left: 4,
            },

            '&::after': {
                transform: 'rotate(-45deg)',
                top: 6,
                left: 1,
                opacity: 1,
            },

            '@media (max-width: 600px)': {
                '&::before': {
                    top: 0,
                    left: 3,
                },
                '&::after': {
                    top: 5,
                },
            },
        },
    }),
    { name: 'MenuButton' },
);

function MenuButton(props) {
    const { className, open, ...rest } = props;
    const classes = useStyles();

    return (
        <button
            className={classNames(classes.root, className, {
                [classes.open]: open,
            })}
            {...rest}
        ></button>
    );
}

MenuButton.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
};

export default MenuButton;
