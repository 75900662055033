import React from 'react';
import PropTypes from 'prop-types';
import DonorsList from '../../components/DonorsList/DonorsList';

function DonorsTabContent(props) {
    return <DonorsList {...props} />;
}

DonorsTabContent.propTypes = {
    name: PropTypes.string,
    layerItem: PropTypes.object,
};

export default DonorsTabContent;
