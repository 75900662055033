import AboutIcon from './AboutIcon';
import CommunityIcon from './CommunityIcon';
import CountryIcon from './CountryIcon';
import DonorIcon from './DonorIcon';
import TeamIcon from './TeamIcon';

export default {
    about: AboutIcon,
    community: CommunityIcon,
    country: CountryIcon,
    donor: DonorIcon,
    team: TeamIcon,
};
