import '@babel/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/he';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/ru';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/yi';
import '@formatjs/intl-pluralrules/locale-data/ar';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/nl';
import React from 'react';
import { render } from 'react-dom';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import CampaignPage from 'client/components/CampaignPage';
import * as axios from 'client/helpers/axios';
import campaign from './components/CampaignPage/features/campaign/campaignSlice';
import recentDonationPanel from './components/CampaignPage/features/recentDonationPanel/recentDonationPanelSlice';
import donorsList from './components/CampaignPage/features/donorsList/donorsListSlice';
import teamsList from './components/CampaignPage/features/layersList/teamsListSlice';
import teamDonorsList from './components/CampaignPage/features/donorsList/teamDonorsListSlice';
import checkoutPopup from './components/CampaignPage/features/checkout/checkoutPopupSlice';
import checkout from './components/CampaignPage/features/checkout/checkoutSlice';
import lastDonation from './components/CampaignPage/features/checkout/lastDonationSlice';
import donationFlasher, {
    name as donationFlasherName,
} from 'client/components/CampaignPage/components/DonationFlasher/donationFlasherSlice';
import heroSlider from './components/CampaignPage/components/HeroSlider/heroSliderSlice';
import sharePanel from './components/CampaignPage/components/SharePanel/sharePanelSlice';

axios.setup();

const store = configureStore({
    reducer: {
        campaign,
        recentDonationPanel,
        donorsList,
        teamsList,
        teamDonorsList,
        checkoutPopup,
        checkout,
        [donationFlasherName]: donationFlasher,
        ui: combineReducers({
            heroSlider,
            sharePanel,
        }),
        lastDonation,
    },
});

const applicationRootElement = document.getElementById('app');

if (applicationRootElement) {
    render(
        <Provider store={store}>
            <CampaignPage />
        </Provider>,
        applicationRootElement,
    );
}
