import React from 'react';

function MinusIcon(props) {
    return (
        <svg
            height="24px"
            width="24px"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.96904"
            version="1.1"
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M 1.4865313,11.765235 H 22.51346" />
        </svg>
    );
}

export default MinusIcon;
