import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectError } from './checkoutSlice';
import { makeStyles } from '@material-ui/core';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { ReactComponent as CardIcon } from 'client/components/CampaignPage/components/FormInput/CardIcon.svg';
import { ReactComponent as LockIcon } from 'client/components/CampaignPage/components/FormInput/LockIcon.svg';
import SecurePayments from '../../components/SecurePayments/SecurePayments';
import useLoadScript from '../../hooks/loadScript';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import focusHandler from '../../../../helpers/focusHandler';
import { languageDirection } from '../../../../../common/helpers/languages';
import useIsMobile from 'client/hooks/isMobile';
import { useIntl } from 'react-intl';
import { CC_TYPES } from 'common/constants';

const VALIDATION_INFO = Object.freeze({
    ccnumber: 'nmiCardNumber',
    ccexp: 'nmiExpire',
    cvv: 'nmiCvv',
});

const ERROR_MESSAGES = {
    nmiCardNumber: {
        id: 'DonationForm.cardNumber.invalid',
        defaultMessage: 'Card number is invalid',
    },
    nmiExpire: {
        id: 'DonationForm.expire.invalid',
        defaultMessage: 'Card expiration date is invalid',
    },
    nmiCvv: {
        id: 'DonationForm.cvv.invalid',
        defaultMessage: 'Card CVV is invalid',
    },
};

const placeholderTags = [
    {
        tagName: 'data-field-ccnumber-placeholder',
        tagValue: '1234 1234 1234 1234',
    },
    {
        tagName: 'data-field-ccexp-placeholder',
        tagValue: 'MM / YY',
    },
    {
        tagName: 'data-field-cvv-placeholder',
        tagValue: 'CVV',
    },
];

const getNmiConfigurationVariables = (locale, width) => {
    const direction = languageDirection(locale);
    return {
        variant: 'inline',
        styleSniffer: 'false',
        customCss: {
            border: 'none',
            height: width < 600 ? '38px' : '50px',
            'font-family': 'Heebo, sans-serif',
            'font-size': width < 600 ? '13px' : '15px',
            color: '#707070',
            'outline-style': 'none',
            'background-color': 'transparent',
            'text-align': direction === 'rtl' ? 'right' : 'left',
        },
        validCss: {
            color: '#82B486',
        },
        invalidCss: {
            color: '#FF0E0E',
        },
    };
};

const NmiInput = props => {
    const { name, internalErrors, dirtyFields, internalControl, children } =
        props;
    const isDirty = dirtyFields[name];
    const errorMessage = internalErrors[name]?.message;
    const { setValue } = internalControl || {};
    const isEmpty = errorMessage
        ? errorMessage?.toLowerCase()?.startsWith('field is empty')
        : false;
    const { formatMessage } = useIntl();
    const formattableErrorMessage = ERROR_MESSAGES[name];

    useEffect(() => {
        if (isDirty) {
            const value = {
                empty: isEmpty,
                error: errorMessage
                    ? { message: formatMessage(formattableErrorMessage) }
                    : undefined,
            };
            setValue?.(name, value, {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
    }, [isDirty, errorMessage, setValue]);

    return <>{children}</>;
};

const cardNumberDiv = props => {
    const classes = useStyles();

    return (
        <NmiInput {...props}>
            <div className={classes.cardContainer} id={'ccnumber'}></div>
        </NmiInput>
    );
};
const cardExpiryDiv = props => {
    const classes = useStyles();
    return (
        <NmiInput {...props}>
            <div className={classes.expiryContainer} id={'ccexp'}></div>
        </NmiInput>
    );
};
const cardCvcDiv = props => {
    const classes = useStyles();
    return (
        <NmiInput {...props}>
            <div className={classes.cardContainer} id={'cvv'}></div>
        </NmiInput>
    );
};

const CardNumber = withController(withFormInput(cardNumberDiv));
const CardExpiry = withController(withFormInput(cardExpiryDiv));
const CardCvc = withController(withFormInput(cardCvcDiv));

const useStyles = makeStyles({
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    cardContainer: {
        height: '50px',
        padding: '0px 80px',
        '@media (max-width: 600px)': {
            height: '38px',
            padding: '0px 0px 0px 65px',
            'html[dir=rtl] &': {
                padding: '0px 65px 0px 0px',
            },
        },
    },
    expiryContainer: {
        height: '50px',
        padding: '0px 20px',
        '@media (max-width: 600px)': {
            height: '38px',
        },
    },
});

const NmiCardInfo = ({
    publicApiKey,
    control,
    rules = { cc: {}, ccv: {}, expire: {} },
    errors = {},
    nmiDataRef,
    locale,
}) => {
    const [internalErrors, setInternalErrors] = useState({});
    const [internalDirty, setInternalDirty] = useState({});
    const paymentError = useSelector(selectError);
    const fieldsShouldRefresh =
        paymentError !== null && nmiDataRef.current.status === 'idle';
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { width } = useIsMobile();
    const nmiScriptStatus = useLoadScript(
        'https://secure.networkmerchants.com/token/Collect.js',
        [
            {
                tagName: 'data-tokenization-key',
                tagValue: publicApiKey,
            },
            ...placeholderTags,
        ],
    );
    const configurationVariables = getNmiConfigurationVariables(locale, width);

    const handleNmiEvent = (field, isValid, message) => {
        const fieldName = VALIDATION_INFO[field];
        setInternalDirty(dirty => {
            if (dirty[fieldName]) {
                return dirty;
            }

            return { ...dirty, [fieldName]: true };
        });
        setInternalErrors(errors => ({
            ...errors,
            [fieldName]: isValid ? undefined : { message },
        }));
    };

    useEffect(() => {
        if (nmiScriptStatus === 'ready' && window.CollectJS) {
            window.CollectJS.configure({
                ...configurationVariables,
                validationCallback: function (field, valid, message) {
                    if (!fieldsShouldRefresh)
                        handleNmiEvent(field, valid, message);
                },
                callback: function (response) {
                    nmiDataRef.current.token = response;
                    nmiDataRef.current.status = 'idle';
                },
            });
            if (fieldsShouldRefresh) {
                setInternalDirty({});
                window.CollectJS.clearInputs();
                nmiDataRef.current = { status: 'blank', token: null };
            }
        }
    }, [nmiScriptStatus, fieldsShouldRefresh, width]);

    return (
        <>
            <FormGroup inline className={classes.fields}>
                <CardNumber
                    type="text"
                    name="nmiCardNumber"
                    required
                    startIcon={<CardIcon />}
                    control={control}
                    rules={rules.cc}
                    dirtyFields={internalDirty}
                    errors={errors}
                    internalErrors={internalErrors}
                    internalControl={control}
                    defaultValue=""
                    onFocus={focusHandler('ccnumber')}
                />
            </FormGroup>
            <FormGroup
                inline
                gap={19}
                label={formatMessage({
                    id: 'DonationForm.enterCardValidity',
                })}
                className={classes.fields}
            >
                <CardExpiry
                    name="nmiExpire"
                    width="100%"
                    required
                    control={control}
                    rules={rules.expire}
                    dirtyFields={internalDirty}
                    errors={errors}
                    internalErrors={internalErrors}
                    internalControl={control}
                    defaultValue=""
                    onFocus={focusHandler('ccexp')}
                />
                <CardCvc
                    type="text"
                    name="nmiCvv"
                    required
                    startIcon={<LockIcon />}
                    control={control}
                    rules={rules.cvc}
                    dirtyFields={internalDirty}
                    errors={errors}
                    internalErrors={internalErrors}
                    internalControl={control}
                    defaultValue=""
                    onFocus={focusHandler('cvv')}
                />
            </FormGroup>
            <FormGroup>
                <SecurePayments
                    cardsToHide={[CC_TYPES.DISCOVER, CC_TYPES.AMERICAN_EXPRESS]}
                />
            </FormGroup>
        </>
    );
};

NmiCardInfo.propTypes = {
    control: PropTypes.object.isRequired,
    rules: PropTypes.shape({
        cc: PropTypes.object.isRequired,
        ccv: PropTypes.object.isRequired,
        expire: PropTypes.object.isRequired,
    }).isRequired,
    errors: PropTypes.object,
    publicApiKey: PropTypes.string.isRequired,
    nmiDataRef: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
};

export default NmiCardInfo;
