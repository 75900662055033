import api from 'client/services/api';
import createListItemSlice from './createLayerItemListSlice';
import { TILES_PER_PAGE } from '../recentDonationPanel/constants';

const sliceWithSelectors = createListItemSlice({
    stateName: 'teamsList',
    fetchItems: async query =>
        (await api.layerItem.findPublicLayerItems(query)).data,
    paginate: TILES_PER_PAGE,
});

export const slice = sliceWithSelectors;

export default sliceWithSelectors.reducer;
