import {
    createSlice,
    createAsyncThunk,
    createSelector,
} from '@reduxjs/toolkit';
import api from 'client/services/api';

export const STATE_PREFIX = 'recentDonationPanel';

function createTab(layer, isLayer = true) {
    return {
        id: layer.id,
        label: layer.name,
        // tmp soltion, until layer without `icon` attribute
        icon:
            layer.icon ||
            layer.locales.en.name.replace(/\s/gi, '_').toLowerCase(),
        tabContentId: layer.tabContentId || layer.name.toLowerCase(),
        counter: layer.layerItemsCount || 0,
        layer: isLayer ? { ...layer } : null,
        translationId: layer.translationId || undefined,
    };
}

function updateTab(tabs, tabId, updateFn) {
    const tabIndex = tabs.findIndex(tab => tab.id === tabId);

    if (tabIndex > -1) {
        updateFn(tabs[tabIndex]);
    }

    return tabs;
}

function addTabs(tabs, newTabs) {
    const head = [...tabs];
    const tail = head.splice(-1);

    return [...head, ...newTabs, ...tail];
}

export const fetchLayers = createAsyncThunk(
    `${STATE_PREFIX}/fetchLayers`,
    async campaignId => api.layer.getPublicLayers(campaignId),
);

export const fetchChildLayers = createAsyncThunk(
    `${STATE_PREFIX}/fetchChildLayers`,
    async ({ parentLayerId, layerItemId }) => {
        const childLayers = await api.layer.getPublicLayerItemChildLayers({
            layerItemId,
        });
        return { parentLayerId, childLayers };
    },
);

export const fetchLayerItemParentsData = createAsyncThunk(
    `${STATE_PREFIX}/fetchLayerItemParentsData`,
    async layerItemId => {
        const response = await api.layerItem.getPublicLayerItemParentsData(
            null,
            layerItemId,
        );
        return response.data;
    },
);

const recentDonationPanel = createSlice({
    name: STATE_PREFIX,
    initialState: {
        tabs: [
            createTab(
                {
                    id: -1,
                    name: 'donors',
                    icon: 'donor',
                    translationId: 'Donor',
                },
                false,
            ),
            createTab(
                {
                    id: -2,
                    name: 'about',
                    icon: 'about',
                    translationId: 'Tabs.about',
                },
                false,
            ),
        ],
        layers: [],
        childLayers: [], // child layers of the selected layer item
        childLayersParentId: null,
        selectedItemParentsData: [],
        layersIsFetched: false,
        childLayersIsFetched: false,
        loading: 'idle',
        selectedTab: null,
        IsDefaultTabSelected: false,
    },
    reducers: {
        setTabCounter(state, { payload }) {
            const { tabId, counter } = payload;
            state.tabs = updateTab(
                state.tabs,
                tabId,
                tab => (tab.counter = counter),
            );
        },
        setSelectedTab(state, { payload }) {
            //when switching tabs, set childLayersIsFetched flag to false, so that it loads again when needed.
            if (!state.selectedTab || state.selectedTab.id !== payload.id) {
                state.childLayersIsFetched = false;
            }

            state.selectedTab = payload;
        },
        setIsDefaultTabSelected(state, { payload }) {
            state.IsDefaultTabSelected = payload;
        },
        increaseDonatonTabCounter(state, { payload = 1 }) {
            state.tabs = updateTab(
                state.tabs,
                -1,
                tab => (tab.counter += payload),
            );
        },
        resetChildLayers(state) {
            state.childLayersIsFetched = false;
            state.childLayers = [];
            state.childLayersParentId = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLayers.fulfilled, (state, action) => {
            state.layers = action.payload;
            state.tabs = addTabs(
                state.tabs,
                action.payload.map(layer => createTab(layer, true)),
            );
            //if layer item page is loaded select the tab by default
            if (window.selectedLayerItem) {
                const selectedLayerId = window.selectedLayerItem.layerId;
                const arr = state.tabs.filter(tab => tab.id == selectedLayerId);
                if (arr.length > 0) {
                    state.selectedTab = arr[0];
                    state.childLayersIsFetched = false;
                }
            }
            state.layersIsFetched = true;
        });
        builder.addCase(fetchLayers.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.error;
        });
        builder.addCase(fetchLayers.pending, state => {
            state.loading = 'pending';
            state.error = null;
        });
        builder.addCase(fetchChildLayers.fulfilled, (state, action) => {
            state.childLayers = action.payload.childLayers;
            state.childLayersParentId = action.payload.parentLayerId;
            state.childLayersIsFetched = true;
        });
        builder.addCase(fetchChildLayers.rejected, (state, action) => {
            state.error = action.error;
        });
        builder.addCase(fetchChildLayers.pending, state => {
            state.error = null;
        });
        builder.addCase(fetchLayerItemParentsData.pending, state => {
            state.loading = 'pending';
            state.error = null;
        });
        builder.addCase(
            fetchLayerItemParentsData.fulfilled,
            (state, action) => {
                state.selectedItemParentsData = action.payload;
            },
        );
        builder.addCase(fetchLayerItemParentsData.rejected, (state, action) => {
            state.error = action.error;
        });
    },
});

export const {
    setTabCounter,
    setSelectedTab,
    setIsDefaultTabSelected,
    increaseDonatonTabCounter,
    resetChildLayers,
} = recentDonationPanel.actions;

export const selectRecentDonationPanel = state => state[STATE_PREFIX];

export const selectLayersIsFetched = createSelector(
    selectRecentDonationPanel,
    panel => panel.layersIsFetched,
);
export const selectChildLayersIsFetched = createSelector(
    selectRecentDonationPanel,
    panel => panel.childLayersIsFetched,
);
export const selectIsDefaultTabSelected = createSelector(
    selectRecentDonationPanel,
    panel => panel.IsDefaultTabSelected,
);
export const selectTabs = createSelector(
    selectRecentDonationPanel,
    panel => panel.tabs || [],
);
export const selectSelectedItemParentsData = createSelector(
    selectRecentDonationPanel,
    panel => panel.selectedItemParentsData,
);
export const selectChildLayers = createSelector(
    selectRecentDonationPanel,
    panel => panel.childLayers,
);
export const selectChildLayersParentId = createSelector(
    selectRecentDonationPanel,
    panel => panel.childLayersParentId,
);
export const selectIsLoading = createSelector(
    selectRecentDonationPanel,
    panel => panel.loading !== 'idle',
);
export const selectSelectedTab = createSelector(
    selectRecentDonationPanel,
    panel => panel.selectedTab || panel.tabs[panel.tabs.length - 1], //todo. about tab is set as default. this should be customizable in the dashboard
);
export const selectDonationLayers = createSelector(
    selectRecentDonationPanel,
    panel => panel.layers.filter(layer => layer.allowDonations),
);

export default recentDonationPanel.reducer;
