import { useEffect, useState } from 'react';

const getVw = () => document.documentElement.clientWidth || window.innerWidth;

function useIsMobile() {
    const [width, setWidth] = useState(getVw());
    const updateWidth = () => {
        setWidth(getVw());
    };

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    return {
        width,
        isMobile: width < 480,
        updateWidth,
    };
}

export default useIsMobile;
