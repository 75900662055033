const { mapValues, flow } = require('lodash');
const php = /[!'()*~]/g;
const rfc3986 = /[!'()*]/g;

const encodeChar = char => '%' + char.charCodeAt(0).toString(16).toUpperCase();
const decodeChar = char =>
    String.fromCharCode(parseInt(char.replace('%', ''), 16));
const encode = pat => str => str.replace(pat, encodeChar);
const decode = pat => str => str.replace(pat, decodeChar);
const plus = str => str.replace(/%20/g, '+');
const trim = str => str.trim();
const stringify = val => val?.toString() || '';

const flavors = {
    payfast: [trim, encodeURIComponent, encode(php), plus],
    urlencode: [encodeURIComponent, encode(rfc3986)],
    yaadpay: [encodeURIComponent, encode(rfc3986)],
    rfc5987: [encodeURIComponent, encode(rfc3986), decode(/%(7C|60|5E)/g)],
};

module.exports = mapValues(flavors, transformers =>
    flow([stringify, ...transformers]),
);
