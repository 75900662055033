import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import FlagIcon from 'client/components/Icons/Flag';
import filterQueryParams from 'client/helpers/filterQueryParams';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    selectCampaignSlug,
    selectEnableDonations,
} from '../../features/campaign/campaignSlice';
import Button from '../Button/Button';
import ItemCard from '../ItemCard/ItemCard';
import { Box } from '@material-ui/core';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette }) => ({
    bonusRound: {
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderImage: `linear-gradient(45deg, ${palette.secondary.light}, ${palette.secondary.light}) 1`,
        '&::after': {
            boxShadow: `-1px 2px 8px ${changeColorAlpha(
                palette.secondary.main,
                0.2,
            )}`,
        },
        '& .amountCustom': {
            color: `${palette.secondary.main} !important`,
        },
    },
    actions: {
        textAlign: 'center',
    },
    actionBtn: {
        margin: '5px 7px',
        padding: '5px 15px',
        fontSize: '1.2rem',
        '@media (max-width: 600px)': {
            fontSize: '0.938rem',
            padding: '2px 13px',
        },
    },
    donateBtn: {
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        '&:hover': {
            textShadow: `0 0 1px ${palette.common.white}`,
        },
    },
    viewBtn: {
        '&::after': {
            display: 'inline-block',
            content: '"\\00A0➔"',

            'html[dir=rtl] &': {
                content: '"➔\\00A0"',
                transformOrigin: 'center center',
                transform: 'rotate(180deg)',
            },
        },
    },
    bonusGoalBanner: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
        marginInlineEnd: 40,
        alignItems: 'center',
        padding: '0 0.325em',
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        borderRadius: '0 0 5px 5px',
        color: palette.secondary.contrastText,
        fontSize: '1.125rem',
        fontWeight: 500,
        border: isLightColor(palette.secondary.main, 0.7)
            ? `1px solid ${palette.secondary.contrastText}`
            : 'none',
        '[dir=rtl] &': {
            right: 'auto',
            left: 0,
        },
        '& svg': {
            stroke: palette.secondary.contrastText,
        },
        '@media (max-width: 600px)': {
            marginInlineEnd: 25,
        },
    },
}));

function TeamCard({
    onView,
    allowDonations,
    onDonate,
    hasEnteredBonusGoal = false,
    ...rest
}) {
    const classes = useStyles();
    const { palette } = useTheme();
    const campaignSlug = useSelector(selectCampaignSlug);
    const enableCampaignDonations = useSelector(selectEnableDonations);
    const { filteredQueryString: queryString } = filterQueryParams(
        document.location.search,
    );

    return (
        <ItemCard
            className={classNames({
                [classes.bonusRound]: hasEnteredBonusGoal,
            })}
            hasEnteredBonusGoal={hasEnteredBonusGoal}
            {...rest}
            onClick={onView}
        >
            <>
                <div className={classes.actions}>
                    <Button
                        outline
                        color={palette.primary.main}
                        hoverColor={palette.primary.main}
                        className={classNames(
                            classes.actionBtn,
                            classes.viewBtn,
                        )}
                        onClick={onView}
                    >
                        <FormattedMessage id="Button.view" />
                    </Button>
                    {allowDonations && enableCampaignDonations && (
                        <Link to={`/${campaignSlug}/donate/${queryString}`}>
                            <Button
                                onClick={onDonate}
                                className={classNames(
                                    classes.actionBtn,
                                    classes.donateBtn,
                                )}
                            >
                                <FormattedMessage id="Button.donateNow" />
                            </Button>
                        </Link>
                    )}
                </div>
                {hasEnteredBonusGoal && (
                    <div className={classes.bonusGoalBanner}>
                        <FlagIcon color={palette.secondary.contrastText} />
                        <Box m={0.5} />
                        <FormattedMessage
                            id="common.bonusGoal"
                            defaultMessage="Bonus Goal!"
                        />
                    </div>
                )}
            </>
        </ItemCard>
    );
}

TeamCard.propTypes = {
    onView: PropTypes.func,
    onDonate: PropTypes.func,
    isCustomTileEnabled: PropTypes.bool,
    allowDonations: PropTypes.bool,
    hasEnteredBonusGoal: PropTypes.bool,
};

export default TeamCard;
