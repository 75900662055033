import get from 'lodash/get';

export const selectSelectedGiving = state =>
    state.givingItem.selectedGivingItem;
export const createPageOptionsSelector =
    (selectedPage, defaultPage) => (item, option) => {
        const firstExistsPage = Object.keys(item?.pageOptions || {})[0];
        // try to get for selected page
        return get(
            item,
            `pageOptions.${selectedPage}.${option}`,
            // otherwise for default page
            get(
                item,
                `pageOptions.${defaultPage}.${option}`,
                // otherwise for any page
                get(item, `pageOptions.${firstExistsPage}.${option}`, null),
            ),
        );
    };
