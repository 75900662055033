import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import MatbiaIcon from './MatbiaIcon.js';
import MatbiaIconChecked from './MatbiaIconChecked.js';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 75,
        height: 26,
        '@media (max-width: 600px)': {
            width: 65,
            height: 19,
        },
    },
}));

function MatbiaLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={props.checked ? MatbiaIconChecked : MatbiaIcon}
                viewBox="0 0 104 19"
                className={props.className ? props.className : classes.svg}
            />
        </div>
    );
}

MatbiaLogo.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
};

export default MatbiaLogo;
