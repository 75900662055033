import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const iconTransform = (dir = 'ltr') => {
    return ({ endIcon }) => {
        if (endIcon) {
            return `translate(${dir === 'ltr' ? '-50%' : '50%'}, -50%)`;
        }

        return 'none';
    };
};

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
        position: 'relative',
        borderRadius: 13,
        border: '1px solid #999',
        overflow: 'hidden',
        background: '#fff',
        width: props => props.width,

        '&::before': {
            content: '""',
            display: 'block',
            width: 25,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            borderInlineStart: '1px solid #999',
            background: '#fff',
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                right: 'auto',
                left: 0,
            },
        },
        '&::after': {
            content: '""',
            display: 'block',
            width: 4,
            height: 4,
            border: 'solid #999',
            borderWidth: '1px 0 0 0',
            borderInlineEnd: '1px solid #999',
            position: 'absolute',
            right: 11,
            top: '50%',
            transform: 'translateY(calc(-50% - 1px)) rotate(135deg)',
            transformOrigin: 'center center',
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                right: 'auto',
                left: 11,
                transform: 'translateY(calc(-50% - 1px)) rotate(-135deg)',
            },
        },

        '& svg:last-child': {
            position: 'absolute',
            top: '50%',
            pointerEvents: 'none',
            left: 'auto',
            right: 27,
            transform: iconTransform(),

            'html[dir=rtl] &': {
                right: 'auto',
                left: 27,
                transform: iconTransform('rtl'),
            },
        },
    },
    select: {
        appearance: 'none',
        height: 25,
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        paddingInlineStart: '15px',
        paddingInlineEnd: '35px',
        color: '#808080',
        textOverflow: 'ellipsis',
        border: 0,
        fontSize: 15,
        fontFamily: 'Heebo, sans-serif',
        boxSizing: 'border-box',
        outline: 'none',
        background: 'none',
        '@media (max-width: 600px)': {
            fontSize: 13,
        },
    },
});

function Select(props) {
    const {
        className,
        width = 'auto',
        options = [],
        value,
        inputRef,
        endIcon,
        ...rest
    } = props;
    const classes = useStyles({ width, endIcon });
    const getSelectedProperty = useCallback(() => {
        const selectedProperty = options.find(option => option.selected);
        return value || selectedProperty?.value || '';
    }, [options, value]);

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <select
                className={classes.select}
                ref={inputRef}
                {...rest}
                value={getSelectedProperty()}
            >
                {options.map(option => (
                    <option
                        readOnly={option?.readonly}
                        disabled={option?.disabled}
                        hidden={option?.hidden}
                        value={option?.value}
                        key={option?.name}
                    >
                        {option?.name}
                    </option>
                ))}
            </select>
            {!!endIcon && endIcon}
        </div>
    );
}

Select.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    endIcon: PropTypes.node,
};

export default Select;
