import { create, handleResponse, handleError } from 'client/helpers/axios';

const axios = create({ baseUrl: '/chariot' });

async function campaignConnectChariot(data) {
    return axios
        .post(`/connect/campaign`, data)
        .then(handleResponse)
        .catch(handleError);
}

export default { campaignConnectChariot };
