import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { adjustColor } from 'client/helpers/colorUtils';
import config from 'client/config';

const useStyles = makeStyles({
    root: {
        width: 163,
        height: 141,
        overflow: 'visible',

        '@media (max-width: 600px)': {
            width: 110,
            height: 110,
        },
    },
});

function DonationGraph(props) {
    const { className, goal = 0, goal1 = 0, goal2 = 0, ...rest } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const line = useRef();
    const line1 = useRef();
    const line2 = useRef();
    const circle = useRef();
    const icon = useRef();
    const icon1 = useRef();
    const [lineLength, setLineLength] = useState();

    const getIconPos = percent => {
        return line.current.getPointAtLength((percent * lineLength) / 100);
    };
    const setIconTransform = (el, poz) => {
        el.current.setAttributeNS(
            null,
            'transform',
            `translate(${poz.x},${poz.y})`,
        );
    };

    useEffect(() => {
        setLineLength(line.current.getTotalLength());
    }, []);
    useEffect(() => {
        if (lineLength) {
            setIconTransform(circle, getIconPos(goal));
        }
    }, [lineLength, goal]);
    useEffect(() => {
        if (lineLength && goal1 > goal) {
            setIconTransform(icon, getIconPos(goal));
        }
    }, [lineLength, goal, goal1]);
    useEffect(() => {
        if (lineLength && goal2 > goal1) {
            setIconTransform(icon1, getIconPos(goal1));
        }
    }, [lineLength, goal1, goal2]);

    return (
        <svg
            {...rest}
            viewBox="0 0 163 141"
            className={classNames(classes.root, className)}
        >
            <defs>
                <linearGradient id="goalLineGradient">
                    <stop
                        offset="10%"
                        stopColor={adjustColor(palette.primary.main, 20)}
                    />
                    <stop offset="80%" stopColor={palette.primary.main} />
                </linearGradient>
                <linearGradient id="bonusLine1Gradient">
                    <stop
                        offset="65%"
                        stopColor={adjustColor(palette.secondary.main, 12)}
                    />
                    <stop offset="100%" stopColor={palette.secondary.main} />
                </linearGradient>
                <linearGradient id="bonusLine2Gradient">
                    <stop
                        offset="88%"
                        stopColor={adjustColor(palette.secondary.main, 9)}
                    />
                    <stop offset="100%" stopColor={palette.secondary.main} />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(4 6)">
                <g strokeLinecap="round" strokeWidth={9.5}>
                    <path
                        d="M0.772,130.34 C13.038,102.654 35.791,91.073 56.812,84.017 C77.833,76.961 100.441,69.212 117.219,50.106 C132.997,31 133.997,16 153.997,0"
                        stroke={palette.grey[400]}
                    />
                    <path
                        ref={line2}
                        d="M0.772,130.34 C13.038,102.654 35.791,91.073 56.812,84.017 C77.833,76.961 100.441,69.212 117.219,50.106 C132.997,31 133.997,16 153.997,0"
                        stroke="url('#bonusLine2Gradient')"
                        strokeDasharray={`${(207 / 100) * goal2} ${
                            goal2 > 0 ? 207 : 250
                        }`}
                    />
                    <path
                        ref={line1}
                        d="M0.772,130.34 C13.038,102.654 35.791,91.073 56.812,84.017 C77.833,76.961 100.441,69.212 117.219,50.106 C132.997,31 133.997,16 153.997,0"
                        stroke="url('#bonusLine1Gradient')"
                        strokeDasharray={`${(207 / 100) * goal1} ${
                            goal1 > 0 ? 207 : 250
                        }`}
                    />
                    <path
                        ref={line}
                        d="M0.772,130.34 C13.038,102.654 35.791,91.073 56.812,84.017 C77.833,76.961 100.441,69.212 117.219,50.106 C132.997,31 133.997,16 153.997,0"
                        stroke="url('#goalLineGradient')"
                        strokeDasharray={`${(207 / 100) * goal} ${
                            goal > 0 ? 207 : 250
                        }`}
                    />
                    <circle
                        ref={circle}
                        r="6"
                        stroke={palette.primary.main}
                        strokeWidth={5}
                        fill={
                            goal1 >= goal
                                ? 'none'
                                : palette.primary.contrastText
                        }
                    />
                </g>
                <g transform="translate(-5 -5)">
                    {goal1 > goal && (
                        <image
                            ref={icon}
                            href={`${config.publicHost}/images/check.svg`}
                            width={10}
                            height={10}
                        />
                    )}
                    {goal2 > goal1 && (
                        <image
                            ref={icon1}
                            href={`${config.publicHost}/images/check.svg`}
                            width={10}
                            height={10}
                        />
                    )}
                </g>
            </g>
        </svg>
    );
}

DonationGraph.propTypes = {
    className: PropTypes.string,
    goal: PropTypes.number,
    goal1: PropTypes.number,
    goal2: PropTypes.number,
};

export default DonationGraph;
