import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from './IdealLogo.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 22,
        height: 22,
        paddingBottom: 2,
        'html[dir="rtl"] &': {
            marginLeft: 5,
        },
    },
}));

function IdealLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={Logo}
                viewBox="0 0 22 17"
                className={props.className ? props.className : classes.svg}
            />
            <div>iDEAL</div>
        </div>
    );
}

IdealLogo.propTypes = {
    className: PropTypes.string,
};

export default IdealLogo;
