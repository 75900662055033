const fp = require('lodash/fp');
const u2a = require('./u2a');

function trimString(stringValue) {
    return (stringValue || '').trim();
}

function donorPflPrepareName(firstName = '', lastName = '') {
    return fp.flow(
        fp.map(trimString),
        fp.join(' '),
        fp.replace(/[^\u0000-\u007f]/g, ''),
        fp.replace(/[^a-zA-Z0-9.,_-\s]/g, ''),
        fp.trim,
        fp.replace(/[\s]+/g, '-'),
    )([firstName, lastName]);
}

function donorPflPrepareUrl(campaignPageUrl, donorPflSlug, donorName = '') {
    campaignPageUrl = campaignPageUrl.replace(/\/$/, '');
    return `${campaignPageUrl}/d/${donorPflSlug}${
        donorName ? `/${donorName}` : ''
    }`;
}

function donorPflPrepareSlug(integerId) {
    return integerId ? u2a.b32encodeUInt(integerId) : undefined;
}

function donorPflPrepareQueryString(donorPflId, donation) {
    if (!donorPflId) {
        return '';
    }
    let donorName = '';
    if (donation && !donation.isAnonymous) {
        donorName = donorPflPrepareName(donation.firstName, donation.lastName);
    }
    return (
        `&donorPflSlug=${donorPflPrepareSlug(donorPflId)}` +
        (donorName ? `&donorName=${donorName}` : '')
    );
}

function createDonorPfl(integerId, campaignPageUrl, donationProps = {}) {
    if (!integerId || !campaignPageUrl) {
        return null;
    }
    const { firstName = '', lastName = '' } = donationProps;
    return donorPflPrepareUrl(
        campaignPageUrl,
        donorPflPrepareSlug(integerId),
        donorPflPrepareName(firstName, lastName),
    );
}

module.exports = {
    createDonorPfl,
    donorPflPrepareName,
    donorPflPrepareUrl,
    donorPflPrepareSlug,
    donorPflPrepareQueryString,
};
