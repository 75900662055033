const CURRENCY_INFO = Object.freeze({
    USD: {
        sign: '$',
        label: 'USD$',
        minAmount: 10,
        maxAmount: 10000000,
        flagCode: 'US',
        timezone: 'America/New_York',
    },
    ILS: {
        sign: '₪',
        label: 'ILS₪',
        minAmount: 10,
        maxAmount: 20000000,
        flagCode: 'IL',
        timezone: 'Israel',
    },
    CAD: {
        sign: 'C$',
        label: 'CAD$',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'CA',
        timezone: 'Canada/Eastern',
    },
    GBP: {
        sign: '£',
        label: 'GBP£',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'GB',
        timezone: 'Europe/London',
    },
    EUR: {
        sign: '€',
        label: 'EUR€',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'EU',
        timezone: 'Europe/Brussels',
    },
    AUD: {
        sign: 'A$',
        label: 'AUD$',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'AU',
        timezone: 'Australia/ACT',
    },
    ZAR: {
        sign: 'R',
        label: 'ZAR',
        minAmount: 10,
        maxAmount: 20000000,
        flagCode: 'ZA',
        timezone: 'Africa/Johannesburg',
    },
    BRL: {
        sign: 'R$',
        label: 'BRL$',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'BR',
        timezone: 'America/Sao_Paulo',
    },
    CHF: {
        sign: '₣',
        label: 'CHF₣',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'CH',
        timezone: 'Europe/Zurich',
    },
    MXN: {
        sign: 'Mex$',
        label: 'MXN$',
        minAmount: 10,
        maxAmount: 10000000,
        flagCode: 'MX',
        timezone: 'America/Mexico_City',
    },
    NZD: {
        sign: 'NZ$',
        label: 'NZD$',
        minAmount: 5,
        maxAmount: 10000000,
        flagCode: 'NZ',
        timezone: 'Pacific/Auckland',
    },
});

const CURRENCIES = Object.freeze(Object.keys(CURRENCY_INFO));

const CURRENCY_SIGNS = Object.freeze(
    Object.keys(CURRENCY_INFO).reduce((obj, cur) => {
        obj[cur] = CURRENCY_INFO[cur].sign;
        return obj;
    }, {}),
);

const CURRENCY_ZONES = Object.freeze(
    Object.keys(CURRENCY_INFO).reduce((obj, cur) => {
        obj[cur] = CURRENCY_INFO[cur].timezone;
        return obj;
    }, {}),
);

const DONATION_LIMITS = Object.freeze(
    Object.keys(CURRENCY_INFO).reduce((obj, cur) => {
        obj[cur] = {
            MIN: CURRENCY_INFO[cur].minAmount,
            MAX: CURRENCY_INFO[cur].maxAmount,
        };
        return obj;
    }, {}),
);

module.exports = {
    CURRENCY_INFO,
    CURRENCIES,
    CURRENCY_SIGNS,
    CURRENCY_ZONES,
    DONATION_LIMITS,
};
