import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '0.8rem',
        padding: '3px',
    },

    error: {
        color: 'red',
    },
}));

function FormInputHelperText(props) {
    const { error, className, children } = props;
    const classes = useStyles();
    const content = get(error, 'message', children);

    return (
        <span
            className={[classes.root, error ? classes.error : null, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {content}
        </span>
    );
}

FormInputHelperText.propTypes = {
    error: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default FormInputHelperText;
