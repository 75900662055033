import Repository from './Repository';
import querystring from 'query-string';
import axios from 'axios';

const repository = new Repository({ baseUrl: '/campaign' });

repository.findPublishedById = async function (id) {
    return this.axios
        .get(`/${id}?usePublishedVersion`)
        .then(this.handleRequestData);
};

repository.validateSlug = async function (slugs, id = null) {
    return await this.axios.get(
        `/slug-allow?${querystring.stringify({ slugs, id })}`,
    );
};

repository.amount = async function (id) {
    return await this.axios.get(`/${id}/amount`).then(res => res.data);
};

repository.csv = async function (id) {
    return await this.axios.get(`/csv?${querystring.stringify({ id })}`);
};

repository.disable = async function (id) {
    return await this.axios.put(`/${id}/disable`).then(res => res.data);
};

repository.enable = async function (id) {
    return await this.axios.put(`/${id}/enable`).then(res => res.data);
};

repository.rdpOrder = async function (id, data) {
    return await this.axios.put(`/${id}/rdp-order`, data).then(res => res.data);
};

// for backwards compatibility
// TODO: remove
repository.rdpLabel = async function (id, data) {
    return await this.axios
        .put(`/${id}/rdp-label`, {
            rdpLabel: data,
        })
        .then(res => res.data);
};

repository.rdpTab = async function (id, data) {
    return await this.axios.put(`/${id}/rdp-tab`, data).then(res => res.data);
};

repository.exchange = async function (val, from, to) {
    return await this.axios
        .get(`/exchange/?${querystring.stringify({ val, from, to })}`)
        .then(res => res.data);
};

repository.initiateLayers = async function (campaignId) {
    return this.axios.post(`/${campaignId}/init-layers`);
};

repository.fetchBySlug = async function (slug) {
    return axios.get(`public/campaign/${slug}`).then(res => res.data);
};

repository.copy = async function ({ sourceCampaignId, ...data }) {
    const response = await this.axios.post(`/copy/${sourceCampaignId}`, data);
    return response.data;
};

export default repository;
