import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(
    () => ({
        root: {
            maxWidth: 1140,
            margin: '0 auto',
            width: '100%',
            boxSizing: 'border-box',

            '@media (max-width: 1023px)': {
                padding: '0 15px',
            },
        },
    }),
    { name: 'PageSection' },
);

function PageSection(props) {
    const {
        component: Component = 'section',
        className,
        children,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Component
            className={classNames(classes.root, className, 'page-section')}
            {...rest}
        >
            {children}
        </Component>
    );
}

PageSection.propTypes = {
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
    children: PropTypes.node,
};

export default PageSection;
