const _ = require('lodash');

const COUNTRIES_INFO = Object.freeze([
    {
        label: 'United States',
        flagCode: 'US',
        phonePrefix: '+1',
        phoneNumberFormat: '(###) ###-####',
        top: true,
    },
    {
        label: 'Canada',
        flagCode: 'CA',
        phonePrefix: '+1',
        phoneNumberFormat: '(###) ###-####',
        top: true,
    },
    {
        label: 'Israel',
        flagCode: 'IL',
        phonePrefix: '+972',
        phoneNumberFormat: '##-###-####',
        top: true,
        shouldRemoveTrunkPrefix: true,
    },
    {
        label: 'United Kingdom',
        flagCode: 'GB',
        phonePrefix: '+44',
        phoneNumberFormat: '#### ######',
        top: true,
    },
    {
        label: 'Afghanistan',
        flagCode: 'AF',
        phonePrefix: '+93',
        phoneNumberFormat: '',
    },
    {
        label: 'Albania',
        flagCode: 'AL',
        phonePrefix: '+355',
        phoneNumberFormat: '',
    },
    {
        label: 'Algeria',
        flagCode: 'DZ',
        phonePrefix: '+213',
        phoneNumberFormat: '',
    },
    {
        label: 'Andorra',
        flagCode: 'AD',
        phonePrefix: '+376',
        phoneNumberFormat: '',
    },
    {
        label: 'Angola',
        flagCode: 'AO',
        phonePrefix: '+244',
        phoneNumberFormat: '',
    },
    {
        label: 'Argentina',
        flagCode: 'AR',
        phonePrefix: '+54',
        phoneNumberFormat: '',
    },
    {
        label: 'Armenia',
        flagCode: 'AM',
        phonePrefix: '+374',
        phoneNumberFormat: '',
    },
    {
        label: 'Aruba',
        flagCode: 'AW',
        phonePrefix: '+297',
        phoneNumberFormat: '',
    },
    {
        label: 'Australia',
        flagCode: 'AU',
        phonePrefix: '+61',
        phoneNumberFormat: '(##) #### ####',
    },
    {
        label: 'Austria',
        flagCode: 'AT',
        phonePrefix: '+43',
        phoneNumberFormat: '',
    },
    {
        label: 'Azerbaijan',
        flagCode: 'AZ',
        phonePrefix: '+994',
        phoneNumberFormat: '',
    },
    {
        label: 'Bahrain',
        flagCode: 'BH',
        phonePrefix: '+973',
        phoneNumberFormat: '',
    },
    {
        label: 'Bangladesh',
        flagCode: 'BD',
        phonePrefix: '+880',
        phoneNumberFormat: '',
    },
    {
        label: 'Belarus',
        flagCode: 'BY',
        phonePrefix: '+375',
        phoneNumberFormat: '',
    },
    {
        label: 'Belgium',
        flagCode: 'BE',
        phonePrefix: '+32',
        phoneNumberFormat: '### ## ## ##',
    },
    {
        label: 'Belize',
        flagCode: 'BZ',
        phonePrefix: '+501',
        phoneNumberFormat: '',
    },
    {
        label: 'Benin',
        flagCode: 'BJ',
        phonePrefix: '+229',
        phoneNumberFormat: '',
    },
    {
        label: 'Bhutan',
        flagCode: 'BT',
        phonePrefix: '+975',
        phoneNumberFormat: '',
    },
    {
        label: 'Bolivia',
        flagCode: 'BO',
        phonePrefix: '+591',
        phoneNumberFormat: '',
    },
    {
        label: 'Bosnia and Herzegovina',
        flagCode: 'BA',
        phonePrefix: '+387',
        phoneNumberFormat: '',
    },
    {
        label: 'Botswana',
        flagCode: 'BW',
        phonePrefix: '+267',
        phoneNumberFormat: '',
    },
    {
        label: 'Brazil',
        flagCode: 'BR',
        phonePrefix: '+55',
        phoneNumberFormat: '(##) #########',
    },
    {
        label: 'British Indian Ocean Territory',
        flagCode: 'IO',
        phonePrefix: '+246',
        phoneNumberFormat: '',
    },
    {
        label: 'Brunei',
        flagCode: 'BN',
        phonePrefix: '+673',
        phoneNumberFormat: '',
    },
    {
        label: 'Bulgaria',
        flagCode: 'BG',
        phonePrefix: '+359',
        phoneNumberFormat: '',
    },
    {
        label: 'Burkina Faso',
        flagCode: 'BF',
        phonePrefix: '+226',
        phoneNumberFormat: '',
    },
    {
        label: 'Burundi',
        flagCode: 'BI',
        phonePrefix: '+257',
        phoneNumberFormat: '',
    },
    {
        label: 'Cambodia',
        flagCode: 'KH',
        phonePrefix: '+855',
        phoneNumberFormat: '',
    },
    {
        label: 'Cameroon',
        flagCode: 'CM',
        phonePrefix: '+237',
        phoneNumberFormat: '',
    },
    {
        label: 'Cape Verde',
        flagCode: 'CV',
        phonePrefix: '+238',
        phoneNumberFormat: '',
    },
    {
        label: 'Central African Republic',
        flagCode: 'CF',
        phonePrefix: '+236',
        phoneNumberFormat: '',
    },
    {
        label: 'Chad',
        flagCode: 'TD',
        phonePrefix: '+235',
        phoneNumberFormat: '',
    },
    {
        label: 'Chile',
        flagCode: 'CL',
        phonePrefix: '+56',
        phoneNumberFormat: '## ### ####',
    },
    {
        label: 'China',
        flagCode: 'CN',
        phonePrefix: '+86',
        phoneNumberFormat: '',
    },
    {
        label: 'Christmas Island',
        flagCode: 'CX',
        phonePrefix: '+61',
        phoneNumberFormat: '',
    },
    {
        label: 'Cocos Islands',
        flagCode: 'CC',
        phonePrefix: '+61',
        phoneNumberFormat: '',
    },
    {
        label: 'Colombia',
        flagCode: 'CO',
        phonePrefix: '+57',
        phoneNumberFormat: '',
    },
    {
        label: 'Comoros',
        flagCode: 'KM',
        phonePrefix: '+269',
        phoneNumberFormat: '',
    },
    {
        label: 'Costa Rica',
        flagCode: 'CR',
        phonePrefix: '+506',
        phoneNumberFormat: '',
    },
    {
        label: 'Croatia',
        flagCode: 'HR',
        phonePrefix: '+385',
        phoneNumberFormat: '',
    },
    {
        label: 'Cuba',
        flagCode: 'CU',
        phonePrefix: '+53',
        phoneNumberFormat: '',
    },
    {
        label: 'Curacao',
        flagCode: 'CW',
        phonePrefix: '+599',
        phoneNumberFormat: '',
    },
    {
        label: 'Cyprus',
        flagCode: 'CY',
        phonePrefix: '+357',
        phoneNumberFormat: '',
    },
    {
        label: 'Czech Republic',
        flagCode: 'CZ',
        phonePrefix: '+420',
        phoneNumberFormat: '',
    },
    {
        label: 'Democratic Republic of the Congo',
        flagCode: 'CD',
        phonePrefix: '+243',
        phoneNumberFormat: '',
    },
    {
        label: 'Denmark',
        flagCode: 'DK',
        phonePrefix: '+45',
        phoneNumberFormat: '',
    },
    {
        label: 'Djibouti',
        flagCode: 'DJ',
        phonePrefix: '+253',
        phoneNumberFormat: '',
    },
    {
        label: 'East Timor',
        flagCode: 'TL',
        phonePrefix: '+670',
        phoneNumberFormat: '',
    },
    {
        label: 'Ecuador',
        flagCode: 'EC',
        phonePrefix: '+593',
        phoneNumberFormat: '',
    },
    {
        label: 'Egypt',
        flagCode: 'EG',
        phonePrefix: '+20',
        phoneNumberFormat: '',
    },
    {
        label: 'El Salvador',
        flagCode: 'SV',
        phonePrefix: '+503',
        phoneNumberFormat: '',
    },
    {
        label: 'Equatorial Guinea',
        flagCode: 'GQ',
        phonePrefix: '+240',
        phoneNumberFormat: '',
    },
    {
        label: 'Eritrea',
        flagCode: 'ER',
        phonePrefix: '+291',
        phoneNumberFormat: '',
    },
    {
        label: 'Estonia',
        flagCode: 'EE',
        phonePrefix: '+372',
        phoneNumberFormat: '',
    },
    {
        label: 'Ethiopia',
        flagCode: 'ET',
        phonePrefix: '+251',
        phoneNumberFormat: '',
    },
    {
        label: 'Fiji',
        flagCode: 'FJ',
        phonePrefix: '+679',
        phoneNumberFormat: '',
    },
    {
        label: 'Finland',
        flagCode: 'FI',
        phonePrefix: '+358',
        phoneNumberFormat: '',
    },
    {
        label: 'France',
        flagCode: 'FR',
        phonePrefix: '+33',
        phoneNumberFormat: '## ### ####',
    },
    {
        label: 'French Polynesia',
        flagCode: 'PF',
        phonePrefix: '+689',
        phoneNumberFormat: '',
    },
    {
        label: 'Gabon',
        flagCode: 'GA',
        phonePrefix: '+241',
        phoneNumberFormat: '',
    },
    {
        label: 'Gambia',
        flagCode: 'GM',
        phonePrefix: '+220',
        phoneNumberFormat: '',
    },
    {
        label: 'Georgia',
        flagCode: 'GE',
        phonePrefix: '+995',
        phoneNumberFormat: '### ### ###',
    },
    {
        label: 'Germany',
        flagCode: 'DE',
        phonePrefix: '+49',
        phoneNumberFormat: '',
    },
    {
        label: 'Ghana',
        flagCode: 'GH',
        phonePrefix: '+233',
        phoneNumberFormat: '',
    },
    {
        label: 'Greece',
        flagCode: 'GR',
        phonePrefix: '+30',
        phoneNumberFormat: '',
    },
    {
        label: 'Guatemala',
        flagCode: 'GT',
        phonePrefix: '+502',
        phoneNumberFormat: '',
    },
    {
        label: 'Guinea',
        flagCode: 'GN',
        phonePrefix: '+224',
        phoneNumberFormat: '',
    },
    {
        label: 'Guinea-Bissau',
        flagCode: 'GW',
        phonePrefix: '+245',
        phoneNumberFormat: '',
    },
    {
        label: 'Guyana',
        flagCode: 'GY',
        phonePrefix: '+592',
        phoneNumberFormat: '',
    },
    {
        label: 'Haiti',
        flagCode: 'HT',
        phonePrefix: '+509',
        phoneNumberFormat: '',
    },
    {
        label: 'Honduras',
        flagCode: 'HN',
        phonePrefix: '+504',
        phoneNumberFormat: '',
    },
    {
        label: 'Hong Kong',
        flagCode: 'HK',
        phonePrefix: '+852',
        phoneNumberFormat: '',
    },
    {
        label: 'Hungary',
        flagCode: 'HU',
        phonePrefix: '+36',
        phoneNumberFormat: '',
    },
    {
        label: 'Iceland',
        flagCode: 'IS',
        phonePrefix: '+354',
        phoneNumberFormat: '',
    },
    {
        label: 'India',
        flagCode: 'IN',
        phonePrefix: '+91',
        phoneNumberFormat: '#####-#####',
    },
    {
        label: 'Indonesia',
        flagCode: 'ID',
        phonePrefix: '+62',
        phoneNumberFormat: '',
    },
    {
        label: 'Iran',
        flagCode: 'IR',
        phonePrefix: '+98',
        phoneNumberFormat: '',
    },
    {
        label: 'Iraq',
        flagCode: 'IQ',
        phonePrefix: '+964',
        phoneNumberFormat: '',
    },
    {
        label: 'Ireland',
        flagCode: 'IE',
        phonePrefix: '+353',
        phoneNumberFormat: '',
    },
    {
        label: 'Italy',
        flagCode: 'IT',
        phonePrefix: '+39',
        phoneNumberFormat: '### #######',
    },
    {
        label: 'Ivory Coast',
        flagCode: 'CI',
        phonePrefix: '+225',
        phoneNumberFormat: '',
    },
    {
        label: 'Japan',
        flagCode: 'JP',
        phonePrefix: '+81',
        phoneNumberFormat: '',
    },
    {
        label: 'Jordan',
        flagCode: 'JO',
        phonePrefix: '+962',
        phoneNumberFormat: '',
    },
    {
        label: 'Kazakhstan',
        flagCode: 'KZ',
        phonePrefix: '+7',
        phoneNumberFormat: '',
    },
    {
        label: 'Kenya',
        flagCode: 'KE',
        phonePrefix: '+254',
        phoneNumberFormat: '',
    },
    {
        label: 'Kiribati',
        flagCode: 'KI',
        phonePrefix: '+686',
        phoneNumberFormat: '',
    },
    {
        label: 'Kosovo',
        flagCode: 'XK',
        phonePrefix: '+383',
        phoneNumberFormat: '',
    },
    {
        label: 'Kuwait',
        flagCode: 'KW',
        phonePrefix: '+965',
        phoneNumberFormat: '',
    },
    {
        label: 'Kyrgyzstan',
        flagCode: 'KG',
        phonePrefix: '+996',
        phoneNumberFormat: '',
    },
    {
        label: 'Laos',
        flagCode: 'LA',
        phonePrefix: '+856',
        phoneNumberFormat: '',
    },
    {
        label: 'Latvia',
        flagCode: 'LV',
        phonePrefix: '+371',
        phoneNumberFormat: '',
    },
    {
        label: 'Lebanon',
        flagCode: 'LB',
        phonePrefix: '+961',
        phoneNumberFormat: '',
    },
    {
        label: 'Lesotho',
        flagCode: 'LS',
        phonePrefix: '+266',
        phoneNumberFormat: '',
    },
    {
        label: 'Liberia',
        flagCode: 'LR',
        phonePrefix: '+231',
        phoneNumberFormat: '',
    },
    {
        label: 'Libya',
        flagCode: 'LY',
        phonePrefix: '+218',
        phoneNumberFormat: '',
    },
    {
        label: 'Liechtenstein',
        flagCode: 'LI',
        phonePrefix: '+423',
        phoneNumberFormat: '',
    },
    {
        label: 'Lithuania',
        flagCode: 'LT',
        phonePrefix: '+370',
        phoneNumberFormat: '',
    },
    {
        label: 'Luxembourg',
        flagCode: 'LU',
        phonePrefix: '+352',
        phoneNumberFormat: '',
    },
    {
        label: 'Macau',
        flagCode: 'MO',
        phonePrefix: '+853',
        phoneNumberFormat: '',
    },
    {
        label: 'Macedonia',
        flagCode: 'MK',
        phonePrefix: '+389',
        phoneNumberFormat: '',
    },
    {
        label: 'Madagascar',
        flagCode: 'MG',
        phonePrefix: '+261',
        phoneNumberFormat: '',
    },
    {
        label: 'Malawi',
        flagCode: 'MW',
        phonePrefix: '+265',
        phoneNumberFormat: '',
    },
    {
        label: 'Malaysia',
        flagCode: 'MY',
        phonePrefix: '+60',
        phoneNumberFormat: '',
    },
    {
        label: 'Maldives',
        flagCode: 'MV',
        phonePrefix: '+960',
        phoneNumberFormat: '',
    },
    {
        label: 'Mali',
        flagCode: 'ML',
        phonePrefix: '+223',
        phoneNumberFormat: '',
    },
    {
        label: 'Malta',
        flagCode: 'MT',
        phonePrefix: '+356',
        phoneNumberFormat: '',
    },
    {
        label: 'Marshall Islands',
        flagCode: 'MH',
        phonePrefix: '+692',
        phoneNumberFormat: '',
    },
    {
        label: 'Mauritania',
        flagCode: 'MR',
        phonePrefix: '+222',
        phoneNumberFormat: '',
    },
    {
        label: 'Mauritius',
        flagCode: 'MU',
        phonePrefix: '+230',
        phoneNumberFormat: '',
    },
    {
        label: 'Mayotte',
        flagCode: 'YT',
        phonePrefix: '+262',
        phoneNumberFormat: '',
    },
    {
        label: 'Mexico',
        flagCode: 'MX',
        phonePrefix: '+52',
        phoneNumberFormat: '### ### ####',
    },
    {
        label: 'Micronesia',
        flagCode: 'FM',
        phonePrefix: '+691',
        phoneNumberFormat: '',
    },
    {
        label: 'Moldova',
        flagCode: 'MD',
        phonePrefix: '+373',
        phoneNumberFormat: '',
    },
    {
        label: 'Monaco',
        flagCode: 'MC',
        phonePrefix: '+377',
        phoneNumberFormat: '',
    },
    {
        label: 'Mongolia',
        flagCode: 'MN',
        phonePrefix: '+976',
        phoneNumberFormat: '',
    },
    {
        label: 'Montenegro',
        flagCode: 'ME',
        phonePrefix: '+382',
        phoneNumberFormat: '',
    },
    {
        label: 'Morocco',
        flagCode: 'MA',
        phonePrefix: '+212',
        phoneNumberFormat: '',
    },
    {
        label: 'Mozambique',
        flagCode: 'MZ',
        phonePrefix: '+258',
        phoneNumberFormat: '',
    },
    {
        label: 'Myanmar',
        flagCode: 'MM',
        phonePrefix: '+95',
        phoneNumberFormat: '',
    },
    {
        label: 'Namibia',
        flagCode: 'NA',
        phonePrefix: '+264',
        phoneNumberFormat: '',
    },
    {
        label: 'Nauru',
        flagCode: 'NR',
        phonePrefix: '+674',
        phoneNumberFormat: '',
    },
    {
        label: 'Nepal',
        flagCode: 'NP',
        phonePrefix: '+977',
        phoneNumberFormat: '',
    },
    {
        label: 'Netherlands',
        flagCode: 'NL',
        phonePrefix: '+31',
        phoneNumberFormat: '## ### ####',
        shouldRemoveTrunkPrefix: true,
    },
    {
        label: 'Netherlands Antilles',
        flagCode: 'AN',
        phonePrefix: '+599',
        phoneNumberFormat: '',
    },
    {
        label: 'New Caledonia',
        flagCode: 'NC',
        phonePrefix: '+687',
        phoneNumberFormat: '',
    },
    {
        label: 'New Zealand',
        flagCode: 'NZ',
        phonePrefix: '+64',
        phoneNumberFormat: '###-###-####',
    },
    {
        label: 'Nicaragua',
        flagCode: 'NI',
        phonePrefix: '+505',
        phoneNumberFormat: '',
    },
    {
        label: 'Niger',
        flagCode: 'NE',
        phonePrefix: '+227',
        phoneNumberFormat: '',
    },
    {
        label: 'Nigeria',
        flagCode: 'NG',
        phonePrefix: '+234',
        phoneNumberFormat: '',
    },
    {
        label: 'North Korea',
        flagCode: 'KP',
        phonePrefix: '+850',
        phoneNumberFormat: '',
    },
    {
        label: 'Norway',
        flagCode: 'NO',
        phonePrefix: '+47',
        phoneNumberFormat: '',
    },
    {
        label: 'Oman',
        flagCode: 'OM',
        phonePrefix: '+968',
        phoneNumberFormat: '',
    },
    {
        label: 'Pakistan',
        flagCode: 'PK',
        phonePrefix: '+92',
        phoneNumberFormat: '',
    },
    {
        label: 'Palau',
        flagCode: 'PW',
        phonePrefix: '+680',
        phoneNumberFormat: '',
    },
    {
        label: 'Palestine',
        flagCode: 'PS',
        phonePrefix: '+970',
        phoneNumberFormat: '',
    },
    {
        label: 'Panama',
        flagCode: 'PA',
        phonePrefix: '+507',
        phoneNumberFormat: '### #####',
    },
    {
        label: 'Papua New Guinea',
        flagCode: 'PG',
        phonePrefix: '+675',
        phoneNumberFormat: '',
    },
    {
        label: 'Paraguay',
        flagCode: 'PY',
        phonePrefix: '+595',
        phoneNumberFormat: '',
    },
    {
        label: 'Peru',
        flagCode: 'PE',
        phonePrefix: '+51',
        phoneNumberFormat: '',
    },
    {
        label: 'Philippines',
        flagCode: 'PH',
        phonePrefix: '+63',
        phoneNumberFormat: '',
    },
    {
        label: 'Pitcairn',
        flagCode: 'PN',
        phonePrefix: '+64',
        phoneNumberFormat: '',
    },
    {
        label: 'Poland',
        flagCode: 'PL',
        phonePrefix: '+48',
        phoneNumberFormat: '',
    },
    {
        label: 'Portugal',
        flagCode: 'PT',
        phonePrefix: '+351',
        phoneNumberFormat: '',
    },
    {
        label: 'Republic of the Congo',
        flagCode: 'CG',
        phonePrefix: '+242',
        phoneNumberFormat: '',
    },
    {
        label: 'Reunion',
        flagCode: 'RE',
        phonePrefix: '+262',
        phoneNumberFormat: '',
    },
    {
        label: 'Romania',
        flagCode: 'RO',
        phonePrefix: '+40',
        phoneNumberFormat: '',
    },
    {
        label: 'Russia',
        flagCode: 'RU',
        phonePrefix: '+7',
        phoneNumberFormat: '(###) ###-##-##',
    },
    {
        label: 'Rwanda',
        flagCode: 'RW',
        phonePrefix: '+250',
        phoneNumberFormat: '',
    },
    {
        label: 'Saint Barthelemy',
        flagCode: 'BL',
        phonePrefix: '+590',
        phoneNumberFormat: '',
    },
    {
        label: 'Saint Martin',
        flagCode: 'MF',
        phonePrefix: '+590',
        phoneNumberFormat: '',
    },
    {
        label: 'Samoa',
        flagCode: 'WS',
        phonePrefix: '+685',
        phoneNumberFormat: '',
    },
    {
        label: 'San Marino',
        flagCode: 'SM',
        phonePrefix: '+378',
        phoneNumberFormat: '',
    },
    {
        label: 'Sao Tome and Principe',
        flagCode: 'ST',
        phonePrefix: '+239',
        phoneNumberFormat: '',
    },
    {
        label: 'Saudi Arabia',
        flagCode: 'SA',
        phonePrefix: '+966',
        phoneNumberFormat: '',
    },
    {
        label: 'Senegal',
        flagCode: 'SN',
        phonePrefix: '+221',
        phoneNumberFormat: '',
    },
    {
        label: 'Serbia',
        flagCode: 'RS',
        phonePrefix: '+381',
        phoneNumberFormat: '',
    },
    {
        label: 'Seychelles',
        flagCode: 'SC',
        phonePrefix: '+248',
        phoneNumberFormat: '',
    },
    {
        label: 'Sierra Leone',
        flagCode: 'SL',
        phonePrefix: '+232',
        phoneNumberFormat: '',
    },
    {
        label: 'Singapore',
        flagCode: 'SG',
        phonePrefix: '+65',
        phoneNumberFormat: '',
    },
    {
        label: 'Slovakia',
        flagCode: 'SK',
        phonePrefix: '+421',
        phoneNumberFormat: '',
    },
    {
        label: 'Slovenia',
        flagCode: 'SI',
        phonePrefix: '+386',
        phoneNumberFormat: '',
    },
    {
        label: 'Solomon Islands',
        flagCode: 'SB',
        phonePrefix: '+677',
        phoneNumberFormat: '',
    },
    {
        label: 'Somalia',
        flagCode: 'SO',
        phonePrefix: '+252',
        phoneNumberFormat: '',
    },
    {
        label: 'South Africa',
        flagCode: 'ZA',
        phonePrefix: '+27',
        phoneNumberFormat: '## ### ####',
    },
    {
        label: 'South Korea',
        flagCode: 'KR',
        phonePrefix: '+82',
        phoneNumberFormat: '',
    },
    {
        label: 'South Sudan',
        flagCode: 'SS',
        phonePrefix: '+211',
        phoneNumberFormat: '',
    },
    {
        label: 'Spain',
        flagCode: 'ES',
        phonePrefix: '+34',
        phoneNumberFormat: '### ### ###',
    },
    {
        label: 'Sri Lanka',
        flagCode: 'LK',
        phonePrefix: '+94',
        phoneNumberFormat: '',
    },
    {
        label: 'Sudan',
        flagCode: 'SD',
        phonePrefix: '+249',
        phoneNumberFormat: '',
    },
    {
        label: 'Suriname',
        flagCode: 'SR',
        phonePrefix: '+597',
        phoneNumberFormat: '',
    },
    {
        label: 'Svalbard and Jan Mayen',
        flagCode: 'SJ',
        phonePrefix: '+47',
        phoneNumberFormat: '',
    },
    {
        label: 'Swaziland',
        flagCode: 'SZ',
        phonePrefix: '+268',
        phoneNumberFormat: '',
    },
    {
        label: 'Sweden',
        flagCode: 'SE',
        phonePrefix: '+46',
        phoneNumberFormat: '',
    },
    {
        label: 'Switzerland',
        flagCode: 'CH',
        phonePrefix: '+41',
        phoneNumberFormat: '## ### ## ##',
    },
    {
        label: 'Syria',
        flagCode: 'SY',
        phonePrefix: '+963',
        phoneNumberFormat: '',
    },
    {
        label: 'Taiwan',
        flagCode: 'TW',
        phonePrefix: '+886',
        phoneNumberFormat: '',
    },
    {
        label: 'Tajikistan',
        flagCode: 'TJ',
        phonePrefix: '+992',
        phoneNumberFormat: '',
    },
    {
        label: 'Tanzania',
        flagCode: 'TZ',
        phonePrefix: '+255',
        phoneNumberFormat: '',
    },
    {
        label: 'Thailand',
        flagCode: 'TH',
        phonePrefix: '+66',
        phoneNumberFormat: '',
    },
    {
        label: 'Togo',
        flagCode: 'TG',
        phonePrefix: '+228',
        phoneNumberFormat: '',
    },
    {
        label: 'Tonga',
        flagCode: 'TO',
        phonePrefix: '+676',
        phoneNumberFormat: '',
    },
    {
        label: 'Tunisia',
        flagCode: 'TN',
        phonePrefix: '+216',
        phoneNumberFormat: '',
    },
    {
        label: 'Turkey',
        flagCode: 'TR',
        phonePrefix: '+90',
        phoneNumberFormat: '',
    },
    {
        label: 'Turkmenistan',
        flagCode: 'TM',
        phonePrefix: '+993',
        phoneNumberFormat: '',
    },
    {
        label: 'Tuvalu',
        flagCode: 'TV',
        phonePrefix: '+688',
        phoneNumberFormat: '',
    },
    {
        label: 'Uganda',
        flagCode: 'UG',
        phonePrefix: '+256',
        phoneNumberFormat: '',
    },
    {
        label: 'Ukraine',
        flagCode: 'UA',
        phonePrefix: '+380',
        phoneNumberFormat: '',
    },
    {
        label: 'United Arab Emirates',
        flagCode: 'AE',
        phonePrefix: '+971',
        phoneNumberFormat: '',
    },
    {
        label: 'Uruguay',
        flagCode: 'UY',
        phonePrefix: '+598',
        phoneNumberFormat: '',
    },
    {
        label: 'Uzbekistan',
        flagCode: 'UZ',
        phonePrefix: '+998',
        phoneNumberFormat: '',
    },
    {
        label: 'Vanuatu',
        flagCode: 'VU',
        phonePrefix: '+678',
        phoneNumberFormat: '',
    },
    {
        label: 'Venezuela',
        flagCode: 'VE',
        phonePrefix: '+58',
        phoneNumberFormat: '',
    },
    {
        label: 'Vietnam',
        flagCode: 'VN',
        phonePrefix: '+84',
        phoneNumberFormat: '',
    },
    {
        label: 'Western Sahara',
        flagCode: 'EH',
        phonePrefix: '+212',
        phoneNumberFormat: '',
    },
    {
        label: 'Yemen',
        flagCode: 'YE',
        phonePrefix: '+967',
        phoneNumberFormat: '',
    },
    {
        label: 'Zambia',
        flagCode: 'ZM',
        phonePrefix: '+260',
        phoneNumberFormat: '',
    },
    {
        label: 'Zimbabwe',
        flagCode: 'ZW',
        phonePrefix: '+263',
        phoneNumberFormat: '',
    },
]);

const TOP_COUNTRIES_COUNT = _.takeWhile(COUNTRIES_INFO, 'top').length;

const getCountryFromPhonePrefix = value =>
    COUNTRIES_INFO.find(({ phonePrefix }) => phonePrefix === value)?.flagCode;

// these codes are shared across multiple countries
const FALLBACK_COUNTRIES = {
    '+1': 'US', // for example +1 is also used by Canada
    '+7': 'RU',
    '+61': 'AU',
};

const PHONE_PREFIXES = Object.freeze(
    _.uniq(COUNTRIES_INFO.map(({ phonePrefix }) => phonePrefix)),
);

const shouldRemoveTrunkPrefix = phonePrefix =>
    _.get(
        getCountryFromPhonePrefix(phonePrefix),
        'shouldRemoveTrunkPrefix',
        false,
    );

module.exports = {
    COUNTRIES_INFO,
    PHONE_PREFIXES,
    TOP_COUNTRIES_COUNT,
    FALLBACK_COUNTRIES,
    getCountryFromPhonePrefix,
    shouldRemoveTrunkPrefix,
};
