import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import Menu from '../Menu/Menu';
import MenuItem from '../MenuItem/MenuItem';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { slice } from '../../features/layersList/teamsListSlice';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ zIndex, spacing }) => ({
        root: {
            width: '100%',
            padding: '27px 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'transparent',
            transition: 'all 300ms ease-in-out',
            position: 'absolute',
            top: 0,
            left: 0,
            boxSizing: 'border-box',
            zIndex: zIndex.drawer,

            '@media (min-width: 1132px)': {
                padding: '27px 72px',
                width: '100%',
            },
            '@media (max-width: 600px)': {
                height: 50,
                padding: '3px 15px',
            },
        },

        languageOnlyHeader: {
            width: '100%',
            padding: '20px 15px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            background: 'transparent',
            transition: 'all 300ms ease-in-out',
            position: 'absolute',
            top: 0,
            left: 0,
            boxSizing: 'border-box',
            zIndex: zIndex.drawer,

            '@media (min-width: 1132px)': {
                padding: '20px 50px',
                width: '100%',
            },
            '@media (max-width: 600px)': {
                height: 40,
                padding: '3px 15px',
            },
        },

        logo: {
            position: 'relative',

            '@media (max-width: 400px)': {
                top: '-2px',
                maxWidth: 140,
            },
            '@media (min-width:401px) and (max-width: 600px)': {
                top: '-3px',
                maxWidth: 140,
            },
            '@media (min-width: 601px)': {
                top: '-6px',
            },
        },

        controls: {
            display: 'flex',
        },

        tools: {
            marginInlineStart: ({ onlyShowLanguageSelector }) =>
                onlyShowLanguageSelector ? null : spacing(4),
            display: 'flex',
            alignItems: 'center',
        },

        menu: {
            marginInlineStart: spacing(2),
            alignSelf: 'center',
        },

        loginInMenu: {
            'a&': {
                '@media (min-width: 601px)': {
                    display: 'none',
                },
            },
        },
    }),
    { name: 'page-header' },
);

function PageHeader(props) {
    const {
        className,
        lang,
        slugs,
        locales,
        currentPage,
        languages,
        onlyShowLanguageSelector,
    } = props;
    const selectedLayerItem = useSelector(slice.selectors.selectSelectedItem);
    const classes = useStyles({ onlyShowLanguageSelector });
    const handleChangeLang = slug => {
        if (slugs[slug]) {
            let pathName = `/${slugs[slug]}`;
            if (selectedLayerItem?.id) {
                pathName = `${pathName}/${selectedLayerItem.id}`;
            }
            document.location.pathname = pathName;
        }
    };

    if (onlyShowLanguageSelector) {
        return (
            <header className={classes.languageOnlyHeader}>
                <div className={classes.tools}>
                    <LanguageSelector
                        languages={languages}
                        value={locales[currentPage].pageName}
                        onChange={handleChangeLang}
                    />
                </div>
            </header>
        );
    }

    return (
        <header className={classNames(classes.root, className, 'page-header')}>
            <a
                href={`https://www.causematch.com/${
                    locales[currentPage].language &&
                    locales[currentPage].language == 'he'
                        ? locales[currentPage].language + '/'
                        : ''
                }?ref=cm2`}
                style={{ display: 'flex' }}
            >
                <HeaderLogo
                    headerLogoConfig={locales[currentPage]?.headerLogoConfig}
                    lang={lang}
                    className={classes.logo}
                />
            </a>
            <div className={classNames(classes.controls, 'header-controls')}>
                <HeaderButtons
                    lang={locales[currentPage].language}
                    scheduleLink={locales[currentPage]?.scheduleLink}
                />
                <div className={classes.tools}>
                    <LanguageSelector
                        languages={languages}
                        value={locales[currentPage].pageName}
                        onChange={handleChangeLang}
                    />
                </div>
                <Menu className={classes.menu}>
                    <MenuItem
                        href={`https://www.causematch.com/${
                            locales[currentPage].language &&
                            locales[currentPage].language == 'he'
                                ? locales[currentPage].language + '/'
                                : ''
                        }features-and-services/?ref=cm2`}
                    >
                        <FormattedMessage
                            id="PageHeader.about"
                            defaultMessage="About"
                        />
                    </MenuItem>
                    <MenuItem href="https://blog.causematch.com/?ref=cm2">
                        <FormattedMessage
                            id="PageHeader.blog"
                            defaultMessage="Blog"
                        />
                    </MenuItem>
                    <MenuItem href="/login" className={classes.loginInMenu}>
                        <FormattedMessage
                            id="PageHeader.logIn"
                            defaultMessage="Log in"
                        />
                    </MenuItem>
                </Menu>
            </div>
        </header>
    );
}

PageHeader.propTypes = {
    lang: PropTypes.string.isRequired,
    slugs: PropTypes.object.isRequired,
    currentPage: PropTypes.string.isRequired,
    locales: PropTypes.object.isRequired,
    className: PropTypes.string,
    languages: PropTypes.array,
    onlyShowLanguageSelector: PropTypes.bool,
};

export default PageHeader;
