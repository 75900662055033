import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { adjustColor } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette, typography }) => {
    const { main: primaryColor } = palette.primary;
    const { main: secondaryColor, light: secondaryColorLight } =
        palette.secondary;

    return {
        root: {
            width: '100%',
            height: ({ size }) => size || 8,
            background: palette.grey[500],
            borderRadius: 100,
            position: 'relative',
        },
        progressBar: {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            borderRadius: 100,

            'html[dir=rtl] &': {
                transform: 'rotate(180deg)',
                left: 'auto',
                right: 0,
            },
        },
        mainProgress: {
            width: ({ mainProgress }) => `${mainProgress}%`,
            background: `${primaryColor} linear-gradient(90deg, ${adjustColor(
                primaryColor,
                20,
            )} 0%, ${primaryColor} 100%)`,
        },
        bonusProgress: {
            width: ({ bonusProgress, mainProgress }) =>
                `calc(${bonusProgress - mainProgress}% + 10px)`,
            left: ({ mainProgress }) => `calc(${mainProgress}% - 10px)`,
            background: secondaryColor,
            'html[dir=rtl] &': {
                left: 'auto !important',
                right: ({ mainProgress }) => `calc(${mainProgress}% - 10px)`,
            },
        },
        label: {
            background: secondaryColorLight,
            color: palette.secondary.contrastText,
            borderRadius: 5,
            fontWeight: 500,
            fontFamily: typography.fontFamily,
            display: ({ showLabel }) => (showLabel ? 'block' : 'none'),
            position: 'absolute',
            paddingBottom: 3,
            left: ({ mainProgress }) => `${mainProgress}%`,
            bottom: 20,
            fontSize: ({ size }) => size + 16 / size,
            padding: 4,
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
            transform: 'translateX(-69%)',
            'html[dir=rtl] &': {
                transform: 'translateX(50%)',
                left: 'auto !important',
                right: ({ mainProgress }) => `${mainProgress}%`,
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                right: 'calc(50% - 4px)',
                top: '100%',
                border: '4px solid transparent',
                borderTopColor: secondaryColorLight,
            },
        },
    };
});

function Progress(props) {
    const {
        className,
        mainProgressClassName,
        percent = 0,
        label = `${Math.round(percent)}%`,
        bonusPercent,
        size = 8,
        showLabel = true,
    } = props;
    const classes = useStyles({
        mainProgress: Math.min(percent, 100),
        bonusProgress: Math.min(bonusPercent || 0, 100),
        size,
        showLabel,
    });

    return (
        <div className={classNames(classes.root, className)}>
            {showLabel && <div className={classes.label}>{label}</div>}
            {!isNaN(bonusPercent) && bonusPercent > percent && (
                <div
                    className={classNames(
                        classes.progressBar,
                        classes.bonusProgress,
                    )}
                ></div>
            )}
            <div
                className={classNames(
                    classes.progressBar,
                    classes.mainProgress,
                    mainProgressClassName,
                )}
            ></div>
        </div>
    );
}

Progress.propTypes = {
    percent: PropTypes.number,
    bonusPercent: PropTypes.number,
    size: PropTypes.number,
    className: PropTypes.string,
    mainProgressClassName: PropTypes.string,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
};

export default Progress;
