const UTM_TRACKING_PARAMS = Object.freeze({
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmId: 'utm_id',
});

const UTM_TRACKING_PARAM_VALUES = Object.freeze(
    Object.values(UTM_TRACKING_PARAMS),
);
const UTM_TRACKING_PARAM_KEYS = Object.freeze(Object.keys(UTM_TRACKING_PARAMS));

const DONATION_CONVERSION_PARAMS = Object.freeze([
    'status',
    'donation',
    'amount',
    'currency',
    'period',
    'donorPflSlug',
    'donorName',
]);

module.exports = {
    UTM_TRACKING_PARAM_VALUES,
    UTM_TRACKING_PARAM_KEYS,
    DONATION_CONVERSION_PARAMS,
};
