const PasswordStrengthErrors = Object.freeze({
    MINIMAL_LENGTH: 'minimal_length',
    NO_NUMBERS: 'no_numbers',
    NO_LOWER_CASE_LETTERS: 'no_lower_case_letters',
    NO_UPPER_CASE_LETTERS: 'no_upper_case_letters',
    NO_SPECIAL_CHARACTERS: 'no_special_characters',
});

module.exports = {
    PasswordStrengthErrors,
};
