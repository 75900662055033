import Repository from './Repository';
import { create } from 'client/helpers/axios';

const repository = {
    axios: create({ baseUrl: '/account' }),
    async fetchAccounts(queryArg) {
        const query = { ...queryArg };
        const queryString = Repository.prepareQueryString(query);
        const res = await this.axios.get(`?${queryString}`);
        return res.data;
    },

    async fetchAccount(accountId) {
        const res = await this.axios.get(`/${accountId}`);
        return res.data;
    },

    async fetchAccountOptions(campaignId) {
        const res = await this.axios.get(`/options/${campaignId}`);
        return res.data;
    },

    async updateAccount(accountId, commands) {
        const res = await this.axios.put(`/${accountId}`, { commands });
        return res.data;
    },

    async createAccount(commands) {
        const res = await this.axios.post('/', { commands });
        return res.data;
    },

    async validateExternalId(externalId, accountId) {
        const queryString = Repository.prepareQueryString({
            externalId,
            accountId,
        });
        const res = await this.axios.get(
            `/validate-external-id?${queryString}`,
        );
        return res.data;
    },
};

export default repository;
