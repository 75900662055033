import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import Toggle from 'client/components/CampaignPage/components/Toggle/Toggle';
import { selectGiftAid, selectTaxGift } from './checkoutSlice';
import { paymentMethodSupportsGiftAid } from 'common/helpers/paymentMethods';

function GiftFields({ currency, paymentMethod }) {
    const { register } = useFormContext();
    const { enableGiftAid } = useSelector(selectGiftAid);
    const { enableTaxGift: isTaxGiftSupported } = useSelector(selectTaxGift);

    const isGiftAidSupported =
        enableGiftAid &&
        currency === 'GBP' &&
        paymentMethodSupportsGiftAid(paymentMethod);

    return (
        <>
            {isGiftAidSupported && (
                <FormGroup>
                    <Toggle name="giftAid" inputRef={register}>
                        <FormattedMessage id="Checkout.placeholder.giftAid" />
                    </Toggle>
                </FormGroup>
            )}
            {isTaxGiftSupported && (
                <FormGroup>
                    <Toggle name="taxGift" inputRef={register}>
                        <FormattedMessage id="Checkout.placeholder.taxGift" />
                    </Toggle>
                </FormGroup>
            )}
        </>
    );
}

GiftFields.propTypes = {
    currency: PropTypes.string,
    paymentMethod: PropTypes.string,
};

export default GiftFields;
