import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { open } from '../checkout/checkoutPopupSlice';
import { updateDonationCurrency } from '../checkout/checkoutSlice';
import {
    selectPayments,
    selectCurrency,
    selectPageSpecificCampaignData,
    selectHeroSlides,
} from '../campaign/campaignSlice';
import { slice } from './teamsListSlice';
import { slice as donorSlice } from '../donorsList/teamDonorsListSlice';
import {
    selectSelectedTab,
    setSelectedTab,
    selectTabs,
} from '../recentDonationPanel/recentDonationPanelSlice';
import { useHistory } from 'react-router-dom';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';

function LayerItemCard(props) {
    const { item, listRef, component: Component, currency } = props;
    const { goal, bonusGoal, bonusGoalEnabled } = item;
    const amountRaised = Number(
        item.statistics
            ? item.statistics.donationsAmount
            : item.donationsAmount,
    );
    const dispatch = useDispatch();
    const selectedTab = useSelector(selectSelectedTab);
    const tabs = useSelector(selectTabs);
    const currencies = useSelector(selectPayments).currencies;
    const goalCurrency = useSelector(selectCurrency);
    const pageSpecificCampaignData = useSelector(
        selectPageSpecificCampaignData,
    );
    const logo = item.logo || pageSpecificCampaignData.logo;
    const heroSlides = useSelector(selectHeroSlides);

    const handleView = useCallback(() => {
        dispatch(slice.actions.updateScrollTop(listRef.current.scrollTop));
        dispatch(slice.actions.selectChildLayer(null));
        if (selectedTab.id !== item.layer.id) {
            const newTab = tabs.find(tab => tab.id === item.layer.id);
            if (newTab) {
                dispatch(setSelectedTab(newTab));
            }
        }
        dispatch(slice.actions.selectItem(item));
        dispatch(donorSlice.actions.reset());
    }, []);
    const history = useHistory();

    const handleDonate = useCallback(() => {
        currencies.includes(currency)
            ? dispatch(updateDonationCurrency(currency))
            : dispatch(updateDonationCurrency(goalCurrency));
        dispatch(open({ layerItem: item }));
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        const virtualLocation = `${history.location.pathname.replace(
            /\/+$/,
            '',
        )}/donate/${queryString}`;
        triggerGtmEvent(GTM_EVENTS.CLICK_DONATE, {
            eventCategory: 'layercard-donate-button',
        });
        triggerGtmEvent(GTM_EVENTS.OPEN_CHECKOUT, {
            eventCategory: 'virtual-pageview',
            virtualLocation: virtualLocation,
        });
    }, []);

    return (
        <Component
            id={item.id}
            avatarSrc={logo}
            name={item.name}
            description={item.description}
            donorsCount={Number(
                item.statistics
                    ? item.statistics.donorsCount
                    : item.donorsCount,
            )}
            amount={amountRaised}
            goalAmount={Math.round(Number(goal))}
            bonusGoalAmount={Math.round(Number(bonusGoal))}
            bonusGoalEnabled={bonusGoalEnabled}
            hasEnteredBonusGoal={item?.statistics?.hasEnteredBonusGoal}
            listRef={listRef}
            onView={handleView}
            allowDonations={item.layer.allowDonations}
            isCustomTileEnabled={item.layer.isCustomTileEnabled}
            tile={item.creatives?.tile || heroSlides.images[0] || null}
            onDonate={handleDonate}
            currency={currency}
        />
    );
}

LayerItemCard.propTypes = {
    component: PropTypes.func.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number,
        logo: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        donorsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        donationsAmount: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        goal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        layer: PropTypes.object,
        creatives: PropTypes.object,
        statistics: PropTypes.object,
    }),
    listRef: PropTypes.object,
    currency: PropTypes.string,
};

export default LayerItemCard;
