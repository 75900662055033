import React from 'react';
import PropTypes from 'prop-types';

function SvgDonorIcon({ fill = '#fff', ...props }) {
    const styles = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 1.5,
        color: fill,
        stroke: 'currentColor',
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            <g transform="translate(0.75 0.75)" fill="none" style={styles}>
                <path
                    d="M245.124,1613.144a9.927,9.927,0,0,1,9.927-9.927,10.257,10.257,0,0,1,5.773,1.85,9.7,9.7,0,0,1,4.155,8.077Z"
                    transform="translate(-245.124 -1590.235)"
                />
                <path
                    d="M259.759,1601.11a6.109,6.109,0,1,1-6.11-6.11A6.11,6.11,0,0,1,259.759,1601.11Z"
                    transform="translate(-243.722 -1595)"
                />
            </g>
        </svg>
    );
}

SvgDonorIcon.propTypes = {
    fill: PropTypes.string,
};

export default SvgDonorIcon;
