/* Encode unsigned-integer to minimal-length url-safe string
 *
 * A proprietary encoding scheme for unsigned integers, designed to be
 * compact and sensible.  The base 32 alphabet uses only 8 low-frequency
 * letters (upper- and lower-case) so profane words should not occur in the
 * output.
 *
 * Base64 encoding uses standard character set, but is not compatible with
 * standard base64 encoding.  This encoding schema encodes groups of bits
 * starting from the lowest bit (rightmost) and moving up (leftwards) until
 * bits are exhausted.
 *
 * Standard Base64 encoding first pads the input on the right and then
 * encodes from left to right.  So the encodings will be the same only if
 * the input length is a multiple of 3 bytes.
 *
 * For example, let’s encode the number 18,788,512
 *
 * big-endian hex: 01 1e b0 a0
 *
 * bin octets: 00000001 00011110 10110000 10100000
 *
 * bin sextets: 000001 000111 101011 000010 100000
 *
 * sexted vals:      1      7     43      2     32
 *
 * Our encoding      B      H      r      C      g
 *
 *
 * But standard base64 would first pad on the right to 6 bytes, then take
 * sextets starting from the left
 *
 * hex: 01 1e b0 a0 00 00
 *
 * bin octets: 00000001 00011110 10110000 10100000 00000000 00000000
 *
 * bin sextets: 000000 010001 111010 110000 101000 000000 000000 000000
 *
 * sexted vals:      0     17     58     48     40
 *
 * encoding          A      R      6      w      o = =
 */

const BASE_16_CHARS = '0123456789ABCDEF'.split('');

/* alphabet for proprietary base32 encoding
 *  0  1  2  3  4  5  6  7  8  9 10 11 12 13 14 15
 *  0  1  2  3  4  5  6  7  8  9  B  b  D  d  L  l
 *
 * 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31
 *  Q  q  V  v  W  w  X  x  Z  z  _  -  .  ~  :  =
 *
 */
const BASE_32_CHARS = '0123456789BbDdGgLlQqVvWwZz_-.~:='.split('');

// standard url-safe base64 alphabet

const BASE_64_CHARS = (
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
    'abcdefghijklmnopqrstuvwxyz' +
    '0123456789-_'
).split('');

function charsToVals(chars) {
    return chars.reduce((agg, c, i) => {
        agg[c] = i;
        return agg;
    }, {});
}

function getMask(bits) {
    var mask = 0;
    for (let i = 0; i < bits; i++) {
        mask = (mask << 1) | 1;
    }
    return mask;
}

function makeEncodeUInt(chars) {
    let bits = Math.log2(chars.length);
    let mask = getMask(bits);
    return val => {
        let groups = [];
        while (val > 0) {
            groups.unshift(val & mask);
            val = val >> bits;
        }
        if (groups.length === 0) {
            groups.unshift(0);
        }
        return groups.map(g => chars[g]).join('');
    };
}

function makeDecodeUInt(chars) {
    let vals = charsToVals(chars);
    let bits = Math.log2(chars.length);
    return str => str.split('').reduce((agg, g) => (agg << bits) | vals[g], 0);
}

module.exports = {
    b16encodeUInt: makeEncodeUInt(BASE_16_CHARS),
    b16decodeUInt: makeDecodeUInt(BASE_16_CHARS),
    b32encodeUInt: makeEncodeUInt(BASE_32_CHARS),
    b32decodeUInt: makeDecodeUInt(BASE_32_CHARS),
    b64encodeUInt: makeEncodeUInt(BASE_64_CHARS),
    b64decodeUInt: makeDecodeUInt(BASE_64_CHARS),
};
