import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        flex: 1,
    },
    input: {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        width: 0,
        height: 0,
        pointerEvents: 'none',
    },
    button: {
        textAlign: 'center',
        padding: '6px 25px',
        borderRadius: 999,
        color: palette.primary.main,
        fontSize: '1rem',
        fontFamily: typography.fontFamily,
        fontWeight: 700,
        transition: 'all .3s',
        whiteSpace: 'nowrap',
        '&:hover, &:focus': {
            background: palette.grey[200],
        },
        'input:checked + &': {
            background: palette.primary.main,
            color: palette.common.white,
        },

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(15),
            padding: 5,

            'input:checked + &': {
                padding: '5px 25px',
            },
        },
    },
}));

function RadioGroupTab(props, ref) {
    const { children, className, buttonClassName, ...rest } = props;
    const classes = useStyles();

    return (
        <label className={classNames(classes.root, className)}>
            <input type="radio" className={classes.input} {...rest} ref={ref} />
            <div className={classNames(classes.button, buttonClassName)}>
                {children}
            </div>
        </label>
    );
}

RadioGroupTab.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    buttonClassName: PropTypes.object,
};

export default React.forwardRef(RadioGroupTab);
