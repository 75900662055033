import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectRedirectAfterDonation } from '../../features/campaign/campaignSlice';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        background: palette.background.default,
        position: 'relative',
    },

    close: {
        position: 'absolute',
        right: 45,
        top: 45,
        background: 'none',
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: palette.grey[600],
        transition: 'color .3s',
        cursor: 'pointer',
        fontFamily: typography.fontFamily,
        fontWeight: 300,
        '& svg': {
            display: 'block',
            marginBottom: 5,
        },
        '&:hover, &:focus': {
            color: palette.common.black,
        },
        'html[dir=rtl] &': {
            right: 'auto',
            left: 45,
        },
        '@media (max-width: 600px)': {
            top: 20,
            right: 20,
            fontSize: 10,
            '& svg': {
                width: 18,
                height: 18,
            },
            'html[dir=rtl] &': {
                right: 'auto',
                left: 20,
            },
        },
    },

    main: {
        maxWidth: 612,
        margin: '0 auto',
        padding: '115px 15px',

        '@media (max-width: 600px)': {
            padding: '80px 20px 30px',
        },
    },

    donated: {
        '& svg': {
            width: 20,
            height: 20,
        },
    },
}));

function CheckoutPopup(props) {
    const { children, isOpen, className, onClose, isDonated } = props;
    const classes = useStyles();

    const { isRedirectAfterDonationEnabled } = useSelector(
        selectRedirectAfterDonation,
    );

    if (!isOpen) {
        return null;
    }

    return (
        <div
            className={classNames(classes.root, className, {
                [classes.donated]: isDonated,
            })}
        >
            {(!isDonated || !isRedirectAfterDonationEnabled) && (
                <button className={classes.close} onClick={onClose}>
                    <CloseIcon />
                    <FormattedMessage id="CheckoutPopup.close" />
                </button>
            )}
            <div className={classes.main}>{children}</div>
        </div>
    );
}

CheckoutPopup.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isDonated: PropTypes.bool,
};

export default CheckoutPopup;
