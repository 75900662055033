import { create } from 'client/helpers/axios';
import { stringify } from 'querystring';
import isNotEmpty from 'common/helpers/isNotEmpty';
import pickBy from 'lodash/pickBy';
import { arrayToQuery } from '../../../common/helpers';
import parseErrorMessage from 'client/helpers/parseErrorMessage';

export default class Repository {
    constructor({ baseUrl }) {
        this.axios = create({ baseUrl });
    }

    static prepareQueryString(queryJson) {
        return stringify(pickBy(queryJson, isNotEmpty));
    }

    handleRequestData(response) {
        return { ...response.data, goal: Math.round(response.data.goal) };
    }

    async findById(id) {
        return this.axios.get(`/${id}`).then(this.handleRequestData);
    }

    async find(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios.get(queryString ? `?${queryString}` : '');
    }

    async search(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios.get(`/search${queryString ? `?${queryString}` : ''}`);
    }

    async create(dataJson) {
        return this.axios.post('', dataJson);
    }

    async update(id, jsonData) {
        return this.axios.put(`/${id}`, jsonData);
    }

    async partialUpdate(id, jsonData) {
        return this.axios.patch(`/${id}`, jsonData);
    }

    async delete(id) {
        if (id instanceof Array) {
            return this.axios.delete(`?${arrayToQuery(id)}`);
        }
        return this.axios.delete(`/${id}`);
    }

    async handleResponse(res) {
        return res.data;
    }

    async handleError(err) {
        throw new Error(parseErrorMessage(err));
    }
}
