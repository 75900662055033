import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../Link/Link';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        listStyle: 'none',
        margin: 0,
        padding: 0,

        '& a': {
            color: palette.text.primary,
            fontSize: '1rem',
            display: 'block',
            padding: '10px 15px',
            textDecoration: 'none',
            transition: 'all 0.3s ease',
        },
    },
}));

function MenuItem(props) {
    const { children, ...rest } = props;
    const classes = useStyles();

    return (
        <li className={classes.root}>
            <Link {...rest}>{children}</Link>
        </li>
    );
}

MenuItem.propTypes = {
    children: PropTypes.node,
};

export default MenuItem;
