import { create, handleError, handleResponse } from 'client/helpers/axios';
import Repository from './Repository';
import { FEATURES } from 'common/constants';
import axios from 'axios';
import { get } from 'lodash';

const repository = {
    axios: create({ baseUrl: '/account' }),

    async createAccountGatewayConfig(accountId, data) {
        const res = await this.axios.post(`/${accountId}/gateway-config`, data);
        return res.data;
    },

    async getAccountGatewayConfig(accountId, accountGatewayConfigId) {
        const res = await this.axios.get(
            `/${accountId}/gateway-config/${accountGatewayConfigId}`,
        );
        return res.data;
    },

    async validateAccountGatewayConfigName(
        accountId,
        gatewayConfigName,
        accountGatewayConfigId,
    ) {
        const queryString = Repository.prepareQueryString({
            accountGatewayConfigId,
        });
        const res = await this.axios.get(
            `/${accountId}/gateway-config/validate/${gatewayConfigName}?${queryString}`,
        );
        return res.data;
    },

    async getAllAccountGatewayConfigs(accountId, filters) {
        const queryString = Repository.prepareQueryString(filters);
        const res = await this.axios.get(
            `/${accountId}/gateway-config/?${queryString}`,
        );
        return res.data;
    },

    async updateAccountGatewayConfig(accountId, accountGatewayConfigId, data) {
        const res = await this.axios.put(
            `/${accountId}/gateway-config/${accountGatewayConfigId}`,
            data,
        );
        return res.data;
    },

    async deleteAccountGatewayConfig(accountId, accountGatewayConfigId) {
        const res = await this.axios.delete(
            `/${accountId}/gateway-config/${accountGatewayConfigId}`,
        );
        return res.data;
    },

    async initiateStripeConnect(accountId) {
        const res = await this.axios.post(
            `/gateway-config/stripe/connect/initiate/${accountId}`,
        );
        return res.data;
    },

    async resumeStripeConnect(
        financialIdentifier,
        stripeAccountId,
        redirectToUrl = false,
    ) {
        const res = await this.axios.get(
            `/gateway-config/stripe/connect/refresh/${financialIdentifier}/${stripeAccountId}/${redirectToUrl}`,
        );
        return res.data;
    },

    async initiateChariotConnect(accountId, connectData) {
        return this.axios
            .post(`/${accountId}/gateway-config/chariot/connect`, connectData)
            .then(handleResponse)
            .catch(handleError);
    },

    async getIsFeatureEnabled() {
        const res = await axios.get(`/features`);
        const accountFeature = res.data.find(
            feature => feature.name === FEATURES.ACCOUNT_GATEWAY_CONFIG,
        );
        return get(accountFeature, 'isEnabled', false);
    },

    async getAccountCurrencies(accountId) {
        const res = await this.axios.get(
            `/${accountId}/gateway-config/currencies`,
        );
        return res.data;
    },
};

export default repository;
