import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PageSection from '../PageSection/PageSection';
import Text from '../Text/Text';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 30,
        marginBottom: ({ bottomSpacing }) => (bottomSpacing ? 50 : 'auto'),
        wordBreak: 'break-word',

        '& *': {
            lineHeight: '1.35',
        },
        '& p': {
            paddingBottom: 8,
        },

        '@media (max-width: 600px)': {
            marginTop: 20,
            marginBottom: () => 20,

            '& *': {
                lineHeight: '1.25',
            },
            '& p': {
                fontSize: '13px',
                fontWeight: '400',
                paddingBottom: 4,
            },
        },
        '& img': {
            display: 'block',
            margin: '10px auto',
            height: 'auto',
            maxWidth: '100%',
        },
    },

    centerText: {
        textAlign: 'center',
    },
}));

function PageCenterText(props) {
    const {
        children,
        html = false,
        alignment = 'center',
        bottomSpacing = false,
        className,
    } = props;
    const classes = useStyles({ bottomSpacing });

    if (children === null || children === undefined || !children.length) {
        return null;
    }

    return (
        <PageSection
            className={classNames(
                classes.root,
                alignment === 'center' ? classes.centerText : '',
                className,
            )}
        >
            <Text html={html}>{children}</Text>
        </PageSection>
    );
}

PageCenterText.propTypes = {
    children: PropTypes.node,
    html: PropTypes.bool,
    alignment: PropTypes.string,
    bottomSpacing: PropTypes.bool,
    className: PropTypes.string,
};

export default PageCenterText;
