const { get } = require('lodash');
const { gatewayFlow } = require('./gateways');
const { voucherFlow } = require('./vouchers');
const { paymentMethodType } = require('./paymentMethods');
const { getDonationMonths } = require('./index');
const initDonationAmount = require('./createDonationAmount');
const {
    CURRENCY_INFO,
    DONATION_PAYMENT_MODE,
    DONATION_STATUS,
    PAYMENT_TRANSACTION_STATUSES,
} = require('../constants');

const donationFlow = (paymentGateway, paymentMethod) => {
    return paymentMethodType(paymentMethod) === 'gateway'
        ? gatewayFlow(paymentGateway, paymentMethod)
        : voucherFlow(paymentMethod);
};

const isRegularPaymentMode = paymentMode =>
    Number(paymentMode) === DONATION_PAYMENT_MODE['regular'];

const isLimitedRecurringPaymentmode = paymentMode =>
    Number(paymentMode) === DONATION_PAYMENT_MODE['monthly'];

const isInstallmentsPaymentMode = paymentMode =>
    Number(paymentMode) === DONATION_PAYMENT_MODE['installments'];

const isUnlimitedRecurringPaymentMode = paymentMode =>
    Number(paymentMode) === DONATION_PAYMENT_MODE['unlimitedRecurring'];

const isStripeOrBanquestInstallment = donation => {
    return (
        ['stripe', 'banquest'].includes(donation.paymentGateway) &&
        isInstallmentsPaymentMode(donation.paymentMode)
    );
};

const getPaymentModeType = paymentMode =>
    Object.keys(DONATION_PAYMENT_MODE).filter(
        type => DONATION_PAYMENT_MODE[type] === paymentMode,
    )[0];

const getTransactionStatusFromDonation = ({ status }) => {
    switch (status) {
        case DONATION_STATUS.PENDING:
            return PAYMENT_TRANSACTION_STATUSES.PENDING;
        case DONATION_STATUS.SUCCESS:
            return PAYMENT_TRANSACTION_STATUSES.SUCCESS;
        default:
            return PAYMENT_TRANSACTION_STATUSES.FAILURE;
    }
};

const getDonationName = ({ receiptName, name, firstName, lastName }) => {
    if (receiptName) {
        return receiptName;
    }

    if (name) {
        return name;
    }

    return [firstName, lastName].filter(Boolean).join(' ').trim();
};

const createDonationAmount = (donation, layerItemsCount) => {
    return initDonationAmount({
        amount: donation.amount,
        months: getDonationMonths(donation),
        countLayerItems: layerItemsCount,
        tip: donation.tip,
        fee: donation.fee,
        isLimitedRecurring: isLimitedRecurringPaymentmode(donation.paymentMode),
        isInstallments: isInstallmentsPaymentMode(donation.paymentMode),
        paymentGateway: donation.paymentGateway,
    });
};

const createDonationApproveMeta = (donations, index, extra = {}) => {
    const donation = donations[0] || null;

    if (!donation) {
        throw new Error(
            'Donation approve meta creation failed - no donations given',
        );
    }

    const donationAmount = createDonationAmount(donation, donations.length);

    return {
        donations: donations.map(donation => {
            donation.donationAmount = createDonationAmount(
                donation,
                donations.length,
            );
            return donation;
        }),
        donationsCount: donations.length,
        donationAmount,
        isBunchHandled: donations.length - 1 === Number(index),
        ...extra,
    };
};

const isDonorEvent = meta => {
    const isBunch = get(meta, 'donationsCount', 0) > 0;
    return isBunch ? get(meta, 'isBunchHandled', false) : true;
};

const minDonationAmount = currency =>
    get(CURRENCY_INFO, `${currency}.minAmount`, 5);

const maxDonationAmount = currency =>
    get(CURRENCY_INFO, `${currency}.maxAmount`, 10000000);

module.exports = {
    donationFlow,
    isRegularPaymentMode,
    isLimitedRecurringPaymentmode,
    isInstallmentsPaymentMode,
    isUnlimitedRecurringPaymentMode,
    isStripeOrBanquestInstallment,
    getPaymentModeType,
    getTransactionStatusFromDonation,
    getDonationName,
    createDonationAmount,
    createDonationApproveMeta,
    minDonationAmount,
    maxDonationAmount,
    isDonorEvent,
};
