import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import scrollTo from 'client/helpers/scrollTo';

const useStyles = makeStyles({
    root: {
        margin: '30px 0 30px',
        display: 'flex',
        padding: '1em',
        color: 'white',
        borderRadius: '5px',
        background: '#f94d4d',

        // '@media (max-width: 600px)': {
        //     margin: '20px 0 40px',
        // },
    },
});

function PaymentError(props) {
    const { error } = props;
    const classes = useStyles();
    const errorRef = useRef();

    useEffect(() => {
        if (!error) {
            return;
        }

        setTimeout(() => errorRef?.current && scrollTo(errorRef.current), 500);
    }, [error]);

    return error ? (
        <div ref={errorRef} className={classes.root}>
            <span>{error}</span>
        </div>
    ) : null;
}

PaymentError.propTypes = {
    error: PropTypes.string,
};

export default PaymentError;
