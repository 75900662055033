import Repository from './Repository';
const repository = new Repository({ baseUrl: '/abandoned' });

repository.find = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);

    return this.axios.get(queryString ? `?${queryString}` : '');
};

repository.findRescued = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);

    return this.axios.get(
        queryString ? `/rescued/?${queryString}` : '/rescued',
    );
};

export default repository;
