import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { currencyLabel } from 'common/helpers';
import FormSelect from 'client/components/CampaignPage/components/FormSelect/FormSelect';
import FormInput from 'client/components/CampaignPage/components/FormInput/FormInput';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import AdornedInput from 'client/components/CampaignPage/components/AdornedInput/AdornedInput';
import usePreventDecimal from 'client/components/CampaignPage/hooks/preventDecimal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
    group: {
        margin: 0,
    },
    currencyAmountSelect: {
        borderRadius: '5px 0 0 5px',
        borderRight: 0,
        borderColor: palette.primary.main,
        flexShrink: 0,

        '@media (min-width: 600px)': {
            'html[lang=de] &': {
                width: 85,
            },
        },
        '& select': {
            paddingLeft: 15,
            color: palette.primary.main,

            '@media (min-width: 600px)': {
                'html[lang=de] &': {
                    padding: '0px 15px',
                },
            },

            'html[dir=rtl] &': {
                direction: 'rtl',
                padding: '0 20px 0 35px',
                paddingInlineEnd: '35px',
                paddingInlineStart: '15px',
            },
        },
        '&::after': {
            borderColor: palette.primary.main,
            right: '13px !important',
            left: 'auto !important',
            transform: 'translateY(calc(-50% - 1px)) rotate(135deg) !important',
            'html[dir=rtl] &': {
                left: '13px !important',
                right: 'auto !important',
            },
        },
        '@media (max-width: 600px)': {
            '& select': {
                fontSize: '0.938rem',
                height: 38,
            },
        },
        'html[dir=rtl] &': {
            borderRadius: '0px 5px 5px 0px',
            borderRight: `1px solid ${palette.primary.main}`,
            borderLeft: 0,
            direction: 'ltr',
        },
    },
    currencyAmountInput: {
        borderRadius: '0 5px 5px 0',
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            fontWeight: 500,
            fontSize: '1.25rem',
            padding: '12px 20px',
            height: 54,
            textAlign: 'right',

            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },

            '&[type=number]': {
                '-moz-appearance': 'textfield',
            },

            '@media (min-width: 600px)': {
                'html[lang=de] &': {
                    padding: '12px 0px',
                },
            },
            '@media (max-width: 600px)': {
                padding: '5px 15px',
                fontSize: '1.125rem',
                height: 38,
            },
        },
        'html[dir=rtl] &': {
            borderRadius: '5px 0px 0px 5px',
            '& input': {
                direction: 'rtl',
            },
        },
    },
    currencyAmountInputSuffix: {
        color: palette.primary.main,
        fontWeight: 500,
        fontSize: '1.25rem',
        padding: '12px 20px',

        '@media (min-width: 600px)': {
            'html[lang=de] &': {
                padding: '12px 7px 12px 0px',
            },
        },

        '@media (max-width: 600px)': {
            padding: '5px 15px',
            fontSize: '1.125rem',
        },
    },
    error: {
        borderColor: palette.error.main,
        '& input': {
            color: palette.error.main,
        },
    },
}));

function AmountInput(props) {
    const {
        defaultAmount,
        onAmountBlur,
        onAmountChange,
        onAmountDecimal,
        onCurrencyChange,
        error,
        className,
        label,
        currency = 'USD',
        currencies = [],
        disabled,
    } = props;
    const classes = useStyles();
    const [amount, setAmount] = useState(
        defaultAmount === 0 ? '' : defaultAmount,
    );
    const [handleKeyDown] = usePreventDecimal(onAmountDecimal);

    const handleCurrencyChange = event => {
        if (typeof onCurrencyChange === 'function') {
            return onCurrencyChange(event.target.value);
        }
    };

    const handleAmountBlur = event => {
        if (typeof onAmountBlur === 'function') {
            onAmountBlur(event.target.value);
        }
    };

    const handleAmountChange = event => {
        const inputAmount = Math.abs(
            Number(event.target.value.replace(',', '.').replace(/\./g, '')),
        );

        if (!isNaN(inputAmount)) {
            const amount = inputAmount === 0 ? 0 : inputAmount;

            setAmount(amount);

            if (typeof onAmountChange === 'function') {
                onAmountChange(amount);
            }
        }
    };

    return (
        <FormGroup
            inline
            gap={0}
            className={className || classes.group}
            label={label}
        >
            <FormSelect
                options={currencies.map(c => ({
                    name: currencyLabel(c.name),
                    value: c.value,
                }))}
                width={100}
                className={classes.currencyAmountSelect}
                onChange={handleCurrencyChange}
                value={currency}
                disabled={disabled || currencies.length <= 1}
            />
            <FormInput
                type="text"
                inputMode="numeric"
                name="amount"
                pattern="[0-9]+$"
                required
                autoFocus
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
                onKeyDown={handleKeyDown}
                value={amount || ''}
                className={clsx(classes.currencyAmountInput, {
                    [classes.error]: error,
                })}
                error={error}
                disabled={disabled}
                component={AdornedInput}
                suffix=".00"
                suffixClassName={classes.currencyAmountInputSuffix}
                ignoreDir={true}
            />
        </FormGroup>
    );
}

AmountInput.propTypes = {
    onCurrencyChange: PropTypes.func.isRequired,
    onAmountChange: PropTypes.func.isRequired,
    onAmountBlur: PropTypes.func,
    onAmountDecimal: PropTypes.func,
    defaultAmount: PropTypes.number,
    currency: PropTypes.string,
    error: PropTypes.object,
    currencies: PropTypes.array,
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default AmountInput;
