import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function MinusOutlineIcon(props) {
    const { palette } = useTheme();

    return (
        <svg focusable="false" viewBox="0 0 24 24" {...props}>
            <path
                d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm5 9H7v-2h10v2z"
                fill={palette.background.paper}
            ></path>
            <path d="M7 11h10v2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </svg>
    );
}

export default MinusOutlineIcon;
