// @ts-check

const fp = require('lodash/fp');

const HEX_COLOR_REGEX = /^#(?:[0-9a-fA-F]{3,4}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;

const RGB_COLOR_REGEX =
    /^(?:[rR][gG][bB]\((\d+),\s*(\d+),\s*(\d+)\)|[rR][gG][bB][aA]\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(?:\.\d+)?)\))$/;

/**
 * @param {any} [colorString='']
 * @returns {boolean}
 */
function isHexColor(colorString = '') {
    return fp.isString(colorString) && HEX_COLOR_REGEX.test(colorString);
}

/**
 * @param {any} [colorString='']
 * @returns {boolean}
 */
function isRgbColor(colorString = '') {
    return fp.isString(colorString) && RGB_COLOR_REGEX.test(colorString);
}

/**
 * @param {any} colorString
 * @returns {boolean}
 */
function isValidColor(colorString = '') {
    if (!fp.isString(colorString)) {
        return false;
    }

    return (
        HEX_COLOR_REGEX.test(colorString) || RGB_COLOR_REGEX.test(colorString)
    );
}

/**
 * @param {any} val
 * @returns {boolean}
 */
function isValidColorOrNull(val) {
    return val === null || isValidColor(val);
}

module.exports = {
    isHexColor,
    isRgbColor,
    isValidColor,
    isValidColorOrNull,
};
