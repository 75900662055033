import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import getLayerLabel from 'client/helpers/campaign/getLayerLabel';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button/Button';
import ItemCardPopup from '../ItemCardPopup/ItemCardPopup';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing }) => ({
    donateBtn: {
        minWidth: 160,
        fontSize: 20,
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        padding: '5px 13px',
        margin: '5px 7px',
        transition: 'all .4s linear',
        '&:hover': {
            textShadow: `0 0 8px ${palette.grey[300]}`,
        },
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '@media (max-width: 600px)': {
            flexWrap: 'nowrap',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    viewBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing(1),
        '@media (max-width: 600px)': {
            justifyContent: 'space-around',
            gap: spacing(0.5),
            flexWrap: 'wrap',
            margin: `${spacing(2)}px 0`,
        },
    },
    viewBtn: {
        border: `2px solid ${palette.primary.main}`,
        display: 'flex',
        justifyContent: 'center',
        fontSize: 18,
        '&:hover, &.selected': {
            borderColor: palette.primary.main,
            backgroundColor: `${palette.primary.main} !important`,
            color: palette.common.white,
        },

        '@media (max-width: 600px)': {
            fontSize: 14,
            padding: '3px 5px',
            flex: '1 1 auto',
        },
    },
}));

function TeamCardPopup(props) {
    const {
        allowDonations,
        onDonate,
        childLayers,
        donorsClick,
        childLayerButtonClick,
        selectedChildLayer,
        ...rest
    } = props;
    const classes = useStyles();
    const { locale: lang } = useIntl();
    const { palette } = useTheme();

    return (
        <ItemCardPopup {...rest} childLayers={childLayers}>
            <div className={classes.actions}>
                {childLayers.length ? (
                    <div className={classes.viewBtns}>
                        <Button
                            className={classNames(
                                classes.viewBtn,
                                !selectedChildLayer ? 'selected' : null,
                            )}
                            color={palette.primary.main}
                            onClick={donorsClick}
                            outline
                        >
                            <FormattedMessage id="Amount.donors" />
                        </Button>
                        {childLayers.map(layer => (
                            <Button
                                className={classNames(
                                    classes.viewBtn,
                                    selectedChildLayer === layer.id
                                        ? 'selected'
                                        : null,
                                )}
                                color={palette.primary.main}
                                key={layer.id}
                                onClick={() => childLayerButtonClick(layer.id)}
                                outline
                            >
                                {getLayerLabel(layer, lang)}
                            </Button>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
                {allowDonations && (
                    <Button onClick={onDonate} className={classes.donateBtn}>
                        <FormattedMessage id="DonateButton.text" />
                    </Button>
                )}
            </div>
        </ItemCardPopup>
    );
}

TeamCardPopup.propTypes = {
    allowDonations: PropTypes.bool,
    onDonate: PropTypes.func,
    childLayers: PropTypes.arrayOf(PropTypes.object),
    donorsClick: PropTypes.func,
    selectedChildLayer: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    childLayerButtonClick: PropTypes.func,
};

export default TeamCardPopup;
