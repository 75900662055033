import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import DonateCard from '../../components/DonateCard/DonateCard';
import { useIntl } from 'react-intl';
import filterQueryParams from 'client/helpers/filterQueryParams';

function DonorItem(props) {
    const {
        donor,
        isMatchMyGiftEnabled,
        onMatchMyGiftClicked,
        isDonorPflEnabled,
        isFirstInGroup,
    } = props;
    const { formatMessage } = useIntl();
    const name = donor.isAnonymous
        ? formatMessage({ id: 'user.anon', defaultMessage: 'Anonymous' })
        : donor.name;
    const throughLayerItemName = get(donor, 'layerItem.name', null);
    const throughLayerItemSlug = get(donor, 'layerItem.slug', null);
    const throughLayerItemId = get(donor, 'layerItem.id', null);
    const { filteredQueryString: queryString } = filterQueryParams(
        document.location.search,
    );
    const throughItemUrl = throughLayerItemId
        ? `/${props.campaignSlug}/${
              throughLayerItemSlug || throughLayerItemId
          }/${queryString}`
        : null;
    const multiplier = get(donor, 'multiplier', 1);
    const recurringMonths = get(donor, 'recurringMonths', 1);
    const givingColor = get(donor, 'giving.color');
    const givingLogoUrl = get(donor, 'giving.logo');
    const bgColor = givingColor ? `${givingColor + 33}` : '';
    const matchMyGiftEnabledForItem = isMatchMyGiftEnabled && !donor.giving?.id;

    return (
        <DonateCard
            donorId={donor.id}
            matchMyGiftCount={donor.matchedDonationsCount}
            amount={donor.amount * multiplier * recurringMonths}
            hideAmount={donor.isAmountHidden || false}
            isMatchMyGiftEnabled={matchMyGiftEnabledForItem}
            onMatchMyGiftClicked={() => {
                onMatchMyGiftClicked(donor);
            }}
            isDonorPflEnabled={isDonorPflEnabled}
            donorPflCount={donor.donorPflCount}
            isFirstInGroup={isFirstInGroup}
            logoUrl={givingLogoUrl}
            borderColor={givingColor}
            bgColor={bgColor}
            currency={donor.currency}
            name={name}
            color={givingColor || get(donor, 'layerItem.color')}
            greetingText={donor.comment}
            throughText={
                throughLayerItemName
                    ? formatMessage({
                          id: 'DonateCard.through',
                          defaultMessage: 'Donation through ',
                      })
                    : null
            }
            throughLayerItem={donor?.layerItem}
            throughLayerItemName={throughLayerItemName}
            throughItemUrl={throughItemUrl}
            className="rdp-card"
        />
    );
}

DonorItem.propTypes = {
    donor: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        amount: PropTypes.number,
        multiplier: PropTypes.number,
        recurringMonths: PropTypes.number,
        layerItem: PropTypes.object,
        currency: PropTypes.string,
        isAnonymous: PropTypes.bool,
        isAmountHidden: PropTypes.bool,
        giving: PropTypes.object,
        comment: PropTypes.string,
        matchedDonationsCount: PropTypes.number,
        donorPflCount: PropTypes.number,
    }),
    campaignSlug: PropTypes.string,
    isMatchMyGiftEnabled: PropTypes.bool,
    onMatchMyGiftClicked: PropTypes.func,
    isDonorPflEnabled: PropTypes.bool,
    isFirstInGroup: PropTypes.bool,
};

export default DonorItem;
