import React from 'react';

function MatbiaIconChecked(props) {
    return (
        <svg
            width="104"
            height="19"
            viewBox="0 0 104 19"
            fill="none"
            {...props}
        >
            <mask
                id="a"
                maskUnits="userSpaceOnUse"
                x="22"
                y="8"
                width="106"
                height="19"
            >
                <path d="M127.189 8.142H22.805v18.71H127.19Z" fill="#fff" />
            </mask>
            <g mask="url(#a)" transform="translate(-22.997 -7.997)">
                <path
                    d="M61.336 18.914a3.126 3.126 0 0 0-2.166-3.856 3.125 3.125 0 0 0-3.855 3.856Z"
                    fill="#fcb646"
                />
                <path
                    d="M126.98 26.852h-13.418a.216.216 0 0 1-.215-.208v-7.516c0-.116.092-.214.215-.214h5.482a.206.206 0 0 0 .208-.208V16.3a.216.216 0 0 0-.208-.214h-8.315a.207.207 0 0 0-.208.214v10.276a.207.207 0 0 1-.209.209h-7.514a.216.216 0 0 1-.214-.209V8.363c0-.116.092-.208.214-.208h24.189c.116 0 .208.092.208.208v18.282a.206.206 0 0 1-.208.208m-26.746-.001H76.034a.204.204 0 0 1-.202-.202V8.344c0-.11.092-.202.202-.202h7.539c.11 0 .202.092.202.202v10.368c0 .11.091.202.201.202h8.335c.11 0 .201-.092.201-.202v-2.43a.204.204 0 0 0-.201-.202h-5.508a.204.204 0 0 1-.201-.202v-7.54c0-.11.091-.202.201-.202h13.45c.11 0 .202.086.202.196v18.306c0 .11-.092.202-.202.202m-53.088.012h-24.17a.19.19 0 0 1-.19-.19v-7.559a.19.19 0 0 1 .19-.19h2.631a.19.19 0 0 0 .19-.19V16.27a.19.19 0 0 0-.19-.19h-2.619a.19.19 0 0 1-.19-.189v-7.56a.19.19 0 0 1 .19-.19H33.57a.19.19 0 0 1 .19.19v10.393c0 .104.085.19.189.19h5.287a.19.19 0 0 0 .19-.19v-2.455a.19.19 0 0 0-.19-.19H36.78a.19.19 0 0 1-.19-.189V8.325a.19.19 0 0 1 .19-.19h10.39a.19.19 0 0 1 .19.19v18.33a.19.19 0 0 1-.19.19m23.993-8.176V8.399a.245.245 0 0 0-.244-.245H49.796a.245.245 0 0 0-.245.245v7.449c0 .134.11.244.245.244h3.58a.24.24 0 0 0 .22-.147c.3-.654.728-1.242 1.267-1.726a5.15 5.15 0 0 1 5.115-1.07h.018a5.165 5.165 0 0 1 3.445 5.563.25.25 0 0 1-.238.208h-13.4a.246.246 0 0 0-.246.245v7.448c0 .135.11.245.245.245h23.944c.134 0 .244-.11.244-.245v-7.442a.246.246 0 0 0-.244-.245h-2.332a.246.246 0 0 1-.245-.245"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default MatbiaIconChecked;
