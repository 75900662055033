import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FlagIcon from '../../../Icons/Flag';
import MountsIcon from '../../../Icons/Mounts';
import Text from '../Text/Text';
import StarDivider from '../StarDivider/StarDivider';
import DonationWidgetAdditional from '../DonationWidgetAdditional/DonationWidgetAdditional';
import DonationGraph from '../DonationGraph/DonationGraph';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { adjustColor, changeColorAlpha } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
    root: {
        display: 'flex',
        padding: '15px 20px',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: palette.grey[100],
        borderRadius: 3,
        flexWrap: ({ hideGoalAmount, hideRaisedAmount }) =>
            hideGoalAmount && hideRaisedAmount ? 'wrap' : null,
        '@media (max-width: 600px)': {
            padding: 15,
        },
    },
    graph: {
        position: 'relative',
        margin: ({ hideGoalAmount, hideRaisedAmount }) =>
            hideGoalAmount && hideRaisedAmount ? 'auto' : '0 20px 0 5px',
        flexShrink: 0,

        '@media (max-width: 600px)': {
            margin: ({ hideGoalAmount, hideRaisedAmount }) =>
                hideGoalAmount && hideRaisedAmount ? 'auto' : 0,
        },
        'html[dir=rtl] &': {
            order: 1,
        },
    },
    mainLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 2,
        fontSize: '1rem',
        fontFamily: typography.fontFamily,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
            marginBottom: 0,
        },
    },
    main: {
        textAlign: 'center',
        direction: 'initial',
    },
    amount: {
        fontSize: '3.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        fontFamily: typography.fontFamily,
        color: palette.text.primary,
        marginBottom: spacing(2),
        '@media (max-width: 600px)': {
            fontSize: '2.25rem',
        },
    },
    divider: {
        margin: '10px 0 15px',

        '@media (max-width: 600px)': {
            margin: '5px 0',
        },
    },
    additionals: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
    notLastAdditional: {
        marginRight: 19,
        '&:last-child': {
            marginRight: 0,
        },

        '@media (max-width: 600px)': {
            marginRight: 10,
        },
    },
    totalPercent: {
        backgroundColor: palette.secondary.main,
        background: `linear-gradient(90deg, ${adjustColor(
            palette.secondary.main,
            12,
        )} 0%, ${palette.secondary.main} 75%)`,
        borderRadius: 5,
        color: palette.secondary.contrastText,
        textShadow: `-0.75px 0.75px 2px ${changeColorAlpha(
            palette.getContrastText(palette.secondary.contrastText),
            0.6,
        )}`,
        fontSize: '1.125rem',
        fontWeight: 500,
        fontFamily: typography.fontFamily,
        position: 'absolute',
        right: 77,
        top: 22,
        padding: spacing(0.5),
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
        '&::after': {
            content: '""',
            position: 'absolute',
            right: '20%',
            top: '100%',
            border: '5px solid transparent',
            borderTopColor: palette.secondary.main,
        },
        '@media (max-width: 600px)': {
            fontSize: '0.75rem',
            right: 51,
        },
    },
}));

function DonationWidget(props) {
    const { formatMessage } = useIntl();

    const {
        className,
        amount = 0,
        goal = 0,
        goalPercent = 0,
        goal1Percent = 0,
        goal2Percent = 0,
        totalPercent = 0,
        currency = 'USD',
        currencySign = '$',
        nextBonusRoundGoal = 0,
        totalGoalAmount = 0,
        hideProgressBar,
        hideGoalAmount,
        hideRaisedAmount,
    } = props;
    const classes = useStyles({ hideGoalAmount, hideRaisedAmount });
    const showBonusGoal = nextBonusRoundGoal > 0;
    const goalTitle = showBonusGoal
        ? formatMessage({
              id: 'common.originalGoal',
              defaultMessage: 'Original goal',
          })
        : formatMessage({
              id: 'DonationWidget.goalTitle',
              defaultMessage: 'Campaign goal',
          });

    return (
        !(hideProgressBar && hideGoalAmount && hideRaisedAmount) && (
            <div
                className={classNames(
                    classes.root,
                    className,
                    'stats-widget-progress',
                )}
            >
                {!hideProgressBar && (
                    <div className={classes.graph}>
                        <DonationGraph
                            goal={goalPercent}
                            goal1={goal1Percent}
                            goal2={goal2Percent}
                        />
                        <div className={classes.totalPercent}>
                            {totalPercent}
                            <FormattedMessage
                                id="donationWidget.percent"
                                defaultMessage="%"
                            />
                        </div>
                    </div>
                )}
                <div className={classes.main}>
                    {!hideRaisedAmount && (
                        <Text className={classes.amount}>
                            {currencySign}
                            {amount.toLocaleString('en-EN')}
                        </Text>
                    )}
                    {!hideRaisedAmount && hideGoalAmount && (
                        <Text className={classes.mainLabel}>
                            <FormattedMessage
                                id="Amount.raised"
                                defaultMessage="Raised"
                            />
                        </Text>
                    )}
                    {!hideGoalAmount && (
                        <div className={classes.additionals}>
                            {showBonusGoal && (
                                <DonationWidgetAdditional
                                    id="DonationWidget.bonusTitle"
                                    label={'Bonus Goal'}
                                    amount={totalGoalAmount}
                                    currency={currency}
                                    currencySign={currencySign}
                                    largeFont={hideRaisedAmount}
                                    icon={FlagIcon}
                                />
                            )}
                            {hideRaisedAmount && (
                                <StarDivider
                                    starCount={3}
                                    className={classes.divider}
                                />
                            )}
                            <DonationWidgetAdditional
                                label={goalTitle}
                                amount={goal}
                                currency={currency}
                                currencySign={currencySign}
                                largeFont={hideRaisedAmount}
                                icon={MountsIcon}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

DonationWidget.propTypes = {
    amount: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
    goal: PropTypes.number,
    bonusGoal: PropTypes.number,
    goalPercent: PropTypes.number,
    goal1Percent: PropTypes.number,
    goal2Percent: PropTypes.number,
    totalPercent: PropTypes.number,
    totalGoalAmount: PropTypes.number,
    currencySign: PropTypes.string,
    nextBonusRoundGoal: PropTypes.number,
    hideProgressBar: PropTypes.bool,
    hideGoalAmount: PropTypes.bool,
    hideRaisedAmount: PropTypes.bool,
};

export default DonationWidget;
