import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import useLoadScript from '../../hooks/loadScript';
import focusElement from 'client/helpers/focusElement';
import api from 'client/services/api';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});
function PaypalButton({
    clientId,
    currency,
    onDonateSuccess,
    makeDonation,
    paymentMode,
    onReady,
}) {
    const classes = useStyles();
    const donationsStore = useRef();
    const paypalContainer = useRef();
    const { formState, getValues, handleSubmit } = useFormContext();

    const isLimitedRecurring = paymentMode === 'recurring';

    const paypalUrl = `https://www.paypal.com/sdk/js?client-id=${clientId}&${
        isLimitedRecurring
            ? `vault=true&intent=subscription`
            : `currency=${currency}`
    }`;
    const status = useLoadScript(paypalUrl);

    const createOrder = async () => {
        const { layerItems, ...donationData } = getValues();
        const donations = await makeDonation(donationData, layerItems);
        donationsStore.current = donations;
        if (donations) {
            return donations.data.id;
        }
        return null;
    };

    const onApprove = data => {
        let requestBody = {};
        if (isLimitedRecurring) {
            requestBody = {
                isPaymentConfirmed: true,
                orderId: donationsStore.current.data.id,
                subscriptionId: data.subscriptionID,
            };
        } else {
            requestBody = {
                isPaymentConfirmed: false,
                orderId: data.orderID,
            };
        }

        return api.donation
            .confirmDonation('paypal', { ...requestBody, isWebhook: false })
            .then(() => {
                if (typeof onDonateSuccess === 'function') {
                    onDonateSuccess(donationsStore.current);
                }
            });
    };

    const createSubscription = async (data, actions) => {
        const planId = await createOrder();
        return actions.subscription.create({
            plan_id: planId,
        });
    };

    useEffect(() => {
        if (formState.isValid) {
            paypalContainer.current?.dispatchEvent(new Event('formvalid'));
        } else {
            paypalContainer.current?.dispatchEvent(new Event('forminvalid'));
        }
    }, [formState.isValid]);

    useEffect(() => {
        if (status === 'ready' && window.paypal) {
            const fundingSource = window.paypal.FUNDING.PAYPAL;
            let paypalFunctions = {};
            if (isLimitedRecurring) {
                paypalFunctions = { createSubscription };
            } else {
                paypalFunctions = { createOrder };
            }

            window.paypal
                .Buttons({
                    fundingSource,
                    ...paypalFunctions,
                    onApprove: onApprove,
                    onInit: function (data, actions) {
                        actions.disable();
                        paypalContainer.current?.addEventListener(
                            'forminvalid',
                            () => actions.disable(),
                        );
                        paypalContainer.current?.addEventListener(
                            'formvalid',
                            () => actions.enable(),
                        );
                        if (onReady) {
                            onReady();
                        }
                    },
                    onClick: function (data, actions) {
                        return handleSubmit(
                            () => actions.resolve(),
                            errors => {
                                if (errors?.amount) {
                                    setTimeout(
                                        () => focusElement('[name="amount"]'),
                                        25,
                                    );
                                }
                                return actions.reject();
                            },
                        )();
                    },
                })
                .render('#paypal-button-container');
        }
    }, [status, paypalUrl]);

    return (
        <>
            <div
                ref={paypalContainer}
                id={'paypal-button-container'}
                className={classes.root}
            ></div>
        </>
    );
}

PaypalButton.propTypes = {
    clientId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    onDonateSuccess: PropTypes.func.isRequired,
    makeDonation: PropTypes.func.isRequired,
    paymentMode: PropTypes.string,
    onReady: PropTypes.func,
};

export default PaypalButton;
