import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useFormContext } from 'react-hook-form';
import { selectDonationTotal } from './checkoutSlice';
import { PAYMENT_MODES } from 'common/constants';
import Loader from 'client/components/Loader';
import useLoadScript from '../../hooks/loadScript';
import focusElement from 'client/helpers/focusElement';

const CARDKNOX_IFIELDS_VERSION = '2.15.2302.0801';

const useStyles = makeStyles(() => ({
    root: {
        display: 'inline-block',
        overflow: 'hidden',
        width: '100%',
    },
    iframe: {
        width: '100%',
        display: ({ isLoading }) => (isLoading ? 'none' : 'inline-block'),
        border: 0,
        height: '54px',
        overflow: 'hidden',
    },
}));

const ENVIRONMENTS = {
    TEST: 'TEST',
    PRODUCTION: 'PRODUCTION',
};

const STATUS_CODES = {
    SUCCESS: 100,
    UNSUPPORTED: -100,
    ERROR: -200,
};

function CardknoxGooglePayButton({
    onDonateSuccess,
    makeDonation,
    paymentMode,
    isTestMode,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const amountStore = useRef();
    const classes = useStyles({ isLoading });
    const { getValues, handleSubmit, trigger } = useFormContext();
    const { totalAmount, monthlyAmount, tipAmount } =
        useSelector(selectDonationTotal);

    const scriptStatus = useLoadScript(
        `https://cdn.cardknox.com/ifields/${CARDKNOX_IFIELDS_VERSION}/ifields.min.js`,
    );

    const amount =
        paymentMode === PAYMENT_MODES.RECURRING
            ? monthlyAmount + tipAmount
            : totalAmount;

    useEffect(() => {
        if (!isLoading) {
            amountStore.current = amount || 0;
            if (typeof window.ckGooglePay?.updateAmount === 'function') {
                window.ckGooglePay.updateAmount();
            }
        }
    }, [isLoading, amount]);

    const onGpButtonLoad = ({ status }) => {
        if (status === STATUS_CODES.SUCCESS) {
            setIsLoading(false);
        }
    };

    const onPaymentProcess = paymentResponse => {
        return new Promise(function (resolve, reject) {
            (async () => {
                try {
                    const { layerItems, ...donationData } = getValues();
                    const donations = await makeDonation(
                        donationData,
                        layerItems,
                        paymentResponse,
                    );
                    if (typeof onDonateSuccess === 'function' && donations) {
                        onDonateSuccess(donations);
                    }
                    resolve({
                        status: STATUS_CODES.SUCCESS,
                    });
                } catch (err) {
                    console.error(err);
                    reject({ error: err });
                }
            })();
        });
    };

    const handleErrors = errors => {
        if (errors?.amount) {
            setTimeout(() => focusElement('[name="amount"]'), 25);
        }
    };

    const onValidation = isValid => {
        if (isValid) {
            return STATUS_CODES.SUCCESS;
        }
        handleSubmit(() => {}, handleErrors)();
        return STATUS_CODES.ERROR;
    };

    const onBeforePaymentProcess = () => {
        return trigger().then(onValidation);
    };

    const getEnvironment = () => {
        if (isTestMode) {
            return ENVIRONMENTS.TEST;
        }
        return ENVIRONMENTS.PRODUCTION;
    };

    const onPaymentCancel = () => {};

    const onGetTransactionInfo = () => {
        return {
            countryCode: 'US',
            currencyCode: getValues('currency'),
            totalPriceStatus: 'FINAL',
            totalPrice: amountStore.current.toString(),
        };
    };

    useEffect(() => {
        if (scriptStatus === 'ready') {
            const googlePayRequest = {
                onGetTransactionInfo,
                onBeforePaymentProcess,
                onPaymentProcess,
                onPaymentCancel,
                onGpButtonLoad,
                initGP: function () {
                    return {
                        merchantInfo: {
                            merchantName: 'Causematch Campaign',
                        },
                        buttonOptions: {
                            buttonSizeMode: 'fill',
                            buttonColor: 'default',
                            buttonType: 'donate',
                        },
                        environment: getEnvironment(),
                        onGetTransactionInfo:
                            'cardknox.gpRequest.onGetTransactionInfo',
                        onBeforeProcessPayment:
                            'cardknox.gpRequest.onBeforePaymentProcess',
                        onProcessPayment: 'cardknox.gpRequest.onPaymentProcess',
                        onPaymentCanceled: 'cardknox.gpRequest.onPaymentCancel',
                        onGPButtonLoaded: 'cardknox.gpRequest.onGpButtonLoad',
                    };
                },
            };

            window.cardknox = window.cardknox || {};
            window.cardknox.gpRequest = googlePayRequest;

            setTimeout(() => {
                if (typeof window.ckGooglePay?.enableGooglePay === 'function') {
                    window.ckGooglePay.enableGooglePay();
                }
            }, 500);
        }
    }, [scriptStatus]);

    return (
        <div className={classes.root}>
            {isLoading && <Loader />}
            <iframe
                id="igp"
                className={classes.iframe}
                data-ifields-id="igp"
                data-ifields-oninit="window.cardknox.gpRequest.initGP"
                src="https://cdn.cardknox.com/ifields/2.9.2109.2701/igp.htm"
                allow="payment"
                sandbox="allow-popups allow-modals allow-scripts allow-same-origin allow-forms allow-popups-to-escape-sandbox allow-top-navigation"
            ></iframe>
        </div>
    );
}

CardknoxGooglePayButton.propTypes = {
    onDonateSuccess: PropTypes.func,
    makeDonation: PropTypes.func,
    paymentMode: PropTypes.string,
    isTestMode: PropTypes.bool,
};

export default CardknoxGooglePayButton;
