import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        background: palette.background.default,
        boxShadow: `0 0 20px ${palette.grey[300]}`,
        borderRadius: 5,
        padding: '10px',
        margin: '25px 0',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gridGap: '5px',
        gap: '5px',
        justifyItems: 'stretch',

        '@media (max-width: 600px)': {
            overflowX: 'auto',
            margin: '20px 0',
        },
    },
}));

function RadioGroupTabs(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>{children}</div>
    );
}

RadioGroupTabs.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default RadioGroupTabs;
