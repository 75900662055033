import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { initConfetti } from './confetti';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { selectEnableConfetti } from '../CampaignPage/features/campaign/campaignSlice';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        pointerEvents: 'none',
        zIndex: 9999,
    },
}));

function Confetti() {
    const enableConfetti = useSelector(selectEnableConfetti);
    const { palette } = useTheme();
    const classes = useStyles();
    const canvasRef = useRef();
    const confettiRef = useRef();

    const confettiColorCombos = [
        [palette.primary.main, palette.secondary.main],
        [palette.primary.dark, palette.primary.light],
        [palette.primary.main, palette.primary.light],
        [palette.secondary.main, palette.secondary.light],
    ];

    useEffect(() => {
        if (confettiRef?.current) {
            confettiRef.current.stop();
            confettiRef.current.clear();
        }
        if (enableConfetti && canvasRef.current) {
            confettiRef.current = initConfetti(
                canvasRef.current.id,
                confettiColorCombos,
            );
            confettiRef.current.start();
        }
    }, [confettiColorCombos, enableConfetti, canvasRef.current]);

    if (!enableConfetti) {
        return null;
    }

    return <canvas ref={canvasRef} id="confetti" className={classes.root} />;
}

export default Confetti;
