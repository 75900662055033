import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from './CashAppLogo.svg';
import { ReactComponent as CheckedLogo } from './CashAppLogoChecked.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 100,
        height: 25,
        paddingBottom: 2,
        '@media (max-width: 600px)': {
            width: 75,
            height: 17,
        },
    },
}));

function CashAppLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={props.checked ? CheckedLogo : Logo}
                viewBox="0 0 148 32"
                className={props.className ? props.className : classes.svg}
            />
        </div>
    );
}

CashAppLogo.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
};

export default CashAppLogo;
