import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    // eslint-disable-next-line no-unused-vars,react/prop-types
    const {
        value,
        inputRef,
        onChange,
        useFloatValue = true,
        disableOnBlur = true,
        onBlur,
        ...other
    } = props;

    const onValueChange = values => {
        if (useFloatValue) {
            onChange(values?.floatValue ?? '');
        } else {
            onChange(values?.value ?? '');
        }
    };

    return (
        <NumberFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={onValueChange}
            onBlur={event => !disableOnBlur && onBlur(event)}
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    useFloatValue: PropTypes.bool,
    disableOnBlur: PropTypes.bool,
    onBlur: PropTypes.func,
};

export default NumberFormatCustom;
