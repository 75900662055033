const _ = require('lodash');

const HOME_ROOT_PATHS = ['/', '/he'];

const PAGE_PATHS = Object.freeze([
    '/about-us',
    '/faq',
    '/careers',
    '/peer-to-peer-fundraising',
    '/privacy-policy',
    '/request-a-demo',
    '/services',
    '/terms-of-service',
    '/donate-page',
]);

const HOME_PATHS = Object.freeze(
    [
        ...PAGE_PATHS.flatMap(path =>
            HOME_ROOT_PATHS.map(root => `${root === '/' ? '' : root}${path}`),
        ),
    ].filter(Boolean),
);

const HOME_FUTURE_PATHS = Object.freeze([
    '/fundraising-tips',
    '/fundraising-guide',
    '/fundraising-ideas',
]);

const HOME_RESERVED_SLUGS = Object.freeze(
    _.uniq(
        [...HOME_ROOT_PATHS, ...HOME_PATHS, ...HOME_FUTURE_PATHS]
            .map(path => path.split('/')[1])
            .filter(Boolean),
    ),
);

module.exports = {
    HOME_ROOT_PATHS,
    HOME_PATHS,
    HOME_RESERVED_SLUGS,
};
