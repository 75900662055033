const get = require('lodash/get');

function isToggled(src, path, defaultValue) {
    const value = get(src, path);
    if (value === undefined) {
        return typeof defaultValue === 'function'
            ? defaultValue(src)
            : !!defaultValue;
    } else {
        return value;
    }
}

module.exports = isToggled;
