import * as qs from 'query-string';
import { camelCase } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import {
    COUNTRIES_INFO,
    PAYMENT_METHODS,
    PAYMENT_MODES,
    CURRENCIES,
} from 'common/constants';
import { normalizePhonePrefix } from 'client/helpers/phone';

const isNonEmptyString = value => typeof value === 'string' && value.length;

const nameValidator = name => isNonEmptyString(name) && name.length <= 100;

const phoneValidator = (phonePrefix, phone) => {
    if (!(phone && phonePrefix)) {
        return false;
    }
    const phonePrefixes = COUNTRIES_INFO.map(({ phonePrefix }) => phonePrefix);
    return phonePrefixes.includes(phonePrefix) && isNumeric(phone);
};

const isEnumEntry = enumObject => {
    return value =>
        isNonEmptyString(value) &&
        Boolean(Object.values(enumObject).includes(value));
};

const AUTO_FILL_PARAMETERS_CONFIG = [
    {
        key: 'payment_mode',
        validate: isEnumEntry(PAYMENT_MODES),
    },
    {
        key: 'months',
        validate: value => !isNaN(value),
        parse: value => parseInt(value),
    },
    {
        key: 'payment_method',
        validate: isEnumEntry(PAYMENT_METHODS),
    },
    {
        key: 'currency',
        validate: value => CURRENCIES.includes(value),
    },
    {
        key: 'amount',
        validate: value => !isNaN(value),
        parse: value => parseInt(value),
    },
    {
        key: 'first_name',
        validate: nameValidator,
    },
    {
        key: 'last_name',
        validate: nameValidator,
    },
    {
        key: 'email',
        validate: isEmail,
    },
    {
        key: 'phone_prefix',
        validate: (_, { phonePrefix, phone }) =>
            phoneValidator(phonePrefix, phone),
        parse: value => normalizePhonePrefix(value.trim()),
    },
    {
        key: 'phone',
        validate: (_, { phonePrefix, phone }) =>
            phoneValidator(phonePrefix, phone),
        parse: value => value.match(/\d+/g).join(''),
    },
    {
        key: 'country',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'state',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'city',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'address_1',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'address_2',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'zip',
        validate: value => isNonEmptyString(value),
    },
    {
        key: 'is_anonymous',
        validate: value => typeof value === 'boolean',
        parse: value => value === 'true',
    },
];

export const autofillParameters = () => {
    const rawQuery = qs.parse(document.location.search);
    const awayFlowParameters = [
        'payment_intent',
        'redirect_status',
        'status',
        'response',
    ];

    if (awayFlowParameters.some(parameter => Boolean(rawQuery[parameter]))) {
        return {};
    }

    const parameters = AUTO_FILL_PARAMETERS_CONFIG.filter(
        ({ key }) => typeof rawQuery[key] === 'string' && rawQuery[key].length,
    );

    const query = parameters.reduce((acc, { key, parse }) => {
        const rawValue = rawQuery[key];
        const value = parse ? parse(rawValue) : rawValue;
        return {
            ...acc,
            [camelCase(key)]: value,
        };
    }, {});

    const result = parameters
        .map(parameter => ({ ...parameter, key: camelCase(parameter.key) }))
        .filter(({ key, validate }) => validate(query[key], query))
        .map(({ key }) => ({ key, value: query[key] }))
        .reduce(
            (acc, { key }) => ({
                ...acc,
                [key]: query[key],
            }),
            { autoFilled: true },
        );

    return result;
};
