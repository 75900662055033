import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { selectPayment, selectStripeKey } from './checkoutSlice';
import { PAYMENT_GATEWAYS } from 'common/constants';

// we cannot use redux, as stripe context is not serializable
const contextStore = {};

function getContextKey(stripeKey, stripeAccount, locale) {
    return `${stripeKey}:${stripeAccount}:${locale}`;
}

async function initializeStripeContext(
    stripeKey,
    stripeAccount,
    locale,
    callback,
) {
    const contextKey = getContextKey(stripeKey, stripeAccount, locale);

    const stripeLocale = locale || 'auto';
    const stripe = await loadStripe(stripeKey, {
        stripeAccount,
    });

    const elements = stripe?.elements({ locale: stripeLocale });

    // we do this to ensure that elements provided by context in
    // the react-stripe-js library is consistent and not re-created
    Object.defineProperty(stripe, 'elements', {
        get: () => () => elements,
        enumerable: true,
    });

    const context = {
        key: contextKey,
        stripe,
        elements: elements ?? null,
    };
    contextStore[contextKey] = context;

    callback?.(context);

    return context;
}

export async function loadStripeContext(stripeKey, stripeAccount, locale) {
    // we only want to initialize stripe context once
    // all subsequent calls should get the same result
    // as the first call, given the same context key

    const contextKey = getContextKey(stripeKey, stripeAccount, locale);

    // we first check if there's anything in the context
    if (contextStore[contextKey]) {
        // if context is being initialized, return existing promise
        if (contextStore[contextKey] instanceof Promise) {
            return await contextStore[contextKey];
        }

        // otherwise, return the resolved context value
        return contextStore[contextKey];
    }

    // we create a promise if this is the first call
    // and set the promise in the store for this context key
    // so that all subsequent calls get the same result
    const contextPromise = new Promise(resolve => {
        initializeStripeContext(stripeKey, stripeAccount, locale, resolve);
    });

    contextStore[contextKey] = contextPromise;

    return await contextPromise;
}

export function useStripeContext() {
    const { locale } = useIntl();
    const paymentConfig = useSelector(selectPayment);
    const stripeKey = useSelector(selectStripeKey);
    const [stripeContext, setStripeContext] = useState(null);

    useEffect(() => {
        // declared in useEffect to prevent
        // re-decleration on each render
        const loadStripe = async () => {
            const context = await loadStripeContext(
                stripeKey,
                paymentConfig.accountId,
                locale,
            );
            setStripeContext(context);
        };

        if (
            paymentConfig?.gateway === PAYMENT_GATEWAYS.STRIPE &&
            stripeKey &&
            paymentConfig.accountId
        ) {
            // only provide stripe context when stripe is selected
            loadStripe();
        } else {
            setStripeContext(null);
        }
    }, [paymentConfig?.gateway, stripeKey, paymentConfig?.accountId, locale]);

    return stripeContext;
}

export function withStripeContext(
    Component,
    { renderWithoutStripe = false } = {},
) {
    function StripeComponent(props) {
        return (
            <StripeProvider renderWithoutStripe={renderWithoutStripe}>
                <Component {...props} />
            </StripeProvider>
        );
    }

    return StripeComponent;
}

function StripeProvider({ children, renderWithoutStripe = false }) {
    const { locale } = useIntl();
    const paymentConfig = useSelector(selectPayment);
    const stripeContext = useStripeContext();

    if (
        !stripeContext?.stripe ||
        !paymentConfig?.gateway === PAYMENT_GATEWAYS.STRIPE
    ) {
        return renderWithoutStripe ? <>{children}</> : null;
    }

    return (
        <StripeElements
            key={stripeContext.key}
            stripe={stripeContext.stripe}
            options={{ locale }}
        >
            {children}
        </StripeElements>
    );
}

StripeProvider.propTypes = {
    children: PropTypes.node,
    renderWithoutStripe: PropTypes.bool,
};

export default StripeProvider;
