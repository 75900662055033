import Repository from './Repository';

const repository = new Repository({ baseUrl: '/public' });

repository.getPageURI = async function (pageType) {
    return this.axios
        .get(`/static-host/${pageType}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

export default repository;
