import Repository from './Repository';
import { create } from 'client/helpers/axios';

const repository = {
    axios: create({ baseUrl: '/messaging' }),
    async fetchMessageAnalytics(queryArg) {
        const query = { ...queryArg };
        if (query.layerIds.length === 0) {
            delete query.layerIds;
        } else {
            query.layerIds = query.layerIds.join(',');
        }
        if (query.donors === null) {
            delete query.donor;
        }
        if (query.search === null) {
            delete query.search;
        }
        const queryString = Repository.prepareQueryString(query);
        const res = await this.axios.get(`/analytics?${queryString}`);
        return {
            ...res.data,
            docs: res.data.docs.map(message => ({
                id: message.messageId,
                ...message,
            })),
        };
    },

    async fetchRecipients(queryArg) {
        const query = { ...queryArg };

        if (!query.search) {
            delete query.search;
        }

        if (!query.startDate === null) {
            delete query.startDate;
        }

        if (!query.endDate === null) {
            delete query.endDate;
        }

        const queryString = Repository.prepareQueryString(query);
        const res = await this.axios.get(
            `/analytics/recipients?${queryString}`,
        );
        return res.data;
    },
};

export default repository;
