import React from 'react';
import PropTypes from 'prop-types';

function SvgAboutIcon({ fill = '#fff', ...props }) {
    const styles = {
        strokeWidth: 0.25,
        color: fill,
        stroke: 'currentColor',
        fill: 'currentColor',
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.597"
            height="24.543"
            viewBox="0 0 24.597 24.543"
            {...props}
        >
            <g transform="translate(0.145 0.125)">
                <path
                    style={styles}
                    d="M972.7,1594a12.169,12.169,0,0,0-12.158,12.146,11.9,11.9,0,0,0,2.01,6.632l-1.963,4.7a.588.588,0,0,0,.764.765l4.7-1.964A12.135,12.135,0,1,0,972.7,1594m0,23.129a11.067,11.067,0,0,1-6.244-1.987.583.583,0,0,0-.33-.106.606.606,0,0,0-.223,0l-3.681,1.576,1.541-3.669a.583.583,0,0,0-.059-.563,10.737,10.737,0,0,1-1.987-6.233,10.983,10.983,0,1,1,10.995,10.971H972.7Z"
                    transform="translate(-960.54 -1594)"
                />
                <path
                    fill="none"
                    d="M972.7,1594a12.169,12.169,0,0,0-12.158,12.146,11.9,11.9,0,0,0,2.01,6.632l-1.963,4.7a.588.588,0,0,0,.764.765l4.7-1.964A12.135,12.135,0,1,0,972.7,1594Zm0,23.129a11.067,11.067,0,0,1-6.244-1.987.583.583,0,0,0-.33-.106.606.606,0,0,0-.223,0l-3.681,1.576,1.541-3.669a.583.583,0,0,0-.059-.563,10.737,10.737,0,0,1-1.987-6.233,10.983,10.983,0,1,1,10.995,10.971H972.7Z"
                    transform="translate(-960.54 -1594)"
                />
                <path
                    style={styles}
                    d="M969.809,1597.8a3.679,3.679,0,0,0-3.692,3.657.587.587,0,1,0,1.175,0,2.517,2.517,0,1,1,2.517,2.564.587.587,0,0,0-.587.587v2.634a.587.587,0,1,0,1.175,0v-2.093a3.692,3.692,0,0,0-.587-7.349"
                    transform="translate(-957.651 -1592.031)"
                />
                <path
                    fill="none"
                    d="M969.809,1597.8a3.679,3.679,0,0,0-3.692,3.657.587.587,0,1,0,1.175,0,2.517,2.517,0,1,1,2.517,2.564.587.587,0,0,0-.587.587v2.634a.587.587,0,1,0,1.175,0v-2.093a3.692,3.692,0,0,0-.587-7.349Z"
                    transform="translate(-957.651 -1592.031)"
                />
                <path
                    style={styles}
                    d="M968.737,1605.376a.644.644,0,1,1-.644.645.645.645,0,0,1,.644-.645"
                    transform="translate(-956.627 -1588.107)"
                />
                <path
                    fill="none"
                    d="M968.737,1605.376a.644.644,0,1,1-.644.645A.645.645,0,0,1,968.737,1605.376Z"
                    transform="translate(-956.627 -1588.107)"
                />
            </g>
        </svg>
    );
}

SvgAboutIcon.propTypes = {
    fill: PropTypes.string,
};

export default SvgAboutIcon;
