import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { changeColorAlpha } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        border: 0,
        margin: '0 5px',
        padding: '10px 35px',
        borderRadius: 3,
        position: 'relative',
        background: 'none',
        cursor: 'pointer',
        lineHeight: 1.2,
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(14),
        fontWeight: 500,
        color: palette.text.primary,
        flex: '0 1 auto',
        justifyContent: 'center',
        '&:not($active)': {
            '&:hover': {
                background: palette.grey[50],
                color: palette.primary.main,
                '& svg path': {
                    color: `${palette.primary.main} !important`,
                },
            },
        },
        '@media (max-width: 600px)': {
            padding: '6px 24px',
            margin: 5,
            flex: '0 1 40%',
        },
    },
    active: {
        color: palette.primary.main,
        outline: `2px solid ${palette.primary.main}`,
        backgroundColor: changeColorAlpha(palette.primary.main, 0.03),
        fontWeight: 700,
        '& svg path': {
            strokeWidth: 2,
        },
    },
    label: {
        textAlign: 'center',
    },
    icon: {
        display: 'block',
    },
    iconStart: {
        marginInlineEnd: '10px',
    },
    iconEnd: {
        marginInlineStart: '10px',
    },
    hidden: {
        display: 'none',
    },
}));

function DonationTab(props) {
    const {
        children,
        className,
        active = false,
        iconStart,
        iconEnd,
        link,
        ...rest
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const Component = link ? 'a' : 'button';

    const renderIcon = (icon, className) => {
        const iconClasses = classNames(classes.icon, className);
        if (typeof icon === 'string') {
            return <img src={icon} alt="" className={iconClasses} />;
        }
        const Icon = icon;
        return (
            <Icon
                className={iconClasses}
                fill={active ? palette.primary.main : palette.text.primary}
            />
        );
    };

    return (
        <Component
            className={classNames(classes.root, className, {
                [classes.active]: active,
            })}
            {...rest}
        >
            {iconStart && renderIcon(iconStart, classes.iconStart)}
            <div className={classes.label}>{children}</div>
            {iconEnd && renderIcon(iconEnd, classes.iconEnd)}
        </Component>
    );
}

DonationTab.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    active: PropTypes.bool,
    iconStart: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
    ]),
    iconEnd: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
    ]),
    link: PropTypes.bool,
    className: PropTypes.string,
};

export default DonationTab;
