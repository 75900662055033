const config = {
    dateFormat: 'h:MM TT, mmmm dS yyyy',
    uploadUrl: '/upload',
    deleteUrl: '/upload',
    apiUrl: window.cmConfig.apiUrl,
    publicHost: window.cmConfig.publicHost,
    googleRecaptchaKey: window.cmConfig.googleRecaptchaKey,
    stripePublicKey: window.cmConfig.stripePublicKey,
    stripeTestPublicKey: window.cmConfig.stripeTestPublicKey,
    features: window.cmConfig.features,
    ipCountry: window.cmConfig.ipCountry,
    maxUploadFileSize: window.cmConfig.maxUploadFileSize,
    posthogProjectApiKey: window.cmConfig.posthogProjectApiKey,
    posthogApiHost: window.cmConfig.posthogApiHost,
    analyticsRegion: window.cmConfig.analyticsRegion,
    analyticsIdentityPoolId: window.cmConfig.analyticsIdentityPoolId,
    analyticsKinesisStreamName: window.cmConfig.analyticsKinesisStreamName,
    analyticsKinesisStreamArn: window.cmConfig.analyticsKinesisStreamArn,
};

delete window.cmConfig;

export default Object.freeze(config);
