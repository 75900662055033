import React from 'react';
import PropTypes from 'prop-types';
import DonorsList from '../../components/DonorsList/DonorsList';
import useDonorsList from './useDonorsList';
import { slice } from './teamDonorsListSlice';

function LayerItemDonorsTabContent(props) {
    const { layerItem } = props;
    const donorListProps = useDonorsList(slice, layerItem);

    return <DonorsList layerItem={layerItem} {...donorListProps} />;
}

LayerItemDonorsTabContent.propTypes = {
    layerItem: PropTypes.object.isRequired,
};

export default LayerItemDonorsTabContent;
