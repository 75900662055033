import React from 'react';

function GlobeIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20.8 20.8"
            {...props}
        >
            <g transform="translate(-51.945 .25)">
                <g data-name="Group 14097" transform="translate(52.195)">
                    <g data-name="Group 14096">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            d="M10 0a10 10 0 1010 10A10.011 10.011 0 0010 0zm0 19.048A9.048 9.048 0 1119.048 10 9.058 9.058 0 0110 19.048z"
                            data-name="Path 11213"
                        ></path>
                    </g>
                </g>
                <g data-name="Group 14099" transform="translate(52.422 .28)">
                    <g data-name="Group 14098">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            d="M11.533 9.833a1.06 1.06 0 00-.537-.784 1.024 1.024 0 00-.93-.039h0a1.628 1.628 0 01-2.1-.684 2.829 2.829 0 00-1.63-1.36 9.579 9.579 0 01-2.135-.851c.063-.021.142-.044.24-.067a3.759 3.759 0 011.07-.09 3.089 3.089 0 003.118-2C8.976 2.922 8.04 1.072 7.621.325l-.183-.328-.361.1A10.053 10.053 0 00.086 7.211l-.09.344.3.183a3.446 3.446 0 001.946.483 1.526 1.526 0 011.128.432 3.58 3.58 0 01.7 2.351 6.022 6.022 0 00.14 1.375c.578 2.528 2.286 2.743 2.851 2.743h.133a2.119 2.119 0 001.886-1.168 2.626 2.626 0 01.918-1.012 3.089 3.089 0 001.535-3.109zm-2.042 2.31a3.574 3.574 0 00-1.249 1.381 1.173 1.173 0 01-1.053.645h-.067c-.059.01-1.472.183-1.971-2a5.055 5.055 0 01-.117-1.159 4.4 4.4 0 00-.981-3.027 2.473 2.473 0 00-1.822-.712h-.05a2.481 2.481 0 01-1.067-.188 9.108 9.108 0 015.86-5.96 4.81 4.81 0 01.754 2.528 2.13 2.13 0 01-2.166 1.35 4.829 4.829 0 00-1.338.114c-.277.065-1.12.264-1.142.916-.02.559.471 1.061 3 1.844a1.87 1.87 0 011.056.9 2.58 2.58 0 003.327 1.091.082.082 0 01.074.006.1.1 0 01.054.076 2.142 2.142 0 01-1.102 2.196z"
                            data-name="Path 11214"
                        ></path>
                    </g>
                </g>
                <g data-name="Group 14101" transform="translate(65.333 6.567)">
                    <g data-name="Group 14100">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            d="M6.518.802L6.409.396l-.416.057a5.774 5.774 0 01-3.352-.24 1.674 1.674 0 00-1.5-.065C.52.448-.176 1.203.041 3.163a3.322 3.322 0 001.81 2.25 1.154 1.154 0 01.617 1.284 3.484 3.484 0 001 3.666l.344.29.309-.327a10.088 10.088 0 002.4-9.524zm-2.761 8.5a2.737 2.737 0 01-.36-2.393A2.106 2.106 0 002.28 4.562a2.387 2.387 0 01-1.3-1.53c-.084-.761-.054-1.73.567-2.026a.583.583 0 01.252-.054.881.881 0 01.4.106 6.232 6.232 0 003.491.389 9.172 9.172 0 01.214 1.967 9.078 9.078 0 01-2.147 5.888z"
                            data-name="Path 11215"
                        ></path>
                    </g>
                </g>
                <g data-name="Group 14103" transform="translate(64 .725)">
                    <g data-name="Group 14102">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            d="M4.585 1.584A9.982 9.982 0 002.216.119L1.946 0l-.432.361C1.014.755-.522 1.952.183 3.313l.063.094a2.175 2.175 0 001.57.718 2.087 2.087 0 001.118-.34 2.594 2.594 0 00.7-.674 2.989 2.989 0 01.706-.648l.772-.44zm-1.711.955a1.692 1.692 0 01-.443.438 1.118 1.118 0 01-1.43-.159c-.09-.206-.178-.705 1.1-1.706a9.047 9.047 0 011.375.815 3.636 3.636 0 00-.602.612z"
                            data-name="Path 11216"
                        ></path>
                    </g>
                </g>
                <g data-name="Group 14105" transform="translate(59.018 15.764)">
                    <g data-name="Group 14104">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.8"
                            d="M5.805 3.296a5.228 5.228 0 01-.43-1.44A2.205 2.205 0 003.175 0a3.107 3.107 0 00-3.1 3.076l-.072.651.385.111a9.99 9.99 0 005.1.126l.575-.136zm-4.779-.272A2.154 2.154 0 013.176.952a1.272 1.272 0 011.258 1.052 6.636 6.636 0 00.283 1.146 9.042 9.042 0 01-3.691-.126z"
                            data-name="Path 11217"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default GlobeIcon;
