import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../Link/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {
            display: 'inline-block',
            position: 'relative',
            background: palette.grey[500],
            color: palette.common.white,
            fontSize: 14,
            borderRadius: '50%',
            width: 27,
            height: 27,
            alignItems: 'center',
            justifyContent: 'center',
        },

        icon: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
    }),
    { name: 'SocialLink' },
);

function SocialLink(props) {
    const { icon, target = '_blank', ...rest } = props;
    const classes = useStyles();

    return (
        <Link className={classes.root} target={target} {...rest}>
            <FontAwesomeIcon className={classes.icon} icon={icon} />
        </Link>
    );
}

SocialLink.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    target: PropTypes.string,
};

export default SocialLink;
