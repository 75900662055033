const qs = require('query-string');
const _ = require('lodash');
import camelize from 'camelize';
import {
    DONATION_CONVERSION_PARAMS,
    UTM_TRACKING_PARAM_VALUES,
} from 'common/constants';

export default (queryString, exclude = []) => {
    const excludeParams = [...DONATION_CONVERSION_PARAMS, ...exclude];

    let queryParameters = qs.parse(queryString);
    let filteredQueryObject = Object.keys(queryParameters)
        .filter(key => !excludeParams.includes(key))
        .reduce((obj, key) => {
            obj[key] = queryParameters[key];
            return obj;
        }, {});
    const filteredQueryString = qs.stringify(filteredQueryObject);
    const obj = {
        filteredQueryString: filteredQueryString
            ? `?${filteredQueryString}`
            : '',
        filteredQueryObject: _.mapKeys(
            filteredQueryObject,
            function (value, key) {
                return UTM_TRACKING_PARAM_VALUES.includes(key)
                    ? camelize(key)
                    : key;
            },
        ),
    };
    return obj;
};
