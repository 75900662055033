import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ensureHttpsProtocol } from 'common/helpers/url';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            '& > a + a': {
                marginInlineStart: spacing(2),
            },
            '@media (max-width: 600px)': {
                display: 'none',
            },
        },
        loginLnk: {
            textDecoration: 'none',
            marginRight: 25,
            color: palette.text.primary,
            fontWeight: 500,
        },
        scheduleDemoBtn: {
            borderRadius: 0,
            fontWeight: 500,
            padding: 10,
        },
    }),
    { name: 'HeaderButtons' },
);

function HeaderButtons(props) {
    const classes = useStyles();

    const getScheduleDemoLink = () => {
        if (props.scheduleLink?.link) {
            // for backward compatibility, before we added validation.
            return ensureHttpsProtocol(props.scheduleLink.link);
        }

        return `https://www.causematch.com/${
            props.lang && props.lang == 'he' ? props.lang + '/' : ''
        }request-a-demo/?ref=cm2`;
    };

    const getScheduleDemoLinkLabel = () => {
        if (props.scheduleLink?.label) {
            return props.scheduleLink.label;
        }

        return (
            <FormattedMessage
                id="PageHeader.scheduleDemo"
                defaultMessage="Schedule a Demo"
            />
        );
    };

    return (
        <div className={classNames(classes.root, 'header-buttons')}>
            <a className={classes.loginLnk} href="/login">
                <FormattedMessage
                    id="HeaderButtons.logIn"
                    defaultMessage="Log in"
                />
            </a>
            <Button
                className={classes.scheduleDemoBtn}
                outline
                link
                href={getScheduleDemoLink()}
            >
                {getScheduleDemoLinkLabel()}
            </Button>
        </div>
    );
}

HeaderButtons.propTypes = {
    scheduleLink: PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
    }),
    lang: PropTypes.string,
};

export default HeaderButtons;
