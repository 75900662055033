import React from 'react';

function BitIcon(props) {
    return (
        <svg
            width="113.462"
            height="61.521"
            viewBox="0 0 113.462 123.042"
            version="1.1"
            {...props}
        >
            <defs id="defs7">
                <clipPath id="clip-Artboard_1">
                    <path id="rect4" d="M0 0h329v212H0z" />
                </clipPath>
            </defs>
            <g
                id="Artboard_1"
                transform="translate(-96.723 -55.956)"
                clipPath="url(#clip-Artboard_1)"
            >
                <g transform="translate(-522.109 -324.574)">
                    <path
                        id="Path_1"
                        fill="#4a4b4c"
                        d="M562.109 420.571c.1-.355.218-.708.312-1.067a8.11 8.11 0 0 1 7.747-6.077c2.678-.039 5.358-.009 8.036-.01h1.139v-1.2c0-7.823-.012-15.645.006-23.468a8.121 8.121 0 0 1 16.22-.649c.131 2.865.057 5.739.06 8.609q.01 7.712 0 15.424c0 .4.033.8.055 1.288h24.27c8.086.007 15.693 1.784 22.443 6.4 9.337 6.389 15.672 14.93 17.751 26.2a40.525 40.525 0 0 1-29.646 47.314 48.8 48.8 0 0 1-15.763 1.048 39.9 39.9 0 0 1-15.37-4.212c-8.851-4.594-14.5-11.906-17.743-21.2a41 41 0 0 1-2.313-14c.079-7.979.021-15.96.021-23.941v-1.206c-.457-.023-.822-.054-1.187-.058-2.9-.03-5.8.059-8.693-.111a7.91 7.91 0 0 1-7.138-6.4 4 4 0 0 0-.215-.6zm33.613 9.187c-.038.37-.087.617-.086.863.06 7.946.08 15.892.223 23.836a25.2 25.2 0 0 0 .762 5.789 24.15 24.15 0 0 0 31.252 16.769c8.84-2.835 14.484-9.2 16.222-18.412 1.735-9.194-1.027-17.078-8.178-23.252a22.3 22.3 0 0 0-15.237-5.61c-7.908.069-15.817.017-23.726.017z"
                    />
                    <path
                        id="Path_2"
                        fill="#4a4b4c"
                        d="M1225.013 413.419v-5.374c0-6.4-.013-12.806.01-19.209a8.171 8.171 0 1 1 16.334.2c.032 7.665.01 15.329.01 22.994v1.38h1.147c6.587 0 13.174-.018 19.76.008a8.176 8.176 0 1 1-.223 16.345c-6.429.029-12.858.007-19.287.007h-1.344c-.019.378-.051.709-.051 1.04 0 8.579-.312 17.173.085 25.734.4 8.674 3.974 15.776 12.309 19.638 3.7 1.715 7.7 2.014 11.715 2.135a7.61 7.61 0 0 1 6.612 3.911 7.76 7.76 0 0 1 .172 8.1 7.65 7.65 0 0 1-7 4.226c-7.887.08-15.412-1.413-22.241-5.544-8.368-5.062-13.464-12.619-16.264-21.843A40.2 40.2 0 0 1 1225 455.16c.054-8.043.016-16.086.016-24.129v-1.257c-1.2 0-2.321.014-3.442 0a8.176 8.176 0 1 1-.426-16.347c1.252-.037 2.501-.008 3.865-.008"
                        transform="translate(-527.822)"
                    />
                    <path
                        id="Path_3"
                        fill="#4a4b4c"
                        d="M1123.27 594.867v32.263a8.02 8.02 0 0 1-5.528 7.857 8.3 8.3 0 0 1-9.343-2.974 8.17 8.17 0 0 1-1.5-5.069q.01-22.754 0-45.508c0-6.339-.017-12.678.01-19.017a8.177 8.177 0 0 1 16.353-.1q.033 16.275.012 32.548z"
                        transform="translate(-441.664 -140.808)"
                    />
                    <path
                        id="Path_4"
                        fill="#e01a33"
                        d="M1572.555 854.334a7 7 0 0 0-1.029-.2q-6.607-.292-13.216-.558c-.126-.005-.252 0-.378-.006-2.117-.054-2.083-.068-2.894-2-1.63-3.89-3.319-7.754-4.933-11.65-.207-.5-.362-1.355-.1-1.63a1.97 1.97 0 0 1 1.683-.351c2.287.677 4.549 1.446 6.791 2.263q14.494 5.276 28.969 10.6a6.5 6.5 0 0 1 1.283.585c1.444.93 1.437 2.248-.028 3.115-2.167 1.282-4.357 2.527-6.537 3.787q-13.491 7.8-27 15.566a3.1 3.1 0 0 1-2.178.437c-.809-.265-.572-1.285-.557-2.024a4.4 4.4 0 0 1 .214-1.2c1.264-4.054 2.553-8.1 3.809-12.158a2.08 2.08 0 0 1 1.765-1.5c2.41-.453 4.825-.876 7.238-1.314 2.01-.365 4.021-.724 6.027-1.111a7 7 0 0 0 1.09-.406z"
                        transform="translate(-800.782 -370.764)"
                    />
                    <path
                        id="Path_5"
                        fill="#e01a33"
                        d="M1110.658 432.807a9.255 9.255 0 1 1-9.248 9.221 9.235 9.235 0 0 1 9.248-9.221"
                        transform="translate(-437.215 -42.37)"
                    />
                </g>
            </g>
        </svg>
    );
}

export default BitIcon;
