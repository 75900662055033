const uuid = require('uuid/v4');
const { isUUID } = require('validator');

const TRACK_ID_HEADER_NAME = 'X-Cm-Track-Id';

function setTrackId(trackId) {
    window.localStorage.setItem(TRACK_ID_HEADER_NAME, trackId);
    return trackId;
}

function resetTrackId() {
    return setTrackId(uuid());
}

function getTrackId() {
    let trackId = window.localStorage.getItem(TRACK_ID_HEADER_NAME);
    if (!trackId || !isUUID(trackId)) {
        trackId = resetTrackId();
    }
    return trackId;
}

function getTrackIdFromHeaders(headers) {
    return (
        headers[TRACK_ID_HEADER_NAME] ||
        headers[TRACK_ID_HEADER_NAME.toLowerCase()]
    );
}

module.exports = {
    TRACK_ID_HEADER_NAME,
    setTrackId,
    getTrackId,
    getTrackIdFromHeaders,
    resetTrackId,
};
