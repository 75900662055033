import createDonorsSlice from './createDonorsListSlice';

export const slice = createDonorsSlice({
    name: 'teamDonorsList',
    // this parameter allow add a donor to the layer name specific list
    // donation will be added to the common campaign donors list,
    // if this parameter is not specified
    groupByLayerName: 'Team',
});
export default slice.reducer;
