import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        marginTop: ({ withMarginTop }) => (withMarginTop ? 60 : 0),
    },
}));

function HeroBanner(props) {
    const { className, src, height, withMarginTop = false, ...rest } = props;
    const classes = useStyles({ withMarginTop });

    return src ? (
        <div
            style={{ height }}
            className={classNames('hero-banner', className)}
        >
            <img className={classes.root} src={src} alt={src} {...rest} />
        </div>
    ) : null;
}

HeroBanner.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withMarginTop: PropTypes.bool,
};

export default React.memo(
    HeroBanner,
    (current, next) =>
        current.src === next.src && current.height === next.heigth,
);
