import scrollTo from './scrollTo';

export default function focusHandler(id) {
    return () => {
        const element = document.getElementById(id);
        if (element) {
            scrollTo(element);
        }
    };
}
