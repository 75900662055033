import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import api from 'client/services/api';
import order from 'common/helpers/orderFactory';
import { SORT_BY } from 'common/constants';
import { selectSelectedCampaign } from '../campaign/campaignSlice';
import {
    TILES_PER_PAGE,
    DEFAULT_PAGES_PER_SET,
    MAX_PAGES_PER_SET,
} from '../recentDonationPanel/constants';
import { groupBy } from 'lodash';

function orderStringToStringifiedJson(orderString) {
    const orderBuilder = order();
    orderString
        .split(',')
        .forEach(orderSet => orderBuilder.add(...orderSet.split(':')));
    return orderBuilder.toString();
}

export const STATE_PREFIX = 'donorsList';
const defaultInitialState = Object.freeze({
    loading: 'idle',
    query: {
        paginate: TILES_PER_PAGE,
        order: 'createdAt:desc',
        searchText: '',
    },
    donors: [],
    error: null,
    resetDonorsAfterLoad: false,
    total: 0,
    scrollTop: 0,
    allowAutoLoad: true,
    pagesInSet: 0,
    pagesPerSet: DEFAULT_PAGES_PER_SET,
    groupedDonations: {},
});

function findInsertIndex(order, donor, donors) {
    let index = -1;
    const amount = donation =>
        donation.amount *
        (donation.multiplier || 1) *
        (donation.recurringMonths || 1);

    if (order === SORT_BY.DONATION_AMOUNT_DESC) {
        index = donors.findIndex(
            donorItem => amount(donorItem) < amount(donor),
        );
    } else if (order === SORT_BY.DONATION_NAME_DESC) {
        index = donors.findIndex(donorItem => donorItem.name <= donor.name);
    } else if (order === SORT_BY.DONATION_NAME_ASC) {
        index = donors.findIndex(donorItem => donorItem.name >= donor.name);
    } else if (order === SORT_BY.CREATED_AT_DESC) {
        index = 0;
    }

    return index > -1 ? index : donors.length;
}

function insertDonor(state, donor) {
    const insertIndex = findInsertIndex(state.query.order, donor, state.donors);

    if (insertIndex > -1) {
        state.donors.splice(insertIndex, 0, donor);
    } else {
        state.donors.unshift(donor);
    }
}

function queryNextPage(state) {
    state.query.page = state.query.page ? state.query.page + 1 : 2;
    state.loading = 'pending';
}

function resetAutoLoad(state, resetPagesPerSet = true) {
    state.allowAutoLoad = true;
    state.pagesInSet = 0;

    if (resetPagesPerSet) {
        state.pagesPerSet = DEFAULT_PAGES_PER_SET;
    }
}

function createDonorsSlice(params = {}) {
    const {
        name = STATE_PREFIX,
        initialState = {},
        extraReducers = null,
        groupByLayerName = null,
    } = params;
    const selectDonorsList = state => state[name];
    const selectQuery = createSelector(
        selectDonorsList,
        donorsList => donorsList.query,
    );
    const selectDonors = createSelector(
        selectDonorsList,
        donorsList => donorsList.donors,
    );
    const selectGroupedDonations = createSelector(
        selectDonorsList,
        donorsList => donorsList.groupedDonations,
    );
    const selectIsLoading = createSelector(
        selectDonorsList,
        donorsList => donorsList.loading === 'pending',
    );
    const selectIsEndCollection = createSelector(
        selectDonorsList,
        ({ isEndCollection }) => isEndCollection,
    );
    const selectAllowAutoLoad = createSelector(
        selectDonorsList,
        ({ allowAutoLoad }) => allowAutoLoad,
    );
    const selectDonorsCount = createSelector(
        selectDonorsList,
        ({ total }) => total,
    );
    const selectScrollTop = createSelector(
        selectDonorsList,
        ({ scrollTop }) => scrollTop,
    );
    const fetchDonors = createAsyncThunk(
        `${name}/fetchDonors`,
        async (query, { getState }) => {
            const { id: campaignId = null } = selectSelectedCampaign(
                getState(),
            );

            if (!campaignId) {
                throw new Error(
                    'Campaign is not loaded, can not load donors list',
                );
            }

            const preparedQuery = {
                order: query.order
                    ? orderStringToStringifiedJson(query.order)
                    : null,
                name: query.searchText,
                page: query.page || 1,
                paginate: query.paginate,
                campaignId,
                layerItemId: query.layerItemId || null,
            };

            return (await api.donation.getPublicDonations(preparedQuery)).data;
        },
    );

    const slice = createSlice({
        name,
        initialState: {
            ...defaultInitialState,
            ...initialState,
        },
        reducers: {
            reset() {
                return {
                    ...defaultInitialState,
                    ...initialState,
                };
            },

            updateQuery(state, { payload }) {
                state.query = {
                    ...state.query,
                    ...payload,
                };

                resetAutoLoad(state, false);
            },

            resetDonors(state) {
                state.donors = [];

                resetAutoLoad(state, false);
            },

            nextPage(state, { payload: page }) {
                if (page) {
                    state.query.page = page;
                } else {
                    queryNextPage(state);
                }
            },

            autoLoad(state) {
                if (
                    !state.allowAutoLoad ||
                    state.isEndCollection ||
                    state.loading === 'pending'
                ) {
                    return;
                }

                queryNextPage(state);
            },

            repeatAutoLoad(state) {
                resetAutoLoad(state, false);
                state.pagesPerSet = Math.min(
                    state.pagesPerSet + 2,
                    MAX_PAGES_PER_SET,
                );
            },

            resetDonorsAfterLoad(state) {
                state.resetDonorsAfterLoad = true;
            },

            updateScrollTop(state, { payload }) {
                state.scrollTop = payload;
            },

            addDonor(state, { payload }) {
                const { donor } = payload;

                if (
                    state.donors.some(existDonor => existDonor.id === donor.id)
                ) {
                    console.warn(
                        `You tried to add a duplicate donor into donors collection, hence skipped.`,
                    );
                } else {
                    if (donor instanceof Array) {
                        state.donors = donor.concat(state.donors);
                    } else {
                        insertDonor(state, donor);
                        state.donors = state.donors.slice(
                            0,
                            (state.page || 1) * state.paginate,
                        );
                    }
                }
            },
            updateDonor(state, { payload }) {
                const donorIndex = state.donors.findIndex(
                    donor => donor.id === payload.id,
                );

                if (donorIndex > -1) {
                    state.donors[donorIndex] = {
                        ...state.donors[donorIndex],
                        ...payload,
                    };
                }
            },
            removeDonor(state, { payload }) {
                const { donor } = payload;
                const donorIndex = state.donors.findIndex(
                    existDonor => existDonor.id === donor.id,
                );
                if (donorIndex !== -1) {
                    state.donors.splice(donorIndex, 1);
                    state.groupedDonations = groupBy(
                        state.donors,
                        'donorPflSlug',
                    );
                }
            },

            increaseTotal(state, { payload = 1 }) {
                state.total = state.total + payload;
                state.pages = Math.ceil(state.total / state.query.paginate);
                state.isEndCollection = state.page >= state.pages;
            },
            updateTotal(state, { payload }) {
                state.total = Math.max(state.total, payload.donationsCount);
                state.pages = Math.ceil(state.total / state.query.paginate);
                state.isEndCollection = state.page >= state.pages;
            },
            decreaseTotal(state, { payload = 1 }) {
                state.total = state.total - payload;
                state.pages = Math.ceil(state.total / state.query.paginate);
                state.isEndCollection = state.page >= state.pages;
            },
            updateMatchedDonationsCount(state, { payload }) {
                const { id, count } = payload;
                const donorIndex = state.donors.findIndex(
                    donor => donor.id === id,
                );

                if (donorIndex > -1) {
                    state.donors[donorIndex] = {
                        ...state.donors[donorIndex],
                        matchedDonationsCount: count,
                    };
                }
            },
            updateDonationPflCountAndSlug(state, { payload }) {
                const { id, donorPflCount, donorPflSlug } = payload;
                const donorIndex = state.donors.findIndex(
                    donor => donor.id === id,
                );

                if (donorIndex > -1) {
                    state.donors[donorIndex] = {
                        ...state.donors[donorIndex],
                        donorPflCount,
                        donorPflSlug,
                    };
                    if (state.groupedDonations[donorPflSlug]?.length > 0) {
                        state.groupedDonations[donorPflSlug].push(
                            state.donors[donorIndex],
                        );
                    } else {
                        state.groupedDonations[donorPflSlug] = [
                            state.donors[donorIndex],
                        ];
                    }
                }
            },
        },
        extraReducers: builder => {
            builder.addCase(fetchDonors.fulfilled, (state, action) => {
                if (state.resetDonorsAfterLoad) {
                    state.donors = [];
                    state.resetDonorsAfterLoad = false;
                    resetAutoLoad(state);
                }
                state.donors = state.donors.concat(action.payload.docs);
                state.groupedDonations = groupBy(state.donors, 'donorPflSlug');
                state.page = action.payload.page;
                state.pages = action.payload.pages;
                state.paginate = action.payload.paginate;
                state.total = action.payload.total;
                state.loading = 'idle';
                state.isEndCollection =
                    action.payload.page >= action.payload.pages;
                state.pagesInSet += 1;
                state.allowAutoLoad = state.pagesInSet < state.pagesPerSet;
            });
            builder.addCase(fetchDonors.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error;
                console.error(action.error);
            });
            builder.addCase(fetchDonors.pending, state => {
                state.loading = 'pending';
                state.error = null;
            });

            if (typeof extraReducers === 'function') {
                extraReducers(builder);
            }
        },
    });

    slice.actions.fetchDonors = fetchDonors;
    slice.selectors = {
        selectQuery,
        selectDonors,
        selectGroupedDonations,
        selectIsLoading,
        selectIsEndCollection,
        selectAllowAutoLoad,
        selectDonorsCount,
        selectScrollTop,
    };

    return slice;
}

export default createDonorsSlice;
