import { createSlice, createSelector } from '@reduxjs/toolkit';

export const STATE_PREFIX = 'checkoutPopup';
const initialState = {
    isOpen: false,
    layerItem: null,
    donationForm: {
        paymentMethod: 'card',
        paymentMode: 'regular',
        layerItems: [],
    },
    showPEFTerms: false,
};

const checkoutPopup = createSlice({
    name: STATE_PREFIX,
    initialState,
    reducers: {
        setIsOpen(state, { payload }) {
            state.isOpen = payload;
        },
        open(state, { payload }) {
            const formHasNotLayerItem =
                state.donationForm.layerItems.findIndex(
                    item => item.value === String(payload.layerItem.id),
                ) === -1;

            state.isOpen = true;

            if (
                payload.layerItem?.layer?.allowDonations &&
                formHasNotLayerItem
            ) {
                state.layerItem = payload.layerItem;
                state.donationForm.layerItems.push({
                    value: String(payload.layerItem.id),
                    name: payload.layerItem.name,
                });
            }

            if (payload.paymentMethod) {
                state.donationForm.paymentMethod = payload.paymentMethod;
            }
        },
        updatePaymentMode(state, { payload }) {
            state.donationForm.paymentMode = payload;
        },
        updatePaymentMethod(state, { payload }) {
            state.donationForm.paymentMethod = payload;
        },
        resetCheckoutPopup() {
            return initialState;
        },
        togglePEFTerms(state, { payload }) {
            state.showPEFTerms = payload;
        },
    },
});

export const {
    setIsOpen,
    open,
    resetCheckoutPopup,
    updatePaymentMethod,
    updatePaymentMode,
    togglePEFTerms,
} = checkoutPopup.actions;

export const selectDonationPopup = state => state[STATE_PREFIX] || {};
export const selectIsOpen = createSelector(
    selectDonationPopup,
    popup => popup.isOpen,
);

export const selectDonationForm = createSelector(
    selectDonationPopup,
    popup => popup.donationForm,
);
export const selectPopupLayerItem = createSelector(
    selectDonationPopup,
    popup => popup.layerItem,
);
export const selectPaymentMethod = createSelector(
    selectDonationForm,
    ({ paymentMethod }) => paymentMethod,
);
export const selectShowPEFTerms = createSelector(
    selectDonationPopup,
    popup => popup.showPEFTerms,
);

export const selectPaymentMode = createSelector(
    selectDonationForm,
    ({ paymentMode }) => paymentMode,
);

export const selectDefaultPhonePrefix = createSelector(
    selectDonationForm,
    ({ phonePrefix }) => phonePrefix,
);

export default checkoutPopup.reducer;
