import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridGap: props => props.gap,
        gap: props => props.gap,
        gridTemplateColumns: props => `repeat(${props.columns}, 1fr)`,

        '@media (max-width: 1023px) and (min-width: 601px)': {
            gridTemplateColumns: props => {
                if (props.columns <= 2) {
                    return `repeat(${props.columns}, 1fr)`;
                }
                return 'repeat(2, 1fr) !important';
            },
        },
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr !important',
            gridGap: '15px !important',
            gap: '15px !important',
        },
    },
});

function ItemCardsGrid(props) {
    const { children, className, columns = 2, gap = '40px', listRef } = props;
    const classes = useStyles({ columns, gap });

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
            ref={listRef}
        >
            {children}
        </div>
    );
}

ItemCardsGrid.propTypes = {
    children: PropTypes.node,
    columns: PropTypes.number,
    gap: PropTypes.string,
    className: PropTypes.string,
    listRef: PropTypes.any,
};

export default ItemCardsGrid;
