import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { PlayCircleOutline as PlayCircleOutlineIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        color: '#fff',
        background: 'rgba(0,0,0, 0.2)',
        borderRadius: '50%',
        fontSize: '7rem',

        '@media (max-width: 600px)': {
            fontSize: '4rem',
        },
    },
}));

function PlayButton({ className, onClick }) {
    const classes = useStyles();

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <PlayCircleOutlineIcon
            fontSize="large"
            className={classNames(classes.root, className)}
            onClick={handleClick}
        />
    );
}

PlayButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default PlayButton;
