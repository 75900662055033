import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function FlagIcon({ width = 16, height = 16, color = '#FFF', ...props }) {
    return (
        <SvgIcon height={height} width={width} viewBox="0 0 16 16" {...props}>
            <g
                transform="matrix(1.048251,0,0,1,-0.7767155,-1.8523425)"
                stroke={color}
                fill={color}
            >
                <path
                    d="m 969.561,3034.706 2.428,-3.532 a 0.224,0.224 0 0 0 0.013,-0.235 0.229,0.229 0 0 0 -0.2,-0.125 h -6.866 v -0.914 a 0.229,0.229 0 0 0 -0.23,-0.23 h -4.8 v -0.456 a 0.23,0.23 0 1 0 -0.459,0 v 13.273 a 0.23,0.23 0 0 0 0.459,0 v -5.722 h 2.523 a 1.13,1.13 0 0 0 -0.235,0.687 1.147,1.147 0 0 0 1.145,1.145 h 8.461 a 0.23,0.23 0 0 0 0.2,-0.123 0.227,0.227 0 0 0 -0.013,-0.237 z m -9.66,-4.578 h 4.577 v 6.179 H 959.9 Z m 3.433,8.009 a 0.687,0.687 0 1 1 0,-1.373 h 1.37 a 0.227,0.227 0 0 0 0.23,-0.229 v -5.263 h 6.432 l -2.272,3.3 a 0.232,0.232 0 0 0 0,0.259 l 2.272,3.3 z m 0,0"
                    transform="translate(-957.365,-3025.983)"
                />
            </g>
        </SvgIcon>
    );
}

FlagIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default FlagIcon;
