import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';
import querystring from 'query-string';

class SignupPageRepository extends Repository {
    async getSignupPage(id) {
        try {
            const response = await this.axios.get(`/${id}`);
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async createSignupPage(values) {
        try {
            const response = await this.axios.post('/', values);
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async createSignupPageLanguage(signupPageId, language, values) {
        try {
            const response = await this.axios.post(
                `/${signupPageId}/${language}`,
                values,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async updateSignupPageLanguage(signupPageId, language, values) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}`,
                values,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async updateSignupPage(signupPageId, data) {
        try {
            const response = await this.axios.put(`/${signupPageId}`, data);
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async deleteSignupPageLanguage({ signupPageId, language }) {
        try {
            const response = await this.axios.delete(
                `/${signupPageId}/${language}`,
            );
            return response?.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async saveSettingsTab({ signupPageId, language, values }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/settings`,
                values,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async saveConfigTab({ signupPageId, language, values }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/config`,
                values,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async saveSeoTab({ signupPageId, language, values }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/seo`,
                values,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async saveCreativeTab({ signupPageId, language, values }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/creative`,
                values,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async saveDisplayTab({ signupPageId, language, values }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/display`,
                values[language],
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async updatePublishStatus({ signupPageId, language, isPublished }) {
        try {
            const response = await this.axios.put(
                `/${signupPageId}/${language}/${
                    !isPublished ? 'publish' : 'unpublish'
                }`,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async validateSlug(validationData) {
        const { data } = await this.axios.get(
            `/validate-slug?${querystring.stringify(validationData)}`,
        );
        return data;
    }
}

export default new SignupPageRepository({ baseUrl: '/signup-page' });
