import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../Text/Text';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        textAlign: 'center',
        margin: '0 15px',

        '@media (max-width: 600px)': {
            margin: '0 12px',
        },
    },
    value: {
        fontSize: '3.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: '2.125rem',
            lineHeight: 1.1,
        },
    },
    label: {
        fontSize: '1rem',
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
}));

function CountdownWidgetItem(props) {
    const { className, value = '00', label = '' } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>
            <Text className={classes.value}>{value}</Text>
            <Text className={classes.label}>
                <FormattedMessage
                    id={`CountdownWidgetItem.${label.toLowerCase()}`}
                    defaultMessage={`{value, plural, one {${label}} other {${
                        label + 's'
                    }}}`}
                    values={{ value }}
                />
            </Text>
        </div>
    );
}

CountdownWidgetItem.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
};

export default CountdownWidgetItem;
