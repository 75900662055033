import { useState } from 'react';

const IGNORE_MS = 3000;

function usePreventDecimal(onDecimal = null, timeout = IGNORE_MS) {
    const [ignoreChange, setIgnoreChange] = useState(false);

    const handleKeyDown = event => {
        const key = event.key;
        if (ignoreChange && /^[0-9]$/.test(key)) {
            event.preventDefault();
        } else if (!ignoreChange && /^(?:Decimal|\.)$/.test(key)) {
            setIgnoreChange(true);
            if (typeof onDecimal === 'function') {
                onDecimal(true);
            }

            setTimeout(() => {
                setIgnoreChange(false);
                if (typeof onDecimal === 'function') {
                    onDecimal(false);
                }
            }, timeout);
        }
    };

    return [handleKeyDown, ignoreChange];
}

export default usePreventDecimal;
