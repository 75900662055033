import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';
import FormTextarea from '../FormTextarea/FormTextarea';
import Checkbox from '../Checkbox/Checkbox';
import FormGroup from '../FormGroup/FormGroup';
import FormLabel from '../FormLabel/FormLabel';
import Radio from '../Radio/Radio';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as CalendarIcon } from '../FormInput/CalendarIcon.svg';
import useFormStyling from '../../hooks/formStyling';

const useStyles = makeStyles({
    datePicker: {
        '& .react-datepicker__triangle': {
            transform: 'translateX(-10px)',
            borderBottomColor: '#aeaeae !important',

            '&::before': {
                borderBottomColor: '#f0f0f0 !important',
                top: '1px !important',
            },
        },
        '& .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle':
            {
                borderTopColor: '#aeaeae !important',

                '&::before': {
                    top: 'auto !important',
                    bottom: '1px !important',
                    borderTopColor: '#fff !important',
                },
            },
    },
    inputGroup: {
        marginBottom: 0,
    },
});

const DatePickerComponent = ({ inputRef, ...props }) => {
    const ref = React.useRef();

    React.useImperativeHandle(inputRef, () => ({
        focus: () => {
            ref.current.input.focus();
        },
    }));

    return <DatePicker ref={ref} {...props} />;
};

const FormInputDatePicker = ({ inputRef, ...props }) => {
    const classes = useStyles();

    return (
        <FormInput
            component={DatePickerComponent}
            className={classes.datePicker}
            startIcon={<CalendarIcon />}
            ref={inputRef}
            {...props}
        />
    );
};

const DatePickerInput = ({ placeholder, required, name, control, ...rest }) => {
    const [date, setDate] = useState();
    const handleChangeDate = args => {
        if (args[0] instanceof Date) {
            setDate(args[0]);
            return args[0];
        }

        return '';
    };

    return (
        <Controller
            render={({ name, ref, value, onBlur, onChange }) => {
                return (
                    <FormInputDatePicker
                        name={name}
                        inputRef={ref}
                        onBlur={onBlur}
                        onChange={onChange}
                        selected={value}
                        placeholderText={placeholder}
                        {...rest}
                    />
                );
            }}
            rules={{ required }}
            required={required}
            control={control}
            name={name}
            selected={date}
            defaultValue={''}
            placeholderText={placeholder}
            onChange={handleChangeDate}
        />
    );
};
function CustomFormInput(props) {
    const {
        type,
        label,
        name,
        inputRef,
        options = [],
        required = false,
        prefix = 'customFields',
        control,
        formState,
        errors,
        getValues,
    } = props;

    const prefixed = name => `${prefix}.${name}`;
    const requiredLabel = label => `${required ? '* ' : ''}${label}`;
    const classes = useStyles();
    const [getFormClass, getFormIcon] = useFormStyling(
        formState.dirtyFields.customFields,
        errors.customFields || {},
        name => getValues(`customFields.${name}`),
    );

    switch (type) {
        case 'dropdown':
            return (
                <Controller
                    render={(
                        { name: prefName, ref, value, onBlur, onChange },
                        { isTouched },
                    ) => {
                        return (
                            <FormSelect
                                defaultSelectable
                                width="100%"
                                name={prefName}
                                inputRef={ref}
                                placeholder={requiredLabel(label)}
                                options={options.map(({ label }) => ({
                                    name: label,
                                    value: label,
                                }))}
                                onChange={event => onChange(event.target.value)}
                                onBlur={onBlur}
                                value={value}
                                className={getFormClass(name, { isTouched })}
                                endIcon={getFormIcon(name, { isTouched })}
                            />
                        );
                    }}
                    rules={{ required }}
                    control={control}
                    name={prefixed(name)}
                    defaultValue={''}
                />
            );
        case 'textarea':
            return (
                <FormTextarea
                    name={prefixed(name)}
                    inputRef={inputRef}
                    placeholder={requiredLabel(label)}
                    className={getFormClass(name)}
                    endIcon={getFormIcon(name)}
                />
            );
        case 'checkboxGroup':
            return (
                <FormGroup className={classes.inputGroup}>
                    <FormLabel>{requiredLabel(label)}</FormLabel>
                    {options.map((option, index) => (
                        <Checkbox
                            name={prefixed(name)}
                            value={option.label}
                            inputRef={inputRef}
                            key={index}
                        >
                            {option.label}
                        </Checkbox>
                    ))}
                </FormGroup>
            );
        case 'checkbox':
            return (
                <>
                    <Checkbox
                        name={prefixed(name)}
                        inputRef={inputRef}
                        defaultChecked={false}
                        value="TRUE"
                    >
                        {requiredLabel(label)}
                    </Checkbox>
                </>
            );
        case 'radioGroup':
            return (
                <FormGroup className={classes.inputGroup}>
                    <FormLabel>{requiredLabel(label)}</FormLabel>
                    {options.map((option, index) => (
                        <Radio
                            name={prefixed(name)}
                            value={option.label}
                            inputRef={inputRef}
                            key={index}
                        >
                            {option.label}
                        </Radio>
                    ))}
                </FormGroup>
            );
        case 'date':
            return (
                <DatePickerInput
                    label={requiredLabel(label)}
                    placeholder={requiredLabel(label)}
                    name={prefixed(name)}
                    control={control}
                    className={getFormClass(name)}
                    endIcon={getFormIcon(name)}
                />
            );
        default:
            return (
                <FormInput
                    type={type}
                    label={requiredLabel(label)}
                    placeholder={requiredLabel(label)}
                    name={prefixed(name)}
                    ref={inputRef}
                    className={getFormClass(name)}
                    endIcon={getFormIcon(name)}
                />
            );
    }
}

CustomFormInput.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    required: PropTypes.bool,
    inputRef: PropTypes.func,
    prefix: PropTypes.string,
    control: PropTypes.object.isRequired,
};
DatePickerInput.propTypes = {
    ...CustomFormInput.propTypes,
};
FormInputDatePicker.propTypes = {
    inputRef: PropTypes.func,
};
DatePickerComponent.propTypes = {
    ...FormInputDatePicker.propTypes,
};

export default CustomFormInput;
