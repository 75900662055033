import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        margin: '30px 0',

        '@media (max-width: 600px)': {
            margin: '20px 0',
        },
    },
    label: {
        marginBottom: 25,
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1.188rem',
        fontWeight: 'bold',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            marginBottom: 20,
            fontSize: '1.063rem',
        },
    },
    list: {
        paddingLeft: 23,

        '@media (max-width: 600px)': {
            paddingLeft: 0,
        },
    },
    item: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        margin: '0 0 10px',
    },
    name: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1rem',
        fontWeight: 'normal',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
            paddingTop: 4,
        },
    },
    amount: {
        flexShrink: 0,
        marginLeft: 'auto',
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1.188rem',
        fontWeight: 500,
        color: palette.primary.main,
    },
    payment: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '0.938rem',
        color: palette.primary.main,
        fontWeight: '400',

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
}));

function SelectedDonations(props) {
    const { formatMessage } = useIntl();
    const {
        className,
        label = formatMessage({
            id: 'DonationForm.teamSelected',
        }),
        items = [],
        currencySign = '$',
        isUnlimitedRecurringDonation = false,
    } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.label}>{label}</div>
            <div className={classes.list}>
                {items.map((item, index) => (
                    <dl className={classes.item} key={index}>
                        <dt className={classes.name}>
                            {formatMessage(
                                {
                                    id: 'DonationForm.throughTeam',
                                },
                                { teamName: item?.name },
                            )}
                        </dt>
                        <dd className={classes.amount}>
                            {currencySign}
                            {item?.amount}
                            {isUnlimitedRecurringDonation && '*'}
                        </dd>
                    </dl>
                ))}
                {isUnlimitedRecurringDonation && (
                    <dl className={classes.item}>
                        <dt className={classes.name}></dt>
                        <dd className={classes.amount}>
                            <div className={classes.payment}>
                                *
                                {formatMessage({
                                    id: 'DonationForm.calculatedOver12months',
                                })}
                            </div>
                        </dd>
                    </dl>
                )}
            </div>
        </div>
    );
}

SelectedDonations.propTypes = {
    label: PropTypes.string,
    itemLabel: PropTypes.string,
    items: PropTypes.array,
    className: PropTypes.string,
    currencySign: PropTypes.string,
    isUnlimitedRecurringDonation: PropTypes.bool,
};

export default SelectedDonations;
