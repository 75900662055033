import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const NAME = 'heroSlider';

export const VIDEO_PLAYER_STATE = Object.freeze({
    LOAD: 'load',
    PLAYING: 'playing',
    STOPPED: 'stopped',
});

const DEFAULT_STATE = {
    currentSlide: 0,
    height: 0,
    images: null,
    isVideoVisible: false,
    isVideoPlaying: false,
    videoPlayerState: VIDEO_PLAYER_STATE.LOAD,
};

const heroSliderSlice = createSlice({
    name: NAME,
    initialState: {
        ...DEFAULT_STATE,
    },
    reducers: {
        setupHeroSlider: (state, { payload: { isVideoAutoPlay } }) => {
            return {
                ...DEFAULT_STATE,
                isVideoVisible: isVideoAutoPlay || DEFAULT_STATE.isVideoVisible,
            };
        },

        resetHeroSlider: (state, { payload: { isVideoAutoPlay, images } }) => {
            return {
                ...DEFAULT_STATE,
                height: state.height,
                images: images || null,
                isVideoVisible: isVideoAutoPlay || DEFAULT_STATE.isVideoVisible,
            };
        },

        nextSlide: state => {
            state.currentSlide = state.currentSlide + 1;
        },

        updateSlide: (state, { payload }) => {
            if (payload !== state.currentSlide) {
                state.currentSlide = payload;
            }
            state.isVideoVisible = false;
            state.isVideoPlaying = false;
            state.videoPlayerState = VIDEO_PLAYER_STATE.STOPPED;
        },

        setHeight: (state, { payload }) => {
            state.height = payload;
        },

        setImages: (state, { payload }) => {
            state.images = payload;
        },

        startVideo: state => {
            state.isVideoVisible = true;
            state.isVideoPlaying = true;
        },

        playVideo: state => {
            state.isVideoPlaying = true;
            state.videoPlayerState = VIDEO_PLAYER_STATE.PLAYING;
        },

        pauseVideo: state => {
            state.isVideoPlaying = false;
            state.videoPlayerState = VIDEO_PLAYER_STATE.STOPPED;
        },

        endVideo: state => {
            state.isVideoVisible = false;
            state.isVideoPlaying = false;
            state.videoPlayerState = VIDEO_PLAYER_STATE.STOPPED;
        },
    },
});

export const {
    setupHeroSlider,
    resetHeroSlider,
    setHeight,
    setImages,
    nextSlide,
    updateSlide,
    startVideo,
    playVideo,
    pauseVideo,
    endVideo,
} = heroSliderSlice.actions;

export const selectHeroSlider = state => get(state, ['ui', NAME], {});

export default heroSliderSlice.reducer;
