import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import scrollTo from 'client/helpers/scrollTo';

const useStyles = makeStyles({
    root: {
        margin: '30px 0 30px',
        display: 'flex',
        padding: '1em',
        color: '#ffffff',
        borderRadius: '5px',
        background: 'rgb(68, 115, 230)',
    },
});

function PaymentAlert(props) {
    const { alert } = props;
    const classes = useStyles();
    const alertRef = useRef();

    useEffect(() => {
        if (!alert) {
            return;
        }

        setTimeout(() => alertRef?.current && scrollTo(alertRef.current), 300);
    }, [alert]);

    return alert ? (
        <div ref={alertRef} className={classes.root}>
            <span>{alert}</span>
        </div>
    ) : null;
}

PaymentAlert.propTypes = {
    alert: PropTypes.string,
};

export default PaymentAlert;
