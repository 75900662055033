const SIGNUP_PAGE_TYPES = Object.freeze({
    LAYER: 'Layer',
    EVENT: 'Event',
});

const SIGNUP_PAGE_TYPE_VALUES = Object.freeze(
    Object.keys(SIGNUP_PAGE_TYPES).reduce((types, key) => {
        types.push(SIGNUP_PAGE_TYPES[key]);
        return types;
    }, []),
);

module.exports = {
    SIGNUP_PAGE_TYPES,
    SIGNUP_PAGE_TYPE_VALUES,
};
