const PAGEBUILDER_PAGE_TYPES = Object.freeze({
    SIGNUP: 'sign-up',
    DONATE_PAGE: 'donate',
});

const PAGEBUILDER_PAGE_TYPE_VALUES = Object.freeze(
    Object.values(PAGEBUILDER_PAGE_TYPES),
);

module.exports = {
    PAGEBUILDER_PAGE_TYPES,
    PAGEBUILDER_PAGE_TYPE_VALUES,
};
