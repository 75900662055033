import React from 'react';
import PropTypes from 'prop-types';

function MultiplierIcon({
    width = 44.63,
    fill = '#0028ff',
    multiplier = 1,
    ...props
}) {
    const rootStyles = {
        direction: 'ltr',
    };
    const styles = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 1.5,
        color: fill,
        stroke: 'currentColor',
    };
    const textStyles = {
        fill,
        fontSize: '16.776px',
        fontFamily: 'Heebo, sans-serif',
        fontWeight: 'bold',
    };

    const viewBoxWidth = Number(multiplier) === 1.5 ? '58.63' : '44.63';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            viewBox={`0 0 ${viewBoxWidth} 52.77`}
            {...props}
        >
            <g transform="translate(1.05 0.645)" style={rootStyles}>
                <path
                    fill="none"
                    style={styles}
                    d="M370.7,924.487A25.533,25.533,0,0,0,387.893,967.6"
                    transform="translate(-363.652 -916.576)"
                />
                <line
                    fill="none"
                    style={styles}
                    transform="translate(24.918)"
                />
                <path
                    d="M383.6,917.183a25.431,25.431,0,0,0-11.082,5.577"
                    transform="translate(-363.652 -916.576)"
                    fill="none"
                    style={{ strokeDasharray: '0 5.012', ...styles }}
                />
                <line
                    fill="none"
                    style={styles}
                    transform="translate(7.049 7.91)"
                />
                <path
                    fill="none"
                    style={styles}
                    d="M390.84,922.705a19.467,19.467,0,1,0-3.18,38.8"
                    transform="translate(-363.652 -916.576)"
                />
                <path
                    fill="none"
                    d="M387.893,920.637l3.034,2.022-3.034,2.528"
                    transform="translate(-363.652 -916.576)"
                    style={styles}
                />
                <line
                    fill="none"
                    style={styles}
                    x2="8.41"
                    y2="9.461"
                    transform="translate(20.968 20.555)"
                />
                <text transform="translate(33.58 30.664)" style={textStyles}>
                    <tspan x="0" y="0">
                        {multiplier}
                    </tspan>
                </text>
                <line
                    fill="none"
                    style={styles}
                    x1="8.41"
                    y2="9.461"
                    transform="translate(20.967 20.555)"
                />
            </g>
        </svg>
    );
}

MultiplierIcon.propTypes = {
    multiplier: PropTypes.number,
    fill: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MultiplierIcon;
