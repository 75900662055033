import Repository from './Repository';

const repository = new Repository({ baseUrl: '/giving' });

repository.moveUp = async function (id) {
    return await this.axios.post(`/${id}/move-up`).then(res => res.data);
};

repository.moveDown = async function (id) {
    return await this.axios.post(`/${id}/move-down`).then(res => res.data);
};

repository.hiddenRecurring = async function (data) {
    return await this.axios
        .post(`/hidden-recurring`, data)
        .then(res => res.data);
};

repository.hiddenByCurrency = async function (data) {
    return await this.axios
        .post(`/hidden-by-currency`, data)
        .then(res => res.data);
};

export default repository;
