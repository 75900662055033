import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faFacebook,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import Link from '../Link/Link';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ spacing, palette }) => ({
        root: {
            flexBasis: 130,
            marginInlineEnd: '80px',
            width: 160,
        },

        logo: {
            marginTop: -18,

            '@media (max-width: 600px)': {
                display: 'none',
            },
        },

        socialLinks: {
            display: 'flex',
            margin: '10px 0 0 2px',
            gap: 20,
            '@media (max-width: 600px)': {
                gap: spacing(1),
            },
        },

        link: {
            fontSize: 20,
            color: palette.text.primary,
            '@media (max-width: 600px)': {
                marginInlineEnd: spacing(2),
            },
        },

        facebookLink: {
            '&:hover': {
                color: palette.brands.facebook,
            },
        },

        linkedinLink: {
            '&:hover': {
                color: palette.brands.linkedin,
            },
        },

        twitterLink: {
            '&:hover': {
                color: palette.brands.twitter,
            },
        },
    }),
    { name: 'FooterSocialLinks' },
);

function FooterSocialLinks({ lang }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <a
                href={`https://www.causematch.com/${
                    lang == 'he' ? lang + '/' : ''
                }?ref=cm2`}
                target="_blank"
                rel="noreferrer"
            >
                <HeaderLogo className={classes.logo} lang={lang} />
            </a>
            <div className={classes.socialLinks}>
                <Link
                    className={classNames(classes.link, classes.facebookLink)}
                    href="https://www.facebook.com/CauseMatchPage"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </Link>
                <Link
                    className={classNames(classes.link, classes.linkedinLink)}
                    href="https://www.linkedin.com/company/the-cause-match"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link
                    className={classNames(classes.link, classes.twitterLink)}
                    href="https://twitter.com/thecausematch"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </Link>
            </div>
        </div>
    );
}

FooterSocialLinks.propTypes = {
    lang: PropTypes.string,
};
export default FooterSocialLinks;
