import React from 'react';
import IField, { CARD_TYPE, CVV_TYPE } from '@cardknox/react-ifields';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { ReactComponent as CardIcon } from 'client/components/CampaignPage/components/FormInput/CardIcon.svg';
import { ReactComponent as LockIcon } from 'client/components/CampaignPage/components/FormInput/LockIcon.svg';
import SecurePayments from '../../components/SecurePayments/SecurePayments';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import { PAYMENT_METHODS } from 'common/constants';

const VALIDATION_INFO = Object.freeze({
    cardValidation: {
        name: 'cardknoxCardNumber',
        errorMessage: {
            id: 'DonationForm.cardNumber.invalid',
            defaultMessage: 'Card number is invalid',
        },
    },
    cvvValidation: {
        name: 'cardknoxCvc',
        errorMessage: {
            id: 'DonationForm.cvv.invalid',
            defaultMessage: 'Card CVV is invalid',
        },
    },
    expValidation: {
        name: 'cardknoxExpire',
        errorMessage: {
            id: 'DonationForm.expire.invalid',
            defaultMessage: 'Card expiration date is invalid',
        },
    },
});

const VALIDATION = Object.freeze(Object.keys(VALIDATION_INFO));

function getDirtyFields(state, prevDirtyFields = {}) {
    const dirtyFields = {};

    VALIDATION.forEach(validation => {
        if (state[validation] === 'valid') {
            dirtyFields[VALIDATION_INFO[validation].name] = true;
        }
    });

    return { ...prevDirtyFields, ...dirtyFields };
}

function getErrors(state, prevErrors = {}, formatMessage) {
    const errors = {};

    VALIDATION.forEach(validation => {
        if (state[validation] === 'invalid') {
            const { name, errorMessage } = VALIDATION_INFO[validation];
            errors[name] = { message: formatMessage(errorMessage) };
        }
    });

    return { ...prevErrors, ...errors };
}

const useStyles = theme => ({
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    empty: {
        border: '1px solid #ccc',
    },
    cardknoxExpiryInput: {
        color: 'rgb(153, 153, 153)',
        width: '100%',
        border: '0px',
        margin: '0px',
        display: 'block',
        fontSize: '16px',
        background: 'none',
        boxSizing: 'border-box',
        fontFamily: 'Heebo, sans-serif',
        outline: 'none',
        height: '100%',
        padding: '16px 20px',
        '@media (max-width: 600px)': {
            padding: '16px 0px 16px 10px',
            'html[dir=rtl] &': {
                padding: '16px 10px 16px 0px',
            },
        },
    },
});

const useStylesfn = makeStyles({
    cardContainer: {
        height: '50px',
        padding: '0px 80px',
        '@media (max-width: 600px)': {
            padding: '0px 0px 0px 65px',
            'html[dir=rtl] &': {
                padding: '0px 65px 0px 0px',
            },
        },
    },
    expiryContainer: {
        height: '50px',
    },
});

const cardNumberDiv = props => {
    const classes = useStylesfn();
    return (
        <div className={classes.cardContainer} id={'card-number-container'}>
            {props.children}
        </div>
    );
};

const cardExpiryDiv = props => {
    const classes = useStylesfn();
    return (
        <div className={classes.expiryContainer} id={'card-expiry-container'}>
            {props.children}
        </div>
    );
};

const cardCvcDiv = props => {
    const classes = useStylesfn();
    return (
        <div className={classes.cardContainer} id={'card-cvc-container'}>
            {props.children}
        </div>
    );
};

const CardNumber = withController(withFormInput(cardNumberDiv));
const CardExpiry = withController(withFormInput(cardExpiryDiv));
const CardCvc = withController(withFormInput(cardCvcDiv));
class CardknoxCardInfo extends React.Component {
    constructor(props) {
        super(props);
        const account = {
            xKey: props.iFieldskey,
            xSoftwareName: 'causematch',
            xSoftwareVersion: '1.0',
        };
        const threeds = {
            enable3DS: false,
            environment: 'production',
        };
        const ccoptions = {
            placeholder: 'Card Number',
            enableLogging: false,
            autoFormat: true,
            autoFormatSeparator: ' ',
            blockNonNumericInput: true,
            autoSubmit: false,
            iFieldstyle: {
                color: '#999',
                width: '100%',
                border: 0,
                margin: 0,
                display: 'block',
                fontSize: '16px',
                background: 'none',
                boxSizing: 'border-box',
                fontFamily: 'Heebo, sans-serif',
                padding: '16px 0px',
                outline: 'none',
                '&::placeholder': {
                    color: '#B3B3B3',
                },
            },
            iFrameStyle: {
                width: '100%',
                height: '100%',
                border: '0px',
            },
            iFrameClassName: 'ifieldiframe',
        };
        const cvvoptions = {
            placeholder: 'CVV',
            enableLogging: false,
            autoSubmit: false,
            iFieldstyle: {
                color: '#999',
                width: '100%',
                border: 0,
                margin: 0,
                display: 'block',
                fontSize: '16px',
                background: 'none',
                boxSizing: 'border-box',
                fontFamily: 'Heebo, sans-serif',
                padding: '16px 0px',
                outline: 'none',
                '&::placeholder': {
                    color: '#B3B3B3',
                },
            },
            iFrameStyle: {
                width: '100%',
                height: '100%',
                border: '0px',
            },
            iFrameClassName: 'ifieldiframe',
        };
        this.cardIfieldRef = React.createRef();
        this.cvvIfieldRef = React.createRef();
        this.state = {
            account,
            threeds,
            ccoptions,
            cvvoptions,
            issuer: '',
            cardValidation: 'empty',
            cvvValidation: 'empty',
            expValidation: 'empty',
            cardExp: '',
        };
    }
    render() {
        const {
            classes,
            intl: { formatMessage },
            paymentMethod,
        } = this.props;
        const dirtyFields = getDirtyFields(this.state);
        const errors = getErrors(this.state, this.props.errors, formatMessage);
        return (
            <>
                <FormGroup inline className={classes.fields}>
                    <CardNumber
                        type="text"
                        name="cardknoxCardNumber"
                        placeholder="* Card Number"
                        required
                        startIcon={<CardIcon />}
                        control={this.props.control}
                        rules={this.props.rules.cc}
                        dirtyFields={dirtyFields}
                        errors={errors}
                        defaultValue=""
                        className={classes[this.state.cardValidation]}
                    >
                        <IField
                            type={CARD_TYPE}
                            account={this.state.account}
                            options={this.state.ccoptions}
                            threeDS={this.state.threeds}
                            onLoad={this.onCardLoad}
                            onUpdate={this.onCardUpdate}
                            onSubmit={this.onSubmit}
                            onToken={this.onToken}
                            onError={this.onError}
                            ref={this.cardIfieldRef}
                        />
                    </CardNumber>
                </FormGroup>
                <FormGroup
                    inline
                    gap={19}
                    label={this.props.validityLabel}
                    className={classes.fields}
                >
                    <CardExpiry
                        placeholder="* Expiry date"
                        name="cardknoxExpire"
                        width="100%"
                        required
                        control={this.props.control}
                        rules={this.props.rules.expire}
                        dirtyFields={dirtyFields}
                        errors={errors}
                        defaultValue=""
                        className={classes[this.state.expValidation]}
                    >
                        <input
                            className={classes.cardknoxExpiryInput}
                            type="text"
                            pattern="\d*"
                            maxLength="4"
                            name="cardknoxExpiry"
                            error={this.props.errors.expire}
                            inputMode="numeric"
                            autoComplete="cc-exp"
                            placeholder="MMYY"
                            onChange={this.handleExpiryChange}
                        />
                    </CardExpiry>
                    {paymentMethod !== PAYMENT_METHODS.MATBIA && (
                        <CardCvc
                            type="text"
                            name="cardknoxCvc"
                            maxLength={4}
                            placeholder="* CVC"
                            required
                            startIcon={<LockIcon />}
                            control={this.props.control}
                            rules={this.props.rules.cvc}
                            dirtyFields={dirtyFields}
                            errors={errors}
                            defaultValue=""
                            className={classes[this.state.cvvValidation]}
                        >
                            <IField
                                type={CVV_TYPE}
                                account={this.state.account}
                                options={this.state.cvvoptions}
                                threeDS={this.state.threeds}
                                issuer={this.state.issuer}
                                onLoad={this.onCvvLoad}
                                onUpdate={this.onCvvUpdate}
                                onSubmit={this.onSubmit}
                                onToken={this.onToken}
                                onError={this.onError}
                                ref={this.cvvIfieldRef}
                            />
                        </CardCvc>
                    )}
                </FormGroup>
                <FormGroup>
                    <SecurePayments />
                </FormGroup>
            </>
        );
    }
    handleExpiryChange = event => {
        this.setState({ cardExp: event.target.value });
        if (event.target.value == '') {
            this.props.setCardknoxExp(null);
            this.props.setCardknoxExpState('empty');
            this.setState({ expValidation: 'empty' });
        } else {
            this.props.setCardknoxExp(event.target.value);
            const regex = /^(0[1-9]|1[0-2])([0-9]{2})$/;
            let m;
            if ((m = regex.exec(event.target.value)) !== null) {
                if (m[2]) {
                    this.props.setCardknoxExpState('valid');
                    this.setState({ expValidation: 'valid' });
                } else {
                    this.props.setCardknoxExpState('invalid');
                    this.setState({ expValidation: 'invalid' });
                }
            } else {
                this.props.setCardknoxExpState('invalid');
                this.setState({ expValidation: 'invalid' });
            }
        }
    };
    onCardLoad = () => {
        this.props.setCardknoxCardState('empty');
        this.props.setCardknoxExpState('empty');
    };
    onCvvLoad = () => {
        this.props.setCardknoxCvvState('empty');
    };
    onCardUpdate = data => {
        if (data.ifieldValueChanged) {
            this.props.setCardknoxCardToken(null);
            this.setState({ issuer: data.issuer });
            if (data.isValid) {
                this.props.setCardknoxCardState('valid');
                this.setState({ cardValidation: 'valid' });
                this.cardIfieldRef.current.getToken();
            } else {
                if (data.isEmpty) {
                    this.props.setCardknoxCardState('empty');
                    this.setState({ cardValidation: 'empty' });
                } else {
                    this.props.setCardknoxCardState('invalid');
                    this.setState({ cardValidation: 'invalid' });
                }
            }
        }
    };
    onCvvUpdate = data => {
        if (data.ifieldValueChanged || data.event === 'issuerupdated') {
            this.props.setCardknoxCvvToken(null);
            if (data.isValid) {
                this.props.setCardknoxCvvState('valid');
                this.setState({ cvvValidation: 'valid' });
                this.cvvIfieldRef.current.getToken();
            } else {
                if (data.isEmpty) {
                    this.props.setCardknoxCvvState('empty');
                    this.setState({ cvvValidation: 'empty' });
                } else {
                    this.props.setCardknoxCvvState('invalid');
                    this.setState({ cvvValidation: 'invalid' });
                }
            }
        }
    };
    onSubmit = data => {};
    onToken = data => {
        if (data.xTokenType == 'card') {
            if (this.state.cardValidation === 'valid') {
                this.props.setCardknoxCardToken(data.xToken);
            }
        } else if (data.xTokenType == 'cvv') {
            if (this.state.cvvValidation === 'valid') {
                this.props.setCardknoxCvvToken(data.xToken);
            }
        }
    };
    onError = data => {
        console.error('CN IFrame errored', data);
        if (data.xTokenType == 'card') {
            this.props.setCardknoxCardState('error');
        } else if (data.xTokenType == 'cvv') {
            this.props.setCardknoxCvvState('error');
        }
    };
}

export default withStyles(useStyles)(injectIntl(CardknoxCardInfo));
