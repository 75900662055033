import * as React from 'react';

function SvgStar(props) {
    return (
        <svg width={8} height={8} viewBox="0 0 7 7" {...props}>
            <defs>
                <clipPath id="clip-path">
                    <rect width={8} height={8} fill="none" />
                </clipPath>
            </defs>
            <g
                id="Repeat_Grid_6"
                data-name="Repeat Grid 6"
                clipPath="url(#clip-path)"
            >
                <g transform="translate(-267.777 -1139.959)">
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M271.5,1346.959l1.15,2.331,2.573.374-1.862,1.814.44,2.562-2.3-1.209-2.3,1.209.44-2.562-1.862-1.814,2.573-.374Z"
                        transform="translate(0 -207)"
                        fill="#b3b3b3"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgStar;
