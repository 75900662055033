import { isEmpty } from 'lodash';

const GTM_EVENTS = Object.freeze({
    TRACK_INIT: 'trackinit',
    DASH_RENDER: 'dashboardrender',
    PURCHASE: 'purchase',
    CLOSE_CHECKOUT: 'close_checkout',
    PLAY_VIDEO: 'play_video',
    CLICK_SHARE: 'click_share',
    CLICK_DONATE: 'click_donate',
    OPEN_CHECKOUT: 'open_checkout',
    CLICK_TAB: 'click_tab',
});

const triggerGtmEvent = (event = null, eventData = {}) => {
    window.dataLayer = window.dataLayer || [];
    if (event) {
        window.dataLayer.push({
            event: event,
            ...eventData,
        });
    } else if (!isEmpty(eventData)) {
        window.dataLayer.push({
            ...eventData,
        });
    }
};

export { GTM_EVENTS, triggerGtmEvent };
