import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../Text/Text';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {
            fontWeight: 600,
            position: 'relative',
            color: palette.text.primary,
            fontSize: 15,
            lineHeight: '18px',
            paddingBottom: 7,
            marginBottom: 10,

            '@media (max-width: 600px)': {
                padding: '6px 0 0',
                fontSize: 13,
                marginBottom: 5,
            },
        },

        underline: {
            position: 'absolute',
            width: 32,
            height: 2,
            backgroundColor: palette.secondary.main,
            bottom: 0,
            left: 0,

            'html[dir=rtl] &': {
                left: 'auto',
                right: 0,
            },

            '@media (max-width: 600px)': {
                width: 8,
                height: 1,
                top: 0,
                bottom: 'auto',
            },
        },
    }),
    { name: 'FooterLinkGroupHeader' },
);

function FooterLinkGroupHeader(props) {
    const { className, children, ...rest } = props;
    const classes = useStyles();

    return (
        <Text className={classNames(classes.root, className)} {...rest}>
            {children}
            <div className={classes.underline}></div>
        </Text>
    );
}

FooterLinkGroupHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default FooterLinkGroupHeader;
