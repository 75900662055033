import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from '@material-ui/core';
import Checkbox from 'client/components/CampaignPage/components/Checkbox/Checkbox';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import FormInputHelperText from 'client/components/CampaignPage/components/FormInputHelperText/FormInputHelperText';
import { selectFiscalSponsorConfig } from './checkoutSlice';
import { selectShowPEFTerms, togglePEFTerms } from './checkoutPopupSlice';
import { FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES } from 'common/constants';

const FLOWING_LANGS = ['he', 'yi'];

const useStyles = makeStyles(theme => ({
    checkbox: {
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 18,
            height: 18,
            border: '1px solid #ccc',
            borderRadius: 2,
            background: '#fff',
            left: 0,
            top: 3,
            zIndex: 1,
            cursor: 'pointer',

            'html[dir=rtl] &': {
                left: 'auto',
                right: 0,
            },
        },

        '& svg': {
            width: 10,
            height: 11.75,
        },
    },

    error: {
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 18,
            height: 18,
            border: '1px solid red',
            borderRadius: 2,
            background: '#fff',
            left: 0,
            top: 3,
            zIndex: 1,
            cursor: 'pointer',
        },
    },

    toggle: {
        fontWeight: 900,
        cursor: 'pointer',
    },

    dialog: {
        backgroundColor: '#fbfbfb',
        color: '#070707',
        maxWidth: '45rem',
        maxHeight: 'calc(100% - 6rem)',
    },

    terms: {
        textAlign: 'justify',
        direction: 'ltr',
        paddingLeft: 24,
        paddingRight: 24,

        '& li': {
            margin: '12px auto',
        },
    },

    text: {
        color: '#070707',
        fontFamily: 'Heebo, sans-serif',
    },

    link: {
        color: theme.palette.primary.main,
        fontWeight: 800,
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function PEFTerms() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { locale } = useIntl();
    const { register, errors } = useFormContext();
    const { enableFiscalSponsor, fiscalSponsor } = useSelector(
        selectFiscalSponsorConfig,
    );
    const showPEFTerms = useSelector(selectShowPEFTerms);

    const isFlowing = FLOWING_LANGS.includes(locale);
    const error = get(errors, 'pefTermsCheckbox', null);

    const handleOpenTerms = event => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(togglePEFTerms(true));
    };

    const handleCloseTerms = () => {
        dispatch(togglePEFTerms(false));
    };

    if (
        !enableFiscalSponsor ||
        fiscalSponsor !== FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES.PEF
    ) {
        return null;
    }

    return (
        <FormGroup>
            <Checkbox
                name="pefTermsCheckbox"
                inputRef={register}
                defaultChecked={false}
                className={classNames(
                    classes.checkbox,
                    error ? classes.error : null,
                )}
            >
                <FormattedMessage
                    id="DonationForm.pefTerms.agreement"
                    defaultMessage="Click here to indicate that you have read and agree to the terms of the"
                />
                {isFlowing ? '' : ' '}
                <a className={classes.toggle} onClick={handleOpenTerms}>
                    <FormattedMessage
                        id="DonationForm.pefTerms.title"
                        defaultMessage="PEF Terms & Conditions"
                    />
                </a>
                .
            </Checkbox>
            <FormInputHelperText error={error} />
            <Dialog
                open={showPEFTerms}
                onClose={handleCloseTerms}
                scroll={'paper'}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle>
                    <Typography variant="h4" classes={{ root: classes.text }}>
                        <FormattedMessage
                            id="DonationForm.pefTerms.title"
                            defaultMessage="PEF Terms & Conditions"
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.terms}>
                        <Typography
                            paragraph={true}
                            classes={{ root: classes.text }}
                        >
                            Please note that you are about to make an online
                            tax-deductible donation to PEF Israel Endowment
                            funds, Inc. (PEF) with your recommendation. While
                            PEF does not charge for processing your
                            contribution, third-party fees such as credit card
                            and platform fees may apply for online
                            contributions. In addition, please see our standard
                            terms and conditions below:
                        </Typography>
                        <Typography
                            paragraph={true}
                            classes={{ root: classes.text }}
                        >
                            By making a donation to PEF, the donor thereby
                            agrees and acknowledges as follows:
                        </Typography>
                        <ol>
                            <li>
                                <Typography classes={{ root: classes.text }}>
                                    PEF has the sole and absolute discretion to
                                    use the donated funds as it sees fit in
                                    accordance with its tax-exempt charitable
                                    purpose. Although PEF considers a donor’s
                                    advice with respect to grant
                                    recommendations, PEF at all times retains
                                    the sole and absolute discretion over the
                                    use and disbursement of all donated funds.
                                    PEF, not the donor, will be the legal
                                    grantor of any funds granted in accordance
                                    with a donor’s recommendation.
                                </Typography>
                            </li>
                            <li>
                                <Typography classes={{ root: classes.text }}>
                                    To the extent a donor wishes to make a grant
                                    recommendation and PEF is willing to
                                    consider the recommendation under the
                                    circumstances, the donor represents that (a)
                                    such recommendation (i) will be solely for
                                    tax-exempt purposes described in Section
                                    170(c)(2)(B) of the Internal Revenue Code of
                                    1986, as amended, and not for lobbying
                                    (including any direct or grassroots lobbying
                                    communications that support or oppose
                                    specific legislative proposals) and (ii)
                                    will not be for the benefit of an
                                    organization that is owned, controlled or
                                    managed by, and will not be for or redound
                                    to the benefit of, any persons listed on the
                                    Specifically Designated Nationals and other
                                    blocked persons list maintained by the U.S.
                                    Department of Treasury, Office of Foreign
                                    Asset Control (“OFAC”) or organized or
                                    resident in a country or region subject to
                                    comprehensive U.S sanctions, and (b) neither
                                    the donor nor any of its affiliates, nor to
                                    the knowledge of the donor, any director,
                                    officer, agent, employee or affiliate of the
                                    donor, is currently subject to any U.S.
                                    sanctions administered by OFAC.
                                </Typography>
                            </li>
                            <li>
                                <Typography classes={{ root: classes.text }}>
                                    A donor making a grant recommendation
                                    further represents that (a) neither the
                                    donor nor any family member<sup>1</sup> or
                                    affiliate will receive more than an
                                    incidental benefit<sup>2</sup> as a result
                                    of PEF making the recommended grant and (b)
                                    the recommended grant, if made by PEF, would
                                    not satisfy any legal obligation, including
                                    a pledge to make a gift, of the donor or any
                                    family member or affiliate.
                                </Typography>
                            </li>
                            <li>
                                <Typography classes={{ root: classes.text }}>
                                    New York law shall govern the terms and
                                    conditions of the donor’s grant made to PEF,
                                    without regard to conflict of law
                                    provisions.
                                </Typography>
                            </li>
                        </ol>
                        <Typography
                            paragraph={true}
                            classes={{ root: classes.text }}
                        >
                            <sup>1</sup> A donor’s family members consist of the
                            donor’s spouse, ancestors, siblings, children,
                            grandchildren, great grandchildren and the spouses
                            of children, grandchildren and great grandchildren.
                        </Typography>
                        <Typography
                            paragraph={true}
                            classes={{ root: classes.text }}
                        >
                            <sup>2</sup> An “incidental benefit” is a token item
                            of tangible personal property, such as a coffee mug,
                            key chain or similar item given by a grantee to the
                            donor in appreciation of the donor’s funding of a
                            grant to the grantee.
                        </Typography>
                        <Typography
                            paragraph={true}
                            classes={{ root: classes.text }}
                        >
                            <a
                                className={classNames(
                                    classes.text,
                                    classes.link,
                                )}
                                target="_blank"
                                rel="noreferrer"
                                href="https://pefisrael.org/terms-conditions-he"
                                title="תנאים והתניות"
                            >
                                Terms and Conditions in Hebrew (תנאים והתניות)
                            </a>
                        </Typography>
                    </div>
                </DialogContent>
            </Dialog>
        </FormGroup>
    );
}

export default PEFTerms;
