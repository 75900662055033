import reduce from 'lodash/reduce';
import { isLightColor } from '../colorUtils';
import { EDITABLE_CAMPAIGN_COLOR_TOKENS } from 'common/constants/campaign';

export default function updateThemePalette(theme, customPalette) {
    const updatedPalette = reduce(
        customPalette,
        (acc, colorValue, colorToken) => {
            if (
                colorValue &&
                EDITABLE_CAMPAIGN_COLOR_TOKENS.includes(colorToken)
            ) {
                acc[colorToken] = theme.palette.augmentColor({
                    main: colorValue,
                    contrastText: isLightColor(colorValue)
                        ? theme.palette.text.primary
                        : theme.palette.common.white,
                });
            }
            return acc;
        },
        {},
    );

    return {
        ...theme,
        palette: {
            ...theme.palette,
            ...updatedPalette,
        },
    };
}
