const FIELD_TYPES = Object.freeze({
    TEXT: 'text',
    NUMBER: 'number',
    TEXTAREA: 'textarea',
    DATE: 'date',
    EMAIL: 'email',
    CHECKBOX: 'checkbox',
    DROPDOWN: 'dropdown',
    DATALIST: 'datalist',
    CHECKBOXGROUP: 'checkboxGroup',
    RADIOGROUP: 'radioGroup',
    IMAGE: 'image',
    TEL: 'tel',
});

const FIELD_TYPE_VALUES = Object.freeze(Object.values(FIELD_TYPES));

module.exports = {
    FIELD_TYPES,
    FIELD_TYPE_VALUES,
};
