import { useEffect, useState } from 'react';
import useLoadScript from './loadScript';

export function useApplePay() {
    const [applePayStatus, setApplePayStatus] = useState({
        isChecked: false,
        isAllowed: false,
    });

    useEffect(() => {
        if (window.ApplePaySession?.canMakePayments()) {
            setApplePayStatus({ isChecked: true, isAllowed: true });
        } else {
            setApplePayStatus({ isChecked: true, isAllowed: false });
        }
    }, []);

    return applePayStatus;
}

export function useGooglePay() {
    const [googlePayStatus, setGooglePayStatus] = useState({
        isChecked: false,
        isAllowed: false,
    });
    const status = useLoadScript('https://pay.google.com/gp/p/js/pay.js');

    useEffect(() => {
        if (status === 'ready' && window.google?.payments) {
            const googlePayClient =
                new window.google.payments.api.PaymentsClient({
                    environment: 'TEST',
                });
            const baseCardPaymentMethod = {
                type: 'CARD',
                parameters: {
                    allowedCardNetworks: [
                        'VISA',
                        'MASTERCARD',
                        'AMEX',
                        'DISCOVER',
                        'INTERAC',
                        'JCB',
                    ],
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                },
            };
            const googlePayBaseConfiguration = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [baseCardPaymentMethod],
            };
            googlePayClient
                .isReadyToPay(googlePayBaseConfiguration)
                .then(function (response) {
                    setGooglePayStatus({
                        isChecked: true,
                        isAllowed: Boolean(response.result),
                    });
                })
                .catch(() =>
                    setGooglePayStatus({ isChecked: true, isAllowed: false }),
                );
        } else if (status !== 'loading') {
            setGooglePayStatus({ isChecked: true, isAllowed: false });
        }
    }, [status]);

    return googlePayStatus;
}
