import React from 'react';
import PropTypes from 'prop-types';
import StarIcon from './StarIcon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        marginTop: 27,
        alignItems: 'center',
        height: 8,
    },

    divider: {
        height: 2,
        width: '100%',
        backgroundColor: '#b3b3b3',
        // boxShadow: '1px 1px 1px rgba(0,0,0,0.15)',

        '@media (max-width: 600px)': {
            boxShadow: 'none',
        },
    },

    star: {
        width: 40,
        textAlign: 'center',
        display: 'inline-block',
        marginTop: -1,

        '@media (max-width: 600px)': {
            width: 26,
            lineHeight: '0.3',

            '& svg': {
                width: 4,
                height: 4,
            },
        },
    },
}));

function StarDivider(props) {
    const { className, starCount = 1 } = props;
    const classes = useStyles();
    const stars = [];

    for (let i = 0; i < starCount; i++) {
        stars.push(
            <div className={classes.star} key={i}>
                <StarIcon />
            </div>,
        );
    }

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <div className={classes.divider}></div>
            {stars}
            <div className={classes.divider}></div>
        </div>
    );
}

StarDivider.propTypes = {
    starCount: PropTypes.number,
    className: PropTypes.string,
};

export default StarDivider;
