import get from 'lodash/get';
import axios from 'axios';
import Repository from './Repository';

class UserRepository extends Repository {}

export const repo = new UserRepository({ baseUrl: '/user' });

export const loadCurrentUser = () =>
    axios('/user/whoami').then(response => response.data);

export const loadLocale = locale =>
    axios({
        baseURL: '/',
        url: `/translations?locale=${locale}`,
    }).then(response => response.data);

export const fetchLayers = async () => {
    try {
        const response = await axios('/user/layers');
        return response.data;
    } catch (error) {
        const message = get(error, 'response.data.message', error);
        throw new Error(message);
    }
};

export const sendInvitation = async userId => {
    return axios.post(`/user/${userId}/resend-invitation`);
};

// backward campatibility
repo.loadCurrentUser = loadCurrentUser;
repo.loadLocale = loadLocale;
repo.fetchLayers = fetchLayers;
repo.sendInvitation = sendInvitation;

repo.setLocale = async function (userId, locale) {
    return this.axios
        .post(`/${userId}/set-locale`, { locale })
        .then(response => response.data);
};

repo.fetchAssignedAccounts = async function () {
    const res = await this.axios.get('/accounts');
    return res.data;
};

export default repo;
