import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { ReactComponent as CardIcon } from 'client/components/CampaignPage/components/FormInput/CardIcon.svg';
import { ReactComponent as LockIcon } from 'client/components/CampaignPage/components/FormInput/LockIcon.svg';
import SecurePayments from '../../components/SecurePayments/SecurePayments';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { selectError, paymentFailed } from './checkoutSlice';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import useLoadScript from '../../hooks/loadScript';

const VALIDATION_INFO = Object.freeze({
    cardNumber: 'paymeCardNumber',
    cardExpiration: 'paymeExpire',
    cvc: 'paymeCvc',
});

const cardNumberDiv = () => {
    const classes = useStyles();
    return (
        <div
            className={classes.cardContainer}
            id={'card-number-container'}
        ></div>
    );
};
const cardExpiryDiv = () => {
    return (
        <div
            style={{ height: '50px', padding: '0px 20px' }}
            id={'card-expiry-container'}
        ></div>
    );
};
const cardCvcDiv = () => {
    const classes = useStyles();
    return (
        <div className={classes.cardContainer} id={'card-cvc-container'}></div>
    );
};

const CardNumber = withController(withFormInput(cardNumberDiv));
const CardExpiry = withController(withFormInput(cardExpiryDiv));
const CardCvc = withController(withFormInput(cardCvcDiv));

function PaymeCardInfo({
    locale = 'en',
    sellerId,
    setPaymeInstance,
    testMode,
    control,
    rules = { cc: {}, ccv: {}, expire: {} },
    errors = {},
    paymeTokenGenerated,
}) {
    const [internalErrors, setInternalErrors] = useState({});
    const [internalDirty, setInternalDirty] = useState({});
    const dispatch = useDispatch();
    const paymentError = useSelector(selectError);
    const fieldsShouldRefresh = paymentError !== null && paymeTokenGenerated;

    const paymeStatus = useLoadScript(
        'https://cdn.paymeservice.com/hf/v1/hostedfields.js',
    );

    const handlePaymeEvent = ({ event, field, isValid, message }) => {
        const name = VALIDATION_INFO[field];

        if (event === 'focus') {
            setInternalDirty(dirty => {
                if (dirty[name]) {
                    return dirty;
                }

                return { ...dirty, [name]: true };
            });
        }

        setInternalErrors(errors => ({
            ...errors,
            [name]: isValid ? undefined : { message },
        }));
    };

    useEffect(() => {
        if (window.PayMe && paymeStatus === 'ready') {
            if (
                !paymeTokenGenerated ||
                (paymentError !== null && paymeTokenGenerated)
            ) {
                window.PayMe.create(sellerId, {
                    testMode: testMode,
                    language: locale,
                })
                    .then(function (instance) {
                        const containerIds = [
                            'card-number-container',
                            'card-expiry-container',
                            'card-cvc-container',
                        ];
                        containerIds.forEach(elementId => {
                            let container = document.getElementById(elementId);
                            if (container) container.innerHTML = '';
                        });
                        const fields = instance.hostedFields();
                        const cardNumber = fields.create(
                            'cardNumber',
                            cardNumberSettings,
                        );
                        const expiration = fields.create(
                            'cardExpiration',
                            expirationFieldSettings,
                        );
                        const cvc = fields.create('cvc', cvcFieldSettings);

                        [cardNumber, expiration, cvc].forEach(field => {
                            field.on('focus', handlePaymeEvent);
                            field.on('validity-changed', handlePaymeEvent);
                        });

                        cardNumber.mount('#card-number-container');
                        expiration.mount('#card-expiry-container');
                        cvc.mount('#card-cvc-container');
                        setPaymeInstance(instance);
                    })
                    .catch(function (error) {
                        console.log(error.message);
                    });
            }
        } else if (paymeStatus === 'error') {
            dispatch(
                paymentFailed(
                    'Certain payment related scripts have not been loaded yet!',
                ),
            );
        }
    }, [fieldsShouldRefresh, paymeStatus]);

    const { formatMessage } = useIntl();

    const DEFAULT_SETTINGS = {
        styles: {
            invalid: {
                color: 'red',
            },
            valid: {
                color: 'green',
            },
        },
    };

    const cardNumberSettings = Object.assign({}, DEFAULT_SETTINGS, {
        placeholder: '4242 4242 4242 4242',
        messages: {
            invalid: formatMessage({
                id: 'DonationForm.cardNumber.invalid',
                defaultMessage: 'Card number is invalid',
            }),
            required: formatMessage({
                id: 'DonationForm.cardNumber.required',
                defaultMessage: 'Card number is required',
            }),
        },
    });

    const expirationFieldSettings = Object.assign({}, DEFAULT_SETTINGS, {
        messages: {
            invalid: formatMessage({
                id: 'DonationForm.expire.invalid',
                defaultMessage: 'Card expiration date is invalid',
            }),
            required: formatMessage({
                id: 'DonationForm.expire.required',
                defaultMessage: 'Card expiration date is required',
            }),
        },
    });

    const cvcFieldSettings = Object.assign({}, DEFAULT_SETTINGS, {
        placeholder: 'CVV',
        messages: {
            invalid: formatMessage({
                id: 'DonationForm.cvv.invalid',
                defaultMessage: 'Card CVV is invalid',
            }),
            required: formatMessage({
                id: 'DonationForm.cvv.required',
                defaultMessage: 'Card CVV is required',
            }),
        },
    });
    const classes = useStyles();
    return (
        <>
            <FormGroup inline className={classes.fields}>
                <CardNumber
                    type="text"
                    name="paymeCardNumber"
                    placeholder="* Card Number"
                    required
                    startIcon={<CardIcon />}
                    control={control}
                    rules={rules.cc}
                    dirtyFields={internalDirty}
                    errors={{ ...errors, ...internalErrors }}
                    defaultValue=""
                />
            </FormGroup>
            <FormGroup
                inline
                gap={19}
                label={formatMessage({
                    id: 'DonationForm.enterCardValidity',
                })}
                className={classes.fields}
            >
                <CardExpiry
                    placeholder="* Expire date"
                    name="paymeExpire"
                    width="100%"
                    required
                    control={control}
                    rules={rules.expire}
                    dirtyFields={internalDirty}
                    errors={{ ...errors, ...internalErrors }}
                    defaultValue=""
                />
                <CardCvc
                    type="text"
                    name="paymeCvc"
                    maxLength={3}
                    placeholder="* CVC / CW"
                    required
                    startIcon={<LockIcon />}
                    control={control}
                    rules={rules.cvc}
                    dirtyFields={internalDirty}
                    errors={{ ...errors, ...internalErrors }}
                    defaultValue=""
                />
            </FormGroup>
            <FormGroup>
                <SecurePayments />
            </FormGroup>
        </>
    );
}

const useStyles = makeStyles({
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    cardContainer: {
        height: '50px',
        padding: '0px 80px',
        '@media (max-width: 600px)': {
            padding: '0px 0px 0px 65px',
            'html[dir=rtl] &': {
                padding: '0px 65px 0px 0px',
            },
        },
    },
});

PaymeCardInfo.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object,
    locale: PropTypes.string,
    paymeTokenGenerated: PropTypes.bool,
    rules: PropTypes.object,
    sellerId: PropTypes.string.isRequired,
    setPaymeInstance: PropTypes.func.isRequired,
    testMode: PropTypes.bool.isRequired,
};

export default PaymeCardInfo;
