import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 999,
        padding: '5px 17px',
        border: `2px solid ${palette.primary.main}`,

        '@media (max-width: 600px)': {
            padding: '3px 17px',
        },
    },
    close: {
        background: 'none',
        border: 0,
        padding: 3,
        marginInlineEnd: spacing(1),
        marginInlineStart: -spacing(1),
        cursor: 'pointer',
        '& svg': {
            display: 'block',
            color: palette.error.main,
        },

        '& svg g': {
            stroke: palette.error.main,
        },

        '@media (max-width: 600px)': {
            marginInlineEnd: '6px',
            '& svg': {
                width: 8,
                height: 8,
            },
        },
    },
    main: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1rem',
        color: palette.primary.main,
        fontWeight: 500,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
}));

function TagItem(props) {
    const { className, children, onClose } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>
            {typeof onClose === 'function' && (
                <button className={classes.close} onClick={onClose}>
                    <CloseIcon />
                </button>
            )}
            <div className={classes.main}>{children}</div>
        </div>
    );
}

TagItem.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default TagItem;
