import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '0.813rem',
        lineHeight: '1rem',
        color: palette.grey[500],
        transition: 'color 500ms',
        cursor: 'pointer',

        '&:hover': {
            color: palette.primary.main,
            transition: 'color 150ms',
        },
    },
}));

function Link(props) {
    const { className, children, ...rest } = props;
    const classes = useStyles();

    return (
        <a className={classNames(classes.root, className)} {...rest}>
            {children}
        </a>
    );
}

Link.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Link;
