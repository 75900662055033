import Repository from 'client/services/api/Repository';
import { stringify } from 'querystring';

const repository = new Repository({ baseUrl: '/donate-page' });

repository.getDonatePage = async function (donatePageId) {
    return this.axios
        .get(`/${donatePageId}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.createDonatePage = async function (data) {
    return this.axios
        .post('/', data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.createDonatePageLanguage = async function (
    donatePageId,
    donatePageLanguage,
    data = {},
) {
    return this.axios
        .post(`/${donatePageId}/${donatePageLanguage}`, data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.patchDonatePageLanguage = async function (
    donatePageId,
    donatePageLanguage,
    data,
) {
    return this.axios
        .patch(`/${donatePageId}/${donatePageLanguage}`, data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.patchDonatePage = async function (
    donatePageId,
    data,
    queryObject = {},
) {
    const queryString = stringify(queryObject);
    return this.axios
        .patch(`/${donatePageId}${queryString && '?' + queryString}`, data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.fetchDonatePageList = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);
    return this.axios
        .get(queryString ? `?${queryString}` : '')
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.deleteDonatePage = async function (donatePageId) {
    return this.axios
        .delete(`/${donatePageId}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.fetchDonatePageDonationsList = async function (queryJson) {
    const queryString = Repository.prepareQueryString(queryJson);
    return this.axios
        .get(`/donation${queryString ? `?${queryString}` : ''}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.fetchDonatePageDonation = async function (donationId) {
    return this.axios
        .get(`/donation/${donationId}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.patchDonatePageDonation = async function (donationId, data) {
    return this.axios
        .patch(`/donation/${donationId}`, data)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.deleteDonatePageDonation = async function (donationId) {
    return this.axios
        .delete(`/donation/${donationId}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

repository.deleteDonatePageLanguage = async function (
    donatePageId,
    donatePageLanguage,
) {
    return this.axios
        .delete(`/${donatePageId}/${donatePageLanguage}`)
        .then(this.handleResponse)
        .catch(this.handleError);
};

export default repository;
