import React from 'react';

function PlusIcon(props) {
    return (
        <svg
            height="24px"
            width="24px"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.9589"
            version="1.1"
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M 1.47945,11.999998 H 22.520542 M 11.999996,1.47945 v 21.041096" />
        </svg>
    );
}

export default PlusIcon;
