import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PageCenterText from '../PageCenterText/PageCenterText';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLink, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialLink from '../SocialLink/SocialLink';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import { selectDonorPfl } from './sharePanelSlice';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        '@media (max-width: 600px)': {
            marginBottom: 60,
        },
    },

    title: {
        color: palette.text.primary,
        fontWeight: 600,
    },

    hideTitleOnSmallScreens: {
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },

    socialLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 auto',
        marginTop: spacing(2),
    },

    width180: {
        width: 180,
    },

    width135: {
        width: 135,
    },

    socialLinkElement: {
        display: 'inline-block',
        position: 'relative',
        background: palette.grey[600],
        color: palette.common.white,
        fontSize: '0.875rem',
        borderRadius: '50%',
        width: 30,
        height: 30,
        '&:hover': {
            color: palette.primary.main,
            transition: 'color 250ms',
        },
    },

    urlWrapper: {
        margin: 'auto',
        marginTop: '16px',
        width: 'fit-content',
        border: `1px solid ${palette.grey[400]}`,
        borderRadius: '5px',
        padding: '1px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        background: 'white',

        '&::before': {
            top: spacing(1),
            width: '1px',
            bottom: spacing(1),
            content: '""',
            position: 'absolute',
            background: palette.grey[400],
            pointerEvents: 'none',
            display: 'block',
            left: '41px',
            right: 'auto',

            'html[dir=rtl] &': {
                right: '41px',
                left: 'auto',
            },
        },
    },

    urlIcon: {
        padding: '5px',
        display: 'flex',
        alignItems: 'center',

        '& a': {
            color: palette.secondary.main,
            backgroundColor: 'transparent',
            cursor: 'auto',

            '&:hover': {
                color: palette.secondary.main,
            },
        },
    },

    urlText: {
        border: 'none',
        padding: '10px',
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '15px',
        fontWeight: '500',
        outline: 'none',
        textOverflow: 'ellipsis',

        '@media (min-width: 768px)': {
            minWidth: '456px',
        },
        '@media (min-width: 481px) and (max-width: 767px)': {
            minWidth: '250px',
        },
    },

    urlCopyButton: {
        padding: '5px',
        borderRadius: '5px',
        color: palette.common.white,
        fontFamily: 'Heebo, sans-serif',
        fontSize: '15px',
        cursor: 'pointer',
        minWidth: '80px',
        transition: 'background-color 200ms, border-color 200ms',
    },

    whatsappLink: {
        '&:hover': {
            backgroundColor: palette.brands.whatsapp,
            color: palette.common.white,
            transition: 'background-color 250ms',
        },
    },

    facebookLink: {
        '&:hover': {
            backgroundColor: palette.brands.facebook,
            color: palette.common.white,
            transition: 'background-color 250ms',
        },
    },

    greenBackgroundAndBorder: {
        backgroundColor: palette.success.main,
        border: `2px solid ${palette.success.main}`,
    },

    lightBlueBackgroundAndBorder: {
        backgroundColor: palette.primary.light,
        border: `2px solid ${palette.primary.light}`,
    },
}));

function SharePanel(props) {
    const { campUrl, title, text } = props;
    const classes = useStyles();
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
    const [pflCopied, setPflCopied] = React.useState(false);
    const donorPfl = useSelector(selectDonorPfl);
    const urlToShare = donorPfl ? donorPfl : campUrl;
    let copyTimeout = null;

    const trackSocialButton = medium => {
        triggerGtmEvent(GTM_EVENTS.CLICK_SHARE, { eventCategory: medium });
    };

    const copyToClipboard = () => {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = urlToShare;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setTooltipIsOpen(true);
        clearTimeout(copyTimeout);
        trackSocialButton('url');
        copyTimeout = setTimeout(function () {
            setTooltipIsOpen(false);
        }, 1000);
    };

    const copyPflToClipboard = () => {
        let copyText = document.querySelector('#pfl-text');
        copyText.select();
        document.execCommand('copy');
        setPflCopied(true);
        setTimeout(function () {
            setPflCopied(false);
        }, 1000);
    };

    return (
        <PageCenterText
            bottomSpacing={true}
            className={classNames('share-panel', classes.root)}
        >
            <span
                className={classNames(classes.title, {
                    [classes.hideTitleOnSmallScreens]: !donorPfl,
                })}
            >
                {donorPfl ? (
                    <FormattedMessage
                        id="ShareDonorPfl.with"
                        defaultMessage="Share your personal fundraising link with family and friends"
                    />
                ) : (
                    <FormattedMessage id="Share.with" />
                )}
            </span>
            {donorPfl && (
                <div className={classes.urlWrapper}>
                    <div className={classes.urlIcon}>
                        <SocialLink
                            className={classes.socialLinkElement}
                            icon={faLink}
                        />
                    </div>
                    <input
                        id="pfl-text"
                        className={classes.urlText}
                        value={donorPfl}
                        readOnly={true}
                    ></input>
                    <button
                        className={classNames(
                            classes.urlCopyButton,
                            pflCopied
                                ? classes.greenBackgroundAndBorder
                                : classes.lightBlueBackgroundAndBorder,
                        )}
                        onClick={() => copyPflToClipboard()}
                    >
                        {pflCopied ? 'Copied' : 'Copy'}
                    </button>
                </div>
            )}
            <div
                className={classNames(
                    classes.socialLinks,
                    donorPfl ? classes.width135 : classes.width180,
                )}
            >
                <SocialLink
                    onClick={() => trackSocialButton('whatsapp')}
                    className={classNames(
                        classes.socialLinkElement,
                        classes.whatsappLink,
                    )}
                    icon={faWhatsapp}
                    href={'https://api.whatsapp.com/send?text=' + urlToShare}
                />
                <SocialLink
                    onClick={() => trackSocialButton('facebook')}
                    className={classNames(
                        classes.socialLinkElement,
                        classes.facebookLink,
                    )}
                    icon={faFacebookF}
                    href={
                        'https://www.facebook.com/sharer/sharer.php?u=' +
                        urlToShare
                    }
                />
                <SocialLink
                    onClick={() => trackSocialButton('mail')}
                    className={classes.socialLinkElement}
                    icon={faEnvelope}
                    href={
                        'mailto:?subject=' +
                        title.replace(/<[^>]+>/g, '') +
                        '&body=' +
                        text?.replace(/<[^>]+>/g, '') +
                        '%0A%0A' +
                        urlToShare +
                        '%0A%0A'
                    }
                />
                {!donorPfl && (
                    <Tooltip
                        title="URL Copied!"
                        placement="top"
                        arrow
                        disableFocusListener
                        disableHoverListener
                        open={tooltipIsOpen}
                        onOpen={() => setTooltipIsOpen(true)}
                        onClose={() => setTooltipIsOpen(false)}
                    >
                        <div onClick={() => copyToClipboard()}>
                            <SocialLink
                                className={classes.socialLinkElement}
                                icon={faLink}
                            />
                        </div>
                    </Tooltip>
                )}
            </div>
        </PageCenterText>
    );
}

SharePanel.propTypes = {
    campUrl: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

export default SharePanel;
