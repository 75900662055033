import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { isLightColor } from 'client/helpers/colorUtils';

const useStyles = makeStyles(
    ({ palette, typography }) => ({
        root: {
            backgroundColor: props => props.color || palette.secondary.main,
            color: props =>
                isLightColor(props.color || palette.secondary.main, 0.7)
                    ? palette.text.primary
                    : palette.common.white,
            padding: '2px 13px',
            borderRadius: 999,
            border: props => {
                const color = props.color || palette.secondary.main;
                return isLightColor(color, 0.7)
                    ? `1px solid ${palette.text.primary}`
                    : 'none';
            },
            outline: 'none',
            fontSize: 15,
            lineHeight: '22px',
            fontWeight: 400,
            fontFamily: typography.fontFamily,
            minWidth: 60,
            textDecoration: 'none',
            display: 'inline-block',
            textAlign: 'center',
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: props =>
                    props.hoverColor || palette.secondary.dark,
                transition: 'all 0.3s ease-in-out',
            },
        },

        outline: {
            background: 'none !important',
            lineHeight: '20px',
            border: props => {
                const color = props.color || palette.secondary.main;
                return `1px solid ${
                    isLightColor(color, 0.7) ? palette.text.primary : color
                } !important`;
            },
            color: props => {
                const color = props.color || palette.secondary.main;
                return isLightColor(color, 0.7) ? palette.text.primary : color;
            },
            '&:hover': {
                backgroundColor: props =>
                    `${props.hoverColor || palette.secondary.main} !important`,
                color: props =>
                    palette.getContrastText(
                        props.hoverColor || palette.secondary.main,
                    ),
                borderColor: props =>
                    props.hoverColor || palette.secondary.main,
                transition: 'all 0.3s ease-in-out',
            },
        },
    }),
    { name: 'button' },
);

function Button(props) {
    const { children, color, hoverColor, className, link, outline, ...rest } =
        props;
    const classes = useStyles({ color, hoverColor });
    const Component = link ? 'a' : 'button';

    return (
        <Component
            className={classNames(
                classes.root,
                {
                    [classes.outline]: outline,
                },
                className,
            )}
            {...rest}
        >
            {children}
        </Component>
    );
}

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    color: PropTypes.string,
    hoverColor: PropTypes.string,
    outline: PropTypes.bool,
    className: PropTypes.string,
    link: PropTypes.bool,
};

export default Button;
