import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > *': {
            marginBottom: '0 !important',
        },
    },
}));

function DonationTabPanel(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {children}
        </div>
    );
}

DonationTabPanel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default DonationTabPanel;
