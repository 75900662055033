import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        position: 'relative',
        fontFamily: typography.fontFamily,
        paddingInlineStart: '47px',
    },
    input: {
        position: 'absolute',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        opacity: 0,
        '&:checked + span::after': {
            left: 19,
            background: palette.primary.main,
        },

        'html[dir=rtl] &': {
            left: 'auto',
            right: 0,
            '&:checked + span::after': {
                left: 'auto',
                right: 19,
            },
        },
    },
    label: {
        display: 'inline-block',
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.grey[600],
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 34,
            height: 18,
            border: `1px solid ${palette.grey[400]}`,
            borderRadius: 10,
            background: palette.common.white,
            left: 0,
            top: 2,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: 14,
            height: 14,
            left: 3,
            top: 5,
            borderRadius: '50%',
            background: palette.grey[400],
            transition: 'all .3s',
        },
        '& small': {
            fontSize: '0.813rem',
            fontWeight: 300,
        },
        '& a': {
            fontWeight: 500,
            color: palette.grey[500],
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        'html[dir=rtl] &': {
            '&::before': {
                left: 'auto',
                right: 0,
            },
            '&::after': {
                left: 'auto',
                right: 3,
            },
        },

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',

            '& small': {
                fontSize: '0.688rem',
            },
        },
    },
    icon: {
        position: 'absolute',
        left: 5,
        top: 7,
        width: 7,
        height: 8.75,
        zIndex: 2,

        'html[dir=rtl] &': {
            left: 'auto',
            right: 5,
        },
    },
}));

function Toggle(props) {
    const { className, children, onChange, inputRef, ...rest } = props;
    const classes = useStyles();

    return (
        <label className={classNames(classes.root, className)}>
            <input
                className={classes.input}
                type="checkbox"
                onChange={onChange}
                ref={inputRef}
                {...rest}
            />
            <span className={classes.label}>{children}</span>
        </label>
    );
}

Toggle.propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    inputRef: PropTypes.func,
};

export default Toggle;
