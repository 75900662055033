import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    iframe: {
        width: '100%',
        boxSizing: 'border-box',
        border: '0px none',
        height: '380px',
    },
    progress: {
        color: palette.primary.main,
        margin: 'auto',
    },
}));
function NedarimCardInfo({ locale = 'en' }) {
    const [isLoading, setisLoading] = useState(true);
    const classes = useStyles();

    const onLoad = () => setisLoading(false);

    return (
        <div className={classes.root}>
            <iframe
                id="nedarimIframe"
                src={`https://matara.pro/nedarimplus/iframe?language=${locale}&style=causematchnew`}
                scrolling="no"
                onLoad={onLoad}
                className={classes.iframe}
            ></iframe>
            {isLoading && (
                <CircularProgress
                    size={28}
                    thickness={3}
                    className={classes.progress}
                />
            )}
        </div>
    );
}

NedarimCardInfo.propTypes = {
    locale: PropTypes.string,
};

export default NedarimCardInfo;
