const { LANGUAGES_INFO, LANGUAGES } = require('../constants');

const languageDirection = language => LANGUAGES_INFO[language].dir || 'ltr';

const isValidLanguage = language =>
    Object.keys(LANGUAGES_INFO).includes(language);

const languageOptions = formatMessage =>
    LANGUAGES.map(lang => ({
        label: formatMessage({ id: lang.i18n }),
        value: lang.id,
    }));

const languageName = language => LANGUAGES_INFO[language].name;

const languageLocale = language => LANGUAGES_INFO[language].locale || 'en_US';

module.exports = {
    languageDirection,
    isValidLanguage,
    languageOptions,
    languageName,
    languageLocale,
};
