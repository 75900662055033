import {
    isEmpty,
    get,
    set,
    difference,
    intersection,
    omit,
    merge,
} from 'lodash';

import {
    DEFAULT_CHECKOUT_CUSTOM_FIELDS,
    GATEWAY_SPECIFIC_CHECKOUT_FIELDS,
} from 'client/screens/Dashboard/Campaigns/campaignDefaults';

const ALL_DEFAULT_CHECKOUT_FIELDS = merge(
    Object.values(GATEWAY_SPECIFIC_CHECKOUT_FIELDS).reduce(
        (acc, fields) => merge(acc, fields),
        {},
    ),
    DEFAULT_CHECKOUT_CUSTOM_FIELDS,
);

export const isDefaultFieldAllowed = ({ name, config, paymentGateway }) => {
    return (
        config.isEnabled &&
        (DEFAULT_CHECKOUT_CUSTOM_FIELDS[name] ||
            GATEWAY_SPECIFIC_CHECKOUT_FIELDS[paymentGateway]?.[name])
    );
};

export const getDefaultFieldLabel = (formatMessage, { key, field }) => {
    if (ALL_DEFAULT_CHECKOUT_FIELDS[key].label === field.label) {
        return formatMessage({
            id: `DonationForm.defaultFields.${key}`,
        });
    }

    return field.label;
};

export const getDefaultCheckoutFieldsForCurrency = currency => {
    const defaultFields = {};

    Object.keys(DEFAULT_CHECKOUT_CUSTOM_FIELDS).forEach(field => {
        const fieldValue = DEFAULT_CHECKOUT_CUSTOM_FIELDS[field];
        if (fieldValue.currencies.includes(currency)) {
            const copyField = { ...fieldValue };
            delete copyField.currencies;
            defaultFields[field] = copyField;
        }
    });

    return defaultFields;
};

export const updateDefaultCheckoutFieldsForGateway = (
    defaultFields,
    gateway,
) => {
    const fields = { ...defaultFields };

    const gatewaySpecificFields =
        GATEWAY_SPECIFIC_CHECKOUT_FIELDS[gateway] || [];

    Object.entries(gatewaySpecificFields).forEach(
        ([specificFieldKey, specificFieldValue]) => {
            const defaultFieldValue = fields[specificFieldKey] || {};
            specificFieldValue.gateway = gateway;

            if (specificFieldValue.canOverride) {
                fields[specificFieldKey] = {
                    ...specificFieldValue,
                    ...defaultFieldValue,
                };
                return;
            }

            fields[specificFieldKey] = {
                ...defaultFieldValue,
                ...specificFieldValue,
                label: defaultFieldValue.label || specificFieldValue.label,
            };
        },
    );
    return fields;
};

export const getDefaultCheckoutFieldsForCurrencyAndGateway = (
    currency,
    gateway,
) => {
    const initialDefaultFields = getDefaultCheckoutFieldsForCurrency(currency);

    const updatedDefaultFields = updateDefaultCheckoutFieldsForGateway(
        initialDefaultFields,
        gateway,
    );

    return updatedDefaultFields;
};

export const checkAndInitializeDefaultCheckoutFields = (
    checkoutFields,
    payments,
    pages,
) => {
    pages.forEach(page => {
        const currencies = get(payments, `${page}.currencies`, null);
        currencies &&
            currencies.forEach(currency => {
                let defaultFields = get(
                    checkoutFields,
                    `${page}.${currency}.defaultFields`,
                    {},
                );
                const gatewayConfigList = get(
                    payments,
                    `${page}.${currency}`,
                    [],
                );
                if (isEmpty(defaultFields)) {
                    defaultFields = gatewayConfigList.reduce(
                        (fields, { gateway }) => {
                            if (
                                !gateway ||
                                !(gateway in GATEWAY_SPECIFIC_CHECKOUT_FIELDS)
                            ) {
                                return fields;
                            }
                            return updateDefaultCheckoutFieldsForGateway(
                                fields,
                                gateway,
                            );
                        },
                        getDefaultCheckoutFieldsForCurrency(currency),
                    );
                } else {
                    defaultFields = checkAndUpdateDefaultFields(
                        defaultFields,
                        currency,
                        gatewayConfigList,
                    );
                }

                set(checkoutFields, `${page}.${currency}.defaultFields`, {
                    ...defaultFields,
                });
            });
    });
    return checkoutFields;
};

export const checkAndUpdateDefaultFields = (
    defaultFields,
    currency,
    gatewayConfigList,
) => {
    const newFields = gatewayConfigList.reduce((fields, { gateway }) => {
        if (!gateway || !(gateway in GATEWAY_SPECIFIC_CHECKOUT_FIELDS)) {
            return fields;
        }
        return updateDefaultCheckoutFieldsForGateway(fields, gateway);
    }, getDefaultCheckoutFieldsForCurrency(currency));

    const differenceInFields = difference(
        Object.keys(defaultFields),
        Object.keys(newFields),
    );

    if (differenceInFields.length !== 0) {
        defaultFields = omit(defaultFields, differenceInFields);
    }

    return defaultFields;
};

export const getDefaultCheckoutFieldsWithoutGateway = (
    checkoutFieldsWithGateway,
    currency,
    gateway,
) => {
    const currentDefaultCheckoutFields = { ...checkoutFieldsWithGateway };
    if (!gateway || !(gateway in GATEWAY_SPECIFIC_CHECKOUT_FIELDS)) {
        return currentDefaultCheckoutFields;
    }

    const initialDefaultCheckoutFields =
        getDefaultCheckoutFieldsForCurrency(currency);
    const gatewaySpecificFields = GATEWAY_SPECIFIC_CHECKOUT_FIELDS[gateway];

    const fieldsSpecificToGateway = difference(
        Object.keys(gatewaySpecificFields),
        Object.keys(initialDefaultCheckoutFields),
    );
    fieldsSpecificToGateway.forEach(
        field => delete currentDefaultCheckoutFields[field],
    );

    const fieldsUpdatedByGateway = intersection(
        Object.keys(initialDefaultCheckoutFields),
        Object.keys(gatewaySpecificFields),
    );
    fieldsUpdatedByGateway.forEach(
        field =>
            (currentDefaultCheckoutFields[field] =
                initialDefaultCheckoutFields[field]),
    );

    return currentDefaultCheckoutFields;
};

export const getUpdatedDefaultCheckoutFieldsOnGatewayChange = (
    checkoutFields,
    currency,
    previousGateway,
    newGateway,
) => {
    const currentDefaultCheckoutFieldsWithoutGateway =
        getDefaultCheckoutFieldsWithoutGateway(
            checkoutFields,
            currency,
            previousGateway,
        );

    const updatedDefaultFields = updateDefaultCheckoutFieldsForGateway(
        currentDefaultCheckoutFieldsWithoutGateway,
        newGateway,
    );

    return updatedDefaultFields;
};

export const getDefaultCheckoutFieldsForNewPage = paymentDefaults => {
    const newPageCheckoutFields = {};
    const currencies = paymentDefaults.currencies;
    currencies.forEach(currency => {
        const defaultFields = getDefaultCheckoutFieldsForCurrencyAndGateway(
            currency,
            get(paymentDefaults, `${currency}.gateway`, 'stripe'),
        );
        set(newPageCheckoutFields, `${currency}.defaultFields`, defaultFields);
    });
    return newPageCheckoutFields;
};

export const cleanupCheckoutFields = (checkoutFields, pages) => {
    if (!isEmpty(checkoutFields)) {
        Object.keys(checkoutFields).forEach(page => {
            if (!pages.includes(page)) {
                delete checkoutFields[page];
            }
        });
    }
    return checkoutFields;
};
