import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Button, Paper, SvgIcon, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';
import filterQueryParams from 'client/helpers/filterQueryParams';
import useIsMobile from 'client/hooks/isMobile';
import { currencySign } from 'common/helpers';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    selectPageName,
    selectSelectedCampaign,
    selectSelectedLayerItem,
} from '../../../../features/campaign/campaignSlice';
import { open } from '../../../../features/checkout/checkoutPopupSlice';
import {
    updateDonationAmount,
    updateDonationCurrency,
    updateGivingAmount,
    updateGivingCount,
    updateGivingId,
    updateGivingPaymentOptions,
} from '../../../../features/checkout/checkoutSlice';
import { slice as teamSlice } from '../../../../features/layersList/teamsListSlice';
import { selectTabs } from '../../../../features/recentDonationPanel/recentDonationPanelSlice';
import Progress from '../../../Progress/Progress';
import PerkPopup from './PerkPopup';
import MinusIcon from 'client/components/Icons/Minus';
import MinusOutlineIcon from 'client/components/Icons/MinusOutline';
import PlusIcon from 'client/components/Icons/Plus';
import PlusOutlineIcon from 'client/components/Icons/PlusOutline';
import {
    adjustColor,
    changeColorAlpha,
    isLightColor,
} from '../../../../../../helpers/colorUtils';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
    root: {
        maxWidth: 450,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        margin: ({ rowGap, columnGap }) => `0 0 ${rowGap}px ${columnGap}px`,
        flexBasis: ({ columnGap }) => `calc(25% - ${columnGap}px)`,
        '&.perk-3': {
            flexBasis: ({ columnGap }) => `calc(33% - ${columnGap}px)`,
        },
        '&.perk-4': {
            flexBasis: ({ columnGap }) => `calc(25% - ${columnGap}px)`,
        },
        '&.perk-5': {
            flexBasis: ({ columnGap }) => `calc(20% - ${columnGap}px)`,
        },
        'html[dir=rtl] &': {
            margin: ({ rowGap, columnGap }) => `0 ${columnGap}px ${rowGap}px 0`,
        },
        '@media (max-width: 480px)': {
            maxWidth: 300,
            margin: ({ rowGapMobile, columnGapMobile }) =>
                `0 0 ${rowGapMobile}px ${columnGapMobile}px`,
            flexBasis: ({ columnGapMobile }) =>
                `calc(50% - ${columnGapMobile}px) !important`,
            'html[dir=rtl] &': {
                margin: ({ rowGapMobile, columnGapMobile }) =>
                    `0 ${columnGapMobile}px ${rowGapMobile}px 0`,
            },
        },
    },
    perkItem: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 85,
        position: 'relative',
        paddingBottom: 28,
        backgroundColor: palette.background.paper,
        '@media (max-width: 480px)': {
            paddingBottom: 24,
            justifySelf: 'center',
            minHeight: 70,
        },
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        marginBottom: spacing(0.5),
        height: 21,
        '@media (max-width: 480px)': {
            fontSize: 12,
            height: 17,
        },
    },
    statusLabel: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px 0 0 0',
        'html[dir=rtl] &': {
            borderRadius: '0 4px 0 0',
        },
    },
    lastOne: {
        padding: '0 5px',
        background: palette.secondary.light,
        color: palette.secondary.contrastText,
    },
    outOfStock: {
        padding: '0 5px',
        color: palette.error.contrastText,
        background: palette.error.main,
    },
    imagePlaceholder: {
        margin: 0,
        marginRight: 6,
        '& img': {
            width: 80,
            height: 80,
            borderRadius: '50%',
            border: ({ isBecomeGrey, isStockEmpty, color, isMobile }) =>
                `${isMobile ? 2 : 5}px solid ${
                    isBecomeGrey && isStockEmpty ? palette.grey[100] : color
                }`,
        },
        '.perk-4 & img': {
            width: 65,
            height: 65,
        },

        '.perk-5 & img': {
            width: 60,
            height: 60,
        },
        'html[dir=rtl] &': {
            marginLeft: 6,
            marginRight: 0,
        },
        '@media (max-width: 480px)': {
            marginRight: spacing(0.5),
            '& img': {
                width: '45px !important',
                height: '45px !important',
            },
            'html[dir=rtl] &': {
                marginLeft: spacing(0.5),
                marginRight: 0,
            },
        },
    },
    noImage: {
        minHeight: 0,
        marginRight: 0,
        marginLeft: spacing(1),
        'html[dir=rtl] &': {
            marginRight: spacing(1),
            marginLeft: 0,
        },
        '@media (max-width: 480px)': {
            height: 0,
        },
    },
    imageOnly: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        '& img': {
            width: '100px !important',
            height: '100px !important',
            marginBottom: 16,
            '@media (max-width: 480px)': {
                width: '65px !important',
                height: '65px !important',
            },
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 8px',
        flex: 1,
        alignItems: 'center',
        cursor: 'pointer',
        wordBreak: 'break-word',
        '@media (max-width: 480px)': {
            padding: '0 5px',
        },
    },
    text: {
        minHeight: 48,
        marginLeft: 0,
        marginRight: 12,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        lineHeight: '1.4',
        'html[dir=rtl] &': {
            marginLeft: 12,
            marginRight: 0,
        },
        '@media (max-width: 480px)': {
            margin: 0,
        },
    },
    title: {
        color: palette.text.primary,
        fontSize: ({ hasDescription }) => (hasDescription ? 16 : 18),
        fontWeight: 'bold',
        textAlign: ({ isOnlyName }) => isOnlyName && 'center',
        '@media (max-width: 480px)': {
            fontSize: '15px !important',
        },
    },
    paragraph: {
        color: palette.text.primary,
        fontSize: 14,
        fontWeight: 400,
        margin: '0',
        whiteSpace: 'pre-wrap',
        '@media (max-width: 480px)': {
            fontWeight: 500,
            fontSize: 11,
        },
        '.perk-4 &': {
            fontSize: 14,
            '@media (max-width: 480px)': {
                fontWeight: 500,
                fontSize: 11,
            },
        },
        '.perk-5 &': {
            fontSize: 13,
            '@media (max-width: 480px)': {
                fontWeight: 500,
                fontSize: 11,
            },
        },
    },
    lineClamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': ({ hasDescription, hasName }) =>
            hasDescription != hasName ? 4 : 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media (max-width: 480px)': {
            '-webkit-line-clamp': 3,
        },
    },
    gifts: {
        display: 'flex',
        marginTop: 8,
        alignSelf: 'stretch',
        alignItems: 'center',
        padding: `0 ${spacing(2)}px`,
        '@media (max-width: 480px)': {
            marginTop: 6,
        },
    },
    giftsData: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: typography.fontFamily,
        marginLeft: spacing(0.5),
        'html[dir=rtl] &': {
            marginLeft: 0,
            marginRight: spacing(0.5),
        },
        '@media (max-width: 480px)': {
            margin: 0,
            fontSize: 14,
        },
    },
    stockCount: {
        color: palette.secondary.main,
    },
    progressBar: {
        background: palette.grey[400],
    },
    mainProgress: {
        background: `${palette.primary.main} linear-gradient(90deg, ${palette.primary.light} 0%, ${palette.primary.main} 100%)`,
    },
    unitControls: {
        position: 'absolute',
        margin: 0,
        height: 80,
        top: '20%',
        width: 24,
        right: -14, // half of the set width + 2px border
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 18,
        backgroundColor: palette.background.paper,
        border: `2px solid ${palette.secondary.main}`,
        boxShadow: '0 3px 15px rgba(0, 0, 0, 0.1)',
        fontFamily: typography.fontFamily,
        '&.no-stock': {
            border: `2px solid ${palette.grey[300]}`,
            background: palette.grey[300],
        },
        'html[dir=rtl] &': {
            left: -14,
            right: 'auto',
        },
        '&.horizontal': {
            flexDirection: 'row-reverse',
            position: 'static',
            height: 'auto',
            maxWidth: 'initial',
            width: 85,
            border: `2px solid ${palette.text.primary}`,
            borderRadius: 0,
            background: palette.background.default,
            padding: 4,
        },
    },
    counterBtn: {
        cursor: 'pointer',
        padding: 5,
        '& svg': {
            stroke: palette.text.primary,
            fontSize: typography.pxToRem(16),
        },
        '&.disabled': {
            '& svg': {
                stroke: changeColorAlpha(palette.text.primary, 0.4),
            },
            cursor: 'no-drop',
            pointerEvents: 'auto',
        },
        '&:hover': {
            background: 'none',
        },
    },
    disabled: {
        opacity: 0.35,
    },
    unitsCount: {
        color: palette.text.primary,
        fontWeight: 600,
        cursor: 'default',
        textAlign: 'center',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        margin: '2px 0',
    },
    bottomRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: -16,
        width: '100%',
        zIndex: 0,
    },
    mobileCounterBtn: {
        '& svg': {
            color: palette.secondary.main,
            fontSize: 32,
        },
        cursor: 'pointer',
        padding: 0,
        margin: '0 -5px',
        '&.disabled': {
            '& svg': {
                filter: 'grayscale(75%)',
            },
            pointerEvents: 'none',
        },
    },
    giftsItem: {
        justifySelf: 'center',
        '@media (max-width: 480px)': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
        },
    },
}));

const PerkButton = withStyles(({ palette }) => ({
    root: {
        background: `linear-gradient(90deg, ${adjustColor(
            palette.secondary.main,
            10,
        )} 0%, ${palette.secondary.main} 35%)`,
        borderRadius: 60,
        border: 0,
        color: isLightColor(palette.secondary.main, 0.7)
            ? palette.text.primary
            : palette.common.white,
        height: 34,
        width: '100%',
        maxWidth: 208,
        margin: '0 8px',
        alignSelf: 'center',
        boxShadow: `0px 0px 6px ${changeColorAlpha(palette.grey[600], 0.5)}`,
        transition: 'all .25s ease-in',
        '@media (max-width: 480px)': {
            padding: '4px 8px',
            height: 30,
            flex: 1,
        },
        '&[disabled]': {
            backgroundColor: palette.grey[100],
            filter: 'grayscale(50%)',
            pointerEvents: 'none',
            cursor: 'no-drop',
        },
    },
    label: {
        textTransform: 'capitalize',
        fontSize: 17,
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: 0,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        '.perk-4 &': {
            fontSize: 16,
        },
        '@media (max-width: 480px)': {
            fontSize: '13px !important',
        },
    },
}))(Button);

const areLinesClamped = ref => {
    if (ref) {
        const scrollHeight = ref.current?.scrollHeight;
        const clientHeight = ref.current?.clientHeight;
        return scrollHeight > clientHeight + 1; // due to a 1 pixel offset with the scroll height
    }
    return false;
};

function Perk(props) {
    const {
        id,
        logo,
        color,
        inStock,
        className,
        isShowAmountSold,
        isShowAmountLeft,
        isBecomeGrey,
        isLimited,
        isSelectEnabled,
        limit,
        isStock,
        soldCount,
        paymentOptions,
        duration,
        disabled,
        isNameEnabled,
        isDescriptionEnabled,
        perkGaps,
    } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { isMobile } = useIsMobile();

    const descRef = useRef();
    const nameRef = useRef();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [shouldShowOpenBtn, setShouldShowOpenBtn] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        if (areLinesClamped(descRef) || areLinesClamped(nameRef)) {
            setShouldShowOpenBtn(true);
        }
    }, [descRef, nameRef]);

    const pageName = useSelector(selectPageName);
    const campaign = useSelector(selectSelectedCampaign);
    const tab = useSelector(selectTabs);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const selectedLayerItemOnCampaignPage = useSelector(
        teamSlice.selectors.selectSelectedItem,
    );
    const selectedLayerItemOnLayerPage = useSelector(selectSelectedLayerItem);
    const amount = useMemo(
        () => selectPageOptions(props, 'amount'),
        [selectPageOptions],
    );
    const totalAmount = amount * count;
    const name = useMemo(
        () => selectPageOptions(props, 'name'),
        [selectPageOptions],
    );
    const description = useMemo(
        () => selectPageOptions(props, 'description'),
        [selectPageOptions],
    );
    const currency = useMemo(
        () => selectPageOptions(props, 'currency'),
        [selectPageOptions],
    );
    const isActive = useMemo(
        () => selectPageOptions(props, 'isActive'),
        [selectPageOptions],
    );
    let selectedLayerItem =
        selectedLayerItemOnLayerPage || selectedLayerItemOnCampaignPage;

    if (selectedLayerItem) {
        const layerId = selectedLayerItem.layerId;
        tab.map(layer => {
            if (layer.layer) {
                if (layer.layer.id === layerId && !layer.layer.allowDonations) {
                    selectedLayerItem = null;
                }
            }
        });
    }

    const hasName = name?.length && isNameEnabled;
    const hasDescription = description?.length && isDescriptionEnabled;
    const shouldShowInfoSection = hasDescription || hasName;
    const isOnlyName = hasName && !hasDescription && !logo;
    const currentlyInStock = inStock - soldCount;
    const isStockEmpty = isStock && currentlyInStock <= 0;
    const isLastOne = isStock && currentlyInStock === 1;
    const shouldShowProgressBar = isShowAmountLeft && isShowAmountSold;
    const shouldShowGiftsSection =
        !!inStock && (isShowAmountLeft || isShowAmountSold);
    const shouldShowCounter =
        isSelectEnabled && !(isLimited && limit <= 1) && amount > 0;
    const canDecrementCounter = () => isSelectEnabled && count > 1;
    const canIncrementCounter = () => {
        if (disabled) {
            return false;
        }
        if (isSelectEnabled) {
            if (isStock) {
                return (
                    count < currentlyInStock &&
                    (isLimited ? count < limit : true)
                );
            }
            if (isLimited) {
                return count < limit;
            }
        }
        return isSelectEnabled;
    };

    const classes = useStyles({
        isBecomeGrey,
        isStockEmpty,
        color,
        isMobile,
        hasName,
        hasDescription,
        isOnlyName,
        ...perkGaps,
    });

    const handleDonate = useCallback(() => {
        if (isStockEmpty) {
            return null;
        }
        dispatch(updateDonationAmount(totalAmount));
        dispatch(updateGivingCount(count));
        dispatch(updateGivingAmount(amount));
        dispatch(
            updateGivingPaymentOptions({
                type: paymentOptions,
                duration: duration,
            }),
        );
        dispatch(updateDonationCurrency(currency));
        dispatch(open({ perk: id, layerItem: selectedLayerItem }));
        dispatch(updateGivingId(id));
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        history.push(
            `${history.location.pathname.replace(
                /\/+$/,
                '',
            )}/donate/${queryString}`,
        );
    }, [count, selectedLayerItem]);

    const incrementCounter = () => {
        if (canIncrementCounter()) {
            setCount(count + 1);
        }
    };

    const decrementCounter = () => {
        if (canDecrementCounter()) {
            setCount(count - 1);
        }
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const getPerkAmountLabel = () => {
        const recurringCount = paymentOptions === 'recurring' ? duration : 1;
        const amountLabel = `${currencySign(
            currency,
        )}${totalAmount.toLocaleString('en-EN')}${
            recurringCount > 1 ? `×${recurringCount}` : ``
        }`;
        return <span dir="ltr">{amountLabel}</span>;
    };

    const renderDonateBtnLabel = () => {
        if (amount > 0) {
            return (
                <>
                    <span>
                        <FormattedMessage id="Perks.donate" />
                        &nbsp;
                    </span>
                    {getPerkAmountLabel()}
                </>
            );
        }
        return <FormattedMessage id="Perks.donate" />;
    };

    const PerkGifts = () => (
        <div className={classes.giftsData}>
            <div>{renderStockLabel()}</div>
            {shouldShowProgressBar && (
                <Progress
                    showLabel={false}
                    percent={100 - (currentlyInStock * 100) / inStock}
                    className={classes.progressBar}
                    mainProgressClassName={classes.mainProgress}
                />
            )}
        </div>
    );

    const PerkCounter = ({ orientation = 'vertical' }) => {
        return (
            <div
                className={classNames(classes.unitControls, {
                    ['no-stock']: isStockEmpty || disabled,
                    horizontal: orientation === 'horizontal',
                })}
            >
                <IconButton
                    className={classNames(
                        classes.counterBtn,
                        canIncrementCounter() ? '' : 'disabled',
                    )}
                    disabled={!canIncrementCounter()}
                    onClick={incrementCounter}
                >
                    <SvgIcon component={PlusIcon} />
                </IconButton>
                <span className={classes.unitsCount}>
                    {isStockEmpty ? 0 : count}
                </span>
                <IconButton
                    className={classNames(
                        classes.counterBtn,
                        canDecrementCounter() ? '' : 'disabled',
                    )}
                    disabled={!canDecrementCounter()}
                    onClick={decrementCounter}
                >
                    <SvgIcon component={MinusIcon} />
                </IconButton>
            </div>
        );
    };
    PerkCounter.propTypes = {
        orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    };

    const PerkBottomRow = ({ children }) => (
        <div className={classes.bottomRow}>
            {isMobile && shouldShowCounter ? (
                <>
                    <IconButton
                        className={classNames(
                            classes.mobileCounterBtn,
                            canDecrementCounter() ? '' : 'disabled',
                        )}
                        onClick={decrementCounter}
                    >
                        <SvgIcon component={MinusOutlineIcon} />
                    </IconButton>
                    {children}
                    <IconButton
                        onClick={incrementCounter}
                        className={classNames(
                            classes.mobileCounterBtn,
                            canIncrementCounter() ? '' : 'disabled',
                        )}
                    >
                        <SvgIcon component={PlusOutlineIcon} />
                    </IconButton>
                </>
            ) : (
                children
            )}
        </div>
    );
    PerkBottomRow.propTypes = {
        children: PropTypes.node,
    };

    const renderStockLabel = () => {
        if (!isShowAmountLeft) {
            return isShowAmountSold ? (
                <>
                    <span className={classes.stockCount}>{soldCount}</span>{' '}
                    <span>
                        <FormattedMessage id="Perks.giftsSold" />
                    </span>
                </>
            ) : (
                <></>
            );
        }
        const count = `${currentlyInStock}${
            isShowAmountSold && inStock >= 0 ? `/${inStock}` : ``
        }`;
        return (
            <>
                <span className={classes.stockCount}>{count}</span>{' '}
                <span className={classes.stockText}>
                    <FormattedMessage id="Perks.giftsLeft" />
                </span>
            </>
        );
    };

    if (!isActive) {
        return null;
    }

    return (
        <div className={classNames(classes.root, className)}>
            <Paper elevation={2} className={classes.perkItem}>
                <div className={classes.topBar}>
                    {isStockEmpty && (
                        <div
                            className={classNames(
                                classes.statusLabel,
                                classes.outOfStock,
                            )}
                        >
                            <FormattedMessage id="Perks.soldOut" />
                        </div>
                    )}
                    {isLastOne && (
                        <div
                            className={classNames(
                                classes.statusLabel,
                                classes.lastOne,
                            )}
                        >
                            <FormattedMessage id="Perks.lastOne" />
                        </div>
                    )}
                    <PerkPopup
                        {...{
                            ...props,
                            name,
                            hasName,
                            description,
                            hasDescription,
                            amount,
                            totalAmount,
                            currency,
                            isActive,
                            currentlyInStock,
                            isStockEmpty,
                            handleDonate,
                            DonateButton: PerkButton,
                            PerkCounter,
                            PerkGifts,
                            getPerkAmountLabel,
                            handleOpenPopup,
                            handleClosePopup,
                            isPopupOpen,
                            shouldShowGiftsSection,
                            shouldShowCounter,
                            shouldShowOpenBtn,
                        }}
                    />
                </div>
                <div
                    className={classNames(classes.content, 'perk-content')}
                    onClick={handleOpenPopup}
                >
                    <figure
                        className={classNames(classes.imagePlaceholder, {
                            [classes.noImage]: !logo,
                            [classes.imageOnly]: logo && !shouldShowInfoSection,
                            [classes.disabled]: disabled || isStockEmpty,
                        })}
                    >
                        {logo && <img src={logo} className={classes.logo} />}
                    </figure>
                    {shouldShowInfoSection && (
                        <>
                            <div
                                className={classNames(classes.text, {
                                    [classes.disabled]:
                                        disabled || isStockEmpty,
                                })}
                            >
                                {hasName ? (
                                    <span
                                        className={classNames(
                                            classes.title,
                                            classes.lineClamp,
                                        )}
                                        ref={nameRef}
                                    >
                                        {name}
                                    </span>
                                ) : null}{' '}
                                {hasDescription ? (
                                    <p
                                        className={classNames(
                                            classes.paragraph,
                                            classes.lineClamp,
                                        )}
                                        ref={descRef}
                                    >
                                        {description}
                                    </p>
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={classNames({
                        [classes.gifts]: shouldShowGiftsSection,
                        [classes.disabled]: disabled || isStockEmpty,
                    })}
                >
                    {shouldShowGiftsSection && <PerkGifts />}
                </div>
                {shouldShowCounter && !isMobile && (
                    <PerkCounter orientation="vertical" />
                )}
            </Paper>
            <PerkBottomRow>
                <PerkButton
                    onClick={handleDonate}
                    disabled={disabled || isStockEmpty}
                    className={'perk-donate-btn'}
                >
                    {renderDonateBtnLabel()}
                </PerkButton>
            </PerkBottomRow>
        </div>
    );
}

Perk.propTypes = {
    id: PropTypes.number,
    logo: PropTypes.string,
    color: PropTypes.string,
    inStock: PropTypes.number,
    className: PropTypes.string,
    isShowAmountSold: PropTypes.bool,
    isShowAmountLeft: PropTypes.bool,
    isBecomeGrey: PropTypes.bool,
    isLimited: PropTypes.bool,
    isSelectEnabled: PropTypes.bool,
    limit: PropTypes.number,
    isStock: PropTypes.bool,
    soldCount: PropTypes.number,
    paymentOptions: PropTypes.string,
    duration: PropTypes.number,
    disabled: PropTypes.bool,
    isNameEnabled: PropTypes.bool,
    isDescriptionEnabled: PropTypes.bool,
    isPriceEnabled: PropTypes.bool,
    perkGaps: PropTypes.shape({
        columnGap: PropTypes.number,
        columnGapMobile: PropTypes.number,
        rowGap: PropTypes.number,
        rowGapMobile: PropTypes.number,
    }),
};

export default Perk;
