import { create } from 'client/helpers/axios';

const repository = {
    axios: create({ baseUrl: '/progress' }),

    convertGoal(fromCode, toCode, goal) {
        return this.axios
            .get(`/convert-goal/${fromCode}/${toCode}/${goal}`)
            .then(res => res.data);
    },
};

export default repository;
