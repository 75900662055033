import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';

class EmailTemplateRepository extends Repository {
    async getTemplate(pageData) {
        try {
            const queryString = Repository.prepareQueryString(pageData);
            const response = await this.axios.get(
                `/get${queryString ? `?${queryString}` : ''}`,
            );
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async setTemplate(templateData) {
        try {
            const response = await this.axios.post(`/set`, templateData);
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }
}

export default new EmailTemplateRepository({ baseUrl: '/emailtemplate' });
