import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormGroup from '../FormGroup/FormGroup';
import FormInput from '../FormInput/FormInput';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import MultiplierIcon from '../MultiplierIcon/MultiplierIcon';
import { scrollToElement } from '../../hooks/scrollTo';
import AmountInput from 'client/components/CampaignPage/components/AmountInput/AmountInput';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        display: 'grid',
        gridGap: '21px',
        gap: '21px',
        alignItems: 'center',
        direction: 'ltr',

        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
            gridGap: '16px',
            gap: '16px',
        },
        'html[dir=rtl] &': {
            direction: 'rtl',
        },
    },
    currencyAmountInputX1: {
        gridTemplateColumns: '1fr',
    },
    currencyAmountInputX2: {
        gridTemplateColumns: '1fr 33px 1fr',

        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
        },
    },
    currencyAmountInputXDecimal: {
        gridTemplateColumns: '1fr 43.35px 1fr',

        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
        },
    },
    icon: {
        '@media (max-width: 600px)': {
            margin: '0 auto',
        },
    },
    group: {
        margin: 0,
        width: '100%',
    },
    currencyAmountSelect: {
        borderRadius: '5px 0 0 5px',
        borderRight: 0,
        borderColor: palette.primary.main,
        flexShrink: 0,
        '& select': {
            paddingLeft: 15,
            color: palette.primary.main,

            'html[dir=rtl] &': {
                direction: 'rtl',
                padding: '0 20px 0 35px',
                paddingInlineEnd: '35px',
                paddingInlineStart: '15px',
            },
        },
        '&::after': {
            borderColor: palette.primary.main,
            right: '13px !important',
            left: 'auto !important',
            transform: 'translateY(calc(-50% - 1px)) rotate(135deg) !important',
            'html[dir=rtl] &': {
                left: '13px !important',
                right: 'auto !important',
            },
        },
        '@media (max-width: 600px)': {
            '& select': {
                fontSize: 15,
                height: 38,
            },
        },
        'html[dir=rtl] &': {
            borderRadius: '0px 5px 5px 0px',
            borderRight: `1px solid ${palette.primary.main}`,
            borderLeft: 0,
            direction: 'ltr',
        },
    },
    currencyAmountInput: {
        borderRadius: '0 5px 5px 0',
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            fontWeight: 500,
            fontSize: 20,
            padding: '12px 20px',
            height: 54,

            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },

            '&[type=number]': {
                '-moz-appearance': 'textfield',
            },

            '@media (max-width: 600px)': {
                padding: '5px 15px',
                fontSize: 18,
                height: 38,
            },
        },
        'html[dir=rtl] &': {
            borderRadius: '5px 0px 0px 5px',
            '& input': {
                direction: 'rtl',
            },
        },
    },
    error: {
        borderColor: palette.error.main,
        '& input': {
            color: palette.error.main,
        },
    },
    sumInput: {
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            fontWeight: 500,
            fontSize: 20,
            padding: '12px 20px',
            height: 54,

            '@media (max-width: 600px)': {
                padding: '5px 15px',
                fontSize: 18,
                height: 38,
            },
        },
        'html[dir=rtl] &': {
            direction: 'rtl',
        },
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
    },
    tooltip: {
        color: palette.primary.main,
        fontWeight: 900,
    },
}));

function PaymentMethodCurrencyWidget(props) {
    const {
        className,
        onCurrencyChange,
        onAmountChange,
        onAmountBlur,
        defaultAmount = '',
        multiplier = 1,
        currencies = [],
        currency = 'USD',
        error,
        disableAll = false,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const inputRef = useRef();
    const [decimalTooltip, setDecimalTooltip] = useState(false);

    const getSum = () => {
        const value = defaultAmount * multiplier;
        return value === 0 ? '' : value;
    };

    useEffect(() => {
        if (inputRef && inputRef.current) {
            scrollToElement(inputRef.current);
        }
    }, []);

    const isDecimalMultiplier = String(multiplier).includes('.');

    let multiplierClassName = classes.currencyAmountInputX1;
    if (multiplier !== 1) {
        multiplierClassName = isDecimalMultiplier
            ? classes.currencyAmountInputXDecimal
            : classes.currencyAmountInputX2;
    }

    return (
        <>
            <div
                className={classNames(
                    classes.root,
                    multiplierClassName,
                    className,
                )}
                ref={inputRef}
            >
                <AmountInput
                    error={error}
                    currencies={currencies}
                    currency={currency}
                    defaultAmount={defaultAmount}
                    className={classes.group}
                    onCurrencyChange={onCurrencyChange}
                    onAmountChange={onAmountChange}
                    onAmountBlur={onAmountBlur}
                    onAmountDecimal={setDecimalTooltip}
                    disabled={disableAll}
                />
                {multiplier > 1 && (
                    <>
                        <MultiplierIcon
                            width={isDecimalMultiplier ? 43.35 : 33}
                            fill={palette.primary.main}
                            multiplier={multiplier}
                            className={classes.icon}
                        />
                        <FormGroup className={classes.group}>
                            <FormInput
                                type="text"
                                name="amount"
                                value={getSum()}
                                disabled
                                className={classes.sumInput}
                            />
                        </FormGroup>
                    </>
                )}
            </div>
            <div
                className={classNames(
                    classes.root,
                    classes.currencyAmountInputX1,
                    className,
                )}
            >
                <div className={classes.messages}>
                    {decimalTooltip && (
                        <FormInputHelperText className={classes.tooltip}>
                            <FormattedMessage
                                id="EnterAmountInput.decimalTooltip"
                                defaultMessage="Decimals are not allowed in donation amount"
                            />
                        </FormInputHelperText>
                    )}
                    {error && <FormInputHelperText error={error} />}
                </div>
            </div>
        </>
    );
}

PaymentMethodCurrencyWidget.propTypes = {
    onCurrencyChange: PropTypes.func.isRequired,
    onAmountChange: PropTypes.func.isRequired,
    onAmountBlur: PropTypes.func,
    currencies: PropTypes.array,
    defaultAmount: PropTypes.number,
    multiplier: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
    error: PropTypes.object,
    disableAll: PropTypes.bool,
};

export default PaymentMethodCurrencyWidget;
