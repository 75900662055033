import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectRedirectAfterDonation } from '../campaign/campaignSlice';
import { selectLastDonation } from './lastDonationSlice';
import { stringify as stringifyQuery } from 'query-string';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        padding: 8,
        fontSize: '1rem',
    },
}));

function PostDonationRedirect() {
    const classes = useStyles();
    const {
        isRedirectAfterDonationEnabled,
        redirectAfterDonationUrl,
        includeDonationDataInRedirectUrl,
    } = useSelector(selectRedirectAfterDonation);
    const lastDonation = useSelector(selectLastDonation);

    useEffect(() => {
        if (isRedirectAfterDonationEnabled) {
            let url = redirectAfterDonationUrl;
            if (includeDonationDataInRedirectUrl && !isEmpty(lastDonation)) {
                url += `?${stringifyQuery(lastDonation)}`;
            }
            const timeout = setTimeout(() => {
                window.location.href = url;
            }, 2500);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isRedirectAfterDonationEnabled, lastDonation]);

    if (!isRedirectAfterDonationEnabled) return null;

    return (
        <div className={classes.root}>
            <FormattedMessage
                id="CheckoutPopup.redirect"
                defaultMessage="You are being redirected..."
            />
        </div>
    );
}

export default PostDonationRedirect;
