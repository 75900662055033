import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { adjustColor, changeColorAlpha } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(() => ({
    root: {
        height: 'auto',
    },
    main: {
        transform: 'scale(-1, 1)',
        transformOrigin: 'calc(50% - 2px) 50%',
    },
}));

function CountdownWidgetGraph(props) {
    const { className, percent = 0, seconds = 0, bgColor, ...rest } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const mainColor = palette.primary.main;
    const lightColor = adjustColor(mainColor, 20);
    const clockOutlineColor = palette.grey[600];

    const radius = 51;
    const line = useRef();
    const dot = useRef();
    const dotSec = useRef();
    const setIconTransform = () => {
        const poz = line.current.getPointAtLength(
            ((100 - percent) * line.current.getTotalLength()) / 100,
        );
        const pozSec = line.current.getPointAtLength(
            (seconds * line.current.getTotalLength()) / 100,
        );
        dot.current.setAttributeNS(
            null,
            'transform',
            `translate(${poz.x},${poz.y})`,
        );
        dotSec.current.setAttributeNS(
            null,
            'transform',
            `translate(${pozSec.x},${pozSec.y})`,
        );
    };

    useEffect(() => {
        setIconTransform();
    }, [percent, seconds]);

    return (
        <svg
            height="133"
            viewBox="0 0 124 133"
            width="124"
            className={classNames(classes.root, className)}
            {...rest}
        >
            <defs>
                <linearGradient
                    id="lineGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                >
                    <stop offset="0%" stopColor={mainColor} />
                    <stop offset="100%" stopColor={lightColor} />
                </linearGradient>
                <linearGradient
                    id="circleGradient"
                    gradientTransform="rotate(90)"
                >
                    <stop
                        offset="0%"
                        stopColor={changeColorAlpha(mainColor, 0.2)}
                    />
                    <stop
                        offset="100%"
                        stopColor={changeColorAlpha(lightColor, 0.05)}
                    />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(2)">
                <g className={classes.main}>
                    <path
                        d="M60,20 C31.8334778,20 9,42.8334778 9,71 C9,99.1665222 31.8334778,122 60,122 C88.1665222,122 111,99.1665222 111,71 C111,42.8334778 88.1665222,20 60,20 Z"
                        stroke="url('#circleGradient')"
                        strokeWidth="102"
                        strokeDasharray={`${(320 / 100) * (100 - percent)} 320`}
                        fillRule="nonzero"
                        opacity="55%"
                    ></path>
                    <path
                        d="M-50,-50 L-50,200 L200,200 L200,-50 L-50,-50 Z M60,21.5 C87.3380951,21.5 109.5,43.6619049 109.5,71 C109.5,98.3380951 87.3380951,120.5 60,120.5 C32.6619049,120.5 10.5,98.3380951 10.5,71 C10.5,43.6619049 32.6619049,21.5 60,21.5 Z"
                        id="Oval"
                        fill={bgColor || palette.grey[100]}
                        fillRule="nonzero"
                    ></path>
                    <circle
                        cx="60"
                        cy="71"
                        r={radius}
                        stroke={palette.grey[400]}
                        strokeLinecap="round"
                        strokeWidth="3"
                    />
                    <path
                        ref={line}
                        d="M60,20 C31.8334778,20 9,42.8334778 9,71 C9,99.1665222 31.8334778,122 60,122 C88.1665222,122 111,99.1665222 111,71 C111,42.8334778 88.1665222,20 60,20 Z"
                        fillRule="nonzero"
                        stroke="url('#lineGradient')"
                        strokeDasharray={`${(320 / 100) * (100 - percent)} 320`}
                        strokeLinecap="round"
                        strokeWidth="5"
                    ></path>
                    <circle
                        ref={dot}
                        cx="0"
                        cy="0"
                        r="3.5"
                        fill={mainColor}
                        transform="translate(-20,-20)"
                    />
                    <circle
                        ref={dotSec}
                        cx="0"
                        cy="0"
                        r="4"
                        strokeWidth="0"
                        fill={clockOutlineColor}
                        transform="translate(-20,-20)"
                    />
                </g>
                <path
                    d="m120 71.000996c0 33.136826-26.8635063 59.999004-60.000332 59.999004s-59.999668-26.862842-59.999668-59.999668c0-33.1368257 26.8621784-60.000332 59.999004-60.000332 15.9133346 0 31.1749761 6.3211664 42.427403 17.5735931 11.252427 11.2524268 17.573593 26.5140683 17.573593 42.4274029z"
                    stroke={clockOutlineColor}
                    strokeWidth="4"
                />
                <g fill={clockOutlineColor}>
                    <path
                        d="m28.8391145 5.25064281 5.1608855 5.16106249-2.4702777 2.4703624 2.4702777 2.4713549-1.646521 1.6465774-2.4712702-2.4703624-2.4702777 2.4703624-5.1608855-5.1610625c-.1607283-.1604839-.2510456-.3782969-.2510456-.6054323 0-.2271353.0903173-.4449483.2510456-.6054323l5.3772457-5.37743009c.3344344-.33419041.8763888-.33419041 1.2108232 0"
                        fillRule="nonzero"
                    />
                    <path
                        d="m91.1951536 5.22993142-5.1951536 5.19515358 2.466336 2.4653455-2.466336 2.4653455 1.644224 1.644224 2.4653455-2.466336 2.4653455 2.466336 5.1951536-5.1951536c.3065752-.3089486.3065752-.8073408 0-1.1162894l-5.4586256-5.45862558c-.3089486-.30657523-.8073408-.30657523-1.1162894 0"
                        fillRule="nonzero"
                    />
                    <path
                        d="m69.9961795 4.54072289c.0033032.49025436-.1871021.96158939-.5290084 1.30952382s-.8070684.54372483-1.2923731.54397016h-4.1026405l-.0721575 3.60578313h-7l-.0578499-3.60481928h-4.1074109c-.4866806.00025587-.9535008-.19494023-1.2976361-.54259326-.3441352-.34765303-.5373561-.81924515-.5371165-1.31090071v-2.68819277c.0036733-1.02174537.8223939-1.84926543 1.8337985-1.85349398h15.3448293c1.0087995.00477988 1.8234965.83340954 1.8213722 1.85253012z"
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </svg>
    );
}

CountdownWidgetGraph.propTypes = {
    className: PropTypes.string,
    percent: PropTypes.number,
    seconds: PropTypes.number,
    bgColor: PropTypes.string,
};

export default CountdownWidgetGraph;
