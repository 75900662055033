import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function MountsIcon({ width = 16, height = 16, color = '#FFF', ...props }) {
    return (
        <SvgIcon height={height} width={width} viewBox="0 0 16 16" {...props}>
            <g
                fill={color}
                stroke={color}
                strokeWidth={8}
                transform="matrix(0.02947228,0,0,0.03295656,0.45509632,-0.82039485)"
            >
                <path d="M 507.345,407.273 381.673,179.2 c -8.145,-17.455 -25.6,-27.927 -44.218,-27.927 -4.655,0 -10.473,1.164 -15.127,2.327 -5.818,2.327 -9.309,8.146 -6.982,15.127 2.327,5.818 8.145,9.309 15.127,6.982 11.636,-3.491 25.6,1.164 31.418,13.964 l 20.945,37.236 -4.655,2.327 c -5.818,2.327 -11.636,3.491 -16.291,3.491 h -12.8 c -6.982,0 -11.636,4.655 -11.636,11.636 0,6.981 4.655,11.637 11.637,11.637 h 12.8 c 9.309,0 17.455,-2.327 25.6,-5.818 l 4.655,-2.327 94.254,171.054 c 1.164,2.327 2.327,4.655 2.327,8.145 0,9.309 -6.982,16.291 -16.291,16.291 H 39.564 c -9.309,-1.164 -16.291,-8.146 -16.291,-17.455 0,-2.327 1.164,-5.818 2.327,-8.146 l 95.418,-182.691 16.291,11.636 c 16.291,12.8 41.891,10.473 55.855,-3.491 l 18.618,-18.618 c 5.818,-5.818 16.291,-4.655 20.945,1.164 l 16.291,22.109 c 9.309,11.636 22.109,18.618 37.236,18.618 h 22.109 l 67.491,144.291 c 2.327,5.818 9.309,8.145 15.127,5.818 5.818,-2.327 8.145,-9.309 5.818,-15.127 L 253.673,97.746 c -8.145,-17.455 -25.6,-27.927 -44.218,-27.927 -18.618,0 -36.073,10.473 -43.055,26.764 L 4.655,407.273 C 1.164,413.091 0,418.909 0,425.891 0,448 17.455,465.455 39.564,465.455 H 472.437 C 494.546,465.455 512,448 512,425.891 c 0,-6.982 -1.164,-12.8 -4.655,-18.618 z m -320,-300.218 c 3.491,-8.145 12.8,-13.964 22.109,-13.964 9.309,0 18.618,5.818 22.109,13.964 l 65.164,137.309 h -11.636 c -6.982,0 -13.964,-3.491 -18.618,-9.309 l -16.291,-22.109 c -6.982,-9.309 -18.618,-15.127 -29.091,-15.127 -10.473,0 -19.782,3.491 -26.764,10.473 l -18.618,18.618 c -6.982,6.982 -17.455,6.982 -25.6,2.327 l -18.618,-13.964 z" />
            </g>
        </SvgIcon>
    );
}

MountsIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

export default MountsIcon;
