import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Text from '../Text/Text';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: ({ largeFont }) => ({
        display: 'grid',
        columnGap: spacing(0.5),
        marginRight: largeFont ? 0 : spacing(1),
        marginBottom: largeFont ? 0 : spacing(0.5),
        '@media (min-width: 600px)': {
            rowGap: largeFont ? spacing(0.5) : 0,
        },
    }),
    label: {
        fontSize: '1rem',
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(12),
            marginBottom: 0,
        },
    },
    goalText: {
        textAlign: 'start',
        gridArea: '2 / 2 / 3 / 3',
    },
    goalTextLarge: {
        fontSize: '1.25rem',
        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    icon: {
        display: 'block',
        padding: 3,
        background: `linear-gradient(90deg, ${palette.secondary.light} 30%, ${palette.secondary.main} 100%)`,
        borderRadius: 5,
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.15)',
        strokeWidth: 0.5,
        width: typography.pxToRem(20),
        height: typography.pxToRem(20),
        gridArea: '1 / 1 / 2 / 2',

        '@media (max-width: 600px)': {
            width: typography.pxToRem(16),
            height: typography.pxToRem(16),
        },
    },
    amount: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        fontSize: '1.125rem',
        fontWeight: 600,
        color: palette.text.primary,
        gridArea: '1 / 2 / 2 / 3',

        '@media (max-width: 600px)': {
            fontSize: '1rem',
            lineHeight: 1,
        },
    },
    amountLarge: {
        fontSize: '1.875rem',
        '@media (max-width: 600px)': {
            fontSize: '1.5rem',
        },
    },
}));

function DonationWidgetAdditional(props) {
    const {
        id = '',
        className,
        icon: Icon,
        label = '',
        amount = 0,
        currencySign = '$',
        largeFont = false,
    } = props;
    const classes = useStyles({ largeFont });
    const { palette } = useTheme();

    return (
        <div className={classNames(classes.root, className)}>
            {Icon && (
                <Icon
                    color={palette.secondary.contrastText}
                    className={classes.icon}
                />
            )}
            <Text
                className={classNames(classes.amount, {
                    [classes.amountLarge]: largeFont,
                })}
            >
                {currencySign}
                {amount.toLocaleString('en-EN')}
            </Text>
            <Text
                className={classNames(classes.label, classes.goalText, {
                    [classes.goalTextLarge]: largeFont,
                })}
            >
                {label && id ? (
                    <FormattedMessage id={id} defaultMessage={label} />
                ) : (
                    label
                )}
            </Text>
        </div>
    );
}

DonationWidgetAdditional.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.func,
    label: PropTypes.string,
    amount: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
    currencySign: PropTypes.string,
    largeFont: PropTypes.bool,
};

export default DonationWidgetAdditional;
