import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import usePreventDecimal from '../../hooks/preventDecimal';
import Select from '../Select/Select';
import FormInputHelperText from '../FormInputHelperText/FormInputHelperText';
import { FormattedMessage } from 'react-intl';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    const inputColor = palette.primary.main;
    return {
        root: {
            position: 'relative',
            width: props => props.width,
            display: 'flex',
            flexDirection: 'column',

            '@media (max-width: 525px)': {
                width: 'auto',
            },
        },
        container: {
            position: 'relative',
            width: '100%',
        },
        input: {
            width: '100%',
            paddingTop: 14,
            paddingBottom: 14,
            paddingInlineStart: '120px',
            paddingInlineEnd: '28px',
            boxSizing: 'border-box',
            border: `2px solid ${inputColor}`,
            borderRadius: 5,
            color: inputColor,
            fontFamily: typography.fontFamily,
            fontWeight: 500,
            fontSize: 22,
            textAlign: 'right',
            background: palette.background.default,
            boxShadow: '0px 3px 6px #00000029',
            '-moz-appearance': 'textfield',
            '&::-webkit-input-placeholder': {
                color: inputColor,
                textAlign: 'left',
            },
            '&::-moz-placeholder': {
                color: inputColor,
                textAlign: 'left',
            },
            '&:-moz-placeholder': {
                color: inputColor,
                textAlign: 'left',
            },
            '&:-ms-input-placeholder': {
                color: inputColor,
                textAlign: 'left',
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            ':not(html[dir=rtl]) &': {
                paddingInlineEnd: ({ hasValue }) =>
                    hasValue ? '45px' : '28px',

                '@media (max-width: 600px)': {
                    paddingInlineEnd: ({ hasValue }) =>
                        hasValue ? '40px' : '15px',
                },
            },
            'html[dir=rtl] &': {
                paddingInlineStart: ({ hasValue }) =>
                    hasValue ? '150px' : '120px',

                '@media (max-width: 600px)': {
                    paddingInlineStart: ({ hasValue }) =>
                        hasValue ? '145px' : '120px',
                },
            },
            '@media (max-width: 600px)': {
                fontSize: 17,
                fontWeight: 400,
                paddingTop: 10,
                paddingBottom: 10,
                paddingInlineEnd: '15px',
            },
        },
        singleCurrency: {
            position: 'absolute',
            left: 15,
            top: '50%',
            transform: 'translateY(-50%)',
            border: `2px solid ${inputColor}`,
            borderRadius: 16,
            overflow: 'visible',
            fontSize: 15,
            height: 26,
            color: inputColor,
            padding: '5px 15px 0 15px',

            'html[dir=rtl] &': {
                left: 'auto',
                right: 15,
            },
        },
        select: {
            position: 'absolute',
            left: 15,
            top: '50%',
            transform: 'translateY(-50%)',
            border: `2px solid ${inputColor}`,
            borderRadius: 16,
            overflow: 'visible',

            '& select': {
                fontSize: 15,
                height: 30,
                color: inputColor,
                padding: '0 35px 0 15px',
            },
            '&::before': {
                left: 'auto !important',
                right: '-2px !important',
                backgroundColor: changeColorAlpha(palette.primary.light, 0.2),
                background: `linear-gradient(0deg, ${changeColorAlpha(
                    palette.primary.light,
                    0.2,
                )} 0%, ${changeColorAlpha(palette.primary.dark, 0.4)} 100%)`,
                border: 'none',
                borderLeft: `2px solid ${inputColor}`,
                borderRadius: '0 16px 16px 0',
            },
            '&::after': {
                borderColor: `${inputColor}`,
                right: '11px !important',
                left: 'auto !important',
            },

            'html[dir=rtl] &': {
                left: 'auto',
                right: 15,
            },

            '@media (max-width: 600px)': {
                '& select': {
                    fontSize: 13,
                    height: 20,
                    color: inputColor,
                    transform: 'translateY(-10%)',
                },
            },
        },
        suffix: {
            color: inputColor,
            fontFamily: typography.fontFamily,
            fontWeight: 500,
            fontSize: 22,
            direction: 'ltr',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 15,

            'html[dir=rtl] &': {
                right: 120,

                '@media (max-width: 600px)': {
                    top: '50%',
                    transform: 'translateY(calc(-50% - 1px))',
                },
            },

            '@media (max-width: 600px)': {
                fontSize: 17,
                fontWeight: 400,
                top: '48%',
            },
        },
        tooltip: {
            color: inputColor,
            fontWeight: 900,
            padding: spacing(1),
        },
    };
});

function MakeDonationInput(props) {
    const {
        options = [],
        className,
        placeholder = '',
        width = 'auto',
        selectProps,
        ...rest
    } = props;
    const classes = useStyles({ width, hasValue: !!rest.value });
    const [handleKeyDown, ignoreChange] = usePreventDecimal();

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <div className={classes.container}>
                <input
                    type="text"
                    inputMode="numeric"
                    placeholder={placeholder}
                    className={classes.input}
                    {...rest}
                    onKeyDown={handleKeyDown}
                />
                {options.length > 1 && (
                    <Select
                        className={classes.select}
                        {...selectProps}
                        options={options}
                        disabled={options.length <= 1}
                    />
                )}

                {options.length === 1 && (
                    <div className={classes.singleCurrency}>
                        {options[0].name}
                    </div>
                )}
                {!!rest.value && <span className={classes.suffix}>.00</span>}
            </div>
            {ignoreChange && (
                <FormInputHelperText className={classes.tooltip}>
                    <FormattedMessage
                        id="EnterAmountInput.decimalTooltip"
                        defaultMessage="Decimals are not allowed in donation amount"
                    />
                </FormInputHelperText>
            )}
        </div>
    );
}

MakeDonationInput.propTypes = {
    options: PropTypes.array,
    placeholder: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectProps: PropTypes.object,
    className: PropTypes.string,
};

export default MakeDonationInput;
