import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import MoreButton from 'client/components/CampaignPage/components/MoreButton/MoreButton';
import Loader from 'client/components/Loader';
import { DONATION_PAYMENT_MODE, SORT_BY } from 'common/constants';
import { convertPaymentModeForFrontEnd } from 'common/helpers/paymentMode';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import filterQueryParams from '../../../../helpers/filterQueryParams';
import DonationTabContent from '../../components/DonationTabContent/DonationTabContent';
import DonationTabContentSearch from '../../components/DonationTabContentSearch/DonationTabContentSearch';
import ItemCardsGrid from '../../components/ItemCardsGrid/ItemCardsGrid';
import Text from '../../components/Text/Text';
import {
    selectCurrentSlug,
    selectIsDonorPflEnabled,
    selectIsMatchMyGiftEnabled,
    selectPageName,
    selectSelectedCampaignAccountId,
    selectSelectedCampaignId,
    selectSelectedLayerItem,
} from '../../features/campaign/campaignSlice';
import { updatePaymentMode } from '../../features/checkout/checkoutPopupSlice';
import {
    updateDonationAmount,
    updateDonationCurrency,
    updateInstallmentMonths,
    updateMatchMyGift,
    updateMatchedDonor,
    updateRecurringMonths,
} from '../../features/checkout/checkoutSlice';
import useAutoLoad from '../../features/recentDonationPanel/useAutoLoad';
import DonationFlasher from '../DonationFlasher/DonationFlasher';
import DonorItem from '../DonorItem/DonorItem';
import { changeColorAlpha } from '../../../../helpers/colorUtils';
import getPaymentModeName from 'common/helpers/getPaymentModeName';
import { sendAnalyticsEvent } from '../../../../services/analytics';

const LightTooltip = withStyles(({ palette }) => ({
    tooltip: {
        backgroundColor: palette.common.white,
        color: palette.grey[600],
        boxShadow: `0 2px 15px ${changeColorAlpha(palette.grey[300], 0.3)}`,
        fontSize: '0.688rem',
        fontFamily: 'Heebo, sans-serif',
        borderRadius: 0,
        padding: '1em',
        fontWeight: 'normal',
    },
    arrow: {
        color: palette.common.white,
    },
}))(Tooltip);

const useStyles = makeStyles(({ palette }) => ({
    root: {},

    moreBtn: {
        display: 'block',
        margin: '90px auto 115px',

        '@media (max-width: 1023px)': {
            margin: '45px auto 60px',
        },
    },

    layerItem: {
        marginBottom: 60,
    },
    matchingText: {
        marginBottom: 0,
        color: palette.primary.main,
        fontFamily: 'Heebo, sans-serif',
        fontSize: '0.9rem',
    },
}));

function DonorsList(props) {
    const {
        isLoading,
        query,
        handleQueryChange,
        donors,
        groupedDonations,
        handleAppendNextPage,
        handleAutoLoad,
        isEndCollection,
        allowAutoLoad,
        listRef,
        multiplier,
    } = props;
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const campaignSlug = useSelector(selectCurrentSlug);
    const isMatchMyGiftEnabled = useSelector(selectIsMatchMyGiftEnabled);
    const isDonorPflEnabled = useSelector(selectIsDonorPflEnabled);
    const dispatch = useDispatch();
    const history = useHistory();
    const campaignId = useSelector(selectSelectedCampaignId);
    const campaignAccountId = useSelector(selectSelectedCampaignAccountId);
    const pageName = useSelector(selectPageName);
    const pageLayerItem = useSelector(selectSelectedLayerItem);

    useAutoLoad(
        allowAutoLoad && !isLoading && !isEndCollection,
        handleAutoLoad,
    );

    const onMatchMyGiftClicked = donor => {
        const { amount, currency } = donor;

        const paymentType = convertPaymentModeForFrontEnd(donor.paymentMode);
        if (donor.paymentMode === DONATION_PAYMENT_MODE.monthly) {
            dispatch(updateRecurringMonths(donor.recurringMonths));
        }
        if (donor.paymentMode === DONATION_PAYMENT_MODE.installments) {
            dispatch(updateInstallmentMonths(donor.installmentMonths));
        }
        dispatch(updateDonationAmount(Math.round(amount)));
        dispatch(updatePaymentMode(paymentType));
        dispatch(updateMatchMyGift(true));
        dispatch(updateMatchedDonor(donor));
        dispatch(updateDonationCurrency(currency));

        const analyticsEventData = {
            event: 'campaign:mmg_button_click',
            org_account_id: campaignAccountId,
            campaign_id: campaignId,
            campaign_sub_page: pageName,
            page_language:
                pageLayerItem?.language || document.documentElement.lang,
            is_layer_item_page: !!pageLayerItem?.id,
            mmg_cta_donation_id: donor.id,
            mmg_cta_amount: Math.round(amount),
            mmg_cta_currency: currency,
            mmg_cta_payment_mode: getPaymentModeName(donor.paymentMode),
            mmg_cta_has_comment: !!donor.comment,
            mmg_cta_has_layer_item: !!donor.layerItem?.id,
            mmg_cta_mmg_count: donor.matchedDonationsCount,
            mmg_cta_pfl_count: donor.donorPflCount,
        };
        sendAnalyticsEvent(analyticsEventData);

        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        history.push(
            `${history.location.pathname.replace(
                /\/+$/,
                '',
            )}/donate/${queryString}`,
        );
    };

    return (
        <DonationTabContent>
            <DonationTabContentSearch
                disabled={isLoading}
                order={query.order}
                searchText={query.searchText}
                sortOptions={{
                    [SORT_BY.CREATED_AT_DESC]: formatMessage({
                        id: 'SortBy.createdAt.desc',
                    }),
                    [SORT_BY.DONATION_NAME_ASC]: formatMessage({
                        id: 'SortBy.name.asc',
                    }),
                    [SORT_BY.DONATION_NAME_DESC]: formatMessage({
                        id: 'SortBy.name.desc',
                    }),
                    [SORT_BY.DONATION_AMOUNT_DESC]: formatMessage({
                        id: 'SortBy.amount.desc',
                    }),
                }}
                onChange={handleQueryChange}
            />
            {multiplier > 1 && (
                <Typography
                    variant="subtitle1"
                    className={classNames(
                        classes.matchingText,
                        'rdp-matchingtext',
                    )}
                >
                    {formatMessage({ id: 'RDP.matchingText' })}
                    <LightTooltip
                        arrow
                        title={formatMessage({ id: 'RDP.matchingTextDesc' })}
                        enterTouchDelay={0}
                    >
                        <IconButton aria-label="info">
                            <InfoIcon />
                        </IconButton>
                    </LightTooltip>
                </Typography>
            )}
            <DonationFlasher />
            <ItemCardsGrid
                className="rdp-list"
                listRef={listRef}
                columns={3}
                gap="25px"
            >
                {donors.length
                    ? donors.map(donor => (
                          <DonorItem
                              key={donor.id}
                              donor={donor}
                              campaignSlug={campaignSlug}
                              isMatchMyGiftEnabled={isMatchMyGiftEnabled}
                              onMatchMyGiftClicked={onMatchMyGiftClicked}
                              isDonorPflEnabled={isDonorPflEnabled}
                              isFirstInGroup={
                                  groupedDonations[donor.donorPflSlug]?.[0]
                                      ?.id === donor.id
                              }
                          />
                      ))
                    : !isLoading && (
                          <Text>
                              {formatMessage({ id: 'RDP.emptyDonorList' })}
                          </Text>
                      )}
            </ItemCardsGrid>
            <Loader
                isLoading={!isEndCollection && (allowAutoLoad || isLoading)}
            />
            {!allowAutoLoad && !isLoading && !isEndCollection && (
                <MoreButton
                    disabled={isLoading}
                    onClick={handleAppendNextPage}
                    className={classNames(classes.moreBtn, 'rdp-loadmore')}
                >
                    {formatMessage({ id: 'RDP.loadMore' })}
                </MoreButton>
            )}
        </DonationTabContent>
    );
}

DonorsList.propTypes = {
    isLoading: PropTypes.bool,
    query: PropTypes.shape({
        order: PropTypes.string,
        searchText: PropTypes.string,
    }),
    donors: PropTypes.array,
    groupedDonations: PropTypes.object,
    isEndCollection: PropTypes.bool,
    allowAutoLoad: PropTypes.bool,
    handleAppendNextPage: PropTypes.func,
    handleQueryChange: PropTypes.func,
    handleAutoLoad: PropTypes.func,
    listRef: PropTypes.object,
    multiplier: PropTypes.number,
};

export default DonorsList;
