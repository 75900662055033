import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ValidIcon } from '../components/FormInput/ValidIcon.svg';
import { ReactComponent as InvalidIcon } from '../components/FormInput/InvalidIcon.svg';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette }) => {
    const validColor = palette.success.dark;
    const invalidColor = palette.error.main;

    return {
        FormInputFieldValid: {
            border: `1px solid ${validColor} !important`,
            color: validColor,
            backgroundColor: changeColorAlpha(palette.success.light, 0.1),
            '&:before': {
                background: validColor,
            },
            '& input': {
                color: validColor,
            },
            '& svg:first-child path': {
                fill: validColor,
            },
            '& svg:first-child circle': {
                fill: validColor,
            },
            '& svg:first-child rect': {
                fill: validColor,
            },
            '& .styledStartAdornment': {
                color: validColor,
            },
            '&.noMuiBordersOnFocus fieldset': {
                border: 'none !important',
            },
        },
        FormInputFieldInvalid: {
            border: `1px solid ${invalidColor} !important`,
            color: invalidColor,
            backgroundColor: changeColorAlpha(palette.error.light, 0.1),
            '&:before': {
                background: invalidColor,
            },
            '& input': {
                color: invalidColor,
            },
            '& svg:first-child path': {
                fill: invalidColor,
            },
            '& svg:first-child circle': {
                fill: invalidColor,
            },
            '& svg:first-child rect': {
                fill: invalidColor,
            },
            '& .styledStartAdornment': {
                color: invalidColor,
            },
            'html[dir=rtl] & svg:last-child text': {
                transform: 'translate(12px, 18px)',
            },
            '&.noMuiBordersOnFocus fieldset': {
                border: 'none !important',
            },
        },
    };
});

function getResult(
    name,
    dirtyFields,
    errors,
    getValues,
    isTouched,
    { validResult = null, invalidResult = null },
) {
    if (errors && errors[name]) {
        return invalidResult;
    }

    if (
        (isTouched || (dirtyFields && dirtyFields[name])) &&
        Boolean(getValues(name))
    ) {
        return validResult;
    }

    return null;
}

export default function useFormStyling(
    dirtyFields = {},
    errors = {},
    getValues = () => true,
) {
    const classes = useStyles();

    const getFormClass = (
        name,
        { extraFields = {}, extraErrors = {}, isTouched = false } = {},
    ) => {
        return getResult(
            name,
            { ...dirtyFields, ...extraFields },
            { ...errors, ...extraErrors },
            getValues,
            isTouched,
            {
                validResult: classes.FormInputFieldValid,
                invalidResult: classes.FormInputFieldInvalid,
            },
        );
    };

    const getFormIcon = (
        name,
        { extraFields = {}, extraErrors = {}, isTouched = false } = {},
    ) => {
        return getResult(
            name,
            { ...dirtyFields, ...extraFields },
            { ...errors, ...extraErrors },
            getValues,
            isTouched,
            {
                validResult: <ValidIcon />,
                invalidResult: <InvalidIcon />,
            },
        );
    };

    return [getFormClass, getFormIcon];
}
