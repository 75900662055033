import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button/Button';
import BitLogo from '../BitLogo/BitLogo';
import classNames from 'classnames';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette, zIndex }) => {
    const textColor = isLightColor(palette.secondary.main, 0.7)
        ? palette.text.primary
        : palette.common.white;
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        },

        donateButton: {
            borderRadius: 0,
            width: 180,
            height: '100%',
            textAlign: 'center',
            fontSize: props => (props.isBitEnabled ? 30 : 39),
            lineHeight: 1.2,
            fontWeight: 500,
            color: textColor,
            display: 'inline-block',
            border: `1px solid ${
                isLightColor(palette.secondary.main, 0.7)
                    ? textColor
                    : palette.secondary.main
            }`,
            backgroundColor: palette.secondary.main,
            backgroundSize: '200% 200%',
            backgroundImage: `repeating-radial-gradient(circle at -90px -10px, ${
                palette.secondary.main
            } 0, ${changeColorAlpha(palette.secondary.light, 0.18)} 24px)`,
            transition: 'all 2.5s',
            '&:hover, &:focus': {
                transition: 'all 1.2s',
                backgroundColor: `${palette.secondary.main}`,
                backgroundPosition: '20% 0',
                textShadow: `0 0 10px ${changeColorAlpha(
                    palette.common.white,
                    0.3,
                )}`,
            },

            '@media (max-width: 600px)': {
                position: 'fixed',
                height: 48,
                fontSize: props => (props.isBitEnabled ? 24 : 28),
                width: '100%',
                left: 0,
                bottom: 0,
                zIndex: zIndex.modal + ' !important',
            },

            'html[lang=ru] &': {
                fontSize: 26,
            },
        },
        buttonTextX1: {
            fontSize: props => (props.isBitEnabled ? 23 : 29),
        },
        disabled: {
            background: 'none',
            backgroundColor: palette.grey[300],
            pointerEvents: 'none',
        },
        bitButton: {
            display: 'flex',
            alignItems: 'center',
            background: `linear-gradient(180deg, ${palette.brands.bit.primary}, ${palette.brands.bit.secondary})`,
            borderRadius: 35,
            height: props => (props.multiplier === 1 ? 25 : 30),
            width: props => (props.multiplier === 1 ? '58%' : '75%'),
            bottom: '7%',
            position: 'absolute',

            '@media (max-width: 600px)': {
                position: 'fixed',
                height: '35px !important',
                fontSize: '1.375rem',
                width: '60% !important',
                bottom: '8% !important',
                left: 'auto !important',
            },
            '@media (max-height: 600px)': {
                bottom: '10% !important',
            },
        },
    };
});

function DonateButton(props) {
    const {
        children,
        className,
        multiplier,
        disabled,
        isBitEnabled,
        onClick,
        ...rest
    } = props;
    const classes = useStyles({ isBitEnabled, multiplier });

    const mergedClassNames = classNames(
        classes.donateButton,
        multiplier === 1 ? classes.buttonTextX1 : '',
        disabled ? classes.disabled : '',
        className,
    );

    const handleClick = (paymentMethod = 'card') => {
        if (typeof onClick === 'function') {
            onClick(paymentMethod);
        }
    };

    return (
        <div className={classes.root}>
            <Button
                {...rest}
                disabled={disabled}
                onClick={() => handleClick('card')}
                className={mergedClassNames}
            >
                {children}
            </Button>
            {isBitEnabled && !disabled && (
                <Button
                    className={classes.bitButton}
                    onClick={() => handleClick('bit')}
                >
                    <BitLogo checked />
                </Button>
            )}
        </div>
    );
}

DonateButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    multiplier: PropTypes.number,
    disabled: PropTypes.bool,
    isBitEnabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default DonateButton;
