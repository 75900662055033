import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import { useSelect } from 'react-select-search';
import { ReactComponent as ArrowIcon } from './ArrowIcon.svg';
import Text from '../Text/Text';
import Checkbox from 'client/components/CampaignPage/components/Checkbox/Checkbox';
import classNames from 'classnames';
import { changeColorAlpha } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        margin: '30px 0',
        fontFamily: 'Heebo, sans-serif',
        background: `${changeColorAlpha(palette.primary.light, 0.1)}`,
        padding: '28px 23px',
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 23,
    },
    label: {
        fontSize: '0.938rem',
        color: palette.primary.main,
        display: 'flex',

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
        '& svg': {
            top: 3,
            left: 1,
            width: 15,
            height: 15,
            background: palette.primary.main,

            'html[dir=rtl] &': {
                left: 'auto',
                right: 1,
            },
        },
        '& svg path': {
            stroke: `${palette.common.white} !important`,
        },
        '& span': {
            '&::before': {
                top: 2,
                border: `1px solid ${palette.primary.main}`,
            },
            '& div': {
                color: palette.primary.main,
                fontWeight: 'bold',
            },
        },
    },
    bottom: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '0.938rem',
        lineHeight: '22px',
        marginBottom: 25,

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
            lineHeight: '19px',
        },
    },
    info: {
        color: palette.grey[600],
        '& span': {
            color: palette.primary.main,
        },
    },
    amount: {
        minWidth: 165,
        flexShrink: 0,
        fontWeight: 500,
        color: palette.primary.main,
        textAlign: 'right',
    },
    select: {
        position: 'relative',
        display: 'inline-block',
        minWidth: '160px',
        maxWidth: '160px',
        flexShrink: 0,
        marginInlineStart: '20px',
    },
    input: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        background: palette.grey[50],
        border: `2px solid ${palette.primary.main}`,
        paddingTop: 6,
        paddingBottom: 6,
        paddingInlineStart: '20px',
        paddingInlineEnd: '45px',
        margin: 0,
        borderRadius: 999,
        whiteSpace: 'nowrap',
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1rem',
        lineHeight: '18px',
        fontWeight: 'bold',
        color: palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
    },
    arrow: {
        position: 'absolute',
        right: 14,
        top: 8,
        pointerEvents: 'none',

        'html[dir=rtl] &': {
            right: 'auto',
            left: 14,
        },
        '& path': {
            stroke: palette.primary.main,
        },
    },
    opened: {
        transform: 'rotate(180deg)',
    },
    options: {
        position: 'absolute',
        top: '100%',
        left: 0,
        minWidth: '100%',
        background: palette.background.default,
        listStyle: 'none',
        padding: '5px 0',
        margin: 0,
        boxShadow: '0px 0px 10px #8B8B8B29',
        borderRadius: 5,

        'html[dir=rtl] &': {
            left: 'auto',
            right: 0,
        },
    },
    option: {
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            width: 11,
            height: 11,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: 2,
            background: palette.background.default,
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                left: 'auto',
                right: 10,
            },
        },
        '&::after': {
            content: '""',
            display: 'none',
            width: 4,
            height: 4,
            borderStyle: 'solid',
            borderWidth: '1px 1px 0 0',
            borderColor: palette.primary.main,
            transform: 'scale(1, 1.5) translateY(-50%) rotate(135deg)',
            position: 'absolute',
            left: 14,
            top: 'calc(50% - 1px)',
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                left: 'auto',
                right: 14,
            },
        },
    },
    optionBtn: {
        background: 'none',
        display: 'block',
        width: '100%',
        border: 0,
        padding: 0,
        paddingInlineStart: '20px',
        margin: 0,
        textAlign: 'left',
        fontSize: '0.938rem',
        color: palette.grey[600],
    },
    selected: {
        '& button': {
            color: palette.primary.main,
        },
        '&::before': {
            borderColor: palette.primary.main,
        },
        '&::after': {
            display: 'block',
        },
    },
    otherBtn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.938rem',
        color: palette.grey[600],
        padding: 0,
        paddingInlineStart: '20px',
    },
    otherSelected: {
        '& div': {
            color: palette.primary.main,
        },
        '& input': {
            color: palette.primary.main,
            borderBottomColor: palette.primary.main,
        },
    },
    otherInput: {
        border: 0,
        borderBottom: `1px solid ${palette.grey[500]}`,
        margin: '0 5px',
        padding: '2px 5px',
        color: palette.grey[600],
        fontSize: '0.938rem',
    },
    tipInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '& p': {
            color: palette.grey[600],
            fontSize: '1rem',
            lineHeight: '19px',
        },
        '& span': {
            color: palette.primary.main,
            fontSize: '1.188rem',
            flexShrink: 0,
            fontFamily: 'Heebo, sans-serif',
            fontWeight: 500,
            marginLeft: 'auto',
        },
    },
}));

function CustomSelect(props) {
    const {
        amount,
        currencySign = '$',
        onChange,
        options,
        tipSelected,
        value,
    } = props;
    const classes = useStyles();
    const otherMin = 0;
    const otherMax = 999;

    const [opened, setOpened] = useState(false);
    const [isOtherSelected, setOtherSelected] = useState(
        !options.find(option => option.value == value),
    );
    const [otherValue, setOtherValue] = useState(value || 0);

    const [snapshot, valueProps, optionProps] = useSelect({
        options,
        value: String(value),
        onChange,
    });

    const optionClick = event => {
        event.preventDefault();
        setOtherSelected(false);
        setOpened(false);
    };

    const otherClick = event => {
        event.preventDefault();
        setOtherSelected(true);
        setOpened(false);
        onChange(otherValue);
    };
    const selectClick = event => {
        event.preventDefault();
        setOpened(!opened);
    };
    const otherChange = event => {
        let tipPercent = event.target?.value;
        if (!/^\d*\.*\d{0,2}$/.test(tipPercent)) {
            return;
        }
        if (tipPercent) {
            tipPercent = Math.round(Number(tipPercent) * 100) / 100;
        }
        if (tipPercent < otherMin) {
            tipPercent = otherMin;
        } else if (tipPercent > otherMax) {
            tipPercent = otherMax;
        }
        setOtherValue(tipPercent);
        if (isOtherSelected) {
            onChange(tipPercent);
        }
    };
    const otherKeyDown = event => {
        if (event.keyCode === 13 || event.keyCode === 27) {
            setOpened(false);
        }
    };
    const getDisplayValue = () => {
        const tip = value || 0;
        return `${tip}% (${currencySign}${((amount * tip) / 100).toFixed(2)})`;
    };

    useEffect(() => {
        setOpened(tipSelected);
    }, [tipSelected]);

    return (
        <ClickAwayListener onClickAway={() => setOpened(false)}>
            <div className={classes.select}>
                <button
                    className={classes.input}
                    {...valueProps}
                    onClick={selectClick}
                >
                    {getDisplayValue()}
                </button>
                <ArrowIcon
                    className={classNames(classes.arrow, {
                        [classes.opened]: opened,
                    })}
                />
                {opened && (
                    <ul className={classes.options}>
                        {snapshot.options.map(option => {
                            return (
                                <li
                                    className={classNames(classes.option, {
                                        [classes.selected]:
                                            !isOtherSelected &&
                                            option.value ===
                                                snapshot?.value?.value,
                                    })}
                                    key={option.value}
                                >
                                    <button
                                        {...optionProps}
                                        onClick={optionClick}
                                        value={option.value}
                                        className={classes.optionBtn}
                                    >
                                        {option.name}
                                    </button>
                                </li>
                            );
                        })}
                        <li
                            className={classNames(classes.option, {
                                [classes.selected]: isOtherSelected,
                                [classes.otherSelected]: isOtherSelected,
                            })}
                        >
                            <button
                                {...optionProps}
                                onClick={otherClick}
                                value={otherValue}
                                className={classes.optionBtn}
                            >
                                Other
                            </button>
                            <div className={classes.otherBtn}>
                                <input
                                    type="number"
                                    className={classes.otherInput}
                                    min={otherMin}
                                    max={otherMax}
                                    value={otherValue}
                                    onChange={otherChange}
                                    onKeyDown={otherKeyDown}
                                />
                                <span>%</span>
                            </div>
                        </li>
                    </ul>
                )}
            </div>
        </ClickAwayListener>
    );
}

function TipWidget(props) {
    const {
        amount = 0,
        className,
        currencySign = '$',
        defaultTipPercent = 0,
        onTipPercentChange,
        percents = [],
        tipPercent = 0,
        tippingCheckoutText = '',
        tippingThankYouText = '',
    } = props;
    const classes = useStyles();
    const [tipSelected, setTipSelected] = useState(false);
    const options = useMemo(
        () =>
            [...percents]
                .sort((p, c) => p - c)
                .map(percent => {
                    const percentTip = (amount * percent) / 100;
                    return {
                        name: `${percent}% (${currencySign}${percentTip.toFixed(
                            2,
                        )})`,
                        value: String(percent),
                    };
                }),
        [amount, percents, currencySign],
    );

    const handleChange = value => {
        if (typeof onTipPercentChange === 'function') {
            onTipPercentChange(value);
        }
    };

    const handleCheckboxChecked = evt => {
        if (evt.target.checked) {
            let newPercent = defaultTipPercent;
            if (defaultTipPercent) {
                newPercent = defaultTipPercent;
            } else if (percents.length > 0 && percents[0]) {
                newPercent = percents[0];
            } else if (percents.length > 1 && percents[1]) {
                newPercent = percents[1];
            } else {
                newPercent = 1; //collect 1%
            }
            onTipPercentChange(newPercent);
            setTipSelected(true);
        } else {
            onTipPercentChange(0);
            setTipSelected(false);
        }
    };

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.top}>
                <div className={classes.label}>
                    <Checkbox
                        checked={!!tipPercent}
                        type="checkbox"
                        onChange={handleCheckboxChecked}
                    >
                        <Text html style={{ marginTop: -16 }}>
                            {tippingThankYouText}
                        </Text>
                    </Checkbox>
                </div>
                {!isNaN(tipPercent) && ( //do not remove this check isNaN. It makes sure correct checkbox is checked while loading dropdown for first time.
                    <CustomSelect
                        options={options}
                        tipSelected={tipSelected}
                        onChange={handleChange}
                        value={String(tipPercent)}
                        amount={amount}
                        currencySign={currencySign}
                    />
                )}
            </div>

            {tippingCheckoutText && (
                <div className={classes.tipInfo}>
                    <Text html className={classes.info}>
                        {tippingCheckoutText}
                    </Text>
                </div>
            )}
        </div>
    );
}

TipWidget.propTypes = {
    amount: PropTypes.number,
    className: PropTypes.string,
    currencySign: PropTypes.string,
    defaultTipPercent: PropTypes.number,
    fee: PropTypes.number,
    layersCount: PropTypes.number,
    onTipPercentChange: PropTypes.func,
    percents: PropTypes.array,
    tipPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tippingCheckoutText: PropTypes.string,
    tippingThankYouText: PropTypes.string,
    tipSelected: PropTypes.bool,
};

CustomSelect.propTypes = {
    amount: PropTypes.number,
    currencySign: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    tipSelected: PropTypes.bool,
    value: PropTypes.string,
};

export default TipWidget;
