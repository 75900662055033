import https from 'https';
import axios from 'axios';
import camelize from 'camelize';
import { get, once } from 'lodash';
import { getTrackId, TRACK_ID_HEADER_NAME } from 'common/helpers/trackId';
import config from 'client/config';
import parseErrorMessage from './parseErrorMessage';

export const setup = (instance = axios) => {
    instance.defaults.baseURL = config.apiUrl;
    instance.defaults.headers.common['Accept'] = 'application/json';
    instance.defaults.headers.common[TRACK_ID_HEADER_NAME] = getTrackId();
    instance.defaults.httpsAgent = https.Agent({
        rejectUnauthorized: false,
    });
    instance.interceptors.response.use(
        response => {
            if (!response.config.url.startsWith('/translation')) {
                response.data = camelize(response.data);
            }
            return response;
        },
        async error => {
            const statusCode = error?.response?.status;
            const requestUrl = error?.response?.config?.url;
            if (statusCode === 401) {
                await handleUnauthenticatedError(requestUrl);
            }
            return Promise.reject(error);
        },
    );

    return instance;
};

export const create = ({ baseUrl: baseURL = null, ...defaults }) => {
    const instance = setup(axios.create(), defaults);

    if (baseURL) {
        instance.defaults.baseURL = `${instance.defaults.baseURL}${baseURL}`;
    }
    instance.defaults = { ...instance.defaults, ...defaults };

    return instance;
};

export const getErrorResponse = error => get(error, 'response.data', error);

export const handleResponse = response => response.data;

export const handleError = error => {
    throw new Error(parseErrorMessage(error));
};

const loggedOutAlert = once(() => alert('You have been logged out'));

const handleUnauthenticatedError = async requestUrl => {
    const isLoggedIn = await isUserSessionValid(requestUrl);
    if (!isLoggedIn) {
        loggedOutAlert();
        window.location.href = '/login';
    }
};

const isUserSessionValid = async requestedURL => {
    const whoamiURL = '/user/whoami';
    // If whoami request failed with 401, there is no need to call it again
    if (requestedURL === whoamiURL) {
        return false;
    }
    try {
        await axios(whoamiURL);
    } catch (error) {
        if (error?.response?.status === 401) {
            return false;
        }
    }
    return true;
};
