import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        padding: '8px 13px',
        background: palette.background.default,
        boxShadow: `0 3px 15px ${changeColorAlpha(palette.text.primary, 0.1)}`,
        borderRadius: 3,
    },
}));

function DonationTabs(props) {
    const { children, className, ...rest } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)} {...rest}>
            {children}
        </div>
    );
}

DonationTabs.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default DonationTabs;
