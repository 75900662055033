import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Flag from 'react-world-flags';
import { makeStyles } from '@material-ui/core/styles';
import { FALLBACK_COUNTRIES } from 'common/constants';
import { normalizePhonePrefix } from 'client/helpers/phone';

const EMPTY_SELECTION = { label: '', phonePrefix: null, flagCode: '' };

const useStyles = makeStyles(theme => ({
    option: {
        width: '20rem',
    },
    flagAdornment: {
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
        },
    },
}));

function CountrySelector(props) {
    const classes = useStyles();

    const {
        input,
        clientCountryCode = null,
        countries: countriesOptions,
        textFieldProps,
        showSelectedCountryFlag = true,
        inputRootClassname,
        ...restProps
    } = props;
    const { value, onChange: onChangeProp, ref, ...restInput } = input;

    const countries = [EMPTY_SELECTION, ...countriesOptions];

    const selectedCountry =
        countries.find(country => country.flagCode === value) || countries[0];

    const onChange = (_target, newValue) => {
        if (!newValue) {
            onChangeProp(EMPTY_SELECTION);
        } else {
            onChangeProp(newValue);
        }
    };

    const optionsFilter = (options, selection) => {
        const inputQuery = selection.inputValue
            .toLowerCase()
            .replace(/\+/g, '');
        return options
            .filter(option => option.label.length)
            .filter(
                option =>
                    option.label.toLowerCase().startsWith(inputQuery) ||
                    option.phonePrefix
                        .replace(/\+/g, '')
                        .startsWith(inputQuery),
            );
    };

    const renderInput = params => {
        const isCountrySelected =
            selectedCountry.flagCode === params.inputProps.value &&
            selectedCountry.phonePrefix !== null;

        const displayValue = isCountrySelected
            ? selectedCountry.phonePrefix
            : params.inputProps.value;

        if (selectedCountry && isCountrySelected && params.InputProps) {
            params.InputProps.startAdornment = (
                <InputAdornment className={classes.flagAdornment}>
                    {showSelectedCountryFlag && (
                        <Flag code={selectedCountry.flagCode} width={21} />
                    )}
                </InputAdornment>
            );
        }

        const resolveCountry = matchedCountries => {
            if (matchedCountries.length === 0) {
                return null;
            }

            if (matchedCountries.length === 1) {
                return matchedCountries[0];
            }

            const phonePrefix = matchedCountries[0].phonePrefix;
            const fallbackCountryCode =
                clientCountryCode ?? FALLBACK_COUNTRIES[phonePrefix];
            const country = matchedCountries.find(
                c => (c.flagCode = fallbackCountryCode),
            );

            return country ?? matchedCountries[0];
        };

        const onInputBlur = event => {
            const textInputPrefix = normalizePhonePrefix(
                event.target.value || '',
            );

            const matchedCountries = countries.filter(
                c => c.phonePrefix === textInputPrefix,
            );

            // If input prefix was entered manually (or by autocomplete),
            // we need to synchronize with options available in the drop-down
            if (
                matchedCountries.length > 0 &&
                selectedCountry.phonePrefix !== textInputPrefix
            ) {
                onChangeProp(resolveCountry(matchedCountries));
            }
            params.inputProps.onBlur(event);
        };

        const tooltipTitle =
            showSelectedCountryFlag && selectedCountry
                ? selectedCountry.label
                : '';

        return (
            <Tooltip title={tooltipTitle}>
                <TextField
                    dir="ltr"
                    name="countryCode"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        className: `${params.InputProps.className} ${
                            inputRootClassname ? inputRootClassname : ''
                        }`,
                    }}
                    variant="outlined"
                    {...textFieldProps}
                    inputProps={{
                        ...params.inputProps,
                        value: displayValue,
                        autoComplete: 'tel-country-code',
                        onBlur: onInputBlur,
                    }}
                    inputRef={ref}
                />
            </Tooltip>
        );
    };

    const renderOption = option => (
        <Box
            component="div"
            sx={{ '& > svg': { mr: 2, flexShrink: 0 } }}
            className={classes.option}
        >
            <Flag code={option.flagCode} width="21" />
            <span style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                {option.label} ({option.flagCode}) {option.phonePrefix}
            </span>
        </Box>
    );

    const CountrySelectorPopper = props => (
        <div className={'customPopper'}>
            <Popper
                disablePortal={true}
                {...props}
                style={{ width: '25rem' }}
            />
        </div>
    );

    return (
        <Autocomplete
            sx={{ width: 300 }}
            options={countries}
            autoHighlight
            getOptionLabel={option => option.flagCode}
            PopperComponent={CountrySelectorPopper}
            filterOptions={optionsFilter}
            renderOption={renderOption}
            renderInput={renderInput}
            {...restInput}
            {...restProps}
            value={selectedCountry}
            onChange={onChange}
        />
    );
}

CountrySelector.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        value: PropTypes.string,
        ref: PropTypes.node,
    }),
    inputRootClassname: PropTypes.string,
    textFieldProps: PropTypes.object,
    showSelectedCountryFlag: PropTypes.bool,
    clientCountryCode: PropTypes.string,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            flagCode: PropTypes.string,
            phonePrefix: PropTypes.string,
        }),
    ),
};

export default CountrySelector;
