const get = require('lodash/get');
const { FINANCIALS_DEFAULT_TIPPING_TEXTS } = require('../constants/financials');

function translateTippingText(defaultLanguage, targetLanguage, id, message) {
    if (defaultLanguage === targetLanguage || !targetLanguage) {
        return message;
    }

    const rawMessage = message.replace(/^\s*<p>\s*|\s*<\/p>\s*$/g, '');
    const defaultLanguageText = get(
        FINANCIALS_DEFAULT_TIPPING_TEXTS,
        `${defaultLanguage}.${id}`,
        '',
    );
    const translatedText = get(
        FINANCIALS_DEFAULT_TIPPING_TEXTS,
        `${targetLanguage}.${id}`,
        '',
    );

    // we only want to translate if the message is still the default text
    if (rawMessage === defaultLanguageText && translatedText) {
        return translatedText;
    }

    // if message was customized, use the customized message
    return message;
}

module.exports = {
    translateTippingText,
};
