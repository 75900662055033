import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MakeDonationInput from '../MakeDonationInput/MakeDonationInput';
import MultiplierIcon from '../MultiplierIcon/MultiplierIcon';
import { useIntl } from 'react-intl';
import Text from '../Text/Text';
import { currencyLabel } from 'common/helpers';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            padding: '20px 40px',
            textAlign: 'center',
            background: palette.grey[100],
            borderRadius: 3,

            '@media (max-width: 600px)': {
                padding: '10px 15px',
            },
        },

        content: {
            display: 'inline-flex',
            marginTop: 18,
            alignItems: 'center',
            fontSize: '1rem',
            color: palette.primary.main,
            fontFamily: 'Heebo, sans-serif',

            '&:empty': {
                display: 'none',
            },
            '& svg': {
                marginInlineEnd: '19px',

                '& g': {
                    direction: 'ltr',
                },
            },
            '@media (max-width: 600px)': {
                marginTop: 15,
                fontSize: 14,

                '& svg': {
                    marginInlineEnd: '12px',
                    width: 28,
                },
            },
        },

        donationText: {
            display: 'inline-flex',
            alignItems: 'center',
            marginTop: spacing(2),
            width: '85%',
            wordBreak: 'break-word',

            '& *': {
                lineHeight: 1.25,
            },
            '& p': {
                paddingBottom: spacing(1),
            },
        },
    }),
    { name: 'MakeDonationWidget' },
);

function MakeDonationWidget(props) {
    const {
        className,
        currencies,
        onAmountChange,
        onCurrencyChange,
        amount,
        currency,
        multiplier = 1,
        multiplierText = '',
        showDonationText = false,
        donationText = '',
        disabled = false,
    } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { palette } = useTheme();
    const getText = useCallback(() => {
        if (multiplierText) {
            return multiplierText;
        }

        switch (multiplier) {
            case 1:
                return null;
            case 2:
                return intl.formatMessage({
                    id: 'Matching.double',
                    defaultMessage:
                        'Matching Donors Will Double Your Donation!',
                });
            case 3:
                return intl.formatMessage({
                    id: 'Matching.triple',
                    defaultMessage:
                        'Matching Donors Will Triple Your Donation!',
                });
            case 4:
                return intl.formatMessage({
                    id: 'Matching.quadruple',
                    defaultMessage:
                        'Matching Donors Will Quadruple Your Donation!',
                });
            default:
                return intl.formatMessage({
                    id: 'Matching.multiply',
                    defaultMessage:
                        'Matching Donors Will Multiply Your Donation!',
                });
        }
    }, [multiplier, multiplierText, intl]);

    return (
        <div className={classNames(classes.root, className)}>
            <MakeDonationInput
                options={currencies.map(c => ({
                    name: currencyLabel(c.name),
                    value: c.value,
                }))}
                placeholder={intl.formatMessage({
                    id: 'EnterAmountInput.placeholder',
                    defaultMessage: 'Enter Amount...',
                })}
                onChange={onAmountChange}
                value={amount || ''}
                selectProps={{
                    onChange: onCurrencyChange,
                    value: currency || '',
                }}
                pattern="[0-9]+$"
                disabled={disabled}
            />
            {multiplier > 1 && (
                <div className={classes.content}>
                    {multiplier && (
                        <MultiplierIcon
                            fill={palette.primary.main}
                            multiplier={multiplier}
                        />
                    )}
                    {getText()}
                </div>
            )}
            {!!donationText && showDonationText && (
                <Text html className={classes.donationText}>
                    {donationText}
                </Text>
            )}
        </div>
    );
}

MakeDonationWidget.propTypes = {
    onAmountChange: PropTypes.func.isRequired,
    onCurrencyChange: PropTypes.func.isRequired,
    currencies: PropTypes.array.isRequired,
    multiplier: PropTypes.number,
    multiplierText: PropTypes.string,
    className: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    donationText: PropTypes.string,
    showDonationText: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default MakeDonationWidget;
