import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        margin: props => props.m,
        fontFamily: 'Heebo, sans-serif',
        fontSize: 16,
        color: '#808080',
    },
});

function FormLabel(props) {
    const { children, className, m = 0 } = props;
    const classes = useStyles({ m });

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            {children}
        </div>
    );
}

FormLabel.propTypes = {
    children: PropTypes.node,
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
};

export default FormLabel;
