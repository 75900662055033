import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '../Avatar/Avatar';
import Progress from '../Progress/Progress';
import { FormattedMessage } from 'react-intl';
import Text from '../Text/Text';
import { currencySign } from 'common/helpers';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        background: ({ tiled }) =>
            tiled ? palette.grey[100] : palette.common.white,
        border: `1.5px solid ${palette.grey[200]}`,
        cursor: ({ tiled }) => (tiled ? 'pointer' : 'default'),
        position: 'relative',

        '&::after': {
            content: '""',
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: '-1px 2px 8px rgb(0, 0, 0, 0.1)',
            opacity: 0,
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '1.1s',
        },
        '&:hover::after': {
            transitionDuration: ' 0.4s',
            opacity: 1,
        },
    },
    content: {
        padding: ({ tiled }) => (tiled ? '11px 30px' : '25px 40px'),

        '@media (max-width: 600px)': {
            padding: '25px 25px 10px !important',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },
    avatar: {
        marginInlineEnd: '13px',
        flexShrink: 0,
        boxShadow: '0px 0px 15px -10px #000',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    name: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '1.25rem',
        lineHeight: 1.1,
        fontWeight: 600,

        '@media (max-width: 600px)': {
            fontSize: '1.063rem',
        },
    },
    description: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '0.875rem',
        lineHeight: 1,
        paddingTop: 4,
        marginBottom: 4,

        '@media (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    info: {
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    counts: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '0.125em',
        marginInlineStart: 'auto',
    },
    count: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        color: palette.primary.main,
        fontFamily: typography.fontFamily,
        fontSize: '0.938rem',
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    amounts: {
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        fontSize: '0.75rem',
        color: palette.text.primary,
        paddingTop: '0.125em',
        '& strong': {
            marginBottom: -2,
            marginInlineEnd: '6px',
            color: palette.primary.main,
            fontSize: '1.063rem',
            fontWeight: 'bold',
        },

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',

            '& strong': {
                fontSize: '0.938rem',
            },
        },
    },
    amountCustom: {
        marginBottom: 0,
        color: ({ hasEnteredBonusGoal }) =>
            hasEnteredBonusGoal ? palette.secondary.main : palette.primary.main,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        fontFamily: typography.fontFamily,
    },
    goalAmount: {
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        marginInlineStart: '3px',
        '@media (max-width: 600px)': {
            fontSize: 'inherit',
            marginTop: -2,
        },
    },
    headInner: {},
    banner: {
        position: 'relative',
        backgroundColor: palette.grey[100],
        width: '100%',

        '& img': {
            width: '100%',
            maxHeight: 144,
            objectFit: ({ blurredTile }) => (blurredTile ? 'contain' : 'cover'),
            '@media (max-width: 1023px)': {
                maxHeight: 110,
            },
            '@media (max-width: 820px)': {
                maxHeight: 92,
            },
        },
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        filter: 'blur(9px)',
        backgroundSize: '200%',
        backgroundPosition: '-50% -50%',
    },
    itemProgress: {
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '0.938rem',
        margin: '0 0.4em',
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
            marginBottom: -1,
        },
    },
}));

function ItemCard(props) {
    const {
        children,
        className,
        avatarSrc,
        name,
        description,
        donorsCount,
        teamsCount,
        amount,
        currency = 'USD',
        goalAmount = 0,
        isCustomTileEnabled = false,
        tile = null,
        onClick = null,
        blurredTile = false,
        bonusGoalAmount = 0,
        hasEnteredBonusGoal,
        baseGoalPercent = Math.round((amount / (goalAmount || 1)) * 100),
    } = props;
    const classes = useStyles({
        tiled: isCustomTileEnabled,
        blurredTile,
        hasEnteredBonusGoal,
    });

    const totalGoalAmount =
        Math.round(
            hasEnteredBonusGoal ? goalAmount + bonusGoalAmount : goalAmount,
        ) || 1;

    return (
        <div className={classNames(classes.root, className)} onClick={onClick}>
            {isCustomTileEnabled && Boolean(tile) && (
                <div className={classes.banner}>
                    {blurredTile && (
                        <div
                            className={classes.bg}
                            style={{ backgroundImage: `url(${tile})` }}
                        />
                    )}
                    <img src={tile} alt={name} />
                </div>
            )}
            <div className={classes.content}>
                <div className={classes.header}>
                    <Avatar size={84} className={classes.avatar}>
                        <img
                            src={
                                avatarSrc
                                    ? avatarSrc
                                    : 'https://via.placeholder.com/31x31'
                            }
                            alt=""
                        />
                    </Avatar>
                    <div className={classes.headInner}>
                        <div className={classes.name}>{name}</div>
                        {description && (
                            <div className={classes.description}>
                                {description}
                            </div>
                        )}
                    </div>
                </div>
                {goalAmount > 0 && !isNaN(amount) && (
                    <>
                        {hasEnteredBonusGoal ? (
                            <Progress
                                percent={(goalAmount * 100) / totalGoalAmount}
                                label={`${baseGoalPercent}%`}
                                bonusPercent={Math.round(
                                    (amount / totalGoalAmount) * 100,
                                )}
                                size={12}
                            />
                        ) : (
                            <Progress percent={baseGoalPercent} size={12} />
                        )}
                    </>
                )}
                <div className={classes.info}>
                    <div className={classes.amounts}>
                        {!isNaN(amount) ? (
                            <>
                                <Text className={classes.amountCustom}>
                                    {currencySign(currency)}
                                    {amount.toLocaleString('en-EN')}
                                </Text>
                                <div className={classes.itemProgress}>
                                    {goalAmount > 0 ? (
                                        <>
                                            <FormattedMessage id="Amount.outOf" />{' '}
                                            <span
                                                className={classes.goalAmount}
                                            >
                                                {currencySign(currency)}
                                                {hasEnteredBonusGoal ? (
                                                    <>
                                                        {(
                                                            goalAmount +
                                                            bonusGoalAmount
                                                        ).toLocaleString(
                                                            'en-EN',
                                                        )}{' '}
                                                        <FormattedMessage
                                                            id="DonationWidget.bonusTitle"
                                                            defaultMessage="Bonus Goal"
                                                        />
                                                    </>
                                                ) : (
                                                    goalAmount.toLocaleString(
                                                        'en-EN',
                                                    )
                                                )}
                                            </span>
                                        </>
                                    ) : (
                                        <span>
                                            <FormattedMessage
                                                id="Amount.raised"
                                                defaultMessage="Raised"
                                            />
                                        </span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <span>Analytics in progress...</span>
                        )}
                    </div>
                    <div className={classes.counts}>
                        {!isNaN(donorsCount) && amount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Donor"
                                    values={{ counter: donorsCount }}
                                />
                            </div>
                        )}
                        {!isCustomTileEnabled && teamsCount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Team"
                                    values={{ counter: teamsCount }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isCustomTileEnabled && children}
            </div>
        </div>
    );
}

ItemCard.propTypes = {
    children: PropTypes.node,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    donatePercent: PropTypes.number,
    donorsCount: PropTypes.number,
    teamsCount: PropTypes.number,
    amount: PropTypes.number,
    goalAmount: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
    tile: PropTypes.string,
    isCustomTileEnabled: PropTypes.bool,
    onClick: PropTypes.func,
    blurredTile: PropTypes.bool,
    bonusGoalAmount: PropTypes.number,
    baseGoalPercent: PropTypes.number,
    hasEnteredBonusGoal: PropTypes.bool,
};

export default ItemCard;
