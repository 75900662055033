import Repository from './Repository';
import axios from 'axios';
import parseErrorMessage from '../../helpers/parseErrorMessage';
import ClientError from 'client/helpers/clientError';

class LayerItemRepository extends Repository {
    async detachUser(layerItem, user) {
        return this.axios.delete(`/${layerItem.id}/user/${user.id}`);
    }

    async attachUser(layerItem, user) {
        return this.axios.post(`/${layerItem.id}/user/${user.id}`);
    }

    async findWithAnalytics(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios.get(
            `/analytics${queryString ? `?${queryString}` : ''}`,
        );
    }

    async findAllWithAnalytics(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios.get(
            `/all-analytics${queryString ? `?${queryString}` : ''}`,
        );
    }

    async findLayerItemsForSelection({ campaignId, layerIds }) {
        return this.axios
            .get(`/selection?campaignId=${campaignId}&layerIds=${layerIds}`)
            .then(response => response.data);
    }

    async getActiveLayerItems(queryJson) {
        if (typeof queryJson !== 'object') {
            queryJson = { campaignId: queryJson };
        }
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios
            .get('/active' + (queryString ? `?${queryString}` : ''))
            .then(response => response.data);
    }

    async findShortList(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return this.axios.get(
            `/short-list${queryString ? `?${queryString}` : ''}`,
        );
    }

    async findPublicLayerItems(queryJson) {
        const queryString = Repository.prepareQueryString(queryJson);
        return axios.get(
            `/public/layer-items${queryString ? `?${queryString}` : ''}`,
        );
    }

    async getPublicLayerItemParentsData(queryJson, layerItemId) {
        const queryString = Repository.prepareQueryString(queryJson);
        return axios.get(
            `/public/layeritem/${layerItemId}/parents${
                queryString ? `?${queryString}` : ''
            }`,
        );
    }

    async previewCalculatedGoals(layerItemId) {
        const { data } = await axios.get(
            `/layeritem-preview/goals/${layerItemId}`,
        );
        return data;
    }

    async importLayerItems({ files, data }) {
        const file = files[0] || null;
        const formData = new FormData();
        formData.append('file', file);

        if (data) {
            Object.keys(data).forEach(property => {
                formData.append(property, data[property]);
            });
        }
        const response = await axios.post(`/import/layer`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    }

    async sendImportMessage({
        batchId,
        templateData,
        transportType,
        useExistingBalance,
        duplicatePolicy,
    }) {
        try {
            const response = await this.axios.post(`/messaging/import-batch`, {
                importBatchId: batchId,
                templateData,
                transportType,
                useExistingBalance,
                duplicatePolicy,
            });
            return response;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new ClientError(message, error.response.data.errorData);
        }
    }

    async sendMessage({
        layerItemIds,
        templateData,
        transportType,
        useExistingBalance,
        duplicatePolicy,
    }) {
        try {
            return await this.axios.post(`/messaging/batch`, {
                layerItemIds,
                templateData,
                transportType,
                useExistingBalance,
                duplicatePolicy,
            });
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new ClientError(message, error.response.data.errorData);
        }
    }

    async delete(layerItemIds) {
        let response;

        if (Array.isArray(layerItemIds)) {
            response = this.axios.delete(`/`, { data: { layerItemIds } });
        } else {
            response = this.axios.delete(`/${layerItemIds}`);
        }

        return response
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    async validateSlug(campaignId, slug, layerItemId) {
        return this.axios(
            `/validate-slug/${campaignId}/${slug}${
                layerItemId ? `?layerItemId=${layerItemId}` : ''
            }`,
        )
            .then((...args) => this.handleResponse(...args))
            .catch((...args) => this.handleError(...args));
    }
}

export default new LayerItemRepository({ baseUrl: '/layeritem' });
