import { useEffect, useCallback } from 'react';

export const DEFAULT_ANIMATION_DURATION = 300;

function animate({ timing, draw, duration }) {
    let start = performance.now();

    requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;
        let progress = timing(timeFraction);
        draw(progress);
        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        }
    });
}

function quad(timeFraction) {
    return Math.pow(timeFraction, 5);
}

export function scrollTo(elementRef, duration) {
    const startScrollTop = elementRef.current.scrollTop;
    const endScrollTop =
        elementRef.current.scrollHeight - elementRef.current.clientHeight;
    const scrollDiff = endScrollTop - startScrollTop;

    animate({
        timing: quad,
        draw(progress) {
            elementRef.current.scroll(
                0,
                startScrollTop + scrollDiff * progress,
            );
        },
        duration,
    });
}

export function scrollWindowTo(
    position,
    duration = DEFAULT_ANIMATION_DURATION,
) {
    const distance = position - window.scrollY;

    animate({
        timing: quad,
        draw(progress) {
            window.scroll(0, window.scrollY + distance * progress);
        },
        duration,
    });
}

export function scrollToElement(
    element,
    duration = DEFAULT_ANIMATION_DURATION,
) {
    const rect = element.getBoundingClientRect();
    const startPosition = window.scrollY;
    const endPosition =
        window.scrollY + rect.y - window.innerHeight / 2 + rect.height / 2;
    const distance = endPosition - startPosition;

    animate({
        timing: quad,
        draw(progress) {
            window.scroll(0, startPosition + distance * progress);
        },
        duration,
    });
}

export default function useScrollTo(elementRef, options) {
    const {
        startScrollTop,
        duration = DEFAULT_ANIMATION_DURATION,
        saveScrollPosition,
    } = options;
    const scrollToPosition = useCallback(() => {
        if (elementRef.current) {
            scrollTo(elementRef, duration);
        }
    }, []);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollTop = startScrollTop;
        }
        return () => {
            if (elementRef.current) {
                saveScrollPosition(elementRef.current.scrollTop);
            }
        };
    }, []);

    return scrollToPosition;
}
