import { useEffect } from 'react';

function hasScrolledBy(element, percent) {
    const scrollRange = element.scrollHeight - element.clientHeight;
    const scrollRemaining = scrollRange - element.scrollTop;
    const scrollTarget = (scrollRange * (100 - percent)) / 100;
    return scrollRemaining <= scrollTarget;
}

function useAutoLoad(canAutoLoad, handleAutoLoad) {
    useEffect(() => {
        const handleScroll = e => {
            if (canAutoLoad && hasScrolledBy(e.target.documentElement, 70)) {
                handleAutoLoad();
            }
        };

        document.addEventListener('scroll', handleScroll, false);

        return () => {
            document.removeEventListener('scroll', handleScroll, false);
        };
    }, [canAutoLoad]);
}

export default useAutoLoad;
