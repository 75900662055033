import { useEffect } from 'react';

export default function useTimeOnPage(handler, enableTimer, idleTimerMin = 10) {
    let idleTimeout;

    function resetIdleTimer() {
        clearTimeout(idleTimeout);
        idleTimeout = setTimeout(handler, idleTimerMin * 60 * 1000);
    }

    useEffect(() => {
        if (enableTimer) {
            resetIdleTimer();
            window.addEventListener('keydown', resetIdleTimer);
        }

        return () => {
            clearTimeout(idleTimeout);
            window.removeEventListener('keydown', resetIdleTimer);
        };
    }, [enableTimer]);
}
