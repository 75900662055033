import { get } from 'lodash';

export const paymeValidator = (donationData, formatMessage) => {
    if (window.PayMe) {
        const validators = [
            'payerFirstName',
            'payerLastName',
            'payerEmail',
            'payerPhone',
        ];
        const errorMessages = {
            payerFirstName: formatMessage({
                id: 'paymeValidation.firstNameLetter',
                defaultMessage: 'FirstName should contain letters only',
            }),
            payerLastName: formatMessage({
                id: 'paymeValidation.lastNameLetter',
                defaultMessage: 'LastName should contain letters only',
            }),
            payerEmail: formatMessage({
                id: 'paymeValidation.email',
                defaultMessage: 'Email is not in the correct format',
            }),
            payerPhone: formatMessage({
                id: 'paymeValidation.phone',
                defaultMessage: 'Phone number is not in the correct format',
            }),
        };
        for (let field in validators) {
            let validationResult = window.PayMe.validators[
                validators[field]
            ].test(get(donationData, validators[field]));
            if (validationResult?.invalid || validationResult?.required) {
                const errorMessage = get(errorMessages, validators[field]);
                return errorMessage;
            }
        }
    }
};
